import { orderConstants } from '../_constants';
import { orderService } from '../_services';
import { alertActions } from './';
import { history } from '../_helpers';

export const orderActions = {
    getAll,
    getByClient,
    getById,
    getByIdNoToken,
    update,
    addItems,
    updateItemClient,
    deleteItemClient,
    addItemStore,
};

function getById(id, lasturl) {
    return dispatch => {
        dispatch(request());

        orderService.getById(id)
            .then(
                order => dispatch(success(order.data)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                    if (lasturl)
                        localStorage.setItem('lasturl', lasturl);
                    history.push('/login');
                }
            );
    };

    function request() { return { type: orderConstants.GETBYID_REQUEST } }
    function success(order) { return { type: orderConstants.GETBYID_SUCCESS, order } }
    function failure(error) { return { type: orderConstants.GETBYID_FAILURE, error } }
}

function getByIdNoToken(token, sessionId) {
    return dispatch => {
        dispatch(request());

        orderService.getByIdNoToken({ 'token': token, 'session_id': sessionId })
            .then(
                order => dispatch(success(order.data, order.metadata)),
                error => {
                    dispatch(failure(error.toString()));
                    history.push('/404');
                }
            );
    };

    function request() { return { type: orderConstants.GETBYID_REQUEST } }
    function success(order, metadata) { return { type: orderConstants.GETBYID_SUCCESS, order, metadata } }
    function failure(error) { return { type: orderConstants.GETBYID_FAILURE, error } }
}

function getByClient(client, lasturl) {
    return dispatch => {
        dispatch(request(client));

        orderService.getByClient(client)
            .then(
                clientOrders => dispatch(success(clientOrders.data)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                    if (lasturl)
                        localStorage.setItem('lasturl', lasturl);
                    history.push('/login');
                }
            );
    };

    function request() { return { type: orderConstants.GETBYCLIENT_REQUEST } }
    function success(clientOrders) { return { type: orderConstants.GETBYCLIENT_SUCCESS, clientOrders } }
    function failure(error) { return { type: orderConstants.GETBYCLIENT_FAILURE, error } }
}

function getAll(lasturl) {
    return dispatch => {
        dispatch(request());

        orderService.getAll()
            .then(
                orders => dispatch(success(orders.data)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                    if (lasturl)
                        localStorage.setItem('lasturl', lasturl);
                    history.push('/login');
                }
            );
    };

    function request() { return { type: orderConstants.GETALL_REQUEST } }
    function success(orders) { return { type: orderConstants.GETALL_SUCCESS, orders } }
    function failure(error) { return { type: orderConstants.GETALL_FAILURE, error } }
}

function update(order, message, lasturl) {
    return dispatch => {
        dispatch(request(order));

        orderService.update(order)
            .then(
                () => {
                    dispatch(success(order.data));
                    dispatch(getAll());
                    if (message) dispatch(alertActions.success(message))
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                    if (lasturl)
                        localStorage.setItem('lasturl', lasturl);
                    history.push('/login');
                }
            );
    };

    function request() { return { type: orderConstants.UPDATE_REQUEST } }
    function success(order) { return { type: orderConstants.UPDATE_SUCCESS, order } }
    function failure(error) { return { type: orderConstants.UPDATE_FAILURE, error } }
}

// async function detectEventIntent(
//     sessionId,
//     eventName
// ) {
//     // const { struct } = require('pb-util');

//     // Imports the Dialogflow library
//     const dialogflow = require('@google-cloud/dialogflow');

//     // Instantiates a session client
//     const sessionClient = new dialogflow.SessionsClient();

//     // The path to identify the agent that owns the created intent.
//     const sessionPath = sessionClient.projectAgentSessionPath(
//         process.env.REACT_APP_AGENT_ID,
//         sessionId
//     );

//     // The text query request.
//     const request = {
//         session: sessionPath,
//         queryInput: {
//             event: {
//                 name: eventName,
//                 arameters: {},
//                 // parameters: struct.encode({ foo: 'bar' }),
//                 languageCode: "en-US",
//             },
//         },
//     };

//     const [response] = await sessionClient.detectIntent(request);
//     console.log('Detected intent');
//     const result = response.queryResult;

//     console.log(`  Query: ${result.queryText}`);
//     console.log(`  Response: ${result.fulfillmentText}`);
//     if (result.intent) {
//         console.log(`  Intent: ${result.intent.displayName}`);
//     } else {
//         console.log('  No intent matched.');
//     }
// }

function addItems(data, sessionId, message) {
    return dispatch => {
        dispatch(request(data));

        orderService.addItems(data)
            .then(
                (orderId) => {
                    dispatch(success(orderId.data));
                    // detectEventIntent(sessionId, 'added-multiple-items');
                    if (message) dispatch(alertActions.success(message))
                },
                error => {
                    dispatch(alertActions.error(error.toString()))
                    dispatch(failure(error.toString()))
                }
            );
    };

    function request() { return { type: orderConstants.ADDITEMS_REQUEST } }
    function success(orderId) { return { type: orderConstants.ADDITEMS_SUCCESS, orderId } }
    function failure(error) { return { type: orderConstants.ADDITEMS_FAILURE, error } }
}

function addItemStore(data, orderId, message) {
    return dispatch => {
        dispatch(request());

        orderService.addItemStore(data, orderId)
            .then(
                (orderId) => {
                    dispatch(success(orderId.data));
                    // detectEventIntent(sessionId, 'added-multiple-items');
                    if (message) dispatch(alertActions.success(message))
                },
                error => {
                    dispatch(alertActions.error(error.toString()))
                    dispatch(failure(error.toString()))
                }
            );
    };

    function request() { return { type: orderConstants.ADDITEMS_REQUEST } }
    function success() { return { type: orderConstants.ADDITEMS_SUCCESS } }
    function failure(error) { return { type: orderConstants.ADDITEMS_FAILURE, error } }
}
function updateItemClient(data, orderId, itemId, message) {
    return dispatch => {
        dispatch(request());

        orderService.updateItemClient(data, orderId, itemId)
            .then(
                () => {
                    dispatch(success());
                    // detectEventIntent(sessionId, 'added-multiple-items');
                    if (message) dispatch(alertActions.success(message))
                },
                error => {
                    dispatch(alertActions.error(error.toString()))
                    dispatch(failure(error.toString()))
                }
            );
    };

    function request() { return { type: orderConstants.ADDITEMS_REQUEST } }
    function success() { return { type: orderConstants.ADDITEMS_SUCCESS } }
    function failure(error) { return { type: orderConstants.ADDITEMS_FAILURE, error } }
}
function deleteItemClient(data, orderId, itemId, message) {
    return dispatch => {
        dispatch(request());

        orderService.deleteItemClient(data, orderId, itemId)
            .then(
                () => {
                    dispatch(success());
                    // detectEventIntent(sessionId, 'added-multiple-items');
                    if (message) dispatch(alertActions.success(message))
                },
                error => {
                    dispatch(alertActions.error(error.toString()))
                    dispatch(failure(error.toString()))
                }
            );
    };

    function request() { return { type: orderConstants.ADDITEMS_REQUEST } }
    function success() { return { type: orderConstants.ADDITEMS_SUCCESS } }
    function failure(error) { return { type: orderConstants.ADDITEMS_FAILURE, error } }
}