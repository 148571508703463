import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { store } from './_helpers';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { ThemeProvider } from '@mui/material/styles';
import './index.css';
import theme from "./theme";

ReactDOM.render(
    <ThemeProvider theme={theme}>
        <MuiThemeProvider theme={theme}>
            <Provider store={store}>
                <App />
            </Provider>
        </MuiThemeProvider>,
    </ThemeProvider>,
document.getElementById('root')
);
serviceWorker.register();