import { authHeader } from '../_helpers';

export const clientService = {
    getById,
    getAll,
    setClientLog,
    setStore,
    setClientQR
};

const url = process.env.REACT_APP_API_URL;

async function setStore(sessionid, storeid) {
    const body = {
        session: sessionid,
        store: storeid
    };
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
    };
    const response = await fetch(`${url}/presetstore`, requestOptions);
    return handleResponse(response);
}

async function setClientLog(data) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };

    const response = await fetch(`${url}/client/log_activity`, requestOptions);
    return handleResponse(response);
}

async function setClientQR(data) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };

    const response = await fetch(`${url}/client/ref_code`, requestOptions);
    return handleResponse(response);
}

async function getById(id, userId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    const response = await fetch(`${url}/store/clients/${id}&${userId}`, requestOptions);
    return handleResponse(response);
}

async function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    }
    const response = await fetch(`${url}/store/clients/`, requestOptions);
    return handleResponse(response);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}