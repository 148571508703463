/* eslint-disable react/jsx-no-bind */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Appbar from '../_components/Appbar';
import withStyles from '@material-ui/core/styles/withStyles';
import { Tabs, Tab, AppBar, Typography, Grid, Link, TableCell, Tooltip } from '@material-ui/core';
import Payment from '@material-ui/icons/MonetizationOn';
import TagId from '@mui/icons-material/Tag';
import { userActions } from '../_actions';
// import { history } from '../_helpers';
import ResponsiveTable from '../_components/Table';
import TabPanel from '../_components/TabPanel';
import UserList from './UserList';
import Notifications from '../_components/Notifications';


// eslint-disable-next-line no-unused-vars
const styles = theme => ({
    tabpanelHeader: {
        'backgroundColor': '#025e9f',
        width: '100%',
        'text-align': 'center',
        'z-index': 0,
        height: '27px',
        paddingTop: '6px',
        'margin-top': '10px',
    },
    link: {
        color: '#124e5c',
        textDecoration: 'none',
        "&:hover": {
            cursor: 'pointer',
        },
    },
    link2: {
        color: '#ffed00',
        textDecoration: 'none',
        "&:hover": {
            cursor: 'pointer',
        },
    },
    icoRed: {
        'font-size': '20px',
        color: '#ff0000',
    },
    icoGreen: {
        'font-size': '20px',
        color: '#02ff00',
    },
    icoYellow: {
        'font-size': '20px',
        color: '#ffed00',
    },
    cellRowsIco: {
        borderBottom: '1px solid #b7b7b7',
        boxShadow: 'none',
        padding: '5px 7px 0px 5px',
        width: '50px',
        'text-align': 'end',
    },
    cellRows: {
        borderBottom: '1px solid #b7b7b7',
        boxShadow: 'none',
        padding: '1px 10px 0px 5px',
        'text-align': 'end',
    },
    tabmetric: {
        'backgroundColor': '#2876ad',
        'text-align': 'left',
        padding: '18px 15px 40px 30px',
        height: '215px',
    },
    inline: {
        display: 'inline-flex',
        'align-items': 'center',
    },
    container: {
        position: 'relative',
        width: '100%',
        overflow: 'hidden',
    },
    responsiveiframe: {
        // position: 'absolute',
        // top: '0',
        // left: '0',
        // bottom: '0',
        // right: '0',
        width: '100%',
        height: '250px',
        border: 'none',
    },
});

var update = 0;

class Home extends React.Component {
    static propTypes = {
        user: PropTypes.object,
        history: PropTypes.object,
        classes: PropTypes.object,
        dispatch: PropTypes.func,
        orders: PropTypes.object,
        clientList: PropTypes.array,
        setCurrentOrder: PropTypes.func,
        sessionId: PropTypes.string,
        setWidget: PropTypes.func,
        getData: PropTypes.func,
        syncAC: PropTypes.func,
    };

    state = {
        updated: false,
        expanded: false,
        acceptDialog: false,
        accept: true,
        dialogMessage: '',
        listWarning: [],
        listOk: [],
        listInfo: [],
        listError: [],
        value: 0,
        value2: 0,
    };


    onBack = event => {
        event.preventDefault();
        setTimeout(window.close, 600);
        setTimeout(window.stop, 500);
        window.open('', '_self').close();
    }

    onLogout = event => {
        event.preventDefault();
        this.props.cleanData(true);
        this.props.history.push('/login');
    }

    handleTableClick = (event) => {
        this.props.setCurrentOrder(event);
        this.props.history.push(`/orderdetail/${event.alphanum_id}`);
    }

    a11yProps = (index) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    a11yProps2 = (index) => {
        return {
            id: `simple-tab2-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    handleChange = (event, newValue) => {
        this.setState({ value: newValue });
    };
    handleChange2 = (event, newValue) => {
        this.setState({ value2: newValue });
    };

    renderPayment = (item, index) => {
        const stat = item.payment_status;
        let status = '';

        if (stat === 'pending') status = 'Pago no definido';
        if (stat === 'required') status = 'Pago solicitado';
        if (stat === 'processing') status = 'Pago en proceso';
        if (stat === 'done') status = 'Pago aprobado'

        return (<TableCell key={index} classes={{ root: this.props.classes.cellRowsIco }}>
            <Tooltip title={status} placement="right" arrow>
                <Payment
                    classes={
                        ((stat === 'done') ? { root: this.props.classes.icoGreen } :
                            (stat === 'required' || stat === 'processing') ? { root: this.props.classes.icoYellow } :
                                { root: this.props.classes.icoRed })
                    }
                />
            </Tooltip>
        </TableCell>)
    }

    renderId = (item, index) => {
        return (<TableCell key={index} classes={{ root: this.props.classes.cellRows }}>
            {(!item.original_id) &&
                item.alphanum_id
            }
            {(item.original_id) &&
                <Tooltip title={`original id importado de woocommerce: ${item.original_id}`} placement="right" arrow>
                    <div className={this.props.classes.inline}>
                        <Typography variant="body2" color="inherit">{item.alphanum_id}</Typography>
                        <TagId />
                    </div>
                </Tooltip>
            }
        </TableCell>)
    }

    // renderNotifications = () => {
    //     return (
    //         <Typography variant="h3" color="inherit">
    //             {this.state.notifications.map((notification, index) => {
    //                 return (
    //                     <Tooltip key={`notiff-id-${index}`} title={notification.desc} placement="right" arrow>
    //                         <div className={this.props.classes.inline + ' ' + this.props.classes.link2}>
    //                             <Anno classes={{ root: this.props.classes.icoYellow }}></Anno>
    //                             <Typography variant="body1" color="inherit"
    //                                 onClick={
    //                                     () => {
    //                                         this.props.history.push(notification.link);
    //                                     }
    //                                 }
    //                             >
    //                                 <b>{notification.title}</b>
    //                             </Typography>
    //                         </div>
    //                     </Tooltip>
    //                 )
    //             })}
    //         </Typography>
    //     )
    // }

    handleTableClientsClick = (event) => {
        this.props.setCurrentClient(event);
        this.props.history.push('/clientdetail');
    }

    checkFlags = () => {
        if (this.props.user && this.props.user.store && this.props.user.store.social_networks) {
            const { user, dispatch } = this.props;
            let social = user.store.social_networks;
            let save = false;

            if (!social.flags) {
                if (social) {
                    social['flags'] = {
                        store: false,
                        storeTS: "",
                        crew: false,
                        wsnumber: false,
                        products: false,
                        sales: false,
                        bot: false,
                        qrs: false,
                        qrsTS: "",
                        calendar: false,
                        calendarTS: false,
                        pms: false,
                        pmsTS: false,
                        faqs1: false,
                        faqs1TS: false,
                        faqs2: false,
                        faqs2TS: false,
                    };
                    save = true;
                }

                if (!social.flags.store) {
                    social.flags['store'] = false;
                    save = true;
                }

                if (!social.flags.storeTS) {
                    social.flags['storeTS'] = false;
                    save = true;
                }

                if (!social.flags.crew) {
                    social.flags['crew'] = false;
                    save = true;
                }

                if (!social.flags.wsnumber) {
                    social.flags['wsnumber'] = false;
                    save = true;
                }

                if (!social.flags.products) {
                    social.flags['products'] = false;
                    save = true;
                }

                if (!social.flags.sales) {
                    social.flags['sales'] = false;
                    save = true;
                }

                if (!social.flags.bot) {
                    social.flags['bot'] = false;
                    save = true;
                }

                if (!social.flags.qrs) {
                    social.flags['qrs'] = false;
                    save = true;
                }

                if (!social.flags.qrsTS) {
                    social.flags['qrsTS'] = false;
                    save = true;
                }

                if (!social.flags.calendar) {
                    social.flags['calendar'] = false;
                    save = true;
                }

                if (!social.flags.calendarTS) {
                    social.flags['calendarTS'] = false;
                    save = true;
                }

                if (!social.flags.pms) {
                    social.flags['pms'] = false;
                    save = true;
                }

                if (!social.flags.pmsTS) {
                    social.flags['pmsTS'] = false;
                    save = true;
                }

                if (!social.flags.faqs1) {
                    social.flags['faqs1'] = false;
                    save = true;
                }

                if (!social.flags.faqs1TS) {
                    social.flags['faqs1TS'] = false;
                    save = true;
                }

                if (!social.flags.faqs2) {
                    social.flags['faqs2'] = false;
                    save = true;
                }

                if (!social.flags.faqs2TS) {
                    social.flags['faqs2TS'] = false;
                    save = true;
                }

                const today = new Date();

                let storeTS = new Date(social.flags.storeTs);
                storeTS.setDate(storeTS.getDate() + 7);

                let qrsTS = new Date(social.flags.qrsTS);
                qrsTS.setDate(qrsTS.getDate() + 15);

                let calendarTS = new Date(social.flags.calendarTS);
                calendarTS.setDate(calendarTS.getDate() + 10);

                let pmsTS = new Date(social.flags.pmsTS);
                pmsTS.setDate(pmsTS.getDate() + 21);

                let faqs1TS = new Date(social.flags.faqs1TS);
                faqs1TS.setDate(faqs1TS.getDate() + 21);

                let faqs2TS = new Date(social.flags.faqs2TS);
                faqs2TS.setDate(faqs2TS.getDate() + 21);

                if (storeTS < today) {
                    social.flags.store = false;
                    save = true;
                }
                if (qrsTS < today) {
                    social.flags.qrs = false;
                    save = true;
                }
                if (calendarTS < today) {
                    social.flags.calendar = false;
                    save = true;
                }
                if (pmsTS < today) {
                    social.flags.pms = false;
                    save = true;
                }
                if (faqs1TS < today) {
                    social.flags.faqs1 = false;
                    save = true;
                }
                if (faqs2TS < today) {
                    social.flags.faqs2 = false;
                    save = true;
                }

                if (save) {
                    dispatch(userActions.setUserData(this.props.user.store.id, {
                        "social_networks": social,
                    }))
                    user.store.social_networks['flags'] = social['flags'];
                }
            }
        }
    }

    checkNotifications = (prevProps) => {
        if (prevProps) {
            if (this.props.entrantesList && (prevProps.entrantesList.length !== this.props.entrantesList.length))
                if (this.props.entrantesList.length > 0) {
                    let list = this.state.listInfo;
                    if (!list.find((notif) => notif.title === "Tienes nuevos pedidos"))
                        list.push({
                            title: 'Tienes nuevos pedidos',
                            desc: 'Tienes pedidos pendientes de confirmación',
                            link: '/orderlist?estado=Provisorio',
                            open: true
                        });
                    // eslint-disable-next-line react/no-did-update-set-state
                    this.setState({ listInfo: list });
                    update = update + 1;
                }
            if (this.props.adespacharList && (prevProps.adespacharList.length !== this.props.adespacharList.length))
                if (this.props.adespacharList.length > 0) {
                    let list = this.state.listWarning;
                    if (!list.find((notif) => notif.title === "Tienes pedidos a despachar"))
                        list.push({
                            title: 'Tienes pedidos a despachar',
                            desc: 'Tienes pedidos completados a despachar',
                            link: '/orderlist?estado=Entregar',
                            open: true
                        });
                    // eslint-disable-next-line react/no-did-update-set-state
                    this.setState({ listWarning: list });
                    update = update + 1;
                }
        }
        else {
            if (this.props.entrantesList.length > 0) {
                if ((this.props.entrantesList.find((notif) => notif.title !== "Tienes nuevos pedidos")))
                    this.state.listInfo.push({
                        title: 'Tienes nuevos pedidos',
                        desc: 'Tiene pedidos pendientes de confirmación',
                        link: '/orderlist?estado=Provisorio',
                        open: true
                    });
                update = update + 1;
            }

            if (this.props.adespacharList.length > 0) {
                if ((this.props.adespacharList.find((notif) => notif.title !== "Tienes pedidos a despachar")))
                    this.state.listWarning.push({
                        title: 'Tienes pedidos a despachar',
                        desc: 'Tienes pedidos completados a despachar',
                        link: '/orderlist?estado=Entregar',
                        open: true
                    });
                update = update + 1;
            }


            if (this.props.user && this.props.user.store && this.props.user.store.social_networks) {

                const flags = this.props.user.store.social_networks.flags;
                if (!flags.store) {
                    let lista2 = this.state.listError;
                    lista2.push({
                        title: `Por favor completa tu registro con la información básica que piden los clientes.`,
                        desc: 'Debe completar los datos para poder operar correctamente',
                        link: '/options',
                        open: true
                    });
                    // eslint-disable-next-line react/no-did-update-set-state, react/no-did-mount-set-state
                    this.setState({ listWarning: lista2 });
                    update = update + 1;
                }

                if (!flags.products) {
                    let lista2 = this.state.listError;
                    lista2.push({
                        title: 'Necesitas cargar productos para operar',
                        desc: 'Debe cargar los productos de su negocio',
                        link: '/options',
                        open: true
                    });
                    // eslint-disable-next-line react/no-did-update-set-state, react/no-did-mount-set-state
                    this.setState({ listWarning: lista2 });
                    update = update + 1;
                }

                if (!flags.qrs) {
                    let lista2 = this.state.listWarning;
                    lista2.push({
                        title: 'Es tiempo de actualizar tus acciones comerciales',
                        desc: 'Es tiempo de crear nuevos qrs',
                        link: '/options',
                        open: true
                    });
                    // eslint-disable-next-line react/no-did-update-set-state, react/no-did-mount-set-state
                    this.setState({ listWarning: lista2 });
                    update = update + 1;
                }

                if (!flags.calendar) {
                    let lista2 = this.state.listWarning;
                    lista2.push({
                        title: 'Es hora de renovar tus ofertas y mensajes promocionales',
                        desc: 'Es tiempo de actualizar el calendario',
                        link: '/options',
                        open: true
                    });
                    // eslint-disable-next-line react/no-did-update-set-state, react/no-did-mount-set-state
                    this.setState({ listWarning: lista2 });
                    update = update + 1;
                }

                if (!flags.pms) {
                    let lista2 = this.state.listWarning;
                    lista2.push({
                        title: '¿Qué tal ajustar el saludo y un mensaje para renovarse?',
                        desc: 'Es tiempo de actualizar los mensajes personalizados',
                        link: '/options',
                        open: true
                    });
                    // eslint-disable-next-line react/no-did-update-set-state, react/no-did-mount-set-state
                    this.setState({ listWarning: lista2 });
                    update = update + 1;
                }
                if (!flags.faqs1) {
                    let lista2 = this.state.listWarning;
                    lista2.push({
                        title: '¿Desea cargar una lista de respuestas frecuentes?',
                        desc: 'Es tiempo de actualizar las FAQS',
                        link: '/options',
                        open: true
                    });
                    // eslint-disable-next-line react/no-did-update-set-state, react/no-did-mount-set-state
                    this.setState({ listWarning: lista2 });
                    update = update + 1;
                }
            }
        }
    }

    render() {
        const { classes, user } = this.props;
        if (!user || !user.user) {
            this.props.history.push('/login');
            return null;
        }

        const { is_admin } = user;
        const { entrantesList, enprocesoList, adespacharList, entregadasList, numPedidos, amountPedidos, numEntregas,
            amountEntregas, entrantesAmount, enprocesoAmount, adespacharAmount, entregadasAmount, clientList,
            clientMetricActive30, clientMetricActiveM, clientMetricRergister30, clientMetricRergisterM } = this.props;
        const { value, value2 } = this.state;


        let titulo = "Arturito Online";
        if (user.userType === 'administrador') {
            titulo = 'Modo Administrador'
        }

        return (
            <React.Fragment>
                <Appbar menu={true} label={titulo} onBack={this.onBack} onLogout={this.onLogout} user={user} dispatch={this.props.dispatch}
                    update={() => (user.is_admin ? this.props.getData(['users']) : this.props.getData(['orders']))} />
                {(!is_admin) &&
                    <Grid container spacing={0}
                        direction="row"
                        alignItems="flex-start"
                    >
                        <Notifications
                            listWarning={this.state.listWarning}
                            listOk={this.state.listOk}
                            listInfo={this.state.listInfo}
                            listError={this.state.listError}
                            update={update}
                            history={this.props.history}
                        />
                        {(user.store && user.store.urls && user.store.urls.report_slim) &&
                            <div className={classes.container}>
                                <Typography variant="subtitle2" className={classes.tabpanelHeader}>
                                    INFORMES RESUMEN
                                </Typography>
                                <iframe height={230}
                                    title={'Dashboard'}
                                    src={this.props.user.store.urls.report_slim}
                                    loading="lazy"
                                    className={classes.responsiveiframe}
                                    sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
                                ></iframe>
                                {(user.store && user.store.report_general) &&
                                    <Link onClick={() => this.props.history.push('/dashboard')} className={classes.link}>
                                        <b>* para ver los informes completos presione aquí.</b>
                                    </Link>
                                }
                            </div>
                        }
                        <Typography variant="subtitle2" className={classes.tabpanelHeader}>
                            ACTIVIDAD DE CLIENTES
                        </Typography>
                        <Grid item xs={12} md={2} className={classes.tabmetric}>
                            <br />
                            <Typography variant="h3" color="inherit">
                                <b>Usuarios registrados</b>
                            </Typography>
                            <Typography variant="h4" color="inherit">
                                {`30 días : ${clientMetricRergister30}`}
                            </Typography>
                            <Typography variant="h4" color="inherit">
                                {`Mes actual : ${clientMetricRergisterM}`}
                            </Typography>
                            <br />
                            <Typography variant="h3" color="inherit">
                                <b>Usuarios activos</b>
                            </Typography>
                            <Typography variant="h4" color="inherit">
                                {`30 días : ${clientMetricActive30}`}
                            </Typography>
                            <Typography variant="h4" color="inherit">
                                {`Mes actual : ${clientMetricActiveM}`}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={10} >
                            <ResponsiveTable
                                headCells={[
                                    {
                                        id: 'email',
                                        label: 'EMAIL'
                                    },
                                    {
                                        id: 'first_name',
                                        label: 'NOMBRE'
                                    },
                                    {
                                        id: 'last_name',
                                        label: 'APELLIDO'
                                    },
                                    {
                                        id: 'phone',
                                        label: 'TELEFONO'
                                    },
                                    {
                                        id: 'last_seen',
                                        label: 'ULTIMO INGRESO',
                                        type: 'date'
                                    },
                                    {
                                        id: 'first_seen',
                                        label: 'REGISTRADO',
                                        type: 'date'
                                    },
                                ]}
                                rows={clientList}
                                handleSelectRow={this.handleTableClientsClick}
                            />
                            <Link onClick={() => this.props.history.push('/clientlist')} className={classes.link}>
                                <b>* listado parcial de clientes, para verlo completo presione aquí.</b>
                            </Link>
                        </Grid>
                    </Grid>
                }
                <br />
                {
                    (!is_admin) &&
                    <Grid container spacing={2}
                        direction="row"
                        alignItems="flex-start"
                    >
                        <Grid item xs={12} md={6} >
                            <Typography variant="subtitle2" className={classes.tabpanelHeader}>
                                PEDIDOS | CANTIDAD: {numPedidos} | Total: {amountPedidos}
                            </Typography>
                            <AppBar position="static" >
                                <Tabs value={value} onChange={this.handleChange} aria-label="">
                                    <Tab label={`ENTRANTES  ${entrantesAmount}`} {...this.a11yProps(0)} />
                                    <Tab label={`EN PROCESO  ${enprocesoAmount}`} {...this.a11yProps(1)} />
                                </Tabs>
                            </AppBar>
                            <TabPanel value={value} index={0}>
                                <ResponsiveTable
                                    headCells={[
                                        {
                                            id: 'created_at',
                                            label: 'FECHA',
                                            type: 'date'
                                        },
                                        {
                                            id: 'client',
                                            label: 'CLIENTE'
                                        },
                                        {
                                            id: 'alphanum_id',
                                            label: 'N PEDIDO',
                                            render: (item, index) => this.renderId(item, index + 2000)
                                        },
                                        {
                                            id: 'total_price',
                                            label: 'VALOR',
                                            type: 'number',
                                        },
                                        {
                                            id: 'payment',
                                            label: 'PAGO',
                                            noSortable: true,
                                            render: (item, index) => this.renderPayment(item, index + 1000)
                                        },
                                    ]}
                                    curr_symbol={this.props.user.store.curr_symbol}
                                    rows={entrantesList}
                                    handleSelectRow={this.handleTableClick}
                                />
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                <ResponsiveTable
                                    headCells={[
                                        {
                                            id: 'created_at',
                                            label: 'FECHA',
                                            type: 'date'
                                        },
                                        {
                                            id: 'client',
                                            label: 'CLIENTE'
                                        },
                                        {
                                            id: 'alphanum_id',
                                            label: 'N PEDIDO',
                                            render: (item, index) => this.renderId(item, index + 4000)
                                        },
                                        {
                                            id: 'total_price',
                                            label: 'VALOR',
                                            type: 'number',
                                        },
                                        {
                                            id: 'payment',
                                            label: 'PAGO',
                                            noSortable: true,
                                            render: (item, index) => this.renderPayment(item, index + 3000)
                                        },
                                    ]}
                                    curr_symbol={this.props.user.store.curr_symbol}
                                    rows={enprocesoList}
                                    handleSelectRow={this.handleTableClick}
                                />
                            </TabPanel>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="subtitle2" color="inherit" className={classes.tabpanelHeader}>
                                ENTREGAS | CANTIDAD: {numEntregas} | TOTAL: {amountEntregas}
                            </Typography>
                            <AppBar position="static">
                                <Tabs value={value2} onChange={this.handleChange2} aria-label="">
                                    <Tab label={`A DESPACHAR  ${adespacharAmount}`} {...this.a11yProps2(0)} />
                                    <Tab label={`ENTREGADAS  ${entregadasAmount}`} {...this.a11yProps2(1)} />
                                </Tabs>
                            </AppBar>
                            <TabPanel value={value2} index={0}>
                                <ResponsiveTable
                                    headCells={[
                                        {
                                            id: 'created_at',
                                            label: 'FECHA',
                                            type: 'date'
                                        },
                                        {
                                            id: 'client',
                                            label: 'CLIENTE'
                                        },
                                        {
                                            id: 'alphanum_id',
                                            label: 'N PEDIDO',
                                            render: (item, index) => this.renderId(item, index + 5000)
                                        },
                                        {
                                            id: 'total_price',
                                            label: 'VALOR',
                                            type: 'number',
                                        },
                                        {
                                            id: 'payment',
                                            label: 'PAGO',
                                            noSortable: true,
                                            render: (item, index) => this.renderPayment(item, index + 6000)
                                        },
                                    ]}
                                    curr_symbol={this.props.user.store.curr_symbol}
                                    rows={adespacharList}
                                    handleSelectRow={this.handleTableClick}
                                />
                            </TabPanel>
                            <TabPanel value={value2} index={1}>
                                <ResponsiveTable
                                    headCells={[
                                        {
                                            id: 'created_at',
                                            label: 'FECHA',
                                            type: 'date'
                                        },
                                        {
                                            id: 'client',
                                            label: 'CLIENTE'
                                        },
                                        {
                                            id: 'alphanum_id',
                                            label: 'N PEDIDO',
                                            render: (item, index) => this.renderId(item, index + 7000)
                                        },
                                        {
                                            id: 'total_price',
                                            label: 'VALOR',
                                            type: 'number',
                                        },
                                        {
                                            id: 'payment',
                                            label: 'PAGO',
                                            noSortable: true,
                                            render: (item, index) => this.renderPayment(item, index + 8000)
                                        },
                                    ]}
                                    curr_symbol={this.props.user.store.curr_symbol}
                                    rows={entregadasList}
                                    handleSelectRow={this.handleTableClick}
                                />
                            </TabPanel>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Link onClick={() => this.props.history.push('/orderlist')} className={classes.link}>
                                <b>* listado parcial de ordenes, para verlo completo presione aquí.</b>
                            </Link>
                        </Grid>
                    </Grid>
                }
                {
                    (is_admin) &&
                    <UserList syncAC={this.props.syncAC} />
                }
                <div ref={el => (this.instance = el)} />
            </React.Fragment >
        );
    }

    componentDidMount() {
        const { user } = this.props;


        if (!user || !this.props.setWidget) {
            console.log('Error user or session, redirecting')
            localStorage.setItem('lasturl', `/home`);
            this.props.history.push('/login');
            return null;
        }

        if (user.is_admin)
            this.props.getData(['users']);
        else if (this.props.orders && !this.props.orders.orders) {
            this.props.getData(['orders']);
        }

        if (window.location.href.startsWith('https://arturito.chat') || window.location.href.startsWith('https://arturito.store')) {
            //tracking script from ActiveCampaign
            const s = document.createElement('script');
            s.type = 'text/javascript';
            s.async = true;
            s.innerHTML = '(function(e,t,o,n,p,r,i){e.visitorGlobalObjectAlias=n;e[e.visitorGlobalObjectAlias]=e[e.visitorGlobalObjectAlias]||function(){(e[e.visitorGlobalObjectAlias].q=e[e.visitorGlobalObjectAlias].q||[]).push(arguments)};e[e.visitorGlobalObjectAlias].l=(new Date).getTime();r=t.createElement("script");r.src=o;r.async=true;i=t.getElementsByTagName("script")[0];i.parentNode.insertBefore(r,i)})(window,document,"https://diffuser-cdn.app-us1.com/diffuser/diffuser.js","vgo");vgo("setAccount", "476725427");vgo("setTrackByDefault", true);vgo("process");';
            this.instance.appendChild(s);
            console.log('AC --------- : step 2');

            //tracking script from GA
            if (typeof window.gtag !== 'undefined')
                window.gtag('config', 'G-5RJQ8E255C');
            console.log('GA --------- : SH');
            // window.gtag('config', 'G-Q64F793B3C');
        } else {
            console.log('AC --------- : off');
            console.log('GA --------- : off');
        }

        this.checkFlags();
        this.checkNotifications();

        this.props.setWidget(true, false);

    }

    componentDidUpdate(prevProps, prevState) {
        this.checkNotifications(prevProps);
    }

    componentWillUnmount() {
        localStorage.setItem('lasturl', `/home`);
    }
}

function mapStateToProps(state) {
    const { user } = state.authentication;
    const { orders, clients, products, users, csvstatus } = state;

    return {
        user,
        users,
        orders,
        clients,
        products,
        csvstatus
    };
}

export default connect(mapStateToProps)(withStyles(styles)(Home));