/* eslint-disable react/jsx-no-bind */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { userActions, alertActions } from '../_actions';
import Input from '../_components/Input';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';
import Appbar from '../_components/Appbar';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import ReCAPTCHA1 from "react-google-recaptcha";

// eslint-disable-next-line no-unused-vars
const styles = theme => ({
    alert: {
        color: '#f44336',
    },
    dividerInset: {
        'margin-left': '-20px',
    },
    divider: {
        background: '#ececec !important',
        'margin-left': '-30px',
        'margin-right': '-30px',
    },
});

class Register extends React.Component {
    static propTypes = {
        classes: PropTypes.object.isRequired,
        dispatch: PropTypes.func,
        history: PropTypes.object,
        // setWidget: PropTypes.func,
        user: PropTypes.object,
        syncAC: PropTypes.func,
    };

    state = {
        submitted: false,
        hashtag: '',
        name: '',
        emailUser: '',
        password: '',
        password2: '',
        CAsubmitted: false,
        errorHashtag: false,
        errorEmailUser: false,
        errorPass: false,
        errorPassMessage: '',
    };

    handleSubmit = event => {
        event.preventDefault();
        this.setState({ submitted: true });
        const { name, CAsubmitted, emailUser, password, password2, hashtag, errorPass,
            errorEmailUser, errorHashtag } = this.state;
        const { dispatch } = this.props;

        if (name && emailUser && hashtag && password && password2 && CAsubmitted && (password === password2) &&
            !errorPass && !errorEmailUser && !errorHashtag) {
            dispatch(userActions.register({
                "hashtag": hashtag,
                "name": name,
                "prefered_language": (navigator.language || navigator.userLanguage || 'es'),
                "user": {
                    "email": emailUser,
                    "password": password
                }
            }));
            this.props.syncAC([
                {
                    "field": "24",
                    "value": hashtag,
                },
                {
                    "field": "23",
                    "value": new Date().toISOString(),
                },
                {
                    "field": "27",
                    "value": "admin",
                },
            ], emailUser);
            this.props.history.push('/login');
        } else {
            this.setState({
                password2: "",
            })
        }
    }

    onBack = event => {
        event.preventDefault();
        this.props.history.push('/login');
    }

    validateEmail = (email) => {
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
    }

    validatePass = (pass) => {
        if (pass.length < 6) {
            return ("La contraseña debe ser mayor a 6 digitos");
        } else if (pass.length > 50) {
            return ("Contraseña muy larga");
        } else if (pass.search(/\d/) === -1) {
            return ("La contraseña debe contener numeros y letras");
        } else if (pass.search(/[a-zA-Z]/) === -1) {
            return ("La contraseña debe contener numeros y letras");
            // eslint-disable-next-line no-useless-escape
        } else if (pass.search(/[^a-zA-Z0-9\!\@\#\$\%\^\&\*\(\)\_\+]/) !== -1) {
            return ("La contraseña posee un caracter invalido");
        }
        return ("ok");
    }

    handleChange = event => {
        event.preventDefault();
        if (event.target.name === 'hashtag') {
            if (!event.target.value.startsWith('#')) {
                this.setState({
                    errorHashtag: true,
                });
            } else {
                const subst = event.target.value.substring(1);
                if (/^[A-Za-z0-9]+$/.test(subst)) {
                    this.setState({
                        errorHashtag: false,
                    });
                } else {
                    this.setState({
                        errorHashtag: true,
                    });
                }
            }
        }
        if (event.target.name === 'emailUser') {
            if (!this.validateEmail(event.target.value)) {
                this.setState({
                    errorEmailUser: true,
                });
            } else {
                this.setState({
                    errorEmailUser: false,
                });
            }
        }
        if (event.target.name === 'password') {
            const error = this.validatePass(event.target.value);
            if (error !== 'ok') {
                this.setState({
                    errorPass: true,
                    errorPassMessage: error,
                });
            } else {
                this.setState({
                    errorPass: false,
                    errorPassMessage: '',
                });
            }
        }

        this.setState({
            [event.target.name]: event.target.value,
        });
    }

    onChangeCA = () => {
        // event.preventDefault();
        this.setState({
            CAsubmitted: true,
        });
    }

    render() {
        const { classes, user } = this.props;

        if (user) {
            this.props.history.push('/home');
            return null;
        }

        const { name, submitted, emailUser, hashtag, password, password2, CAsubmitted,
            errorHashtag, errorEmailUser, errorPass, errorPassMessage } = this.state;

        const titulo = 'REGISTRO DEL VENDEDOR'
        let labelboton = 'Registrarse';

        return (
            <React.Fragment>
                <Appbar back={true} label={titulo} onBack={this.onBack} />
                <FormControl onSubmit={this.handleSubmit} >
                    <Grid container spacing={3}
                        direction="column"
                        alignItems="center"
                    >
                        <Grid
                            item xs={10} md={5}
                            container
                            direction="column"
                            alignItems="stretch"
                        >
                            <div ref={el => (this.instance = el)} />
                            <br />
                            <Typography
                                className={classes.dividerInset}
                                display="block"
                                variant="caption"
                                color="primary"
                            >
                                Datos del usuario        (obligatorios)
                            </Typography>
                            <Divider className={classes.divider} />
                            <Input
                                id='input-email-user'
                                type='text'
                                label='Email del usuario *'
                                submitted={submitted}
                                disableAuto={true}
                                error={errorEmailUser}
                                name='emailUser'
                                value={emailUser}
                                errorMessage="Por favor ingrese un email valido"
                                onChange={this.handleChange}
                            />
                            <Input
                                id='input-pass1'
                                type='password'
                                eye={true}
                                label='Contraseña *'
                                submitted={submitted}
                                disableAuto={true}
                                name='password'
                                value={password}
                                error={errorPass}
                                errorMessage={(errorPassMessage ? errorPassMessage : "Por favor ingrese la contraseña")}
                                onChange={this.handleChange}
                            />
                            <Input
                                id='input-pass2'
                                label='Repetir Contraseña *'
                                type='password'
                                disableAuto={true}
                                submitted={submitted}
                                name='password2'
                                value={password2}
                                errorMessage="Por favor ingrese la misma contraseña"
                                onChange={this.handleChange}
                            />
                            <br />
                            <Typography
                                className={classes.dividerInset}
                                color="primary"
                                variant="caption"
                            >
                                Datos de la empresa (obligatorios)
                            </Typography>
                            <Divider className={classes.divider} />
                            <Input
                                id='input-name'
                                type='text'
                                label='Nombre fantasia *'
                                disableAuto={true}
                                submitted={submitted}
                                name='name'
                                value={name}
                                errorMessage="Por favor ingrese el nombre de la empresa"
                                onChange={this.handleChange}
                            />
                            <Input
                                id='input-hashtag'
                                type='text'
                                label='Hashtag# *'
                                disableAuto={true}
                                submitted={submitted}
                                error={errorHashtag}
                                name='hashtag'
                                value={hashtag}
                                errorMessage="Por favor ingrese un hashtag valido (debe comenzar con #, solo letras y numeros)"
                                onChange={this.handleChange}
                            />
                        </Grid>
                        <Grid
                            item xs={10} md={5}
                            container
                            direction="column"
                            alignItems="stretch"
                        >
                            <br />
                            <ReCAPTCHA1
                                sitekey="6LdJYAEbAAAAAApeuN9Wsv1h_52YHwQGzC4JmxxC"
                                onChange={this.onChangeCA}
                            />
                            {!CAsubmitted && submitted &&
                                <div className={classes.alert}>Por favor, complete el CAPTCHA</div>
                            }
                            <br /><br />
                            <Button variant="contained" color="primary" onClick={this.handleSubmit}>{labelboton}</Button>
                            <br /><br />
                        </Grid>
                    </Grid>
                </FormControl>
            </React.Fragment>
        );
    }

    componentDidMount() {

        const { dispatch, user } = this.props;

        if (user) {
            dispatch(alertActions.error('Error, para registrar un usuario primero tiene que cerrar la sesion actual'));
            this.props.history.push('/home');
            return null;
        }

        if (window.location.href.startsWith('https://arturito.chat') || window.location.href.startsWith('https://arturito.store')) {
            //tracking script from ActiveCampaign
            const s = document.createElement('script');
            s.type = 'text/javascript';
            s.async = true;
            s.innerHTML = '(function(e,t,o,n,p,r,i){e.visitorGlobalObjectAlias=n;e[e.visitorGlobalObjectAlias]=e[e.visitorGlobalObjectAlias]||function(){(e[e.visitorGlobalObjectAlias].q=e[e.visitorGlobalObjectAlias].q||[]).push(arguments)};e[e.visitorGlobalObjectAlias].l=(new Date).getTime();r=t.createElement("script");r.src=o;r.async=true;i=t.getElementsByTagName("script")[0];i.parentNode.insertBefore(r,i)})(window,document,"https://diffuser-cdn.app-us1.com/diffuser/diffuser.js","vgo");vgo("setAccount", "476725427");vgo("setTrackByDefault", true);vgo("process");';
            this.instance.appendChild(s);
        }

        // this.props.setWidget(true, false);
    }
}

function mapStateToProps(state) {
    const { loggingIn } = state.authentication;
    const { user } = state.authentication;
    return {
        loggingIn,
        user
    };
}

export default connect(mapStateToProps)(withStyles(styles)(Register));