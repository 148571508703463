import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, TextField, Dialog, DialogActions, DialogContent,
  DialogContentText, DialogTitle
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// eslint-disable-next-line no-undef
// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles(theme => ({
  root: {
    overflow: 'hidden',
  },
  tabpanel: {
    width: '100%',
    overflow: 'auto',
  },
}));

ItemPopUp.propTypes = {
  //  True if the back button is on
  open: PropTypes.bool,
  // The label to show over the bar
  title: PropTypes.string,
  //  True if the menu button is on
  text: PropTypes.string,
  textField: PropTypes.string,
  // The function to call when input changes
  handleClose: PropTypes.func,
  accept: PropTypes.bool,
  handleAccept: PropTypes.func,
  changeTextField: PropTypes.func,
}

ItemPopUp.defaultProps = {
  open: false,
  title: '',
  text: '',
  textField: '',
};

function ItemPopUp({ open, title, text, handleClose, accept, handleAccept, textField, changeTextField }) {
  const classes = useStyles();
  // const [state, setState] = React.useState({
  //   left: false,
  //   top: false,
  // });

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby=" "
        aria-describedby="description"
        className={classes.tabpanel}
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent
        >
          <DialogContentText color='primary' variant="body1" id="alert-dialog-description">
            {text}
          </DialogContentText>
          <TextField
            id="filled-multiline-static"
            // label="title"
            multiline
            maxRows={25}
            value={textField}
            variant="filled"
            className={classes.tabpanel}
            onChange={changeTextField}
          />
        </DialogContent>
        <DialogActions>
          {accept && <Button onClick={handleAccept} color="secondary" autoFocus>
            Guardar
          </Button>}
          <Button onClick={handleClose} color="secondary" autoFocus>
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ItemPopUp;
