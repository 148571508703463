export const clientConstants = {
    REGISTER_REQUEST: 'CLIENTS_REGISTER_REQUEST',
    REGISTER_SUCCESS: 'CLIENTS_REGISTER_SUCCESS',
    REGISTER_FAILURE: 'CLIENTS_REGISTER_FAILURE',

    GETALL_REQUEST: 'CLIENTS_GETALL_REQUEST',
    GETALL_SUCCESS: 'CLIENTS_GETALL_SUCCESS',
    GETALL_FAILURE: 'CLIENTS_GETALL_FAILURE',

    GETBYID_REQUEST: 'CLIENTS_GETBYID_REQUEST',
    GETBYID_SUCCESS: 'CLIENTS_GETBYID_SUCCESS',
    GETBYID_FAILURE: 'CLIENTS_GETBYID_FAILURE',

    GETLOGS_REQUEST: 'USERS_GETLOGS_REQUEST',
    GETLOGS_SUCCESS: 'USERS_GETLOGS_SUCCESS',
    GETLOGS_FAILURE: 'USERS_GETLOGS_FAILURE',

    SETLOGS_REQUEST: 'USERS_SETLOGS_REQUEST',
    SETLOGS_SUCCESS: 'USERS_SETLOGS_SUCCESS',
    SETLOGS_FAILURE: 'USERS_SETLOGS_FAILURE',

    SETSTORE_REQUEST: 'USERS_SETSTORE_REQUEST',
    SETSTORE_SUCCESS: 'USERS_SETSTORE_SUCCESS',
    SETSTORE_FAILURE: 'USERS_SETSTORE_FAILURE',
};
