import { authHeader } from '../_helpers';

export const productService = {
    getById,
    getByField,
    getAll,
    getAllNoToken,
    update,
    create,
    deleteProd,
};

const url = process.env.REACT_APP_API_URL;

async function getById(id, userId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    const response = await fetch(`${url}/store/products/${id}&${userId}`, requestOptions);
    return handleResponse(response);
}

async function getByField(field1, value1, field2, value2, field3, value3) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    const response = await fetch(`${url}/store/products/byfield/${field1}&${value1}&${field2}&${value2}&${field3}&${value3}`, requestOptions);
    return handleResponse(response);
}

async function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    const response = await fetch(`${url}/store/products/`, requestOptions);
    return handleResponse(response);
}

async function getAllNoToken(params) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(params)
    };
    const response = await fetch(`${url}/client/productlist`, requestOptions);
    return handleResponse(response);
}

async function deleteProd(ids) {
    const requestOptions = {
        method: 'DELETE',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(ids)
    };

    const response = await fetch(`${url}/store/products/`, requestOptions);
    return handleResponse(response);
}

async function create(product) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(product)
    };

    const response = await fetch(`${url}/store/products/`, requestOptions);
    return handleResponse(response);
}

async function update(product) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(product)
    };

    const response = await fetch(`${url}/store/products/${product.id}`, requestOptions);
    return handleResponse(response);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}