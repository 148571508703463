import { productConstants } from '../_constants';
import { productService } from '../_services';
import { history } from '../_helpers';
import { alertActions } from './';

export const productActions = {
    getAll,
    getByField,
    getById,
    update,
    create,
    getAllNoToken,
    deleteProd,
};


function getById(id, userId) {
    return dispatch => {
        dispatch(request());

        productService.getById(id, userId)
            .then(
                product => dispatch(success(product)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: productConstants.GETBYID_REQUEST } }
    function success(product) { return { type: productConstants.GETBYID_SUCCESS, product } }
    function failure(error) { return { type: productConstants.GETBYID_FAILURE, error } }
}

function deleteProd(ids, lasturl) {
    return dispatch => {
        dispatch(request());

        productService.deleteProd(ids)
            .then(
                () => {
                    dispatch(getAll());
                    dispatch(success());
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                    if (lasturl)
                        localStorage.setItem('lasturl', lasturl);
                    history.push('/login');
                }
            );
    };

    function request() { return { type: productConstants.DELETEPROD_REQUEST } }
    function success() { return { type: productConstants.DELETEPROD_SUCCESS } }
    function failure(error) { return { type: productConstants.DELETEPROD_FAILURE, error } }
}

function getByField(name1, value1, name2, value2, name3, value3) {
    return dispatch => {
        dispatch(request());

        productService.getByField(name1, value1, name2, value2, name3, value3)
            .then(
                products => dispatch(success(products)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: productConstants.GETBYFIELD_REQUEST } }
    function success(products) { return { type: productConstants.GETBYFIELD_SUCCESS, products } }
    function failure(error) { return { type: productConstants.GETBYFIELD_FAILURE, error } }
}

function getAll(lasturl) {
    return dispatch => {
        dispatch(request());

        productService.getAll()
            .then(
                products => {
                    dispatch(success(products.data))
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                    if (lasturl)
                        localStorage.setItem('lasturl', lasturl);
                    history.push('/login');
                }
            );
    };

    function request() { return { type: productConstants.GETALL_REQUEST } }
    function success(products) { return { type: productConstants.GETALL_SUCCESS, products } }
    function failure(error) { return { type: productConstants.GETALL_FAILURE, error } }
}

function getAllNoToken(token, sessionId) {
    return dispatch => {
        dispatch(request());

        productService.getAllNoToken({ 'token': token, 'session_id': sessionId })
            .then(
                products => {
                    dispatch(success(products.data, products.metadata));
                },
                error => {
                    dispatch(failure(error.toString()));
                    history.push('/404');
                }
            );
    };

    function request() { return { type: productConstants.GETALL_REQUEST } }
    function success(products, metadata) { return { type: productConstants.GETALL_SUCCESS, products, metadata } }
    function failure(error) { return { type: productConstants.GETALL_FAILURE, error } }
}

function update(product) {
    return dispatch => {
        dispatch(request(product));

        productService.update(product)
            .then(
                // eslint-disable-next-line no-unused-vars
                product => {
                    dispatch(success(product));
                    // if (message) dispatch(alertActions.success(message))
                },
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: productConstants.UPDATE_REQUEST } }
    function success(product) { return { type: productConstants.UPDATE_SUCCESS, product } }
    function failure(error) { return { type: productConstants.UPDATE_FAILURE, error } }
}

function create(product, message, lasturl) {
    return dispatch => {
        dispatch(request(product));

        productService.create(product)
            .then(
                // eslint-disable-next-line no-unused-vars
                product => {
                    dispatch(success(product));
                    if (message) dispatch(alertActions.success(message))
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                    if (lasturl)
                        localStorage.setItem('lasturl', lasturl);
                    history.push('/login');
                }
            );
    };

    function request() { return { type: productConstants.UPDATE_REQUEST } }
    function success(product) { return { type: productConstants.UPDATE_SUCCESS, product } }
    function failure(error) { return { type: productConstants.UPDATE_FAILURE, error } }
}