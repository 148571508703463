/* eslint-disable react/jsx-no-bind */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    Grid, TableCell, Tooltip
} from '@material-ui/core';
import Payment from '@material-ui/icons/MonetizationOn';
import withStyles from '@material-ui/core/styles/withStyles';
import Appbar from '../_components/Appbar';
import ResponsiveTable from '../_components/Table';
import Filters from '../_components/Filters';
import Pagination from '@mui/material/Pagination';

// eslint-disable-next-line no-unused-vars
const styles = theme => ({
    table: {
        overflow: 'auto',
    },
    icoRed: {
        'font-size': '20px',
        color: '#ff0000',
    },
    icoGreen: {
        'font-size': '20px',
        color: '#02ff00',
    },
    icoYellow: {
        'font-size': '20px',
        color: '#ffed00',
    },
    cellRowsIco: {
        borderBottom: '1px solid #b7b7b7',
        boxShadow: 'none',
        padding: '5px 7px 0px 5px',
        width: '50px',
        'text-align': 'end',
    },
});


class OrderList extends React.Component {
    static propTypes = {
        classes: PropTypes.object.isRequired,
        orders: PropTypes.oneOfType([
            PropTypes.array,
            PropTypes.object
        ]),
        history: PropTypes.object,
        setCurrentOrder: PropTypes.func,
        setWidget: PropTypes.func,
        user: PropTypes.object,
        getData: PropTypes.func,
    };

    state = {
        tabView: 0,
        orderList: [],
        updatedList: [],
        pagelist: [],
        npages: 0,
        npage: 0,
        selectedStatus: '',
    };

    onBack = event => {
        event.preventDefault();
        this.props.history.push('/home');
    }

    handleTableClick = (event) => {
        this.props.setCurrentOrder(event);
        this.props.history.push('/orderdetail');
    }

    updateList = (list) => {
        this.setPages(list);
        this.setState({
            updatedList: list,
        })
    }

    renderPayment = (item, index) => {
        const stat = item.payment_status;
        let status = '';

        if (stat === 'pending') status = 'Pago no definido';
        if (stat === 'required') status = 'Pago solicitado';
        if (stat === 'processing') status = 'Pago en proceso';
        if (stat === 'done') status = 'Pago aprobado'

        return (<TableCell key={index} classes={{ root: this.props.classes.cellRowsIco }}>
            <Tooltip title={status} placement="right" arrow>
                <Payment
                    classes={
                        ((stat === 'done') ? { root: this.props.classes.icoGreen } :
                            (stat === 'required' || stat === 'processing') ? { root: this.props.classes.icoYellow } :
                                { root: this.props.classes.icoRed })
                    }
                />
            </Tooltip>
        </TableCell>)
    }

    updateStatus = (status) => {
        this.setState({
            selectedStatus: status
        });
    }

    // eslint-disable-next-line no-unused-vars
    handlePageChange = (event, page) => {
        const nitems = 15;
        this.setState({
            pagelist: this.state.updatedList.slice(nitems * (page - 1), nitems * page),
            npage: page
        });
    }

    setPages = (list) => {
        const nitems = 15;
        this.setState({
            pagelist: list.slice(0, 15),
            npages: Math.ceil(list.length / nitems),
            npage: 1
        });
    }

    render() {
        const { classes } = this.props;
        const { orderList, updatedList, pagelist, npages, npage, selectedStatus } = this.state;

        let titulo = "LISTA DE PEDIDOS";


        return (
            <React.Fragment>
                <Appbar back={true} label={titulo} user={this.props.user} onBack={this.onBack}
                    update={() => {
                        this.props.getData(['orders']);
                        ++this.childKey;
                    }} />
                <Filters
                    key={this.childKey}
                    label='Filtros'
                    pantalla='Ordenes'
                    exportXls={true}
                    reset={true}
                    fullWidth={true}
                    fieldList={[
                        {
                            label: 'CLIENTE',
                            text: true,
                            field: 'client'
                        },
                        {
                            label: 'FECHA',
                            date: true,
                            field: 'created_at'
                        },
                        {
                            label: 'ESTADO',
                            combo: true,
                            field: 'estado'
                        },
                        {
                            label: 'N PEDIDO',
                            text: true,
                            field: 'alphanum_id'
                        },
                        {
                            label: 'ORIGINAL ID',
                            text: true,
                            field: 'original_id'
                        }
                    ]}
                    list={orderList}
                    filteredList={updatedList}
                    selectedStatus={selectedStatus}
                    updateList={this.updateList}
                />
                <Grid item xs={12} sm={12} className={classes.table}>
                    <ResponsiveTable
                        headCells={[
                            {
                                id: 'alphanum_id',
                                label: 'N PEDIDO'
                            },
                            {
                                id: 'created_at',
                                label: 'FECHA',
                                type: 'date',
                            },
                            {
                                id: 'client',
                                label: 'CLIENTE'
                            },
                            {
                                id: 'total_price',
                                label: 'VALOR',
                                type: 'number',
                            },
                            {
                                id: 'payment',
                                label: 'PAGO',
                                noSortable: true,
                                render: (item, index) => this.renderPayment(item, index + 1000)
                            },
                            {
                                id: 'estado',
                                label: 'ESTADO'
                            },
                            {
                                id: 'nitems',
                                label: 'N ITEMS'
                            },
                            {
                                id: 'original_id',
                                label: 'ORIGINAL ID'
                            },
                        ]}
                        curr_symbol={(this.props.user ? this.props.user.store.curr_symbol : '')}
                        rows={pagelist}
                        handleSelectRow={this.handleTableClick}
                    />
                </Grid>
                <Pagination
                    count={npages}
                    // size="large"
                    // variant="outlined"
                    shape="rounded"
                    page={npage}
                    onChange={this.handlePageChange}
                    color="primary"
                    sx={{ padding: '10px' }}
                />
                < div ref={el => (this.instance = el)
                } />
            </React.Fragment >
        );
    }
    componentDidMount() {
        if (window.location.href.startsWith('https://arturito.chat') || window.location.href.startsWith('https://arturito.store')) {
            //tracking script from ActiveCampaign
            const s = document.createElement('script');
            s.type = 'text/javascript';
            s.async = true;
            s.innerHTML = '(function(e,t,o,n,p,r,i){e.visitorGlobalObjectAlias=n;e[e.visitorGlobalObjectAlias]=e[e.visitorGlobalObjectAlias]||function(){(e[e.visitorGlobalObjectAlias].q=e[e.visitorGlobalObjectAlias].q||[]).push(arguments)};e[e.visitorGlobalObjectAlias].l=(new Date).getTime();r=t.createElement("script");r.src=o;r.async=true;i=t.getElementsByTagName("script")[0];i.parentNode.insertBefore(r,i)})(window,document,"https://diffuser-cdn.app-us1.com/diffuser/diffuser.js","vgo");vgo("setAccount", "476725427");vgo("setTrackByDefault", true);vgo("process");';
            this.instance.appendChild(s);
        }

        const { user } = this.props;

        if (!user || !this.props.setWidget) {
            console.log('Error user or session, redirecting')
            localStorage.setItem('lasturl', `/orderlist`);
            this.props.history.push('/login');
            return null;
        }

        if (this.props.orders && !this.props.orders.orders)
            this.props.getData(['orders']);
        else {
            this.setPages(this.props.orders.orders);
            // eslint-disable-next-line react/no-did-mount-set-state
            this.setState({
                orderList: this.props.orders.orders,
                updatedList: this.props.orders.orders,
            });
        }
        this.props.setWidget(true, false);

    }

    componentDidUpdate(prevProps, prevState) {
        if ((prevProps.orders !== this.props.orders) && (this.props.orders && this.props.orders.orders)) {
            this.setPages(this.props.orders.orders);
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({
                orderList: this.props.orders.orders,
                updatedList: this.props.orders.orders,
            });
        }

        const queryParams = new URLSearchParams(window.location.search)
        const status = queryParams.get("estado")

        if (status && this.state.selectedStatus === "") {
            this.updateStatus(status);
        }
    }

    componentWillUnmount() {
        localStorage.setItem('lasturl', `/orderlist`);
    }
}

function mapStateToProps(state) {
    const { user } = state.authentication;
    const { orders } = state;

    return {
        user,
        orders
    };
}

export default connect(mapStateToProps)(withStyles(styles)(OrderList));