export const userConstants = {
    GETALL_REQUEST: 'USERS_GETALL_REQUEST',
    GETALL_SUCCESS: 'USERS_GETALL_SUCCESS',
    GETALL_FAILURE: 'USERS_GETALL_FAILURE',

    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

    REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
    REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
    REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',

    UPDATE_REQUEST: 'USERS_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'USERS_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'USERS_UPDATE_FAILURE',

    ENABLE_REQUEST: 'USERS_ENABLE_REQUEST',
    ENABLE_SUCCESS: 'USERS_ENABLE_SUCCESS',
    ENABLE_FAILURE: 'USERS_ENABLE_FAILURE',

    DISABLE_REQUEST: 'USERS_DISABLE_REQUEST',
    DISABLE_SUCCESS: 'USERS_DISABLE_SUCCESS',
    DISABLE_FAILURE: 'USERS_DISABLE_FAILURE',

    ACTIONS_REQUEST: 'USERS_ACTIONS_REQUEST',
    ACTIONS_SUCCESS: 'USERS_ACTIONS_SUCCESS',
    ACTIONS_FAILURE: 'USERS_ACTIONS_FAILURE',

    LOGOUT: 'USERS_LOGOUT',

    GETCSVSTATUS_REQUEST: 'USERS_GETCSVSTATUS_REQUEST',
    GETCSVSTATUS_SUCCESS: 'USERS_GETCSVSTATUS_SUCCESS',
    GETCSVSTATUS_FAILURE: 'USERS_GETCSVSTATUS_FAILURE',

    SETINTEGRATIONS_REQUEST: 'USERS_SETINTEGRATIONS_REQUEST',
    SETINTEGRATIONS_SUCCESS: 'USERS_SETINTEGRATIONS_SUCCESS',
    SETINTEGRATIONS_FAILURE: 'USERS_SETINTEGRATIONS_FAILURE',

    GETINTEGRATIONS_REQUEST: 'USERS_GETINTEGRATIONS_REQUEST',
    GETINTEGRATIONS_SUCCESS: 'USERS_GETINTEGRATIONS_SUCCESS',
    GETINTEGRATIONS_FAILURE: 'USERS_GETINTEGRATIONS_FAILURE',

    SETFAQ_REQUEST: 'USERS_SETFAQ_REQUEST',
    SETFAQ_SUCCESS: 'USERS_SETFAQ_SUCCESS',
    SETFAQ_FAILURE: 'USERS_SETFAQ_FAILURE',

    GETFAQ_REQUEST: 'USERS_GETFAQ_REQUEST',
    GETFAQ_SUCCESS: 'USERS_GETFAQ_SUCCESS',
    GETFAQ_FAILURE: 'USERS_GETFAQ_FAILURE',

    GETFAQOAI_REQUEST: 'USERS_GETFAQOAI_REQUEST',
    GETFAQOAI_SUCCESS: 'USERS_GETFAQOAI_SUCCESS',
    GETFAQOAI_FAILURE: 'USERS_GETFAQOAI_FAILURE',

    SETPROMOS_REQUEST: 'USERS_SETPROMOS_REQUEST',
    SETPROMOS_SUCCESS: 'USERS_SETPROMOS_SUCCESS',
    SETPROMOS_FAILURE: 'USERS_SETPROMOS_FAILURE',

    GETPROMOS_REQUEST: 'USERS_GETPROMOS_REQUEST',
    GETPROMOS_SUCCESS: 'USERS_GETPROMOS_SUCCESS',
    GETPROMOS_FAILURE: 'USERS_GETPROMOS_FAILURE',

    GETQRS_REQUEST: 'USERS_GETQRS_REQUEST',
    GETQRS_SUCCESS: 'USERS_GETQRS_SUCCESS',
    GETQRS_FAILURE: 'USERS_GETQRS_FAILURE',
    SETQRS_REQUEST: 'USERS_SETQRS_REQUEST',
    SETQRS_SUCCESS: 'USERS_SETQRS_SUCCESS',
    SETQRS_FAILURE: 'USERS_SETQRS_FAILURE',

    SETPM_REQUEST: 'USERS_SETPM_REQUEST',
    SETPM_SUCCESS: 'USERS_SETPM_SUCCESS',
    SETPM_FAILURE: 'USERS_SETPM_FAILURE',

    GETPM_REQUEST: 'USERS_GETPM_REQUEST',
    GETPM_SUCCESS: 'USERS_GETPM_SUCCESS',
    GETPM_FAILURE: 'USERS_GETPM_FAILURE',

    UPLOADFAQ_REQUEST: 'USERS_UPLOADFAQ_REQUEST',
    UPLOADFAQ_SUCCESS: 'USERS_UPLOADFAQ_SUCCESS',
    UPLOADFAQ_FAILURE: 'USERS_UPLOADFAQ_FAILURE',

    UPLOADCSV_REQUEST: 'USERS_UPLOADCSV_REQUEST',
    UPLOADCSV_SUCCESS: 'USERS_UPLOADCSV_SUCCESS',
    UPLOADCSV_FAILURE: 'USERS_UPLOADCSV_FAILURE',

    GETCSV_REQUEST: 'USERS_GETCSV_REQUEST',
    GETCSV_SUCCESS: 'USERS_GETCSV_SUCCESS',
    GETCSV_FAILURE: 'USERS_GETCSV_FAILURE',

    GETLOGS: 'USERS_GETLOGS',

    GETLOGS_REQUEST: 'USERS_GETLOGS_REQUEST',
    GETLOGS_SUCCESS: 'USERS_GETLOGS_SUCCESS',
    GETLOGS_FAILURE: 'USERS_GETLOGS_FAILURE',

    SETLOGS_REQUEST: 'USERS_SETLOGS_REQUEST',
    SETLOGS_SUCCESS: 'USERS_SETLOGS_SUCCESS',
    SETLOGS_FAILURE: 'USERS_SETLOGS_FAILURE',

    SESSION_REQUEST: 'USERS_SESSION_REQUEST',
    SESSION_SUCCESS: 'USERS_SESSION_SUCCESS',
    SESSION_FAILURE: 'USERS_SESSION_FAILURE',

    SETTHEME_REQUEST: 'USERS_SETTHEME_REQUEST',
    SETTHEME_SUCCESS: 'USERS_SETTHEME_SUCCESS',
    SETTHEME_FAILURE: 'USERS_SETTHEME_FAILURE',

    GETTHEME_REQUEST: 'USERS_GETTHEME_REQUEST',
    GETTHEME_SUCCESS: 'USERS_GETTHEME_SUCCESS',
    GETTHEME_FAILURE: 'USERS_GETTHEME_FAILURE',
};
