import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import QRCode from "react-qr-code";
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import DialogContentText from '@material-ui/core/DialogContentText';
import ContentCopy from '@material-ui/icons/FileCopy';
import { convertDate } from '../_helpers';

QRPreview.propTypes = {
  //  True if the back button is on
  open: PropTypes.bool,
  // The label to show over the bar
  user: PropTypes.string,
  //  True if the menu button is on
  index: PropTypes.string,
  qr: PropTypes.object,
  // The function to call when input changes
  handleClose: PropTypes.func,
}

QRPreview.defaultProps = {
  open: false,
  title: '',
  text: '',
  user: '',
};

function onImageCownload(id, text, user) {
  const svg = document.getElementById(id);
  const svgData = new XMLSerializer().serializeToString(svg);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  const img = new Image();
  img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
  img.onload = () => {
    canvas.width = img.width + 100;
    canvas.height = img.height + 100;
    ctx.fillStyle = "#FFFFFF";
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    ctx.drawImage(img, 50, 50);
    ctx.lineWidth = 1;
    ctx.fillStyle = "#000000";
    ctx.font = "17px sans-serif";
    ctx.fillText(user, 50, 43);
    ctx.font = "11px sans-serif";
    ctx.fillText("ARTURITO.COM", 217, 320);
    const pngFile = canvas.toDataURL("image/png");
    const downloadLink = document.createElement("a");
    downloadLink.download = `QR_${text}${user}`;
    downloadLink.href = `${pngFile}`;
    downloadLink.click();
  };
}

function QRPreview({ open, index, qr, user, handleClose }) {

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="Error"
        aria-describedby="description"
      >
        <DialogTitle id="alert-dialog-title">{(qr && qr.description) ? qr.description : ""}</DialogTitle>
        <DialogContent>
          {(qr) &&
            <div>
              <DialogContentText id="alert-dialog-description2">
                {`${convertDate(qr.created_at)}`}
              </DialogContentText>
              <DialogContentText id="alert-dialog-description3">
                {`Mensaje: ${qr.client_msg_preamble}`}
              </DialogContentText>
              <DialogContentText id="alert-dialog-description4">
                {`Respuesta: ${qr.use_confirm_finisher}`}
              </DialogContentText>
              <br />
              <QRCode id={`QRPROMO-${index}`} title={`QRPROMO-${index}`} value={qr.url}
                sx={{ border: '10px solid #fff' }}
                // eslint-disable-next-line react/jsx-no-bind
                onClick={() => {
                  qr.bkd = false;
                }}
              />
            </div>
          }
        </DialogContent>
        <DialogActions>
          <Button
            component="label"
            // eslint-disable-next-line react/jsx-no-bind
            onClick={(e) => {
              e.preventDefault();
              onImageCownload(`QRPROMO-${index}`, (qr.description ? qr.description.substr(0, 10) : ''), user)
            }}
          >
            <ArrowDownward />
          </Button>
          <Button
            component="label"
            // eslint-disable-next-line react/jsx-no-bind
            onClick={() => {
              navigator.clipboard.writeText(qr.url);
            }}
          >
            <ContentCopy />
          </Button>
          <Button onClick={handleClose} variant="contained" color="primary" autoFocus>
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default QRPreview;
