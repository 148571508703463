import React from 'react';
import { Route, Redirect } from 'react-router-dom';

export const PrivateRoute = ({ component: Component, base, ...rest }) => (
    // eslint-disable-next-line react/jsx-no-bind
    <Route {...rest} render={props => (
        localStorage.getItem('user')
            ? <Component {...props} />
            : <Redirect to={{ pathname: (base === 'store' ? '/home':'/homeclient'), state: { from: props.location } }} />
    )} />
)