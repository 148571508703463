/* eslint-disable react/jsx-no-bind */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    Button, TableCell, Grid, TextField
} from '@material-ui/core';
import Fade from '@mui/material/Fade';
import withStyles from '@material-ui/core/styles/withStyles';
import InputAdornment from '@material-ui/core/InputAdornment';
import AddCircle from '@material-ui/icons/AddCircle';
// import AddShoppingCart from '@material-ui/icons/Check';
// import AddShoppingCart from '@mui/icons-material/AddShoppingCart';
// import ShoppingCart from '@material-ui/icons/ShoppingCart';
// import ShoppingCartOutlined from '@material-ui/icons/ShoppingCartOutlined';
import Appbar from '../_components/Appbar';
import { productActions, orderActions, userActions, clientActions } from '../_actions';
import ResponsiveTable from '../_components/Table';
import FiltersV2 from '../_components/FiltersV2';
import { dicUnits } from '../_helpers/utils';
import ImagePopUp from '../_components/ImagePopUp';
import Avatar from '@material-ui/core/Avatar';
import Pagination from '@mui/material/Pagination';
import ProductPreview from '../_components/ProductPreview';


// eslint-disable-next-line no-unused-vars
const styles = theme => ({
    cellRows: {
        color: '#333 !important',
        // borderBottom: '1px solid #b7b7b7',
        display: 'flex',
        'justify-content': 'flex-end',
        'border-bottom': '0px',
        'padding-top': '2px',
        'padding-bottom': '2px',
        "@media (min-width:0px) and (max-width: 800px)": {
            'padding-left': '0px',
            'padding-right': '0px',
        },

    },
    table: {
        overflow: 'auto',
    },
    button: {
        'min-width': '36px',
    },
    cellRowsUnit: {
        'font-size': '12px',
        'margin-left': '0px',
    },
    cellText: {
        'min-width': '56px',
        'text-align': 'right',
        'max-width': '100px',
        'align-self': 'center',
    },
    widgetCart: {
        'z-index': '100',
        'backgroundColor': '#025e9f',
        'border-radius': '20%',
        bottom: '96px',
        height: '95px',
        position: 'fixed',
        right: '2px',
        width: '95px',
        border: '10px solid #fff',
        "&:hover": {
            cursor: 'pointer',
            'backgroundColor': '#134859',
        },
    },
    widgetAddExpand: {
        'z-index': '100',
        'backgroundColor': '#12B16F',
        'border-radius': '20%',
        bottom: '195px',
        height: '130px',
        position: 'fixed',
        right: '2px',
        width: '130px',
        border: '10px solid #fff',
        "&:hover": {
            cursor: 'pointer',
            'backgroundColor': '#62B16F',
        },
    },
    widgetAdd: {
        'z-index': '100',
        'backgroundColor': '#025e9f',
        'border-radius': '20%',
        bottom: '195px',
        height: '95px',
        position: 'fixed',
        right: '2px',
        width: '95px',
        border: '10px solid #fff',
        "&:hover": {
            cursor: 'pointer',
            'backgroundColor': '#134859',
        },
    },
    icoButton: {
        // position: 'absolute',
        // 'line-height': '1.25 !important',
        // left: '-5%',
        // top: '5%',
        color: 'white',
        width: '100%',
        height: '100%'
    },
    ico: {
        'font-size': '30px',
        color: '#fff'
    },
    avatar: {
        width: '60px',
        height: '60px',
    }
});


var tableClick = false;
// var orderId = '';
var updatedSession = false;
// var dicProducts = {};

// var lastPageLocal = true;
var oneTimeWelcome = false;

class ProducList extends React.Component {
    static propTypes = {
        classes: PropTypes.object.isRequired,
        history: PropTypes.object,
        dispatch: PropTypes.func,
        match: PropTypes.object,
        products: PropTypes.object,
        setWidget: PropTypes.func,
        sessionId: PropTypes.string,
        token: PropTypes.string,
        orderId: PropTypes.object,
        session_id: PropTypes.object,
        session: PropTypes.object,
        error: PropTypes.object,
        updateSessionId: PropTypes.func,
        updateWidgetStyle: PropTypes.func,
    };

    state = {
        productList: [],
        updatedList: [],
        tagList: [],
        updated: false,
        cartList: [],
        toStatus: false,
        toAdd: false,
        // toAdd2: false,
        // token: '',
        curr_symbol: '',
        selectedTag: '',
        openImagePopUp: false,
        urlImagePopUp: '',
        pagelist: [],
        npages: 0,
        npage: 0,
        productData: null,
        productOpen: false,
    };

    processData = () => {
        const { products } = this.props;

        if (products && products.products && (products.products.length > 0)) {
            products.products.forEach(product => {
                product.brandname = (product.brand ? product.brand.name : '');
                product.kindname = (product.kind ? product.kind.name : '');
                product.unit = dicUnits(product.quantity_unit);
            })
            this.setPages(products.products);
            this.setState({
                productList: products.products,
                updatedList: products.products,
                selectedTag: (products.metadata && products.metadata.filters && products.metadata.filters.tag ? products.metadata.filters.tag : ''),
                updated: true,
                curr_symbol: products.products[0].curr_symbol,
            });
        }
    }

    checkSession = () => {
        const { dispatch } = this.props;
        let welcome = false;

        if (this.props.session.session) {
            if ((this.props.session.session.session_id !== null) && (this.props.session.session.session_id !== 'null')) {
                this.props.updateSessionId(this.props.session.session.session_id);
                const { dispatch } = this.props;
                if (this.props.sessionId) {
                    const log = {
                        "activity_name": "Lista de productos, sesión  recuperada",
                        "payload": { 'ID': this.props.session.session.session_id },
                        "session_id": this.props.session.session.session_id
                    };
                    dispatch(clientActions.setClientLog(log));
                }
                const { color_set, widget_logo } = this.props.session.session.store_public_data;
                this.props.updateWidgetStyle(color_set);
                if (oneTimeWelcome) {
                    welcome = 'MENU'
                }
                // this.props.setWidget(false, (lastPageLocal ? false : true), '', welcome, color_set.df_messenger_button_titlebar_text, widget_logo);
                this.props.setWidget(false, false, '', welcome, color_set.df_messenger_button_titlebar_text, widget_logo);
                oneTimeWelcome = false;
            }
            dispatch(productActions.getAllNoToken(this.props.match.params.token, this.props.sessionId));
            updatedSession = true;
        }
        if (this.props.session.error) {
            if (this.props.sessionId) {
                dispatch(productActions.getAllNoToken(this.props.match.params.token, this.props.sessionId));
                updatedSession = true;
            }
            else {
                dispatch(productActions.getAllNoToken(this.props.match.params.token, null));
                updatedSession = true;
            }
            welcome = false;
            if (oneTimeWelcome) {
                welcome = 'MENU'
            }
            // this.props.setWidget(false, (lastPageLocal ? false : true), '', welcome, '');
            this.props.setWidget(false, false, '', welcome, '');
            oneTimeWelcome = false;
        }
    }

    onBack = event => {
        event.preventDefault();
        // this.props.history.push('/homeclient');
        window.history.back();
    }

    updateList = (list) => {
        this.setPages(list);
        this.setState({
            updatedList: list,
        })
    }

    handleSubmitItems = () => {

        if (this.state.toAdd) {
            let items = [];
            let itemsPayload = [];
            this.state.cartList.forEach(item => {
                if (item.amount !== 0 && item.amount !== '0' && item.amount !== '')
                    if (!items.find(({ id }) => id === item.id)) {
                        items.push(
                            {
                                "product_id": item.id,
                                "amount": item.amount,
                                "raw": `quiero ${item.amount} ${item.unit} de ${item.name}`,
                                "quantity_unit": item.unit,
                                "name": item.name,
                            }
                        );
                    }
                itemsPayload.push({
                    'nombre': item.name,
                    'original_id': item.original_id,
                    'id': item.id
                });
                item.amount = '';
            });

            if (items.length >= 1) {
                const { dispatch, sessionId } = this.props;
                if (sessionId) {
                    const log = {
                        "activity_name": "Lista de productos, click en boton agregar items al pedido",
                        "payload": { 'Productos': itemsPayload },
                        "session_id": this.props.sessionId
                    };
                    dispatch(clientActions.setClientLog(log));
                }
                dispatch(orderActions.addItems(
                    {
                        "items": items,
                        "session_id": sessionId,
                    }
                ));
                var text = document.querySelector("#df-messenger").shadowRoot.querySelector("div > df-messenger-chat").shadowRoot.querySelector("div > df-messenger-user-input").shadowRoot.querySelector("div > div.input-box-wrapper.valid > input[type=text]");
                var text1 = document.querySelector("#df-messenger").shadowRoot.querySelector("div > df-messenger-chat").shadowRoot.querySelector("div > df-messenger-user-input").shadowRoot.querySelector("div > div.input-box-wrapper > input[type=text]")
                if (text) text.value = `Items agregados, ver estado del pedido`;
                if (text1) text1.value = `Items agregados, ver estado del pedido`;
                var button = document.querySelector("#df-messenger").shadowRoot.querySelector("div > df-messenger-chat").shadowRoot.querySelector("div > df-messenger-user-input").shadowRoot.querySelector("#sendIcon");
                button.dispatchEvent(new Event('click'));
                oneTimeWelcome = false;
            }
            this.setState({
                toAdd: false,
                // toAdd2: true,
                cartList: [],
            });
            return;
        }
        // if (this.state.toAdd2) {
        if (this.props.orderId) {
            if (this.props.sessionId) {
                const { dispatch } = this.props;
                const log = {
                    "activity_name": "Lista de productos, click en finalizar pedido",
                    "payload": null,
                    "session_id": this.props.sessionId
                };
                dispatch(clientActions.setClientLog(log));
            }
            this.props.setWidget(false, true, '', false);
            this.setState({
                toAdd: false,
                cartList: [],
            });
            // var text2 = document.querySelector("#df-messenger").shadowRoot.querySelector("div > df-messenger-chat").shadowRoot.querySelector("div > df-messenger-user-input").shadowRoot.querySelector("div > div.input-box-wrapper.valid > input[type=text]");
            // var text12 = document.querySelector("#df-messenger").shadowRoot.querySelector("div > df-messenger-chat").shadowRoot.querySelector("div > df-messenger-user-input").shadowRoot.querySelector("div > div.input-box-wrapper > input[type=text]")
            // if (text2) text2.value = `Finalizar pedido`;
            // if (text12) text12.value = `Finalizar pedido`;
            // var button1 = document.querySelector("#df-messenger").shadowRoot.querySelector("div > df-messenger-chat").shadowRoot.querySelector("div > df-messenger-user-input").shadowRoot.querySelector("#sendIcon");
            // button1.dispatchEvent(new Event('click'));
        }
        // if (items.length === 1) {
        //     var text = document.querySelector("#df-messenger").shadowRoot.querySelector("div > df-messenger-chat").shadowRoot.querySelector("div > df-messenger-user-input").shadowRoot.querySelector("div > div.input-box-wrapper.valid > input[type=text]");
        //     var text1 = document.querySelector("#df-messenger").shadowRoot.querySelector("div > df-messenger-chat").shadowRoot.querySelector("div > df-messenger-user-input").shadowRoot.querySelector("div > div.input-box-wrapper > input[type=text]")
        //     if (text) text.value = `quiero ${items[0].amount} ${dicUnits(items[0].quantity_unit)} de ${items[0].name}`;
        //     if (text1) text1.value = `quiero ${items[0].amount} ${dicUnits(items[0].quantity_unit)} de ${items[0].name}`;
        //     var button = document.querySelector("#df-messenger").shadowRoot.querySelector("div > df-messenger-chat").shadowRoot.querySelector("div > df-messenger-user-input").shadowRoot.querySelector("#sendIcon");

        //     button.dispatchEvent(new Event('click', { 'detail': 'mydetail', cancelable: true }));
        //     this.props.setWidget(false, false, '', false, 'Pedido actualizado, haz click para conversar');
        //     this.setState({ oneItemAdd: true });
        // }
    }

    runEvent = (i) => {
        if (i > 7) return;
        try {
            var button1 = document.querySelector("#df-messenger").shadowRoot.querySelector("div > df-messenger-chat").shadowRoot.querySelector("div > df-message-list").shadowRoot.querySelector("#messageList > df-card").shadowRoot.querySelector("df-button").shadowRoot.querySelector("#dfButtonAnchorWrapper");
            if (button1) button1.dispatchEvent(new Event('click'));
            oneTimeWelcome = false;
        } catch {
            setTimeout(() => {
                this.runEvent(i + 1);
            }, 100);
        }
    }

    handleGoToStatus = (event) => {
        event.preventDefault();
        if (this.props.session && this.props.session.session && this.props.session.session.current_order_token) {
            const { dispatch } = this.props;
            const log = {
                "activity_name": "Lista de productos, click en ver catalogo",
                "payload": null,
                "session_id": this.props.sessionId
            };
            dispatch(clientActions.setClientLog(log));
            this.props.history.push('/orderstatus/' + this.props.session.session.current_order_token);
            return
        }
        if (this.props.orderId && this.props.orderId.orderId && this.props.orderId.orderId.token) {
            const { dispatch } = this.props;
            const log = {
                "activity_name": "Lista de productos, click en ver catalogo",
                "payload": null,
                "session_id": this.props.sessionId
            };
            dispatch(clientActions.setClientLog(log));
            this.props.history.push('/orderstatus/' + this.props.orderId.orderId.token);
        }
        // else if (this.state.oneItemAdd) {
        //     var text = document.querySelector("#df-messenger").shadowRoot.querySelector("div > df-messenger-chat").shadowRoot.querySelector("div > df-messenger-user-input").shadowRoot.querySelector("div > div.input-box-wrapper.valid > input[type=text]");
        //     var text1 = document.querySelector("#df-messenger").shadowRoot.querySelector("div > df-messenger-chat").shadowRoot.querySelector("div > df-messenger-user-input").shadowRoot.querySelector("div > div.input-box-wrapper > input[type=text]")
        //     if (text) text.value = `Ver estado del pedido`;
        //     if (text1) text1.value = `Ver estado del pedido`;
        //     var button = document.querySelector("#df-messenger").shadowRoot.querySelector("div > df-messenger-chat").shadowRoot.querySelector("div > df-messenger-user-input").shadowRoot.querySelector("#sendIcon");
        //     button.dispatchEvent(new Event('click'));
        //     this.props.setWidget(false, false, '', false, 'Nuevo mensaje, haz click para conversar');

        //     setTimeout(() => {
        //         this.runEvent(1);
        //     }, 500);
        // }
    }

    renderActions = (item, index) => {
        var url = item.image_url ? item.image_url : '../images/default.png';
        return (
            <TableCell key={index} classes={{ root: this.props.classes.cellRows }}>
                <Avatar
                    alt="Imagen"
                    src={url}
                    classes={{ root: this.props.classes.avatar }}
                    variant="square" />
                <Button classes={{ root: this.props.classes.button }}
                    component="label"
                    onClick={() => {
                        tableClick = true;
                        if (item.amount === undefined || item.amount === '') {
                            if (item.quantity_unit === 'gr' || item.quantity_unit === 'gramo' || item.quantity_unit === 'g' || item.quantity_unit === 'grs')
                                item.amount = 100;
                            else item.amount = 1;
                        } else {
                            if (item.quantity_unit === 'gr' || item.quantity_unit === 'gramo' || item.quantity_unit === 'g' || item.quantity_unit === 'grs')
                                item.amount = parseInt(item.amount) + 100;
                            else item.amount = parseInt(item.amount) + 1;
                        }
                        item.blockButton = true;
                        var elementPos = this.state.cartList.map(function (x) { return x.id; }).indexOf(item.id);
                        if (elementPos < 0) {

                            this.props.setWidget(false, false);
                            this.setState({
                                cartList: [...this.state.cartList, item],
                                toAdd: true
                            })
                        } else {
                            // eslint-disable-next-line react/no-direct-mutation-state
                            this.state.cartList[elementPos].amount = item.amount;
                        }
                        const { dispatch } = this.props;
                        if (this.props.sessionId) {
                            const log = {
                                "activity_name": "Lista de productos, click en agregar item, boton +",
                                "payload": {
                                    "Producto": {
                                        'nombre': item.name,
                                        'original_id': item.original_id,
                                        'id': item.id
                                    }
                                },
                                "session_id": this.props.sessionId
                            };
                            dispatch(clientActions.setClientLog(log));
                        }
                    }}><AddCircle />
                </Button>
                <TextField color="secondary" type="number"
                    classes={{ root: this.props.classes.cellText }}
                    InputProps={{
                        endAdornment: <InputAdornment position="end" classes={{ root: this.props.classes.cellRowsUnit }}>({item.unit})</InputAdornment>,
                    }}
                    id={item.id}
                    value={(item.amount || '')}
                    onClick={() => {
                        tableClick = true;
                    }}
                    onChange={(event) => {
                        item.amount = event.target.value;
                        item.blockButton = true;
                        var elementPos = this.state.cartList.map(function (x) { return x.id; }).indexOf(item.id);
                        if (elementPos < 0) {
                            const { dispatch } = this.props;
                            if (this.props.sessionId) {
                                const log = {
                                    "activity_name": "Lista de productos, click en agregar item campo cantidad",
                                    "payload": {
                                        "Producto": {
                                            'nombre': item.mane,
                                            'original_id': item.original_id,
                                            'id': item.id
                                        }
                                    },
                                    "session_id": this.props.sessionId
                                };
                                dispatch(clientActions.setClientLog(log));
                            }
                            this.setState({
                                cartList: [...this.state.cartList, item],
                                toAdd: true,
                            })
                        } else {
                            // eslint-disable-next-line react/no-direct-mutation-state
                            this.state.cartList[elementPos].amount = item.amount;
                        }
                        this.forceUpdate();
                    }}
                // onKeyDown={(event) => {
                //     if (event.key === 'Enter') {
                //         var text = document.querySelector("#df-messenger").shadowRoot.querySelector("div > df-messenger-chat").shadowRoot.querySelector("div > df-messenger-user-input").shadowRoot.querySelector("div > div.input-box-wrapper.valid > input[type=text]");
                //         var text1 = document.querySelector("#df-messenger").shadowRoot.querySelector("div > df-messenger-chat").shadowRoot.querySelector("div > df-messenger-user-input").shadowRoot.querySelector("div > div.input-box-wrapper > input[type=text]")
                //         if (text) text.value = `quiero ${item.amount} ${item.quantity_unit} de ${item.name}`;
                //         if (text1) text1.value = `quiero ${item.amount} ${item.quantity_unit} de ${item.name}`;
                //         var button = document.querySelector("#df-messenger").shadowRoot.querySelector("div > df-messenger-chat").shadowRoot.querySelector("div > df-messenger-user-input").shadowRoot.querySelector("#sendIcon");
                //         button.dispatchEvent(new Event('click'));
                //         this.props.setWidget(false, true, this.props.match.params.id);
                //     }
                // }}
                />
                {/* <Button color="secondary"
                    disabled={!(item.blockButton || false)}
                    classes={{ root: this.props.classes.button }}
                    onClick={() => {
                        var text = document.querySelector("#df-messenger").shadowRoot.querySelector("div > df-messenger-chat").shadowRoot.querySelector("div > df-messenger-user-input").shadowRoot.querySelector("div > div.input-box-wrapper.valid > input[type=text]");
                        var text1 = document.querySelector("#df-messenger").shadowRoot.querySelector("div > df-messenger-chat").shadowRoot.querySelector("div > df-messenger-user-input").shadowRoot.querySelector("div > div.input-box-wrapper > input[type=text]")
                        if (text) text.value = `quiero ${item.amount} ${item.quantity_unit} de ${item.name}`;
                        if (text1) text1.value = `quiero ${item.amount} ${item.quantity_unit} de ${item.name}`;
                        var button = document.querySelector("#df-messenger").shadowRoot.querySelector("div > df-messenger-chat").shadowRoot.querySelector("div > df-messenger-user-input").shadowRoot.querySelector("#sendIcon");
                        button.dispatchEvent(new Event('click'));
                        item.amount = 0;
                        this.props.setWidget(false, true, this.props.match.params.id);
                    }}><ShoppingCartOutlined />
                </Button> */}
            </TableCell>
        )
    }

    handleCloseImageDialog = () => {
        this.setState({
            openImagePopUp: false,
            urlImagePopUp: ''
        });
    }

    // eslint-disable-next-line no-unused-vars
    handlePageChange = (event, page) => {
        const nitems = 15;
        this.setState({
            pagelist: this.state.updatedList.slice(nitems * (page - 1), nitems * page),
            npage: page
        });
    }

    setPages = (list) => {
        const nitems = 15;
        this.setState({
            pagelist: list.slice(0, 15),
            npages: Math.ceil(list.length / nitems),
            npage: 1
        });
    }

    render() {
        const { classes } = this.props;
        const { productList, selectedTag, openImagePopUp, urlImagePopUp,
            pagelist, npages, npage,
            productOpen, productData
        } = this.state;

        let titulo = "LISTADO DE PRODUCTOS";

        return (
            <React.Fragment>
                <Appbar back={true} label={titulo} onBack={this.onBack} xs={true}
                    update={() => {
                        const { dispatch } = this.props;
                        dispatch(productActions.getAllNoToken(this.props.match.params.token, this.props.sessionId));
                        updatedSession = true;
                        ++this.childKey;
                    }} />
                <FiltersV2
                    key={this.childKey}
                    reset={true}
                    fullWidth={true}
                    onbarfieldlist={[
                        {
                            label: 'BUSCAR',
                            text: true,
                            field: 'name'
                        },
                    ]}
                    fieldList={[
                        {
                            label: 'Categoria',
                            combo: true,
                            field: 'category'
                        },
                        {
                            label: 'Tipo',
                            combo: true,
                            field: 'kindname'
                        },
                        {
                            label: 'Recomendados',
                            list: true,
                            field: 'tags'
                        },
                    ]}
                    list={productList}
                    selectedTag={selectedTag}
                    updateList={this.updateList}
                />
                <Grid className={classes.table}>
                    <ResponsiveTable
                        headCells={[
                            {
                                id: 'pedir',
                                label: 'CANTIDAD',
                                noSortable: true,
                                render: (item, index) => this.renderActions(item, index + 1000)
                            },
                            {
                                id: 'name',
                                label: 'NOMBRE'
                            },
                            {
                                id: 'price_per_unit',
                                label: 'PRECIO',
                                type: 'number',
                            },
                            {
                                id: 'brandname',
                                label: 'MARCA'
                            },
                            {
                                id: 'category',
                                label: 'CATEGORIA'
                            },
                            {
                                id: 'unit',
                                label: 'UNIDAD'
                            },
                            {
                                id: 'tags',
                                label: 'Recomendados'
                            },
                            {
                                id: 'description',
                                label: 'Descripcion'
                            },
                        ]}
                        curr_symbol={this.state.curr_symbol}
                        rows={pagelist}
                        handleSelectRow={(row) => {
                            if (row) {

                                const log = {
                                    "activity_name": "Vista de producto",
                                    "payload": {
                                        "Producto": {
                                            'nombre': row.name,
                                            'original_id': row.original_id,
                                            'id': row.id
                                        }
                                    },
                                    "session_id": this.props.sessionId
                                };
                                const { dispatch } = this.props;
                                dispatch(clientActions.setClientLog(log));
                                if (!tableClick) {
                                    this.setState({
                                        productData: row,
                                        productOpen: true,
                                    })
                                }
                                tableClick = false;
                            }
                        }}
                    />
                </Grid>
                <Pagination
                    count={npages}
                    // size="large"
                    // variant="outlined"
                    shape="rounded"
                    page={npage}
                    onChange={this.handlePageChange}
                    color="primary"
                    sx={{ padding: '10px', margin: '0 auto' }}
                />
                <div ref={el => (this.instance = el)} />
                {/* <Tooltip title={(this.state.toAdd ? 'Agregar items al pedido' : 'Antes debe cargar su email en arturito, y seleccionar cantidad en uno o mas productos')} placement="right" arrow> */}
                <Fade in={this.state.toAdd && !((this.props.orderId && this.props.orderId.orderId) ? true : false)}
                    {...(this.state.toAdd ? { timeout: 500 } : {})}>
                    <div className={classes.widgetAdd}>
                        <Button
                            className={classes.icoButton}
                            onClick={this.handleSubmitItems}>
                            Agregar al pedido
                            {/* <AddShoppingCart className={this.state.toAdd ? classes.ico : ''} /> */}
                        </Button>
                    </div>
                </Fade>
                <Fade in={this.state.toAdd &&
                    ((this.props.orderId && this.props.orderId.orderId) ? true : false)}>
                    <div className={classes.widgetAddExpand}>
                        <Button
                            className={classes.icoButton}
                            onClick={this.handleSubmitItems}>
                            Agregar al pedido
                            {/* <AddShoppingCart className={this.state.toAdd ? classes.ico : ''} /> */}
                        </Button>
                    </div>
                </Fade>
                <Fade in={!this.state.toAdd &&
                    ((this.props.orderId && this.props.orderId.orderId) ? true : false)}>
                    <div className={classes.widgetAddExpand}>
                        <Button
                            className={classes.icoButton}
                            onClick={this.handleSubmitItems}>
                            Finalizar pedido
                            {/* <AddShoppingCart className={this.state.toAdd ? classes.ico : ''} /> */}
                        </Button>
                    </div>
                </Fade>
                {
                    (((this.props.session && this.props.session.session && this.props.session.session.current_order_token) ? true : false) ||
                        ((this.props.orderId && this.props.orderId.orderId && this.props.orderId.orderId.token) ? true : false)) &&
                    <div className={classes.widgetCart}>
                        <Button
                            className={classes.icoButton}
                            onClick={this.handleGoToStatus}
                        >
                            Ver pedido
                        </Button>
                    </div>
                }
                <ProductPreview
                    open={productOpen}
                    product={productData}
                    handleClose={() => {
                        this.setState({
                            productOpen: false,
                        })
                    }
                    }
                ></ProductPreview>
                <ImagePopUp
                    open={openImagePopUp}
                    url={urlImagePopUp}
                    handleClose={this.handleCloseImageDialog}
                />
            </React.Fragment>
        );
    }

    componentDidMount() {
        if (window.location.href.startsWith('https://arturito.chat') || window.location.href.startsWith('https://arturito.store')) {
            //tracking script from ActiveCampaign
            const s = document.createElement('script');
            s.type = 'text/javascript';
            s.async = true;
            s.innerHTML = '(function(e,t,o,n,p,r,i){e.visitorGlobalObjectAlias=n;e[e.visitorGlobalObjectAlias]=e[e.visitorGlobalObjectAlias]||function(){(e[e.visitorGlobalObjectAlias].q=e[e.visitorGlobalObjectAlias].q||[]).push(arguments)};e[e.visitorGlobalObjectAlias].l=(new Date).getTime();r=t.createElement("script");r.src=o;r.async=true;i=t.getElementsByTagName("script")[0];i.parentNode.insertBefore(r,i)})(window,document,"https://diffuser-cdn.app-us1.com/diffuser/diffuser.js","vgo");vgo("setAccount", "476725427");vgo("setTrackByDefault", true);vgo("process");';
            this.instance.appendChild(s);
        }

        if (!this.props.setWidget) {
            console.log('Error user or session, redirecting')
            this.props.history.push('/homeclient');
            return null;
        }

        // lastPageLocal = sessionStorage.getItem("homeStart");
        // var lastPageUrl = window.frames.top.document.referrer;
        // lastPageLocal = (lastPageUrl.indexOf('arturito.chat') > 0 ||
        //     lastPageUrl.indexOf('arturito-staging') > 0 ||
        //     lastPageUrl.indexOf('localhost') > 0);
        oneTimeWelcome = !sessionStorage.getItem("homeStart");

        const { dispatch } = this.props;
        if (this.props.sessionId) {
            const log = {
                "activity_name": "Ingreso lista de productos",
                "payload": null,
                "session_id": this.props.sessionId
            };
            dispatch(clientActions.setClientLog(log));
        }

        if (this.props.match.params.token)
            dispatch(userActions.recoverSession(this.props.match.params.token));

        // let welcome = false;
        // let lastPageUrl = document.referrer;
        // if (lastPageUrl.indexOf('arturito.chat') < 0) {
        //     welcome = 'MENU'
        // }


        // this.props.setWidget(false, false, '', welcome, 'Haz click aqui para conversar');
        // eslint-disable-next-line react/no-did-mount-set-state
        // this.setState({ token: this.props.match.params.token });
        this.props.setWidget(false, false);
        // eslint-disable-next-line react/no-did-mount-set-state
        if (this.props.orderId) this.setState({ toAdd2: true })
        // catch widget events
        // const dfMessenger1 = document.querySelector('df-messenger');
        // dfMessenger1.addEventListener('df-response-received', (event) => {
        //     const phraseLista = /\bMostrando lista de productos\b/i

        //     const messages = event.detail.response.queryResult.fulfillmentMessages;

        //     if (event.detail.response.queryResult.intent.displayName === 'order.discard') {
        //         history.push('/homeclient');
        //     }

        //     if (messages) {
        //         messages.forEach(message => {
        //             if (message && message.payload && message.payload.richContent && (message.payload.richContent[0].length > 1) && message.payload.richContent[0][1].event) {
        //                 if (message.payload.richContent[0][1].event.name === 'viewproductlist') {
        //                     Object.assign(dicProducts, { [message.payload.richContent[0][1].event.parameters.store_hash]: message.payload.richContent[0][1].event.parameters.token });
        //                 }
        //             }
        //             if (message && message.payload && message.payload.richContent && message.payload.richContent[0][0].event) {
        //                 if (message.payload.richContent[0][0].event.name === 'viewproductlist') {
        //                     Object.assign(dicProducts, { [message.payload.richContent[0][0].event.parameters.store_hash]: message.payload.richContent[0][0].event.parameters.token });
        //                 }
        //             }
        //             if (message && message.text && message.text.text) {
        //                 message.text.text.forEach(element => {
        //                     if (phraseLista.test(element)) {
        //                         dispatch(productActions.getAllNoToken(dicProducts[String(element.match(/#\S+/g))], this.props.sessionId));
        //                         this.setState({
        //                             updated: false,
        //                             token: dicProducts[String(element.match(/#\S+/g))],
        //                         });
        //                     }
        //                 });
        //             }
        //         });
        //     }
        // });
        localStorage.removeItem('exitHome');
        localStorage.removeItem('exitOrder');
        localStorage.removeItem('sessionid');
        localStorage.removeItem('updateOrder');
        localStorage.removeItem('itemAdded');
        localStorage.removeItem('updatedlist');
        localStorage.removeItem('updatedorder');
    }

    componentWillUnmount() {
        window.removeEventListener('df-response-received', () => { });
    }

    componentDidUpdate() {
        const updatedlist = localStorage.getItem('updatedlist');
        const exitHome = localStorage.getItem('exitHome');
        const exitOrder = localStorage.getItem('exitOrder');
        // const itemAdded = localStorage.getItem('itemAdded', 'true');

        if (exitOrder) {
            // localStorage.removeItem('exitOrder');
            localStorage.removeItem('exitHome');
            // localStorage.removeItem('exitOrder');
            // localStorage.removeItem('sessionid');
            localStorage.removeItem('updateOrder');
            localStorage.removeItem('itemAdded');
            localStorage.removeItem('updatedlist');
            localStorage.removeItem('updatedorder');
            if (this.props.orderId && this.props.orderId.orderId && this.props.orderId.orderId.token) {
                const tkn = this.props.orderId.orderId.token;
                this.props.orderId.orderId = null;
                this.props.history.push('/orderstatus/' + tkn);
                return null
            }
        }

        if (exitHome) {
            localStorage.removeItem('exitHome');
            localStorage.removeItem('exitOrder');
            localStorage.removeItem('sessionid');
            localStorage.removeItem('updateOrder');
            localStorage.removeItem('itemAdded');
            localStorage.removeItem('updatedlist');
            localStorage.removeItem('updatedorder');
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({
                toAdd: false
            });
            this.props.orderId.orderId = null;
            this.props.history.push('/homeclient');
            return null
        }

        // if (itemAdded) {
        //     this.props.history.push('/homeclient');
        //     return null
        // }


        if ((updatedlist) && (this.props.products && this.props.products.products && (this.props.products.products.length > 0))) {
            // eslint-disable-next-line react/no-did-update-set-state
            // this.setState({
            //     token: updatedlist,
            // });
            localStorage.removeItem('updatedlist');
            const { dispatch } = this.props;
            dispatch(productActions.getAllNoToken(this.props.match.params.token, this.props.sessionId));
            updatedSession = true;
        }
        if ((this.state.updated !== undefined && this.state.updated === false) &&
            (this.props.products && this.props.products.products && (this.props.products.products.length > 0))) {
            this.processData();
        }

        if (this.props.session && !updatedSession) {
            this.checkSession();
        }

        // if (this.props.orderId && this.props.orderId.orderId && this.props.orderId.orderId !== orderId) {
        //     var text = document.querySelector("#df-messenger").shadowRoot.querySelector("div > df-messenger-chat").shadowRoot.querySelector("div > df-messenger-user-input").shadowRoot.querySelector("div > div.input-box-wrapper.valid > input[type=text]");
        //     var text1 = document.querySelector("#df-messenger").shadowRoot.querySelector("div > df-messenger-chat").shadowRoot.querySelector("div > df-messenger-user-input").shadowRoot.querySelector("div > div.input-box-wrapper > input[type=text]")
        //     if (text) text.value = `Items agregados, ver estado del pedido`;
        //     if (text1) text1.value = `Items agregados, ver estado del pedido`;
        //     var button = document.querySelector("#df-messenger").shadowRoot.querySelector("div > df-messenger-chat").shadowRoot.querySelector("div > df-messenger-user-input").shadowRoot.querySelector("#sendIcon");
        //     button.dispatchEvent(new Event('click'));
        //     orderId = this.props.orderId.orderId;
        //     this.props.setWidget(false, false, '', false, 'Nuevo mensaje, haz click para conversar');
        // }

    }
}

function mapStateToProps(state) {
    const { products, orderId, session } = state;

    return {
        products,
        orderId,
        session,
    };
}

export default connect(mapStateToProps)(withStyles(styles)(ProducList));