import { clientConstants } from '../_constants';

export function clients(state = {}, action) {
  switch (action.type) {
    case clientConstants.GETALL_REQUEST:
      return {
        loading: true
      };
    case clientConstants.GETALL_SUCCESS:
      return {
        clients: action.clients
      };
    case clientConstants.GETALL_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}

export function logs(state = {}, action) {
  switch (action.type) {
    case clientConstants.GETLOGS_REQUEST:
      return {
        loading: true
      };
    case clientConstants.GETLOGS_SUCCESS:
      return {
        logs: action.logs
      };
    case clientConstants.GETLOGS_FAILURE:
      return {
        error: action.error
      };
    case clientConstants.SETLOGS_REQUEST:
      return {
        loading: true
      };
    case clientConstants.SETLOGS_SUCCESS:
      return {
        logs: action.logs
      };
    case clientConstants.SETLOGS_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}

export function store(state = {}, action) {
  switch (action.type) {
    case clientConstants.SETSTORE_REQUEST:
      return {
        loading: true
      };
    case clientConstants.SETSTORE_SUCCESS:
      return {
        store: action.store
      };
    case clientConstants.SETSTORE_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}