/* eslint-disable react/jsx-no-bind */
import React from 'react';
import PropTypes from 'prop-types';
import {
    Typography, Divider,
} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import SelectM from '@material-ui/core/Select';
import { history } from '../_helpers';
import withStyles from '@material-ui/core/styles/withStyles';

// eslint-disable-next-line no-unused-vars
const styles = theme => ({
    alert: {
        color: '#f44336',
    },
    formControl: {
        minWidth: 100,
        width: '100%'
    },
    main: {
        'margin-top': '16px',
        'margin-bottom': '8px',
    },
    inline: {
        display: 'inline-flex',
        'align-items': 'center',
        height: '45px',
        width: '100%'
    },
    frame: {
        backgroundColor: '#e5ddd5',
        border: '1px solid #d5ddd5',
        padding: '10px',
        'box-shadow': '5px 10px 18px #888888',
        'border-radius': '10px',
        width: '400px',
    },
    dialogBot: {
        backgroundColor: '#fff',
        border: '1px solid #d5ddd5',
        padding: '10px 15px 10px 15px',
        'width': 'fit-content',
        margin: '10px',
        'border-radius': '20px',
        'max-width': '290px',
        'font-size': '13px',
    },
    dialogHuman: {
        backgroundColor: '#dcf8c6',
        border: '1px solid #d5ddd5',
        padding: '10px 15px 10px 15px',
        'width': 'fit-content',
        margin: '10px',
        'border-radius': '20px',
        'max-width': '290px',
        'margin-left': 'auto',
        'margin-right': '10px',
        'font-size': '13px',
    },
    highlight: {
        backgroundColor: '#FFFF00',
    }
});

class WidgetPreview extends React.PureComponent {

    static propTypes = {
        classes: PropTypes.object,
        weekMessages: PropTypes.array,
        refCodes: PropTypes.array,
        welcomeMessage: PropTypes.string,
        orderMessage: PropTypes.string,
        endMessage: PropTypes.string,
        storeHash: PropTypes.string,
        storeName: PropTypes.string,
    }

    state = {
        day: 7,
        code: -1,
    }

    render() {
        const { refCodes, welcomeMessage, orderMessage, endMessage, classes } = this.props;
        const { day, code } = this.state;
        return (
            <div>
                <Typography
                    color="primary"
                    variant="caption"
                >
                    Preview del dialogo del agente
                </Typography>
                <Divider className={classes.divider} />
                <div className={classes.inline}>
                    <Typography variant="subtitle2" color="inherit">Día de la semana:</Typography>
                    &nbsp;&nbsp;
                    <SelectM
                        value={day}
                        onChange={(event) => {
                            this.setState({
                                day: event.target.value,
                            });
                        }}
                        name='day'
                        inputProps={{
                            name: 'selectDay',
                            id: 'select-type',
                        }}
                    >
                        <MenuItem value={7}>Ninguno</MenuItem>
                        <MenuItem value={0}>Lunes</MenuItem>
                        <MenuItem value={1}>Martes</MenuItem>
                        <MenuItem value={2}>Miercoles</MenuItem>
                        <MenuItem value={3}>Jueves</MenuItem>
                        <MenuItem value={4}>Viernes</MenuItem>
                        <MenuItem value={5}>Sábado</MenuItem>
                        <MenuItem value={6}>Domingo</MenuItem>
                    </SelectM>
                    <Typography
                        color="primary"
                        variant="caption"
                        onClick={() => {
                            history.push("/marketing?section=calendario")
                            // this.props.handleChangeTab(event, 7)
                        }}
                    >
                        Ver configuracion
                    </Typography>
                </div>
                <div className={classes.inline}>
                    <Typography variant="subtitle2" color="inherit">Código promocional:</Typography>
                    &nbsp;&nbsp;
                    <SelectM
                        value={code}
                        onChange={(event) => {
                            this.setState({
                                code: event.target.value,
                            });
                        }}
                        name='code'
                        inputProps={{
                            name: 'selectDay',
                            id: 'select-type',
                        }}
                    >
                        <MenuItem value={-1}>Ninguno</MenuItem>
                        {refCodes.map((item, index) => (
                            <MenuItem key={index} value={index}>{item.description}</MenuItem>
                        ))}
                    </SelectM>
                    <Typography
                        color="primary"
                        variant="caption"
                        onClick={() => {
                            history.push("/marketing?section=acciones")
                        }}
                    >
                        Ver configuracion
                    </Typography>
                </div>
                <br /><br />
                Saludo inicial (mas opciones de arriba):
                {(this.state.code === -1) &&
                    <div className={classes.frame}>
                        <div className={classes.dialogHuman}>
                            Hola
                        </div>
                        <div className={classes.dialogBot}>
                            Hola Alejandro!&nbsp;
                            <span className={classes.highlight}>
                                {(welcomeMessage ? welcomeMessage : `Soy Arturito 🤖 el asistente de ${this.props.storeName}.`)}
                                &nbsp;
                            </span>
                            Puedes ver las opciones en cualquier momento escribiendo MENU o 🧭.
                        </div>
                        {(this.state.day !== -1) &&
                            <div>
                                <div className={classes.dialogBot}>
                                    <span className={classes.highlight}>
                                        {(this.props.weekMessages.length>1?this.props.weekMessages[this.state.day].promo_msg:'')}
                                    </span>
                                </div>
                                <div className={classes.dialogBot}>
                                    {'> Ver (o link usando whatsapp)'}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </div>
                            </div>
                        }
                        &nbsp;&nbsp;&nbsp;&nbsp;.&nbsp;.&nbsp;.
                    </div>
                }
                {(this.state.code !== -1) &&
                    <div className={classes.frame}>
                        <div className={classes.dialogHuman}>
                            <span className={classes.highlight}>
                                {`${this.props.refCodes[this.state.code].client_msg_preamble} usando el codigo ${this.props.refCodes[this.state.code].code}`}
                            </span>
                        </div>
                        <div className={classes.dialogBot}>
                            <span className={classes.highlight}>
                                {this.props.refCodes[this.state.code].use_confirm_finisher}
                            </span><br />
                            {`Estas operando con el negocio ${this.props.storeName}`}
                        </div>
                        {(this.state.day !== -1) &&
                            <div>
                                <div className={classes.dialogBot}>
                                    <span className={classes.highlight}>
                                        {this.props.weekMessages[this.state.day].promo_msg}
                                    </span>
                                </div>
                                <div className={classes.dialogBot}>
                                    {'> Ver (o link usando whatsapp)'}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </div>
                            </div>
                        }
                        &nbsp;&nbsp;&nbsp;&nbsp;.&nbsp;.&nbsp;.
                    </div>
                }
                <br />
                Empezar un pedido:
                <div className={classes.frame}>
                    &nbsp;&nbsp;&nbsp;&nbsp;.&nbsp;.&nbsp;.
                    <div className={classes.dialogHuman}>
                        Quiero pedir
                    </div>
                    <div className={classes.dialogBot}>
                        Empezamos un nuevo pedido en {this.props.storeHash} qué producto te puedo ofrecer?
                    </div>
                    <div className={classes.dialogBot}>
                        Si quieres puedes ver la lista de productos en el siguiente enlace:
                    </div>
                    <div className={classes.dialogBot}>
                        {'> Ver (o link usando whatsapp)'}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </div>
                    {orderMessage &&
                        <div className={classes.dialogBot}>
                            <span className={classes.highlight}>
                                {orderMessage}
                            </span>
                        </div>
                    }
                    &nbsp;&nbsp;&nbsp;&nbsp;.&nbsp;.&nbsp;.
                </div>
                <br />
                Finalizar un pedido:
                <div className={classes.frame}>
                    &nbsp;&nbsp;&nbsp;&nbsp;.&nbsp;.&nbsp;.<br />
                    <div className={classes.dialogBot}>
                        Decime cualquier observación extra que tengas sobre el pedido
                    </div>
                    <div className={classes.dialogHuman}>
                        Nada mas
                    </div>
                    <div className={classes.dialogBot}>
                        Genial!
                    </div>
                    {endMessage &&
                        <div className={classes.dialogBot}>
                            <span className={classes.highlight}>
                                {endMessage}
                            </span>
                        </div>
                    }
                </div>
            </div >
        );
    }
}

WidgetPreview.defaultProps = {
    weekMessages: [],
    refCodes: [],
    welcomeMessage: '',
    orderMessage: '',
    endMessage: ''
};

export default (withStyles(styles)(WidgetPreview));