import React from "react";
import PropTypes from "prop-types";
import {
    Button,
    Select,
    InputLabel,
    MenuItem,
    FormControl,
    Typography,
} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SearchIcon from "@material-ui/icons/Search";
import exportFromJSON from "export-from-json";
import Input from "../_components/Input";
import { convertDate } from "../_helpers/utils";
import Drawer from "@mui/material/Drawer";
// import { useMediaQuery } from 'react-responsive';
// import TextField from '@mui/material/TextField';

// const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

// eslint-disable-next-line no-unused-vars
const styles = (theme) => ({
    field: {
        minWidth: "200px",
        maxWidth: "500px",
    },
    searchIcon: {
        float: "left",
        "font-size": "30px",
        "margin-right": "10px",
    },
    clearIcon: {
        float: "left",
        "font-size": "30px",
        "margin-right": "10px",
        "margin-top": "5px",
    },
    filterList: {
        "padding-top": "13px",
        "font-size": "13px",
    },
    smallbtn: {
        height: "38px",
    },
    inline: {
        display: "inline-flex",
        height: "60px",
        width: "100%",
    },
    search: {
        display: "inline-flex",
        "text-align": "end",
        "margin-top": "-3px",
        height: "55px",
        width: "50%",
        "@media (min-width:0px) and (max-width: 800px)": {
            "z-index": "99",
            position: "absolute",
            top: "-4px",
            "text-align": "center",
            width: "75%",
            "background-color": "white",
            left: "14%",
        },
        "@media (min-width:800px)": {
            width: "50%",
        },
    },
    buttons: {
        display: "inline-flex",
        "padding-top": "13px",
        height: "55px",
        "padding-left": "5px",
        "@media (min-width:0px) and (max-width: 800px)": {
            width: "65%",
        },
        "@media (min-width:800px)": {
            width: "35%",
        },
    },
    filter: {
        "padding-top": "8px",
        "margin-left": "auto",
        height: "55px",
        display: "inline-flex",
        width: "195px",
        "margin-right": "-25px",
    },
    drawer: {
        "text-align": "center",
        width: "250px",
        "padding-top": "50px",
    },
});

var tableClick = false;

class FiltersV2 extends React.PureComponent {
    static propTypes = {
        reset: PropTypes.bool,
        exportXls: PropTypes.bool,
        // dispatch: PropTypes.func,
        // The label to show over the Filters
        classes: PropTypes.object,
        list: PropTypes.array,
        filteredList: PropTypes.array,
        updateList: PropTypes.func,
        fieldList: PropTypes.array,
        onbarfieldlist: PropTypes.array,
        pantalla: PropTypes.string,
        selectedTag: PropTypes.string,
        selectedStatus: PropTypes.string,
    };

    state = {
        expanded: false,
        onbarvalues: [],
        values: [], // element format: {field: string, value: string, combo = [items..]}
        valuesString: [],
        updated: false,
        updatedPredefTag: false,
        updatedPredefStatus: false,
        resultList: this.props.list,
        activeTag: false,
        activeTagText: "",
    };

    handleExpand = (event) => {
        event.preventDefault();
        if (!tableClick)
            this.setState({
                expanded: !this.state.expanded,
            });
    };

    handleClear = () => {
        tableClick = true;
        this.setState({
            valuesString: [],
            activeTag: false,
            resultList: this.props.list,
        });
        this.processData(true);
        this.props.updateList(this.props.list);
        setTimeout(() => {
            tableClick = false;
        }, 100);
    };

    handleExport = () => {
        this.processData();
        this.props.updateList(this.props.list);
    };

    handleChange = (event) => {
        event.preventDefault();
        this.setState({
            activeTag: false,
        });
        // eslint-disable-next-line react/no-direct-mutation-state
        this.state.values[event.target.name].value = event.target.value;
        // this.forceUpdate();
        this.handleSearch();
    };

    handleChangeOnbar = (event) => {
        event.preventDefault();
        tableClick = true;
        this.setState({
            activeTag: false,
        });
        // eslint-disable-next-line react/no-direct-mutation-state
        this.state.onbarvalues[event.target.name].value = event.target.value;
        // this.forceUpdate();
        this.handleSearch(this.state.onbarvalues);
    };

    fuzzySearch = (contentIn, fieldIn, date) => {
        let pass = true;
        if (contentIn && fieldIn) {
            const content = contentIn
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .split(/[\s,.!@#$%^&*()_+=:;"'<>?/]+/);
            const field = fieldIn
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .split(/[\s,.!@#$%^&*()_+=:;"'<>?/]+/);

            if (date) {
                if (contentIn.toUpperCase().startsWith(fieldIn.toUpperCase())) {
                    pass = true;
                } else pass = false;
            } else {
                field.forEach((itemfield) => {
                    let pass2 = false;
                    content.forEach((itemcontent) => {
                        if (
                            itemcontent
                                .toUpperCase()
                                .indexOf(itemfield.toUpperCase()) !== -1
                        ) {
                            pass2 = pass2 || true;
                        }
                    });
                    if (pass2) pass = pass && true;
                    else pass = false;
                });
            }
        }
        return pass;
    };

    handleSearch = (valuesIn) => {
        let result = [];
        let resultString1 = "";
        let resultString2 = "";
        let resultString3 = "";
        let values = valuesIn;

        if (!values || values.length === 0) values = this.state.values;

        this.props.list.forEach((item) => {
            let pass = true;

            values.forEach((field) => {
                if (field.value !== "") {
                    if (field.text)
                        if (
                            item[field.field] &&
                            this.fuzzySearch(item[field.field], field.value)
                        ) {
                            // if ((item[field.field]) && (item[field.field].toUpperCase().indexOf(field.value.toUpperCase())) !== -1) {
                            //     pass = pass && true;
                            // } else pass = false;
                            pass = pass && true;
                            if (resultString1.indexOf(field.value) === -1)
                                resultString1 += `${field.value} `;
                        } else pass = false;

                    if (field.date)
                        if (
                            item[field.field] &&
                            this.fuzzySearch(
                                convertDate(item[field.field]),
                                field.value,
                                true
                            )
                        ) {
                            // if ((item[field.field]) && (item[field.field].toUpperCase().indexOf(field.value.toUpperCase())) !== -1) {
                            //     pass = pass && true;
                            // } else pass = false;
                            pass = pass && true;
                            if (resultString1.indexOf(field.value) === -1)
                                resultString1 += `${field.value} `;
                        } else pass = false;

                    if (field.combo) {
                        if (
                            item[field.field] &&
                            field.value &&
                            item[field.field]
                                .toUpperCase()
                                .indexOf(field.value.toUpperCase()) !== -1
                        ) {
                            pass = pass && true;
                            if (resultString2.indexOf(field.value) === -1)
                                resultString2 += `${field.value} `;
                        } else pass = false;
                    }

                    if (field.list) {
                        let pass2 = false;
                        item[field.field].forEach((itemfield) => {
                            // if ((itemfield.toUpperCase().indexOf(field.value.toUpperCase())) !== -1) {
                            if (
                                itemfield.toUpperCase() ===
                                field.value.toUpperCase()
                            ) {
                                pass2 = pass2 || true;
                                if (resultString3.indexOf(field.value) === -1)
                                    resultString3 += `${field.value} `;
                            } else pass2 = pass2 || false;
                        });
                        if (pass2) pass = pass && true;
                        else pass = false;
                    }
                } else pass = pass && true;
            });
            if (pass) result.push(item);
        });
        let valuesString = [];
        if (resultString1) valuesString.push(resultString1);
        if (resultString2) valuesString.push(resultString2);
        if (resultString3) valuesString.push(resultString3);
        this.setState({
            valuesString: valuesString,
            resultList: result,
        });

        this.props.updateList(result);
    };

    processPreTag = () => {
        if (this.props.selectedTag) {
            this.handleSearch();
        }
        this.setState({
            updatedPredefTag: false,
        });
    };

    processPreStatus = () => {
        if (this.props.selectedStatus) {
            this.handleSearch();
        }
        this.setState({
            updatedPredefStatus: false,
        });
    };

    processData = (clean, tag) => {
        const { list, fieldList, onbarfieldlist } = this.props;

        if (fieldList && fieldList.length > 0 && list && list.length > 0) {
            let fl = [];
            fieldList.forEach((field) => {
                let items = [];
                let presentT = false;
                let presentS = false;
                if (field.combo) {
                    list.forEach((elem) => {
                        items.push(elem[field.field]);
                        if (
                            this.props.selectedTag &&
                            elem[field.field] === this.props.selectedTag
                        ) {
                            presentT = true;
                        }
                        if (
                            this.props.selectedStatus &&
                            elem[field.field] === this.props.selectedStatus
                        ) {
                            presentS = true;
                        }
                    });
                    items = [...new Set(items)];
                    items.sort();
                }

                if (field.list) {
                    list.forEach((elem) => {
                        if (elem.tags !== "") {
                            if (!Array.isArray(elem.tags))
                                elem.tags = elem.tags.split(",");

                            if (!Array.isArray(elem.tags))
                                elem.tags = [elem.tags];
                        } else elem.tags = [];

                        elem.tags.forEach((tag) => {
                            items.push(tag);
                            if (
                                this.props.selectedTag &&
                                tag === this.props.selectedTag
                            ) {
                                presentT = true;
                            }
                            if (
                                this.props.selectedStatus &&
                                tag === this.props.selectedStatus
                            ) {
                                presentS = true;
                            }
                        });
                    });
                    items = [...new Set(items)];
                    items.sort();
                }

                const item = {
                    field: field.field,
                    label: field.label,
                    combos: items,
                    text: field.text,
                    date: field.date,
                    combo: field.combo,
                    list: field.list,
                    value: "",
                };
                fl.push(item);

                if (
                    (!clean || tag) &&
                    this.props.selectedTag &&
                    item.field === "tags"
                ) {
                    if (presentT) {
                        item.value = this.props.selectedTag;
                        this.setState({
                            updatedPredefTag: true,
                        });
                    }
                }
                if (
                    !clean &&
                    this.props.selectedStatus &&
                    item.field === "estado"
                ) {
                    if (presentS) {
                        item.value = this.props.selectedStatus;
                        this.setState({
                            updatedPredefStatus: true,
                        });
                    }
                }
            });
            this.setState({
                values: fl,
                updated: true,
            });
        }
        if (
            onbarfieldlist &&
            onbarfieldlist.length > 0 &&
            list &&
            list.length > 0
        ) {
            let fl = [];
            onbarfieldlist.forEach((field) => {
                let items = [];
                let presentT = false;
                let presentS = false;
                if (field.combo) {
                    list.forEach((elem) => {
                        items.push(elem[field.field]);
                        if (
                            this.props.selectedTag &&
                            elem[field.field] === this.props.selectedTag
                        ) {
                            presentT = true;
                        }
                        if (
                            this.props.selectedStatus &&
                            elem[field.field] === this.props.selectedStatus
                        ) {
                            presentS = true;
                        }
                    });
                    items = [...new Set(items)];
                    items.sort();
                }

                if (field.list) {
                    list.forEach((elem) => {
                        if (elem.tags !== "") {
                            if (!Array.isArray(elem.tags))
                                elem.tags = elem.tags.split(",");

                            if (!Array.isArray(elem.tags))
                                elem.tags = [elem.tags];
                        } else elem.tags = [];

                        elem.tags.forEach((tag) => {
                            items.push(tag);
                            if (
                                this.props.selectedTag &&
                                tag === this.props.selectedTag
                            ) {
                                presentT = true;
                            }
                            if (
                                this.props.selectedStatus &&
                                tag === this.props.selectedStatus
                            ) {
                                presentS = true;
                            }
                        });
                    });
                    items = [...new Set(items)];
                    items.sort();
                }

                const item = {
                    field: field.field,
                    label: field.label,
                    combos: items,
                    text: field.text,
                    date: field.date,
                    combo: field.combo,
                    list: field.list,
                    value: "",
                };
                fl.push(item);

                if (!clean && this.props.selectedTag && item.field === "tags") {
                    if (presentT) {
                        item.value = this.props.selectedTag;
                        this.setState({
                            updatedPredefTag: true,
                        });
                    } else {
                        this.setState({
                            valuesString: ["Productos disponibles"],
                        });
                        // const { dispatch } = this.props;
                        // dispatch(alertActions.success('Promocion o tag no valido'));
                    }
                }
                if (
                    !clean &&
                    this.props.selectedStatus &&
                    item.field === "estado"
                ) {
                    if (presentS) {
                        item.value = this.props.selectedStatus;
                        this.setState({
                            updatedPredefStatus: true,
                        });
                    } else {
                        this.setState({
                            valuesString: ["Productos disponibles"],
                        });
                        // const { dispatch } = this.props;
                        // dispatch(alertActions.success('Promocion o tag no valido'));
                    }
                }
            });
            this.setState({
                onbarvalues: fl,
                updated: true,
            });
        }
        this.setState({
            resultList: this.props.list,
        });
    };

    handleExport = () => {
        const fileName = this.props.pantalla
            ? `datosExportados-${this.props.pantalla}`
            : "datosExportados";
        const exportType = exportFromJSON.types.xls;
        const data = this.props.filteredList;
        exportFromJSON({ data, fileName, exportType });
    };

    activatePreTag = () => {
        this.setState({
            activeTag: true,
            activeTagText: this.props.selectedTag,
            updatedPredefTag: true,
        });
        this.processData(true, true);
    };

    render() {
        // eslint-disable-next-line no-unused-vars
        const { classes, reset, exportXls } = this.props;
        const {
            expanded,
            values,
            valuesString,
            onbarvalues,
            activeTag,
            activeTagText,
        } = this.state;

        return (
            <div className={classes.inline}>
                <div className={classes.search}>
                    {onbarvalues &&
                        onbarvalues.length > 0 &&
                        onbarvalues.map((field, index) => {
                            if (field.combos.length > 0) {
                                return (
                                    <FormControl className={classes.field}>
                                        <InputLabel htmlFor=''>
                                            {field.label}
                                        </InputLabel>
                                        <Select
                                            // eslint-disable-next-line react/jsx-no-bind
                                            onClick={() => {
                                                tableClick = true;
                                            }}
                                            // eslint-disable-next-line react/jsx-no-bind
                                            onBlur={() => {
                                                tableClick = false;
                                            }}
                                            name={`${index}`}
                                            value={
                                                this.state.onbarvalues[index]
                                                    .value
                                            }
                                            onChange={this.handleChangeOnbar}
                                            inputProps={{
                                                name: `${index}`,
                                                id: `select-type-${index}`,
                                            }}
                                        >
                                            {field.combos.map((item, index) => (
                                                <MenuItem
                                                    key={index}
                                                    value={item}
                                                >
                                                    {item}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                );
                            }
                            if (field.text | field.date) {
                                return (
                                    <Input
                                        size='small'
                                        variant='outlined'
                                        className={classes.field}
                                        disableAuto={true}
                                        key={`input2-${index}`}
                                        id={`input2-${index}`}
                                        InputProps={{
                                            endAdornment: <SearchIcon />,
                                        }}
                                        name={`${index}`}
                                        value={
                                            this.state.onbarvalues[index].value
                                        }
                                        label={field.label}
                                        // eslint-disable-next-line react/jsx-no-bind
                                        onClick={() => {
                                            tableClick = true;
                                        }}
                                        // eslint-disable-next-line react/jsx-no-bind
                                        onBlur={() => {
                                            tableClick = false;
                                        }}
                                        onChange={this.handleChangeOnbar}
                                    />
                                );
                            }
                            return "";
                        })}
                </div>
                <div className={classes.buttons}>
                    {onbarvalues && onbarvalues.length > 0 && (
                        <Button
                            variant={
                                valuesString.length > 0
                                    ? "outlined"
                                    : "contained"
                            }
                            color='primary'
                            size='small'
                            className={classes.smallbtn}
                            onClick={this.handleClear}
                        >
                            Ver todos
                        </Button>
                    )}
                    &nbsp;&nbsp;
                    {activeTagText && (
                        <Button
                            variant={activeTag ? "contained" : "outlined"}
                            color='primary'
                            size='small'
                            className={classes.smallbtn}
                            onClick={this.activatePreTag}
                        >
                            {activeTagText}
                        </Button>
                    )}
                </div>
                <div className={classes.filter}>
                    {valuesString && (
                        <Typography
                            color='primary'
                            variant='body2'
                            className={classes.filterList}
                        >
                            {this.state.resultList.length}
                            {this.state.resultList.length === 1
                                ? " item"
                                : " items"}
                        </Typography>
                    )}
                    <Button onClick={this.handleExpand}>
                        {valuesString.length > 0 ? "Filtro" : "Filtro"}(
                        {valuesString.length})
                        <ExpandMoreIcon className={classes.searchIcon} />
                    </Button>
                    <Drawer
                        anchor={"right"}
                        open={expanded}
                        onClose={this.handleExpand}
                    >
                        <div className={classes.drawer}>
                            <Typography
                                color='primary'
                                variant='body2'
                                className={classes.filterList}
                            >
                                Filtros:
                            </Typography>
                            {values &&
                                values.length > 0 &&
                                values.map((field, index) => {
                                    if (field.combos.length > 0) {
                                        return (
                                            <div key={`select-${index}`}>
                                                <FormControl
                                                    className={classes.field}
                                                >
                                                    <InputLabel htmlFor=''>
                                                        {field.label}
                                                    </InputLabel>
                                                    <Select
                                                        name={`${index}`}
                                                        value={
                                                            this.state.values[
                                                                index
                                                            ].value
                                                        }
                                                        onChange={
                                                            this.handleChange
                                                        }
                                                        inputProps={{
                                                            name: `${index}`,
                                                            id: `select-type-${index}`,
                                                        }}
                                                    >
                                                         <MenuItem
                                                                    key={'999'}
                                                                    value=''
                                                                >
                                                                    Ninguno
                                                                </MenuItem>
                                                        {field.combos.map(
                                                            (item, index) => (
                                                                <MenuItem
                                                                    key={index}
                                                                    value={item}
                                                                >
                                                                    {item}
                                                                </MenuItem>
                                                            )
                                                        )}
                                                    </Select>
                                                </FormControl>
                                                <br />
                                                <br />
                                            </div>
                                        );
                                    } else {
                                        if (field.text | field.date) {
                                            return (
                                                <div>
                                                    <Input
                                                        className={
                                                            classes.field
                                                        }
                                                        disableAuto={true}
                                                        id={`input-${index}`}
                                                        name={`${index}`}
                                                        value={
                                                            this.state.values[
                                                                index
                                                            ].value
                                                        }
                                                        label={field.label}
                                                        onChange={
                                                            this.handleChange
                                                        }
                                                    />
                                                    <br />
                                                    <br />
                                                </div>
                                            );
                                        }
                                    }
                                    return "";
                                })}
                            {valuesString && (
                                <Typography
                                    color='primary'
                                    variant='body2'
                                    className={classes.filterList}
                                >
                                    {valuesString.length === 1
                                        ? `Aplicado: ${valuesString}`
                                        : ""}
                                    {valuesString.length > 1
                                        ? `Aplicados: ${valuesString}`
                                        : ""}
                                </Typography>
                            )}
                            <br />
                            {`${this.state.resultList.length} ${this.state.resultList.length === 1
                                    ? "resultado"
                                    : "resultados"
                                }`}
                            <br />
                            <br />
                            {/* <Button variant="contained" color="primary" disabled={disabled} onClick={this.handleSearch}>Buscar</Button> */}
                            {reset && (
                                <Button
                                    variant='contained'
                                    color='primary'
                                    className={classes.smallbtn}
                                    onClick={this.handleClear}
                                >
                                    Limpiar Filtros
                                </Button>
                            )}
                            <br />
                            {exportXls && (
                                <Button
                                    variant='contained'
                                    color='primary'
                                    className={classes.smallbtn}
                                    onClick={this.handleExport}
                                >
                                    Exportar
                                </Button>
                            )}
                        </div>
                    </Drawer>
                </div>
            </div>
        );
    }
    componentDidMount() {
        if (!this.state.updated) {
            this.processData(this);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            !this.state.updated ||
            prevProps.selectedStatus !== this.props.selectedStatus
        ) {
            this.processData();
        }
        if (this.state.updatedPredefTag) {
            this.activatePreTag();
            this.processPreTag();
        }
        if (this.state.updatedPredefStatus) {
            this.processPreStatus();
        }
        // if (prevProps.list !== this.state.resultList) {
        //     // eslint-disable-next-line react/no-did-update-set-state
        //     this.setState({
        //         // resultList: this.props.list,
        //     })
        // }
    }
}

FiltersV2.defaultProps = {
    disabled: false,
    label: "",
    list: [],
    fieldList: [], // {label: string, combo: bool, field: string}
    reset: true,
    selectedTag: "",
    selectedStatus: "",
};

export default withStyles(styles)(FiltersV2);
