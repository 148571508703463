import { userConstants } from '../_constants';
import { userService } from '../_services';
import { alertActions } from './';
import { history } from '../_helpers';


export const userActions = {
    login,
    logout,
    register,
    getAll,
    enable,
    disable,
    setUserData,
    changePassword,
    startPasswordRecover,
    passwordRecover,
    uploadFaq,
    uploadCSV,
    getLogs,
    getLogsStore,
    setIntegrations,
    getIntegrations,
    getCSVStatus,
    getCSVStatusStore,
    uploadPricesCSV,
    uploadStockCSV,
    uploadListCSV,
    uploadDeleteCSV,
    uploadUpdateCSV,
    getStockCSV,
    getStoreUsers,
    addStoreUser,
    deleteStoreUser,
    getStorePaymentMethods,
    setStorePaymentMethods,
    setFAQs,
    getFAQs,
    getadminFAQsOAI,
    getFAQsOAI,
    getTheme,
    setTheme,
    setPromos,
    getPromos,
    recoverSession,
    syncAC,
    getClientLog,
    getStoreQRs,
    setStoreQRs,
    updateStore,
};

function syncAC(contact) {
    userService.syncAC(contact);
}

function recoverSession(token) {
    return dispatch => {
        dispatch(request(token));

        userService.recoverSession(token)
            .then(
                (session) => {
                    dispatch(success(session.data));
                },
                error => {
                    dispatch(failure(error.toString()));
                    // dispatch(alertActions.error(error.toString()));
                    // history.push('/404');
                }
            );
    };

    function request() { return { type: userConstants.SESSION_REQUEST } }
    function success(session) { return { type: userConstants.SESSION_SUCCESS, session } }
    function failure(error) { return { type: userConstants.SESSION_FAILURE, error } }
}

function setTheme(data, msg, lasturl) {
    return dispatch => {
        dispatch(request(data));

        userService.setTheme(data)
            .then(
                (theme) => {
                    dispatch(success(theme.data));
                    if (msg) dispatch(alertActions.success(msg))
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                    if (lasturl)
                        localStorage.setItem('lasturl', lasturl);
                    history.push('/login');
                }
            );
    };

    function request() { return { type: userConstants.SETTHEME_REQUEST } }
    function success(theme) { return { type: userConstants.SETTHEME_SUCCESS, theme } }
    function failure(error) { return { type: userConstants.SETTHEME_FAILURE, error } }
}

function getTheme(lasturl) {
    return dispatch => {
        dispatch(request());

        userService.getTheme()
            .then(
                theme => {
                    dispatch(success(theme.data));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                    if (lasturl)
                        localStorage.setItem('lasturl', lasturl);
                    history.push('/login');
                }
            );
    };

    function request() { return { type: userConstants.GETTHEME_REQUEST } }
    function success(theme) { return { type: userConstants.GETTHEME_SUCCESS, theme } }
    function failure(error) { return { type: userConstants.GETTHEME_FAILURE, error } }
}

function setFAQs(data, msg, lasturl) {
    return dispatch => {
        dispatch(request(data));

        userService.setFAQs(data)
            .then(
                (FAQs) => {
                    dispatch(success(FAQs.data));
                    if (msg) dispatch(alertActions.success(msg))
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                    if (lasturl)
                        localStorage.setItem('lasturl', lasturl);
                    history.push('/login');
                }
            );
    };

    function request() { return { type: userConstants.SETFAQ_REQUEST } }
    function success(FAQs) { return { type: userConstants.SETFAQ_SUCCESS, FAQs } }
    function failure(error) { return { type: userConstants.SETFAQ_FAILURE, error } }
}

function getFAQs(lasturl) {
    return dispatch => {
        dispatch(request());

        userService.getFAQs()
            .then(
                FAQs => {
                    dispatch(success(FAQs.data));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                    if (lasturl)
                        localStorage.setItem('lasturl', lasturl);
                    history.push('/login');
                }
            );
    };

    function request() { return { type: userConstants.GETFAQ_REQUEST } }
    function success(FAQs) { return { type: userConstants.GETFAQ_SUCCESS, FAQs } }
    function failure(error) { return { type: userConstants.GETFAQ_FAILURE, error } }
}

function getFAQsOAI(lasturl) {
    return dispatch => {
        dispatch(request());

        userService.getFAQsOAI()
            .then(
                FAQsOAI => {
                    dispatch(success(FAQsOAI.data));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                    if (lasturl)
                        localStorage.setItem('lasturl', lasturl);
                    history.push('/login');
                }
            );
    };

    function request() { return { type: userConstants.GETFAQOAI_REQUEST } }
    function success(FAQsOAI) { return { type: userConstants.GETFAQOAI_SUCCESS, FAQsOAI } }
    function failure(error) { return { type: userConstants.GETFAQOAI_FAILURE, error } }
}

function getadminFAQsOAI(id, lasturl) {
    return dispatch => {
        dispatch(request());

        userService.getadminFAQsOAI(id)
            .then(
                FAQsOAI => {
                    dispatch(success(FAQsOAI.data));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                    if (lasturl)
                        localStorage.setItem('lasturl', lasturl);
                    history.push('/login');
                }
            );
    };

    function request() { return { type: userConstants.GETFAQOAI_REQUEST } }
    function success(FAQsOAI) { return { type: userConstants.GETFAQOAI_SUCCESS, FAQsOAI } }
    function failure(error) { return { type: userConstants.GETFAQOAI_FAILURE, error } }
}

function setPromos(data, msg, lasturl) {
    return dispatch => {
        dispatch(request(data));

        userService.setPromos(data)
            .then(
                (promos) => {
                    dispatch(success(promos.data));
                    if (msg) dispatch(alertActions.success(msg))
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                    if (lasturl)
                        localStorage.setItem('lasturl', lasturl);
                    history.push('/login');
                }
            );
    };

    function request() { return { type: userConstants.SETPROMOS_REQUEST } }
    function success(promos) { return { type: userConstants.SETPROMOS_SUCCESS, promos } }
    function failure(error) { return { type: userConstants.SETPROMOS_FAILURE, error } }
}

function getPromos(lasturl) {
    return dispatch => {
        dispatch(request());

        userService.getPromos()
            .then(
                promos => {
                    dispatch(success(promos.data));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                    if (lasturl)
                        localStorage.setItem('lasturl', lasturl);
                    history.push('/login');
                }
            );
    };

    function request() { return { type: userConstants.GETPROMOS_REQUEST } }
    function success(promos) { return { type: userConstants.GETPROMOS_SUCCESS, promos } }
    function failure(error) { return { type: userConstants.GETPROMOS_FAILURE, error } }
}

function getClientLog(id, lasturl) {
    return dispatch => {
        dispatch(request(id));

        userService.getClientLog(id)
            .then(
                logs => {
                    dispatch(success(logs.data));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                    if (lasturl)
                        localStorage.setItem('lasturl', lasturl);
                }
            );
    };

    function request() { return { type: userConstants.GETLOGS_REQUEST } }
    function success(logs) { return { type: userConstants.GETLOGS_SUCCESS, logs } }
    function failure(error) { return { type: userConstants.GETLOGS_FAILURE, error } }
}

function setUserData(storeId, data, msg) {
    return dispatch => {
        dispatch(request(storeId, data));

        userService.setUserData(storeId, data)
            .then(
                () => {
                    dispatch(success());
                    if (msg) dispatch(alertActions.success(msg))
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                    // if (lasturl)
                    //     localStorage.setItem('lasturl', lasturl);
                    // history.push('/login');
                }
            );
    };

    function request() { return { type: userConstants.ACTIONS_REQUEST } }
    function success() { return { type: userConstants.ACTIONS_SUCCESS } }
    function failure(error) { return { type: userConstants.ACTIONS_FAILURE, error } }
}

function updateStore(storeId, data, msg) {
    return dispatch => {
        dispatch(request(storeId, data));

        userService.updateStore(storeId, data)
            .then(
                () => {
                    dispatch(success());
                    dispatch(userActions.getAll());
                    if (msg) dispatch(alertActions.success(msg))
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: userConstants.ACTIONS_REQUEST } }
    function success() { return { type: userConstants.ACTIONS_SUCCESS } }
    function failure() { return { type: userConstants.ACTIONS_FAILURE } }
}


function changePassword(data, msg, lasturl) {
    return dispatch => {
        dispatch(request(data));

        userService.changePassword(data)
            .then(
                (user) => {
                    dispatch(success(user.data));
                    if (msg) dispatch(alertActions.success(msg))
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                    if (lasturl)
                        localStorage.setItem('lasturl', lasturl);
                    history.push('/login');
                }
            );
    };

    function request() { return { type: userConstants.ACTIONS_REQUEST } }
    function success() { return { type: userConstants.ACTIONS_SUCCESS } }
    function failure(error) { return { type: userConstants.ACTIONS_FAILURE, error } }
}

function startPasswordRecover(data, msg) {
    return dispatch => {
        dispatch(request(data));

        userService.startPasswordRecover(data)
            .then(
                (user) => {
                    dispatch(success(user.data));
                    if (msg) dispatch(alertActions.success(msg))
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: userConstants.UPDATE_REQUEST } }
    function success() { return { type: userConstants.UPDATE_SUCCESS } }
    function failure(error) { return { type: userConstants.UPDATE_FAILURE, error } }
}

function passwordRecover(data, msg) {
    return dispatch => {
        dispatch(request(data));

        userService.passwordRecover(data)
            .then(
                (user) => {
                    dispatch(success(user.data));
                    if (msg) dispatch(alertActions.success(msg))
                    history.push('/login');
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                    history.push('/login');
                }
            );
    };

    function request() { return { type: userConstants.UPDATE_REQUEST } }
    function success() { return { type: userConstants.UPDATE_SUCCESS } }
    function failure(error) { return { type: userConstants.UPDATE_FAILURE, error } }
}



function setIntegrations(data, msg, lasturl) {
    return dispatch => {
        dispatch(request(data));

        userService.setIntegrations(data)
            .then(
                () => {
                    dispatch(success());
                    if (msg) dispatch(alertActions.success(msg))
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                    if (lasturl)
                        localStorage.setItem('lasturl', lasturl);
                    history.push('/login');
                }
            );
    };

    function request() { return { type: userConstants.SETINTEGRATIONS_REQUEST } }
    function success() { return { type: userConstants.SETINTEGRATIONS_SUCCESS } }
    function failure(error) { return { type: userConstants.SETINTEGRATIONS_FAILURE, error } }
}

function getIntegrations(lasturl) {
    return dispatch => {
        dispatch(request());

        userService.getIntegrations()
            .then(
                integrations => {
                    dispatch(success(integrations.data));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                    if (lasturl)
                        localStorage.setItem('lasturl', lasturl);
                    history.push('/login');
                }
            );
    };

    function request() { return { type: userConstants.GETINTEGRATIONS_REQUEST } }
    function success(integrations) { return { type: userConstants.GETINTEGRATIONS_SUCCESS, integrations } }
    function failure(error) { return { type: userConstants.GETINTEGRATIONS_FAILURE, error } }
}

function setStorePaymentMethods(data, msg, lasturl) {
    return dispatch => {
        dispatch(request(data));

        userService.setStorePaymentMethods(data)
            .then(
                paymentMethods => {
                    dispatch(success(paymentMethods.data));
                    if (msg) dispatch(alertActions.success(msg))
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                    if (lasturl)
                        localStorage.setItem('lasturl', lasturl);
                    history.push('/login');
                }
            );
    };

    function request() { return { type: userConstants.SETPM_REQUEST } }
    function success(paymentMethods) { return { type: userConstants.SETPM_SUCCESS, paymentMethods } }
    function failure(error) { return { type: userConstants.SETPM_FAILURE, error } }
}

function getStorePaymentMethods(lasturl) {
    return dispatch => {
        dispatch(request());

        userService.getStorePaymentMethods()
            .then(
                paymentMethods => {
                    dispatch(success(paymentMethods.data));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                    if (lasturl)
                        localStorage.setItem('lasturl', lasturl);
                    history.push('/login');
                }
            );
    };

    function request() { return { type: userConstants.GETPM_REQUEST } }
    function success(paymentMethods) { return { type: userConstants.GETPM_SUCCESS, paymentMethods } }
    function failure(error) { return { type: userConstants.GETPM_FAILURE, error } }
}

function uploadFaq(storeId, texts, msg) {
    return dispatch => {
        dispatch(request(storeId, texts));

        userService.uploadFaq(storeId, texts)
            .then(
                () => {
                    dispatch(success());
                    if (msg) dispatch(alertActions.success(msg))
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: userConstants.UPLOADFAQ_REQUEST } }
    function success() { return { type: userConstants.UPLOADFAQ_SUCCESS } }
    function failure(error) { return { type: userConstants.UPLOADFAQ_FAILURE, error } }
}

function getCSVStatusStore(msg) {
    return dispatch => {
        dispatch(request());

        userService.getCSVStatusStore()
            .then(
                (csvstatus) => {
                    dispatch(success(csvstatus.data));
                    if (msg) dispatch(alertActions.success(msg))
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: userConstants.GETCSVSTATUS_REQUEST } }
    function success(csvstatus) { return { type: userConstants.GETCSVSTATUS_SUCCESS, csvstatus } }
    function failure(error) { return { type: userConstants.GETCSVSTATUS_FAILURE, error } }
}

function getCSVStatus(msg) {
    return dispatch => {
        dispatch(request());

        userService.getCSVStatus()
            .then(
                (csvstatus) => {
                    dispatch(success(csvstatus.data));
                    if (msg) dispatch(alertActions.success(msg))
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: userConstants.GETCSVSTATUS_REQUEST } }
    function success(csvstatus) { return { type: userConstants.GETCSVSTATUS_SUCCESS, csvstatus } }
    function failure(error) { return { type: userConstants.GETCSVSTATUS_FAILURE, error } }
}

function getStockCSV(msg) {
    return dispatch => {
        dispatch(request());

        userService.getStockCSV()
            .then(
                (csv) => {
                    var FileSaver = require('file-saver');
                    var blob = new Blob([csv], { type: "text/plain;charset=utf-8" });
                    FileSaver.saveAs(blob, 'exportStock.csv');
                    dispatch(success(csv));
                    if (msg) dispatch(alertActions.success(msg))
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: userConstants.GETCSV_REQUEST } }
    function success() { return { type: userConstants.GETCSV_SUCCESS } }
    function failure(error) { return { type: userConstants.GETCSV_FAILURE, error } }
}

function uploadStockCSV(csv, msg) {
    return dispatch => {
        dispatch(request(csv));

        userService.uploadStockCSV(csv)
            .then(
                () => {
                    dispatch(success());
                    if (msg) dispatch(alertActions.success(msg))
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: userConstants.UPLOADCSV_REQUEST } }
    function success() { return { type: userConstants.UPLOADCSV_SUCCESS } }
    function failure(error) { return { type: userConstants.UPLOADCSV_FAILURE, error } }
}

function uploadPricesCSV(csv, msg) {
    return dispatch => {
        dispatch(request(csv));

        userService.uploadPricesCSV(csv)
            .then(
                () => {
                    dispatch(success());
                    if (msg) dispatch(alertActions.success(msg))
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: userConstants.UPLOADCSV_REQUEST } }
    function success() { return { type: userConstants.UPLOADCSV_SUCCESS } }
    function failure(error) { return { type: userConstants.UPLOADCSV_FAILURE, error } }
}

function uploadListCSV(csv, msg) {
    return dispatch => {
        dispatch(request(csv));

        userService.uploadListCSV(csv)
            .then(
                () => {
                    dispatch(success());
                    if (msg) dispatch(alertActions.success(msg))
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: userConstants.UPLOADCSV_REQUEST } }
    function success() { return { type: userConstants.UPLOADCSV_SUCCESS } }
    function failure(error) { return { type: userConstants.UPLOADCSV_FAILURE, error } }
}

function uploadUpdateCSV(csv, msg) {
    return dispatch => {
        dispatch(request(csv));

        userService.uploadUpdateCSV(csv)
            .then(
                () => {
                    dispatch(success());
                    if (msg) dispatch(alertActions.success(msg))
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: userConstants.UPLOADCSV_REQUEST } }
    function success() { return { type: userConstants.UPLOADCSV_SUCCESS } }
    function failure(error) { return { type: userConstants.UPLOADCSV_FAILURE, error } }
}

function uploadDeleteCSV(csv, msg) {
    return dispatch => {
        dispatch(request(csv));

        userService.uploadDeleteCSV(csv)
            .then(
                () => {
                    dispatch(success());
                    if (msg) dispatch(alertActions.success(msg))
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: userConstants.UPLOADCSV_REQUEST } }
    function success() { return { type: userConstants.UPLOADCSV_SUCCESS } }
    function failure(error) { return { type: userConstants.UPLOADCSV_FAILURE, error } }
}

function uploadCSV(storeId, csv, msg) {
    return dispatch => {
        dispatch(request(storeId, csv));

        userService.uploadCSV(storeId, csv)
            .then(
                () => {
                    dispatch(success());
                    if (msg) dispatch(alertActions.success(msg))
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: userConstants.UPLOADCSV_REQUEST } }
    function success() { return { type: userConstants.UPLOADCSV_SUCCESS } }
    function failure(error) { return { type: userConstants.UPLOADCSV_FAILURE, error } }
}

function getLogsStore(storeId, storeName) {
    return dispatch => {
        userService.getLogsStore(storeId)
            .then(
                (logs) => {
                    var FileSaver = require('file-saver');
                    var JSZip = require("jszip");
                    var json = JSON.stringify(logs, undefined, 2)
                    var blob = new Blob([json], { type: "text/plain;charset=utf-8" });
                    // FileSaver.saveAs(blob, "exportLogs.json");
                    var zip = new JSZip();
                    zip.file(`exportLogs-${storeName}.json`, blob);
                    zip.generateAsync({ type: "blob" })
                        .then(function (content) {
                            // see FileSaver.js
                            FileSaver(content, `exportLogs-${storeName}.zip`);
                        });
                    dispatch(alertActions.error("Logs exportados con éxito (por favor chequee el directorio de descagas)"));
                },
                error => {
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };
}

function getLogs() {
    return dispatch => {
        userService.getLogs()
            .then(
                (logs) => {
                    var FileSaver = require('file-saver');
                    var JSZip = require("jszip");
                    var json = JSON.stringify(logs, undefined, 2)
                    var blob = new Blob([json], { type: "text/plain;charset=utf-8" });
                    // FileSaver.saveAs(blob, "exportLogs.json");
                    var zip = new JSZip();
                    zip.file("exportLogs.json", blob);
                    zip.generateAsync({ type: "blob" })
                        .then(function (content) {
                            // see FileSaver.js
                            FileSaver(content, "exportLogs.zip");
                        });
                    // var lineArray = [];
                    // logs.forEach(function (infoArray, index) {
                    //     var line = infoArray.join(",");
                    //     lineArray.push(index == 0 ? "data:text/csv;charset=utf-8," + line : line);
                    // });
                    // var csvContent = lineArray.join("\n");
                    // var blob2 = new Blob(csvContent, { type: "text/plain;charset=utf-8" });
                    // FileSaver.saveAs(blob2, "exportLogs.json");
                    dispatch(alertActions.error("Logs exportados con éxito (por favor chequee el directorio de descagas)"));
                },
                error => {
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };
}

function login(email, password) {
    return dispatch => {
        dispatch(request({ email }));

        userService.login(email, password)
            .then(
                user => {
                    dispatch(success(user));
                    const lasturl = localStorage.getItem('lasturl');
                    if (lasturl)
                        history.push(lasturl);
                    else history.push('/home');
                },
                error => {
                    // eslint-disable-next-line
                    if (error == "TypeError: Failed to fetch") {
                        error = 'Conexión no disponible, por favor intentelo de nuevo';
                    }
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}

function getStoreUsers(lasturl) {
    return dispatch => {
        dispatch(request());

        userService.getStoreUsers()
            .then(
                users => {
                    dispatch(success(users.data));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                    if (lasturl)
                        localStorage.setItem('lasturl', lasturl);
                    history.push('/login');
                }
            );
    };

    function request() { return { type: userConstants.GETALL_REQUEST } }
    function success(users) { return { type: userConstants.GETALL_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GETALL_FAILURE, error } }
}

function getStoreQRs(lasturl) {
    return dispatch => {
        dispatch(request());

        userService.getStoreQRs()
            .then(
                qrs => {
                    dispatch(success(qrs.data));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                    if (lasturl)
                        localStorage.setItem('lasturl', lasturl);
                    history.push('/login');
                }
            );
    };

    function request() { return { type: userConstants.GETQRS_REQUEST } }
    function success(qrs) { return { type: userConstants.GETQRS_SUCCESS, qrs } }
    function failure(error) { return { type: userConstants.GETQRS_FAILURE, error } }
}

function setStoreQRs(data, lasturl) {
    return dispatch => {
        dispatch(request());

        userService.setStoreQRs(data)
            .then(
                qrs => {
                    dispatch(success(qrs.data));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                    if (lasturl)
                        localStorage.setItem('lasturl', lasturl);
                    history.push('/login');
                }
            );
    };

    function request() { return { type: userConstants.SETQRS_REQUEST } }
    function success(qrs) { return { type: userConstants.SETQRS_SUCCESS, qrs } }
    function failure(error) { return { type: userConstants.SETQRS_FAILURE, error } }
}

function addStoreUser(data, msg) {
    return dispatch => {
        dispatch(request());

        userService.addStoreUser(data)
            .then(
                () => {
                    dispatch(success());
                    dispatch(getStoreUsers());
                    if (msg) dispatch(alertActions.success(msg))
                },
                (error) => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                    // if (lasturl)
                    //     localStorage.setItem('lasturl', lasturl);
                    // history.push('/login');
                }
            );
    };

    function request() { return { type: userConstants.ENABLE_REQUEST } }
    function success() { return { type: userConstants.ENABLE_SUCCESS } }
    function failure(error) { return { type: userConstants.ENABLE_FAILURE, error } }
}

function deleteStoreUser(id, msg, lasturl) {
    return dispatch => {
        dispatch(request());

        userService.deleteStoreUser(id)
            .then(
                () => {
                    dispatch(success());
                    dispatch(getStoreUsers());
                    if (msg) dispatch(alertActions.success(msg));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                    if (lasturl)
                        localStorage.setItem('lasturl', lasturl);
                    history.push('/login');
                }
            );
    };

    function request() { return { type: userConstants.DISABLE_REQUEST } }
    function success() { return { type: userConstants.DISABLE_SUCCESS } }
    function failure(error) { return { type: userConstants.DISABLE_FAILURE, error } }
}

function getAll() {
    return dispatch => {
        dispatch(request());

        userService.getAll()
            .then(
                users => dispatch(success(users.data)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                    history.push('/login');
                }
            );
    };

    function request() { return { type: userConstants.GETALL_REQUEST } }
    function success(users) { return { type: userConstants.GETALL_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GETALL_FAILURE, error } }
}

function enable(id, msg) {
    return dispatch => {
        dispatch(request(id));

        userService.enable(id)
            .then(
                () => {
                    dispatch(success());
                    if (msg) dispatch(alertActions.success(msg))
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: userConstants.ENABLE_REQUEST } }
    function success() { return { type: userConstants.ENABLE_SUCCESS } }
    function failure(error) { return { type: userConstants.ENABLE_FAILURE, error } }
}

function disable(id, msg) {
    return dispatch => {
        dispatch(request(id));

        userService.disable(id)
            .then(
                () => {
                    dispatch(success());
                    if (msg) dispatch(alertActions.success(msg))
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: userConstants.DISABLE_REQUEST } }
    function success() { return { type: userConstants.DISABLE_SUCCESS } }
    function failure(error) { return { type: userConstants.DISABLE_FAILURE, error } }
}

function register(user) {
    return dispatch => {
        dispatch(request(user));

        userService.register(user)
            .then(
                // eslint-disable-next-line no-unused-vars
                user => {
                    dispatch(success(user));
                    history.push('/login');
                    dispatch(alertActions.success('Usuario registrado exitosamente'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                    history.push('/login');
                }
            );
    };
    function request(user) { return { type: userConstants.REGISTER_REQUEST, user } }
    function success(user) { return { type: userConstants.REGISTER_SUCCESS, user } }
    function failure(error) { return { type: userConstants.REGISTER_FAILURE, error } }

}

function logout() {
    userService.logout();
    return { type: userConstants.LOGOUT };
}