/* eslint-disable react/jsx-no-bind */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { userActions } from '../_actions';
import Input from '../_components/Input';
import logo from '../images/logo.png';
import Button from '@material-ui/core/Button';
import Link1 from '@material-ui/core/Link';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';
// import AlertDialog from '../_components/AlertDialog';


// eslint-disable-next-line no-unused-vars
const styles = theme => ({
    image: {
        width: 220,
        'margin-top': "50px",
    },
    link: {
        color: '#124e5c',
        textDecoration: 'none',
        "&:hover": {
            cursor: 'pointer',
        },
    },
    button: {
        width: '100%',
        borderRadius: '18px',
    }
});

class Login extends React.Component {
    static propTypes = {
        dispatch: PropTypes.func,
        classes: PropTypes.object,
        setWidget: PropTypes.func,
        syncAC: PropTypes.func,
        history: PropTypes.object,
        cleanData: PropTypes.func,
    }

    state = {
        email: '',
        password: '',
        checkLog: false,
        submitted: false,
    }

    handleChange = e => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleForgotPass = () => {
        this.props.history.push('/recoverpass');
    }

    handleSubmit = e => {
        e.preventDefault();

        this.setState({ submitted: true });
        const { email, password } = this.state;
        const { dispatch } = this.props;
        if (email && password) {
            this.props.syncAC([
                {
                    "field": "29",
                    "value": new Date().toISOString(),
                },
            ], email);
            dispatch(userActions.login(email, password));
        }
    }


    render() {
        const { classes } = this.props;
        const { email, password, submitted } = this.state;
        return (
            <React.Fragment>
                <FormControl onSubmit={this.handleSubmit}>
                    <div ref={el => (this.instance = el)} />
                    <Grid container spacing={3}
                        direction="column"
                        alignItems="center"
                    >
                        <Grid item xs={12} md={12}>
                            <img src={logo} alt="Logo" className={classes.image} />
                        </Grid>
                        <Grid
                            item xs={10} md={5}
                            container
                            direction="column"
                            alignItems="stretch"
                        >
                            <Input
                                id='input-name'
                                type='text'
                                label='EMAIL DEL USUARIO'
                                submitted={submitted}
                                name='email'
                                value={email}
                                errorMessage="Por favor ingrese el email del usuario"
                                onChange={this.handleChange}
                            />
                            <Input
                                id='input-pass'
                                type='password'
                                label='CONTRASEÑA'
                                submitted={submitted}
                                eye={true}
                                name='password'
                                value={password}
                                errorMessage="Por favor ingrese la contraseña"
                                onChange={this.handleChange}
                            />
                        </Grid>
                        <Grid
                            item xs={10} md={5}
                            container
                            direction="column"
                            alignItems="center"
                        >
                            <br /><br />
                            <Button variant="contained" color="primary" className={classes.button} onClick={this.handleSubmit}>Ingresar</Button>
                            <br /><br />
                            <Link1 onClick={this.handleForgotPass} className={classes.link}>¿Olvidaste tu contraseña?</Link1>
                            <br />
                            <Link1 onClick={() => { this.props.history.push('/register'); }} className={classes.link}>¿Deseas registrarte?</Link1>
                            <br />
                        </Grid>
                    </Grid>
                </FormControl>
            </React.Fragment>
        );
    }

    componentDidMount() {
        if (window.location.href.startsWith('https://arturito.chat') || window.location.href.startsWith('https://arturito.store')) {
            //tracking script from ActiveCampaign
            const s = document.createElement('script');
            s.type = 'text/javascript';
            s.async = true;
            s.innerHTML = '(function(e,t,o,n,p,r,i){e.visitorGlobalObjectAlias=n;e[e.visitorGlobalObjectAlias]=e[e.visitorGlobalObjectAlias]||function(){(e[e.visitorGlobalObjectAlias].q=e[e.visitorGlobalObjectAlias].q||[]).push(arguments)};e[e.visitorGlobalObjectAlias].l=(new Date).getTime();r=t.createElement("script");r.src=o;r.async=true;i=t.getElementsByTagName("script")[0];i.parentNode.insertBefore(r,i)})(window,document,"https://diffuser-cdn.app-us1.com/diffuser/diffuser.js","vgo");vgo("setAccount", "476725427");vgo("setTrackByDefault", true);vgo("process");';
            this.instance.appendChild(s);
        }

        this.props.cleanData(true);

        this.props.setWidget(true, false);
    }
}

function mapStateToProps(state) {
    const { loggingIn } = state.authentication;
    return {
        loggingIn
    };
}

export default connect(mapStateToProps)(withStyles(styles)(Login));