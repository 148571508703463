/* eslint-disable react/jsx-no-bind */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Appbar from '../_components/Appbar';
import withStyles from '@material-ui/core/styles/withStyles';
import {
    Grid, Typography, Tab, AppBar, Tabs, TableCell, Tooltip
} from '@material-ui/core';
import TabPanel from '../_components/TabPanel';
import { orderActions, userActions } from '../_actions';
import ResponsiveTable from '../_components/Table';
import { dicUnits } from '../_helpers/utils';
import Payment from '@material-ui/icons/MonetizationOn';
import Filters from '../_components/Filters';
// import { clientActions } from '../_actions';


// eslint-disable-next-line no-unused-vars
const styles = theme => ({
    tabpanelHeader: {
        // 'backgroundColor': 'white',
        width: '100%',
        'text-align': 'center',
        'z-index': 0,
        '&.Mui-selected': {
            'backgroundColor': 'black',
        }
    },
    textbox: {
        background: '#aed9e2',
        padding: '35px',
        margin: '10px',
        flex: 1,
        color: 'black',
        // 'margin-top': '-20px',
    },
    tabpanel: {
        width: '100%',
        overflow: 'auto',
        backgroundColor: 'white',
        flexGrow: 1,
    },
    icoRed: {
        'font-size': '20px',
        color: '#ff0000',
    },
    icoGreen: {
        'font-size': '20px',
        color: '#02ff00',
    },
    icoYellow: {
        'font-size': '20px',
        color: '#ffed00',
    },
    cellRowsIco: {
        borderBottom: '1px solid #b7b7b7',
        boxShadow: 'none',
        padding: '5px 7px 0px 5px',
        width: '50px',
        'text-align': 'end',
    },
    cellRows: {
        color: '#333 !important',
        borderBottom: '1px solid #b7b7b7',
        boxShadow: 'none',
        justifyContent: 'flex-end',
        'text-align': 'end',
        // display: 'inline-flex',
        // 'border-bottom': '0px',
        'padding-top': '2px',
        'padding-bottom': '2px',
    },
});


class OrderList extends React.Component {
    static propTypes = {
        classes: PropTypes.object.isRequired,
        clientDic: PropTypes.object,
        orders: PropTypes.object,
        logs: PropTypes.object,
        history: PropTypes.object,
        currentClient: PropTypes.object,
        setCurrentOrder: PropTypes.func,
        setWidget: PropTypes.func,
        dispatch: PropTypes.func,
        user: PropTypes.object,
    };

    state = {
        updated: false,
        updatedLogs: false,
        clientOrders: [],
        updatedList: [],
        value: 0
    };

    onBack = event => {
        event.preventDefault();
        const lasturl = localStorage.getItem('lasturl');
        if (lasturl)
            this.props.history.push(lasturl);
        else this.props.history.push('/home');
    }

    processData = () => {
        const { clientDic, orders } = this.props;

        if (orders && orders.clientOrders && (orders.clientOrders.length > 0)) {
            orders.clientOrders.forEach(order => {
                order.client = clientDic[order.client_id].first_name + " " + (clientDic[order.client_id].last_name ? clientDic[order.client_id].last_name : "");
                order.amount = 0;

                order.items.forEach(item => {
                    item.price = ((item.product_denormalized && item.product_denormalized.price_per_unit) ? item.product_denormalized.price_per_unit : 0);
                    item.total = ((item.revised_price) ? parseFloat(item.revised_price) : ((item.price) ? item.amount * parseFloat(item.price) : 0));
                    item.name = ((item.product_denormalized && item.product_denormalized.name) ? item.product_denormalized.name : "");
                    item.brand = ((item.product_denormalized && item.product_denormalized.brand) ? item.product_denormalized.brand : "");
                    item.kind = ((item.product_denormalized && item.product_denormalized.kind) ? item.product_denormalized.kind : "");
                    item.quantity_unit = dicUnits((item.product_denormalized && item.product_denormalized.quantity_unit) ? item.product_denormalized.quantity_unit : "");
                    if (!(item.total >= 0)) {
                        item.total = 0;
                    }
                    order.amount += item.total;
                });

                order.nitems = order.items.length;
                switch (order.status) {
                    case 'pending':
                        order.estado = 'Pendiente';
                        break;
                    case 'provisional':
                        order.estado = 'Provisorio';
                        break;
                    case 'done':
                        order.estado = 'Entregado';
                        break;
                    case 'preparing':
                        order.estado = 'Proceso';
                        break;
                    case 'charge':
                        order.estado = 'Cobrar';
                        break;
                    case 'waiting_delivery':
                        order.estado = 'Entregar';
                        break;
                    case 'discarded':
                        order.estado = 'Descartado';
                        break;
                    case 'store_canceled':
                        order.estado = 'Cancelado';
                        break;
                    default:
                        order.estado = order.state;
                }
                order.amount = parseFloat(order.amount.toFixed(2));
            });
            this.setState({
                updated: true,
                clientOrders: orders.clientOrders,
            });
        }
    }

    updateList = (list) => {
        this.setState({
            updatedList: list,
        })
    }

    processData2 = () => {
        const { logs } = this.props;
        if (logs && logs.logs && (logs.logs.length > 0)) {
            this.setState({
                updatedList: logs.logs,
                updatedLogs: true,
            });
        }
    }

    handleTableClick = (event) => {
        this.props.setCurrentOrder(event);
        this.props.history.push('/orderdetail');
    }

    a11yProps = (index) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    handleChangeTab = (event, newValue) => {
        this.setState({ value: newValue });
    };

    renderPayment = (item, index) => {
        const stat = item.payment_status;
        let status = '';

        if (stat === 'pending') status = 'Pago no definido';
        if (stat === 'required') status = 'Pago solicitado';
        if (stat === 'processing') status = 'Pago en proceso';
        if (stat === 'done') status = 'Pago aprobado'

        return (<TableCell key={index} classes={{ root: this.props.classes.cellRowsIco }}>
            <Tooltip title={status} placement="right" arrow>
                <Payment
                    classes={
                        ((stat === 'done') ? { root: this.props.classes.icoGreen } :
                            (stat === 'required' || stat === 'processing') ? { root: this.props.classes.icoYellow } :
                                { root: this.props.classes.icoRed })
                    }
                />
            </Tooltip>
        </TableCell>)
    }

    renderPayload = (item, index) => {
        const text = JSON.stringify(item.payload);
        return (
            <TableCell key={index} classes={{ root: this.props.classes.cellRows }}>
                {text}
            </TableCell>
        );
    }

    render() {
        const { classes, currentClient, user, logs } = this.props;
        const { clientOrders, value, updatedList } = this.state;

        let titulo = "DATOS DEL CLIENTE";

        return (
            <React.Fragment >
                <Appbar back={true} label={titulo} onBack={this.onBack} user={user} />
                <Grid
                    item xs={12} md={12}
                    className={classes.tabpanel}
                >
                    <Grid item xs={12} sm={12} container>
                        <Grid item xs={12} sm={6} className={classes.textbox}>
                            <Typography variant="subtitle2" color="inherit">
                                <b>NOMBRE:</b> {(currentClient) ? currentClient.first_name : ''}<br /><br />
                                <b>APELLIDO:</b> {(currentClient) ? currentClient.lasr_name : ''}<br /><br />
                                <b>EMAIL:</b> {(currentClient) ? currentClient.email : ''}<br /><br />
                                <b>TELEFONO:</b> {(currentClient) ? currentClient.phone : ''}<br /><br />
                                <b>DIRECCION:</b> {(currentClient) ? currentClient.address : ''}<br /><br />
                            </Typography>
                        </Grid>
                    </Grid>
                    <AppBar position="static" className={classes.tabpanelHeader}>
                        <Tabs value={value} onChange={this.handleChangeTab}
                            variant="scrollable"
                            scrollButtons="auto"
                            aria-label="scrollable auto tabs example" >
                            <Tab label={`ORDENES DEL CLIENTE: ${(clientOrders) ? clientOrders.length : ''}`} id='simple-tab' aria-controls='simple-tabpanel' />
                            <Tab label={`ACTIVIDAD RECIENTE`} {...this.a11yProps(0)} />
                        </Tabs>
                    </AppBar>
                    <TabPanel value={value} index={0}>
                        <ResponsiveTable
                            headCells={[
                                {
                                    id: 'alphanum_id',
                                    label: 'N PEDIDO'
                                },
                                {
                                    id: 'created_at',
                                    label: 'FECHA',
                                    type: 'date',
                                },
                                {
                                    id: 'amount',
                                    label: 'VALOR',
                                    type: 'number',
                                },
                                {
                                    id: 'payment',
                                    label: 'PAGO',
                                    noSortable: true,
                                    render: (item, index) => this.renderPayment(item, index + 2000)
                                },
                                {
                                    id: 'estado',
                                    label: 'ESTADO'
                                },
                            ]}
                            curr_symbol={this.props.user.store.curr_symbol}
                            rows={(clientOrders) ? clientOrders : []}
                            handleSelectRow={this.handleTableClick}
                        />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <Filters
                            label='Filtros'
                            exportXls={true}
                            reset={true}
                            fullWidth={true}
                            fieldList={[
                                {
                                    label: 'FECHA',
                                    date: true,
                                    field: 'date'
                                },
                                {
                                    label: 'NOMBRE',
                                    text: true,
                                    field: 'activity_name'
                                },
                            ]}
                            list={(logs) ? logs.logs : []}
                            filteredList={updatedList}
                            updateList={this.updateList}
                        />
                        <ResponsiveTable
                            headCells={[
                                {
                                    id: 'created_at',
                                    label: 'FECHA',
                                    type: 'date'
                                },
                                {
                                    id: 'activity_name',
                                    label: 'ACTIVIDAD',
                                },
                                {
                                    id: 'payload',
                                    label: 'DETALLES',
                                    noSortable: true,
                                    render: (item, index) => this.renderPayload(item, index + 1000)
                                },
                            ]}
                            rows={updatedList}
                        />
                    </TabPanel>
                </Grid >
                <div ref={el => (this.instance = el)} />
            </React.Fragment >
        );
    }

    componentDidMount() {
        const { user } = this.props;

        if (!user || !this.props.setWidget) {
            console.log('Error user or session, redirecting')
            localStorage.setItem('lasturl', `/clientlist`);
            this.props.history.push('/login');
            return null;
        }

        if (!this.props.currentClient || !this.props.currentClient.email) {
            this.props.history.push('/home')
        } else {
            const { dispatch } = this.props;
            dispatch(orderActions.getByClient(this.props.currentClient.id));
            dispatch(userActions.getClientLog(this.props.currentClient.id));

            if (window.location.href.startsWith('https://arturito.chat') || window.location.href.startsWith('https://arturito.store')) {
                //tracking script from ActiveCampaign
                const s = document.createElement('script');
                s.type = 'text/javascript';
                s.async = true;
                s.innerHTML = '(function(e,t,o,n,p,r,i){e.visitorGlobalObjectAlias=n;e[e.visitorGlobalObjectAlias]=e[e.visitorGlobalObjectAlias]||function(){(e[e.visitorGlobalObjectAlias].q=e[e.visitorGlobalObjectAlias].q||[]).push(arguments)};e[e.visitorGlobalObjectAlias].l=(new Date).getTime();r=t.createElement("script");r.src=o;r.async=true;i=t.getElementsByTagName("script")[0];i.parentNode.insertBefore(r,i)})(window,document,"https://diffuser-cdn.app-us1.com/diffuser/diffuser.js","vgo");vgo("setAccount", "476725427");vgo("setTrackByDefault", true);vgo("process");';
                this.instance.appendChild(s);
            }

            this.props.setWidget(true, false);
        }
    }

    componentDidUpdate() {
        if ((this.state.updated !== undefined && this.state.updated === false) &&
            (this.props.orders && this.props.orders.clientOrders && (this.props.orders.clientOrders.length > 0))) {
            this.processData();
        }
        if ((this.state.updatedLogs !== undefined && this.state.updatedLogs === false) &&
            (this.props.logs && this.props.logs.logs && (this.props.logs.logs.length > 0))) {
            this.processData2();
        }
    }

    componentWillUnmount() {
        localStorage.setItem('lasturl', `/clientlist`);
    }
}

function mapStateToProps(state) {
    const { orders, logs } = state;
    const { user } = state.authentication;

    return {
        user,
        orders,
        logs,
    };
}

export default connect(mapStateToProps)(withStyles(styles)(OrderList));