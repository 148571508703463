import { combineReducers } from 'redux';

import { authentication } from './authentication.reducer';
import { registration } from './registration.reducer';
import { integrations } from './users.reducer';
import { FAQs, promos, FAQsOAI, actions } from './users.reducer';
import { csvstatus } from './users.reducer';
import { users} from './users.reducer';
import { qrs} from './users.reducer';
import { theme} from './users.reducer';
import { logs } from './users.reducer';
import { session } from './users.reducer';
import { paymentMethods } from './users.reducer';
import { alert } from './alert.reducer';
import { clients } from './clients.reducer';
import { store } from './clients.reducer';
import { orders, orderId } from './orders.reducer';
import { products, product } from './products.reducer';



const rootReducer = combineReducers({
  authentication,
  registration,
  integrations,
  users,
  alert,
  clients,
  orders,
  qrs,
  products,
  product,
  csvstatus,
  paymentMethods,
  FAQs,
  FAQsOAI,
  promos,
  orderId,
  session,
  logs,
  theme,
  store,
  actions
});

export default rootReducer;