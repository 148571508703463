import { createTheme } from "@material-ui/core/styles";

const Options = {
    // breakpoints: {
    //     keys: {
    //         0: "xs",
    //         1: "sm",
    //         2: "md",
    //         3: "lg",
    //         4: "xl"
    //     },
    //     values: {
    //         xs: 0,
    //         lg: 1280,
    //         sm: 600,
    //         xl: 1920,
    //         md: 960
    //     },
    // },
    // mixins: {
    //     toolbar: {
    //         minHeight: 56,
    //         ['@media (min-width: 0px) and (orientation: landscape)']: {
    //             minHeight: 48
    //         },
    //         ['@media (min-width: 600px)']: {
    //             minHeight: 64
    //         }
    //     }
    // },
    // shadows: {
    //     0: "none",
    //     1: "0px 1px 3px 0px rgba(0, 0, 0, 0.2),0px 1px 1px 0px rgba(0, 0, 0, 0.14),0px 2px 1px -1px rgba(0, 0, 0, 0.12)",
    //     2: "0px 1px 5px 0px rgba(0, 0, 0, 0.2),0px 2px 2px 0px rgba(0, 0, 0, 0.14),0px 3px 1px -2px rgba(0, 0, 0, 0.12)",
    //     3: "0px 1px 8px 0px rgba(0, 0, 0, 0.2),0px 3px 4px 0px rgba(0, 0, 0, 0.14),0px 3px 3px -2px rgba(0, 0, 0, 0.12)",
    //     4: "0px 2px 4px -1px rgba(0, 0, 0, 0.2),0px 4px 5px 0px rgba(0, 0, 0, 0.14),0px 1px 10px 0px rgba(0, 0, 0, 0.12)",
    //     5: "0px 3px 5px -1px rgba(0, 0, 0, 0.2),0px 5px 8px 0px rgba(0, 0, 0, 0.14),0px 1px 14px 0px rgba(0, 0, 0, 0.12)",
    //     6: "0px 3px 5px -1px rgba(0, 0, 0, 0.2),0px 6px 10px 0px rgba(0, 0, 0, 0.14),0px 1px 18px 0px rgba(0, 0, 0, 0.12)",
    //     7: "0px 4px 5px -2px rgba(0, 0, 0, 0.2),0px 7px 10px 1px rgba(0, 0, 0, 0.14),0px 2px 16px 1px rgba(0, 0, 0, 0.12)",
    //     8: "0px 5px 5px -3px rgba(0, 0, 0, 0.2),0px 8px 10px 1px rgba(0, 0, 0, 0.14),0px 3px 14px 2px rgba(0, 0, 0, 0.12)",
    //     9: "0px 5px 6px -3px rgba(0, 0, 0, 0.2),0px 9px 12px 1px rgba(0, 0, 0, 0.14),0px 3px 16px 2px rgba(0, 0, 0, 0.12)",
    //     10: "0px 6px 6px -3px rgba(0, 0, 0, 0.2),0px 10px 14px 1px rgba(0, 0, 0, 0.14),0px 4px 18px 3px rgba(0, 0, 0, 0.12)",
    //     11: "0px 6px 7px -4px rgba(0, 0, 0, 0.2),0px 11px 15px 1px rgba(0, 0, 0, 0.14),0px 4px 20px 3px rgba(0, 0, 0, 0.12)",
    //     12: "0px 7px 8px -4px rgba(0, 0, 0, 0.2),0px 12px 17px 2px rgba(0, 0, 0, 0.14),0px 5px 22px 4px rgba(0, 0, 0, 0.12)",
    //     13: "0px 7px 8px -4px rgba(0, 0, 0, 0.2),0px 13px 19px 2px rgba(0, 0, 0, 0.14),0px 5px 24px 4px rgba(0, 0, 0, 0.12)",
    //     14: "0px 7px 9px -4px rgba(0, 0, 0, 0.2),0px 14px 21px 2px rgba(0, 0, 0, 0.14),0px 5px 26px 4px rgba(0, 0, 0, 0.12)",
    //     15: "0px 8px 9px -5px rgba(0, 0, 0, 0.2),0px 15px 22px 2px rgba(0, 0, 0, 0.14),0px 6px 28px 5px rgba(0, 0, 0, 0.12)",
    //     16: "0px 8px 10px -5px rgba(0, 0, 0, 0.2),0px 16px 24px 2px rgba(0, 0, 0, 0.14),0px 6px 30px 5px rgba(0, 0, 0, 0.12)",
    //     17: "0px 8px 11px -5px rgba(0, 0, 0, 0.2),0px 17px 26px 2px rgba(0, 0, 0, 0.14),0px 6px 32px 5px rgba(0, 0, 0, 0.12)",
    //     18: "0px 9px 11px -5px rgba(0, 0, 0, 0.2),0px 18px 28px 2px rgba(0, 0, 0, 0.14),0px 7px 34px 6px rgba(0, 0, 0, 0.12)",
    //     19: "0px 9px 12px -6px rgba(0, 0, 0, 0.2),0px 19px 29px 2px rgba(0, 0, 0, 0.14),0px 7px 36px 6px rgba(0, 0, 0, 0.12)",
    //     20: "0px 10px 13px -6px rgba(0, 0, 0, 0.2),0px 20px 31px 3px rgba(0, 0, 0, 0.14),0px 8px 38px 7px rgba(0, 0, 0, 0.12)",
    //     21: "0px 10px 13px -6px rgba(0, 0, 0, 0.2),0px 21px 33px 3px rgba(0, 0, 0, 0.14),0px 8px 40px 7px rgba(0, 0, 0, 0.12)",
    //     22: "0px 10px 14px -6px rgba(0, 0, 0, 0.2),0px 22px 35px 3px rgba(0, 0, 0, 0.14),0px 8px 42px 7px rgba(0, 0, 0, 0.12)",
    //     23: "0px 11px 14px -7px rgba(0, 0, 0, 0.2),0px 23px 36px 3px rgba(0, 0, 0, 0.14),0px 9px 44px 8px rgba(0, 0, 0, 0.12)",
    //     24: "0px 11px 15px -7px rgba(0, 0, 0, 0.2),0px 24px 38px 3px rgba(0, 0, 0, 0.14),0px 9px 46px 8px rgba(0, 0, 0, 0.12)"
    // },
    // direction: "ltr",
    // overrides: Object,
    // transitions: {
    //     easing: {
    //         easeInOut: "cubic-bezier(0.4, 0, 0.2, 1)",
    //         easeOut: "cubic-bezier(0.0, 0, 0.2, 1)",
    //         easeIn: "cubic-bezier(0.4, 0, 1, 1)",
    //         sharp: "cubic-bezier(0.4, 0, 0.6, 1)"
    //     },
    //     duration: {
    //         standard: 300,
    //         short: 250,
    //         enteringScreen: 225,
    //         shorter: 200,
    //         leavingScreen: 195,
    //         shortest: 150,
    //         complex: 375
    //     }
    // },
    typography: {
        fontSize: 14,
        fontWeightLight: 500,
        fontWeightMedium: 600,
        fontWeightRegular: 700,
        fontFamily: '"Montserrat", "Myriad", "Roboto", "Helvetica", "Arial", sans-serif',
        h1: {
            color: "#025e9f",
            fontFamily: '"Montserrat", "Myriad", "Roboto", "Helvetica", "Arial", sans-serif',
            lineHeight: "1.35417em",
            fontSize: "1.5rem",
            fontWeight: 600
        },
        h2: {
            color: "#124e5c",
            fontFamily: '"Montserrat", "Myriad", "Roboto", "Helvetica", "Arial", sans-serif',
            lineHeight: "1.5417em",
            fontSize: "1.3rem",
            fontWeight: 600
        },
        h3: {
            color: "#124e5c",
            fontFamily: '"Montserrat", "Myriad", "Roboto", "Helvetica", "Arial", sans-serif',
            lineHeight: "1.5417em",
            fontSize: "1rem",
            fontWeight: 600
        },
        h4: {
            color: "#025e9f",
            fontFamily: '"Montserrat", "Myriad", "Roboto", "Helvetica", "Arial", sans-serif',
            lineHeight: "1em",
            fontSize: "1rem",
            fontWeight: 200
        },
        h5: {
            color: "#124e5c",
            fontFamily: '"Montserrat", "Myriad", "Roboto", "Helvetica", "Arial", sans-serif',
            lineHeight: "1.5417em",
            fontSize: "1rem",
            fontWeight: 600
        },
        h6: {
            color: "#025e9f",
            fontFamily: '"Montserrat", "Myriad", "Roboto", "Helvetica", "Arial", sans-serif',
            lineHeight: "1.5417em",
            fontSize: "1.3rem",
            fontWeight: 600
        },
        subtitle1: {
            color: "#fff",
            fontFamily: '"Montserrat", "Myriad", "Roboto", "Helvetica", "Arial", sans-serif',
            lineHeight: "1.16667em",
            fontSize: "2.3125rem",
            fontWeight: 500
        },
        subtitle2: {
            color: "#fff",
            fontFamily: '"Montserrat", "Myriad", "Roboto", "Helvetica", "Arial", sans-serif',
            lineHeight: "1.16667em",
            fontSize: "1rem",
            fontWeight: 600
        },
        body1: {
            color: "#124e5c",
            fontFamily: '"Montserrat", "Myriad", "Roboto", "Helvetica", "Arial", sans-serif',
            lineHeight: "1.46429em",
            fontSize: "0.8975rem",
            fontWeight: 600
        },
        body2: {
            color: "#fff",
            fontFamily: '"Montserrat", "Myriad", "Roboto", "Helvetica", "Arial", sans-serif',
            lineHeight: "1.46429em",
            fontSize: "0.8975rem",
            fontWeight: 500
        },
        caption: {
            color: "#fff",
            fontFamily: '"Montserrat", "Myriad", "Roboto", "Helvetica", "Arial", sans-serif',
            lineHeight: "1.375em",
            fontSize: "0.75rem",
            fontWeight: 500
        },
        button: {
            "text-transform": "capitalize",
            color: "#fff",
            fontFamily: '"Montserrat", "Myriad", "Roboto", "Helvetica", "Arial", sans-serif',
            fontSize: "0.875rem",
            minWidth: '20px',
            borderRadious: '15px',
            fontWeight: 800,
        },
        // "overline","srOnly","inherit"].
        display2: {
            marginLeft: "-.02em",
            color: "#fff",
            fontFamily: '"Montserrat", "Myriad", "Roboto", "Helvetica", "Arial", sans-serif',
            lineHeight: "1.13333em",
            fontSize: "2.8125rem",
            fontWeight: 500
        },
        display3: {
            marginLeft: "-.02em",
            color: "#fff",
            fontFamily: '"Montserrat", "Myriad", "Roboto", "Helvetica", "Arial", sans-serif',
            letterSpacing: "-.02em",
            lineHeight: "1.30357em",
            fontSize: "3.5rem",
            fontWeight: 500
        },
        display4: {
            marginLeft: "-.04em",
            color: "#fff",
            fontFamily: '"Montserrat", "Myriad", "Roboto", "Helvetica", "Arial", sans-serif',
            letterSpacing: "-.04em",
            lineHeight: "1.14286em",
            fontSize: "7rem",
            fontWeight: 500
        },
        display1: {
            color: "#fff",
            fontFamily: '"Montserrat", "Myriad", "Roboto", "Helvetica", "Arial", sans-serif',
            lineHeight: "1.20588em",
            fontSize: "2.125rem",
            fontWeight: 500
        },
        title: {
            color: "#fff",
            fontFamily: '"Montserrat", "Myriad", "Roboto", "Helvetica", "Arial", sans-serif',
            lineHeight: "1.16667em",
            fontSize: "2.3125rem",
            fontWeight: 500
        },
        subheading: {
            color: "#fff",
            fontFamily: '"Montserrat", "Myriad", "Roboto", "Helvetica", "Arial", sans-serif',
            lineHeight: "1.5em",
            fontSize: "1rem",
            fontWeight: 500
        },
    },
    // zIndex: {
    //     modal: 1300,
    //     snackbar: 1400,
    //     drawer: 1200,
    //     appBar: 1100,
    //     mobileStepper: 1000,
    //     tooltip: 1500
    // },
    // shape: {
    //     borderRadius: 4
    // },
    // props: Object,
    // spacing: {
    //     unit: 8
    // },
    palette: {
        // tonalOffset: 0.2,
        background: {
            paper: "#d6e0e4",
            default: "#fff"
        },
        // contrastThreshold: 3,
        // grey: {
        //     50: "#fafafa",
        //     100: "#f5f5f5",
        //     200: "#eeeeee",
        //     300: "#e0e0e0",
        //     400: "#bdbdbd",
        //     500: "#9e9e9e",
        //     600: "#757575",
        //     700: "#616161",
        //     800: "#424242",
        //     900: "#212121",
        //     A700: "#616161",
        //     A100: "#d5d5d5",
        //     A400: "#303030",
        //     A200: "#aaaaaa",
        // },
        text: {
            primary: "#124e5c",
            secondary: "#222",
            disabled: "#aaa",
            hint: "#fff"
        },
        divider: "#fff",
        common: {
            black: "#124e5c",
            white: "#fff"
        },
        error: {
            light: "#e57373",
            main: "#f44336",
            dark: "#d32f2f",
            contrastText: "#fff"
        },
        // // type: "light",
        action: {
            hoverOpacity: 0.08,
            hover: "rgba(0, 0, 0, 0.08)",
            selected: "#aaaaaa",
            disabledBackground: "#fff",
            disabled: "#757575",
            active: "#fff"
        },
        primary: {
            main: "#2876ad",
            light: "rgb(62, 83, 107)",
            dark: "#124e5c",
            contrastText: "#ffffff"
        },
        secondary: {
            main: "#ed841e",
            light: "#f3ab64",
            dark: "#cb6d12",
            contrastText: "#ffffff"
        },
    },
    themeName: "Arturito theme"
};

export default createTheme(Options);