/* eslint-disable react/jsx-no-bind */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    Button, TableCell, Grid, TextField, Tooltip
} from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import withStyles from '@material-ui/core/styles/withStyles';
import Appbar from '../_components/Appbar';
import { productActions } from '../_actions';
import ResponsiveTable from '../_components/Table';
import Filters from '../_components/Filters';
import DeleteIcon from '@material-ui/icons/Delete';
import Avatar from '@material-ui/core/Avatar';
import AddProduct from '../_components/AddProduct';
import ImagePopUp from '../_components/ImagePopUp';
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';
import Pagination from '@mui/material/Pagination';
import { userActions } from '../_actions';

// eslint-disable-next-line no-unused-vars
const styles = theme => ({
    cellRows: {
        color: '#333 !important',
        // borderBottom: '1px solid #b7b7b7',
        boxShadow: 'none',
        justifyContent: 'flex-end',
        display: 'inline-flex',
        'border-bottom': '0px',
        'padding-top': '2px',
        'padding-bottom': '2px',
    },
    table: {
        overflow: 'auto',
    },
    button: {
        padding: '6px 8px',
        'padding-left': '0px',
        'margin-left': '30px',
        'margin-right': '-40px',
    },
    cellText: {
        'width': '100px',
    },
    cellText2: {
        'width': '172px',
        left: '20px',
    },
    avatar: {
        width: '60px',
        height: '60px',
    }
});

var filterKey = 10000;

class ProducStore extends React.Component {
    static propTypes = {
        classes: PropTypes.object.isRequired,
        dispatch: PropTypes.func,
        products: PropTypes.oneOfType([
            PropTypes.array,
            PropTypes.object
        ]),
        history: PropTypes.object,
        setWidget: PropTypes.func,
        user: PropTypes.object,
        getData: PropTypes.func,
        syncAC: PropTypes.func,
        csvstatus: PropTypes.object,
        onBackComp: PropTypes.func,
    };

    state = {
        productList: [],
        updatedList: [],
        pagelist: [],
        npages: 0,
        npage: 0,
        openImagePopUp: false,
        urlImagePopUp: '',
    };

    onBack = event => {
        event.preventDefault();
        // this.props.history.push('/home');
        this.props.onBackComp();
    }

    updateList = (list) => {
        this.setPages(list);
        this.setState({
            updatedList: list,
        })
    }

    handleSave = (item) => {
        //clean product
        let productUpdate = {};

        productUpdate.id = item.id;
        // productUpdate.brand = item.brand;
        // productUpdate.kind = item.kind;
        // productUpdate.name = item.name;
        // productUpdate.quantity_unit = item.quantity_unit
        productUpdate.price_per_unit = (item.price_per_unit !== "") ? item.price_per_unit : null;
        productUpdate.image_url = (item.image_url !== "") ? item.image_url : null;
        productUpdate.tags = item.tags;

        if (productUpdate.tags !== "") {
            if (!Array.isArray(productUpdate.tags))
                productUpdate.tags = productUpdate.tags.split(',');

            if (!Array.isArray(productUpdate.tags))
                productUpdate.tags = [productUpdate.tags];

        } else productUpdate.tags = [];



        // productUpdate.tags = item.tags;



        // if (!Array.isArray(productUpdate.tags))
        // productUpdate.tags = productUpdate.tags.split(',');

        // productUpdate.tags = (productUpdate.tags !== "") ? productUpdate.tags : [];

        filterKey++;
        const { dispatch } = this.props;
        dispatch(productActions.update(productUpdate));
        setTimeout(() => {
            this.props.getData(['products']);
        }, 500);
    };

    renderComboPrice = (item, index) => {
        return (
            <TableCell key={index} classes={{ root: this.props.classes.cellRows }}>
                {// eslint-disable-next-line react/jsx-no-bind
                }
                <TextField color="secondary" type="number"
                    classes={{ root: this.props.classes.cellText }}
                    InputProps={{
                        startAdornment: <InputAdornment position="start"><small>{this.props.user.store.curr_symbol}</small></InputAdornment>,
                    }}
                    id={item.id}
                    // value={item.new_price}
                    onChange={(event) => {
                        // event.preventDefault();
                        // item.saveButton = true;
                        if (event.target.value <0) event.target.value = 0;
                        item.new_price = event.target.value;
                        // this.forceUpdate();
                    }}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            if (item.new_price !== null && item.new_price !== undefined && item.new_price !== '') {
                                item.price_per_unit = item.new_price;
                                let ids = document.getElementById(item.id);
                                ids.value = "";
                            }
                            // item.saveButton = false;
                            // this.forceUpdate();
                            this.handleSave(item);
                        }
                    }}
                />
                <TextField color="secondary" type="text"
                    classes={{ root: this.props.classes.cellText2 }}
                    InputProps={{
                        startAdornment: <InputAdornment position="start"><small>url</small></InputAdornment>,
                    }}
                    id={item.id + 1000}
                    value={item.image_url ? item.image_url : ''}
                    onChange={(event) => {
                        // item.saveButton = true;
                        item.image_url = event.target.value;
                        this.forceUpdate();
                    }}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            item.image_url = e.target.value;
                            // item.saveButton = false;
                            // this.forceUpdate();
                            this.handleSave(item);
                        }
                    }}
                />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <TextField color="secondary" type="text"
                    classes={{ root: this.props.classes.cellText2 }}
                    InputProps={{
                        startAdornment: <InputAdornment position="start"><small>tags</small></InputAdornment>,
                    }}
                    id={item.id + 3000}
                    value={item.tags ? item.tags : ''}
                    onChange={(event) => {
                        // item.saveButton = true;
                        item.tags = event.target.value;
                        this.forceUpdate();
                    }}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            item.tags = e.target.value;
                            // item.saveButton = false;
                            // this.forceUpdate();
                            this.handleSave(item);
                        }
                    }}
                />

                {/* <Button color="primary" variant="contained" classes={{ root: this.props.classes.button }} */}
                <Button classes={{ root: this.props.classes.button }}
                    component="label"
                    onClick={() => {
                        if (item.new_price !== null && item.new_price !== undefined && item.new_price !== '') {
                            item.price_per_unit = item.new_price;
                            let ids = document.getElementById(item.id);
                            ids.value = "";
                            // item.new_price = 0;
                        }
                        // item.saveButton = false;
                        this.handleSave(item);
                    }}
                >
                    <Tooltip title={"Guardar producto"}>
                        <CheckCircleOutline />
                    </Tooltip>
                </Button>

                <Tooltip title={"Eliminar producto"}>
                    <Button classes={{ root: this.props.classes.button }}
                        component="label"
                        onClick={() => {
                            const { dispatch } = this.props;
                            dispatch(productActions.deleteProd(
                                {
                                    "product_ids": [item.id]
                                }
                            ));
                            var newlist = this.state.updatedList;
                            var index = newlist.indexOf(item);
                            if (index !== -1) {
                                newlist.splice(index, 1);
                            }
                            this.setState({
                                updatedList: newlist,
                            });
                        }}
                    >
                        <DeleteIcon />
                    </Button>
                </Tooltip>
            </TableCell>)
    }


    // renderImageUrl = (item, index) => {
    //     return (
    //         <TableCell key={index} classes={{ root: this.props.classes.cellRows }}>
    //             {// eslint-disable-next-line react/jsx-no-bind
    //             }
    //             <TextField color="secondary" type="text"
    //                 classes={{ root: this.props.classes.cellText }}
    //                 id={item.id + 1000}
    //                 onChange={(event) => {
    //                     item.saveButton2 = true;
    //                     item.image_url = event.target.value;
    //                     this.forceUpdate();
    //                 }}
    //                 onKeyDown={(e) => {
    //                     if (e.key === 'Enter') {
    //                         item.image_url = e.target.value;
    //                         item.saveButton2 = false;
    //                         this.forceUpdate();
    //                         this.handleSave(item);
    //                     }
    //                 }}
    //             />
    //             <Button color="primary" variant="contained" disabled={!item.saveButton2} classes={{ root: this.props.classes.button }}
    //                 onClick={() => {
    //                     item.saveButton2 = false;
    //                     this.handleSave(item);
    //                 }}>✓
    //             </Button>
    //         </TableCell>
    //     )
    // }

    renderImage = (item, index) => {
        var url = item.image_url ? item.image_url : '../images/default.png';
        return (
            <TableCell key={index} classes={{ root: this.props.classes.cellRows }}>
                <Button classes={{ root: this.props.classes.cellButton }}
                    component="label"
                    onClick={() => {
                        if (item.image_url) {
                            this.setState({
                                openImagePopUp: true,
                                urlImagePopUp: url,
                            });
                        }
                    }}
                >
                    <Avatar
                        alt="Imagen"
                        src={url}
                        classes={{ root: this.props.classes.avatar }}
                        variant="square" />
                </Button>
            </TableCell>
        )
    }

    handleCloseImageDialog = () => {
        this.setState({
            openImagePopUp: false,
            urlImagePopUp: ''
        });
    }

    // eslint-disable-next-line no-unused-vars
    handlePageChange = (event, page) => {
        const nitems = 15;
        this.setState({
            pagelist: this.state.updatedList.slice(nitems * (page - 1), nitems * page),
            npage: page
        });
    }

    setPages = (list) => {
        const nitems = 15;
        this.setState({
            pagelist: list.slice(0, 15),
            npages: Math.ceil(list.length / nitems),
            npage: 1
        });
    }

    render() {
        const { classes, csvstatus, products } = this.props;
        const { updatedList, productList, openImagePopUp, urlImagePopUp, pagelist, npages, npage } = this.state;
        let titulo = "LISTADO DE PRODUCTOS";


        return (
            <React.Fragment>
                <Appbar back={true} label={titulo} user={this.props.user} onBack={this.onBack}
                    update={() => {
                        this.props.getData(['products']);
                        filterKey++;
                    }} />
                <AddProduct dispatch={this.props.dispatch} syncAC={this.props.syncAC} user={this.props.user}
                    list={productList}
                    filteredList={updatedList}
                    csvstatus={csvstatus}
                    products={products}
                />
                <Filters
                    key={filterKey}
                    label='Filtros'
                    pantalla='Productos'
                    reset={true}
                    exportXls={true}
                    fullWidth={true}
                    onbarfieldlist={[
                        {
                            label: 'NOMBRE',
                            text: true,
                            field: 'name'
                        },
                    ]}
                    fieldList={[
                        {
                            label: 'TIPO',
                            combo: true,
                            field: 'kindname'
                        },
                        {
                            label: 'MARCA',
                            combo: true,
                            field: 'brandname'
                        },
                        {
                            label: 'ID',
                            text: true,
                            field: 'original_id'
                        },
                        {
                            label: 'TAG',
                            list: true,
                            field: 'tags'
                        },
                    ]}
                    list={productList}
                    filteredList={updatedList}
                    updateList={this.updateList}
                />
                <Grid className={classes.table}>
                    <ResponsiveTable
                        headCells={[
                            {
                                id: 'image',
                                label: 'FOTO',
                                noSortable: true,
                                render: (item, index) => this.renderImage(item, index + 3000)
                            },
                            {
                                id: 'brandname',
                                label: 'MARCA'
                            },
                            {
                                id: 'kindname',
                                label: 'TIPO'
                            },
                            {
                                id: 'name',
                                label: 'NOMBRE'
                            },
                            {
                                id: 'price_per_unit',
                                label: 'PRECIO',
                                type: 'number',
                            },
                            {
                                id: 'actions',
                                label: 'NUEVO PRECIO / NUEVOS TAGS / IMAGEN URL / GUARDAR / ELIMINAR ITEM',
                                noSortable: true,
                                render: (item, index) => this.renderComboPrice(item, index + 1000)
                            },
                            {
                                id: 'quantity_unit',
                                label: 'UN'
                            },
                            {
                                id: 'tags',
                                label: 'Tags'
                            },
                            {
                                id: 'original_id',
                                label: 'ID'
                            },
                            {
                                id: 'description',
                                label: 'Descripcion'
                            },
                        ]}
                        curr_symbol={(this.props.user ? this.props.user.store.curr_symbol : '')}
                        rows={pagelist}
                    />
                </Grid>
                <Pagination
                    count={npages}
                    // size="large"
                    // variant="outlined"
                    shape="rounded"
                    page={npage}
                    onChange={this.handlePageChange}
                    color="primary"
                    sx={{ padding: '10px' }}
                />
                <div ref={el => (this.instance = el)} />
                <ImagePopUp
                    open={openImagePopUp}
                    url={urlImagePopUp}
                    handleClose={this.handleCloseImageDialog}
                />
            </React.Fragment>
        );
    }
    componentDidMount() {
        if (window.location.href.startsWith('https://arturito.chat') || window.location.href.startsWith('https://arturito.store')) {
            //tracking script from ActiveCampaign
            const s = document.createElement('script');
            s.type = 'text/javascript';
            s.async = true;
            s.innerHTML = '(function(e,t,o,n,p,r,i){e.visitorGlobalObjectAlias=n;e[e.visitorGlobalObjectAlias]=e[e.visitorGlobalObjectAlias]||function(){(e[e.visitorGlobalObjectAlias].q=e[e.visitorGlobalObjectAlias].q||[]).push(arguments)};e[e.visitorGlobalObjectAlias].l=(new Date).getTime();r=t.createElement("script");r.src=o;r.async=true;i=t.getElementsByTagName("script")[0];i.parentNode.insertBefore(r,i)})(window,document,"https://diffuser-cdn.app-us1.com/diffuser/diffuser.js","vgo");vgo("setAccount", "476725427");vgo("setTrackByDefault", true);vgo("process");';
            this.instance.appendChild(s);
        }

        const { user, dispatch } = this.props;

        if (!user || !this.props.setWidget) {
            console.log('Error user or session, redirecting')
            localStorage.setItem('lasturl', `/productstore`);
            this.props.history.push('/login');
            return null;
        }

        if (this.props.products && !this.props.products.products)
            this.props.getData(['products']);
        else {
            this.setPages(this.props.products.products);
            // eslint-disable-next-line react/no-did-mount-set-state
            this.setState({
                productList: this.props.products.products,
                updatedList: this.props.products.products,
            });
        }
        dispatch(userActions.getCSVStatusStore());

        this.props.setWidget(true, false);
    }

    componentDidUpdate(prevProps, prevState) {
        if ((JSON.stringify(prevProps.products) !== JSON.stringify(this.props.products)) && (this.props.products && this.props.products.products)) {
            this.setPages(this.props.products.products);
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({
                productList: this.props.products.products,
                updatedList: this.props.products.products,
            });
        }
    }

    componentWillUnmount() {
        localStorage.setItem('lasturl', `/productstore`);
    }
}

function mapStateToProps(state) {
    const { user } = state.authentication;
    const { products, csvstatus } = state;

    return {
        user,
        products,
        csvstatus
    };
}

export default connect(mapStateToProps)(withStyles(styles)(ProducStore));