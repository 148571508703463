import React from 'react'
import PropTypes from 'prop-types'
import {
  TableSortLabel,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core'


export default function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort, date } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead classes={{ root: classes.tableHead }}>
      <TableRow classes={{ root: classes.rows }}>
        {props.headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
            classes={{ root: classes.root }}
            align='right'
          >
            {!headCell.noSortable ?
              <TableSortLabel
                active={orderBy === headCell.id}
                date={date}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
                classes={{ root: classes.root, active: classes.active }}
              >
                {headCell.label}
              </TableSortLabel> :
              headCell.label
            }
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  date: PropTypes.bool,
  headCells: PropTypes.array,
};
