/* eslint-disable react/jsx-no-bind */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import {
    Typography, Tab, Tabs, Box, Switch,
    Tooltip, Button, Divider, TableCell, Checkbox, TextField,
    Radio, RadioGroup, FormControlLabel, FormControl, FormLabel
} from '@material-ui/core';
import Appbar from '../_components/Appbar';
import { userActions } from '../_actions';
import Input from '../_components/Input';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import ResponsiveTable from '../_components/Table';
import DeleteIcon from '@material-ui/icons/Delete';
import ContentCopy from '@material-ui/icons/FileCopy';
import AddCircle from '@material-ui/icons/AddCircle';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';
import VisibilityIcon from '@material-ui/icons/Visibility';
import QRCode from "react-qr-code";
// import Backdrop from '@mui/material/Backdrop';
import Filters from '../_components/Filters';
import Pagination from '@mui/material/Pagination';
import QRPreview from '../_components/QRPreview';


// eslint-disable-next-line no-unused-vars
const styles = theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: 'white',
        display: 'flex',
        height: '100%',
    },
    tabs: {
        borderRight: `1px solid #ed841e`,
        backgroundColor: 'white',
        color: 'black',
        width: '160px',
        'min-width': '160px',
        paddingTop: '20px',
    },
    link: {
        textDecoration: 'none',
        cursor: 'pointer',
        "&:hover": {
            color: '#ee8d2d',
        },
    },
    divider: {
        background: '#e0ecec !important',
    },
    cellRows: {
        color: '#333 !important',
        borderBottom: '1px solid #b7b7b7',
        boxShadow: 'none',
    },
    cellButton: {
        padding: '0px',
        margin: '0px',
        'min-width': '0px',
    },
    tabpanel: {
        width: '100%',
        overflow: 'auto',
    },
    inline: {
        display: 'inline-flex',
        'align-items': 'center',
        height: '55px',
    },
    icoRed: {
        color: '#ff0000',
    },
    icoGreen: {
        color: '#02ff00',
    },
    icoYellow: {
        color: '#ffed00',
    },
    icoBlack: {
        color: '#000',
    },
    tab: {
        width: '1100px',
    },
});

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography component={'span'}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

const autoFillCal = ['¡Ofertas irresistibles hoy! 🛒', 'Comida fresca a un click 🍅', 'Descuentos sorprendentes 🔥', 'Ahorra en tus compras 🛍️',
    'Llena tu carrito 🛒', 'Envío rápido y seguro 🚚', 'Súper ofertas en lácteos 🥛', 'Productos de calidad garantizada ✨', '¡Promos imperdibles! 🎉',
    'Ofertas exclusivas para ti 💎', 'Aprovecha precios bajos ⬇️', 'Lo mejor en panadería 🥖', 'Productos locales de primera 🏞️', 'Encuentra todo lo que necesitas 🛍️',
    'Compras fáciles y rápidas 🛒', '¡Probá nuestros productos gourmet! 🍴', 'Vive una explosión de sabores 🔥', 'Haz tus recetas especiales 🍳',
    'Disfruta del placer culinario 🍴', 'Sabores auténticos, sin igual 🥧', 'Descubre el arte de cocinar 🎨', 'Siente la frescura de la naturaleza 🌿',
    'Despierta tus sentidos al comer ✨', 'Descubre la cocina argentina 🇦🇷', 'Viaja a través de la comida 🌍', 'Experiencias gastronómicas únicas 🍽️',
    'Deléitate con nuestros platos 🍱', 'Explora el mundo de los vinos 🍷', 'Vive una experiencia culinaria 🍽️', 'Convierte cada comida en un festín 🎉'];
const autoFillDesc = ['Sorteo', 'Caja 1', 'Vendedor Alberto', 'Promotor Jaime', 'Promoción 20%', 'Packaging retiro', 'Posteo Instagram', 'Campaña Dia de', 'Evento'];
const autoFillRef2Mens = ['Quiero ahorrar', 'Reserva ya', 'Quiero participar', 'Anotame', 'Quiero la promo'];
const autoFillRef2Rta = ['Precios exclusivos 💸', 'Estás registrado 🎟️', 'Te anoté 📝', 'Estás participando 🖊️', 'Estás Registrado! 💸'];

class Marketing extends React.Component {
    static propTypes = {
        //  object types
        classes: PropTypes.object,
        setWidget: PropTypes.func,
        children: PropTypes.node,
        csvstatus: PropTypes.object,
    };

    state = {
        value: 0,
        toSave: false,
        toSaveFAQ: false,
        toSavePromos: false,
        updated: false,
        updated2: false,
        submitted: false,
        codeWidgetSide: 'right',
        //qrs
        QRmsgpre: '',
        QRmsgfinish: '',
        QRdesc: '',
        QRcodextra: '',
        QRType: '1',
        QRisweb: true,
        QRactive: true,
        toSaveQR: false,
        toSaveQRs: false,
        qrsList: [],
        updatedQrsList: [],
        pageQrslist: [],
        npagesQrs: 0,
        npageQrs: 0,
        QRPreviewIndex: 0,
        QRPreviewOpen: false,
        QRPreviewObj: null,
        botDialogInit: (this.props.user.store.dialog_messages.dialog_init ? this.props.user.store.dialog_messages.dialog_init : ''),
        botOrderInit: (this.props.user.store.dialog_messages.pre_order ? this.props.user.store.dialog_messages.pre_order : ''),
        botOrderFinish: (this.props.user.store.dialog_messages.order_close ? this.props.user.store.dialog_messages.order_close : ''),
    }

    updateQrsList = (list) => {
        this.setQrsPages(list);
        this.setState({
            updatedQrsList: list,
        })
    }

    // eslint-disable-next-line no-unused-vars
    handleQrsPageChange = (event, page) => {
        const nitems = 15;
        this.setState({
            pageQrslist: this.state.updatedQrsList.slice(nitems * (page - 1), nitems * page),
            npageQrs: page
        });
    }

    setQrsPages = (list) => {
        const nitems = 15;
        this.setState({
            pageQrslist: list.slice(0, 15),
            npagesQrs: Math.ceil(list.length / nitems),
            npageQrs: 1
        });
    }

    onBack = event => {
        event.preventDefault();
        // this.props.history.push('/home');
        this.props.onBackComp();
    }

    handleChangeTab = (event, newvalue) => {
        this.setState({
            value: newvalue,
        });
    }

    handleChangeSwitch = event => {
        this.setState({
            [event.target.name]: event.target.checked,
            toSave: true,
        });
    }

    handleChange = event => {
        event.preventDefault();
        if (event.target.name === 'emailStore') {
            if (!this.validateEmail(event.target.value)) {
                this.setState({
                    errorEmailStore: true,
                });
            } else {
                this.setState({
                    errorEmailStore: false,
                });
            }
        }
        if (event.target.name === 'QRdesc') {
            this.setState({
                toSaveQR: true,
            });
        }
        if (event.target.name === 'curr_symbol') {
            if (!this.validateCurr(event.target.value)) {
                this.setState({
                    errorCurrStore: true,
                });
            } else {
                this.setState({
                    errorCurrStore: false,
                });
            }
        }
        this.setState({
            [event.target.name]: event.target.value,
            toSave: true,
        });
    }

    renderFAQActions = (item, index) => {
        return (
            <TableCell key={index} classes={{ root: this.props.classes.cellRows }}>
                <Button classes={{ root: this.props.classes.cellButton }}
                    component="label"
                    onClick={() => {
                        item.response = null;
                        this.setState({
                            toSaveFAQ: true,
                        });
                    }}
                >
                    <DeleteIcon />
                </Button>
            </TableCell>
        )
    }

    renderFAQ = (item, index) => {
        return (<TableCell key={index} classes={{ root: this.props.classes.cellRows }}>
            {// eslint-disable-next-line react/jsx-no-bind
            }
            <TextField classes={{ root: this.props.classes.cell }} multiline maxRows={3} value={(item.response) ? item.response : ""}
                onChange={(event) => {
                    event.preventDefault();
                    item.response = event.target.value;
                    this.setState({
                        toSaveFAQ: true,
                    });
                }} />
        </TableCell>)
    }

    renderFAQType = (item, index) => {
        let text = '';
        if (item.faq_type === 'payment_methods') text = 'Métodos de pago'
        if (item.faq_type === 'store_location') text = 'Ubicación de la tienda'
        if (item.faq_type === 'delivery') text = 'Envíos'
        if (item.faq_type === 'freigt_cost') text = 'Costo de envío'
        if (item.faq_type === 'minimum_purchase') text = 'Cómpra mínima'

        return (<TableCell key={index} classes={{ root: this.props.classes.cellRows }}>
            {text}
        </TableCell>)
    }

    renderPromoDay = (item, index) => {
        let day = '';
        if (item.day_of_week === "0") day = 'Por Defecto';
        if (item.day_of_week === "1") day = 'Lunes';
        if (item.day_of_week === "2") day = 'Martes';
        if (item.day_of_week === "3") day = 'Miercoles';
        if (item.day_of_week === "4") day = 'Jueves';
        if (item.day_of_week === "5") day = 'Viernes';
        if (item.day_of_week === "6") day = 'Sabado';
        if (item.day_of_week === "7") day = 'Domingo';
        return (<TableCell key={index} classes={{ root: this.props.classes.cellRows }}>
            {
                day
            }
        </TableCell>)
    }

    renderPromo = (item, param, index) => {

        const products = this.props.products.products;
        let items = [];
        item.selectedTag = item.tag;
        let found = false;
        if (products) {
            products.forEach((elem) => {
                elem.tags.forEach(tag => {
                    items.push(tag);
                    if (tag === item.tag)
                        found = true;
                })
            });
        }
        const promos = this.props.promos.promos;
        if (promos) {
            promos.forEach((elem) => {
                items.push(elem.tag);
                if (elem.tag === item.tag)
                    found = true;

            });
        }
        items = [...new Set(items)];
        items.sort();
        items.push("Ninguna");
        if (!found || item.selectedTag === '') item.selectedTag = 'Ninguna';


        return (<TableCell key={index} classes={{ root: this.props.classes.cellRows }}>
            <Select
                name={`${item.id}`}
                value={item.selectedTag}
                onChange={(event) => {
                    item.selectedTag = event.target.value;
                    if (event.target.value === 'Ninguna')
                        item.tag = '';
                    else item.tag = event.target.value;
                }}
                inputProps={{
                    name: `${item.id}`,
                    id: `select-type-${item.id}`,
                }}
            >
                {items.map((item, index) => (
                    <MenuItem key={index} value={item}>{item}</MenuItem>
                ))}
            </Select>
        </TableCell >)
    }

    renderPromoMsg = (item, param, index) => {
        return (<TableCell key={index} classes={{ root: this.props.classes.cellRows }}>


            {// eslint-disable-next-line react/jsx-no-bind
            }
            <Input
                id={item.id}
                classes={{ root: this.props.classes.cell }}
                multiline
                maxRows={3}
                autoFill={autoFillCal}
                value={(item[param]) ? item[param] : ""}
                onChange={(event) => {
                    event.preventDefault();
                    item[param] = event.target.value;
                    this.setState({
                        toSavePromos: true,
                    });
                }} />
        </TableCell>)
    }

    renderCheckbox = (item, param, index) => {
        return (<TableCell key={index} classes={{ root: this.props.classes.cellRows }}>
            <Checkbox id={item.id}
                checked={(item[param])}
                // eslint-disable-next-line react/jsx-no-bind
                onChange={(event) => {
                    if (event.target.checked) {
                        item[param] = true;
                        // const { dispatch } = this.props;
                        // dispatch(userActions.enable(item.id, `Cuenta ${item.hashtag} activada`));
                    }
                    else {
                        item[param] = false;
                        // const { dispatch } = this.props;
                        // dispatch(userActions.disable(item.id, `Cuenta ${item.hashtag} desactivada`));
                    }
                    this.setState({
                        toSavePM: true,
                    });
                }}
                inputProps={{ 'aria-label': 'primary checkbox' }}
            />
        </TableCell>)
    }

    totext = (array) => {
        var text = "";

        array.forEach(item => {
            text += item;
            text += '\n';
        });

        return text;
    }

    onImageCownload = (id, text) => {
        const svg = document.getElementById(id);
        const svgData = new XMLSerializer().serializeToString(svg);
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        const img = new Image();
        img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
        img.onload = () => {
            canvas.width = img.width + 100;
            canvas.height = img.height + 100;
            ctx.fillStyle = "#FFFFFF";
            ctx.fillRect(0, 0, canvas.width, canvas.height);
            ctx.drawImage(img, 50, 50);
            ctx.lineWidth = 1;
            ctx.fillStyle = "#000000";
            ctx.font = "17px sans-serif";
            ctx.fillText(this.props.user.store.hashtag, 50, 43);
            ctx.font = "11px sans-serif";
            ctx.fillText("ARTURITO.COM", 217, 320);
            const pngFile = canvas.toDataURL("image/png");
            const downloadLink = document.createElement("a");
            downloadLink.download = `QR_${text}${this.props.user.store.hashtag}`;
            downloadLink.href = `${pngFile}`;
            downloadLink.click();
        };
    };

    render() {
        const { classes, user, qrs, FAQs, FAQsOAI, promos } = this.props;

        if (!user) {
            this.props.history.push('/login');
            return null;
        }

        const { value, submitted, toSaveFAQ,
            QRdesc, QRisweb, QRactive, toSaveQR,
            QRmsgpre, QRmsgfinish, QRType,
            qrsList, updatedQrsList, pageQrslist, npagesQrs, npageQrs,
            QRPreviewIndex, QRPreviewOpen, QRPreviewObj,
            theme_widget_logo, codeWidgetSide,
        } = this.state;
        const { handleChangeTab } = this;

        let titulo = "MARKETING";

        return (
            <React.Fragment>
                <Appbar back={true} label={titulo} onBack={this.onBack} user={user} />
                <div className={classes.root}>
                    <Tabs
                        orientation="vertical"
                        variant="scrollable"
                        value={value}
                        onChange={handleChangeTab}
                        aria-label="Marketing"
                        className={classes.tabs}
                    >
                        <Tab label="ACCIONES" {...a11yProps(0)} />
                        <Tab label="CALENDARIO" {...a11yProps(1)} />
                        <Tab label="COMPARTIR" {...a11yProps(2)} />
                        {(user.store_role === "admin") &&
                            <Tab label="FAQS" {...a11yProps(3)} />
                        }
                        {(user.store_role === "admin") &&
                            <Tab label="FAQS II" {...a11yProps(4)} />
                        }
                    </Tabs>
                    <TabPanel value={value} index={0}>
                        <Typography
                            // className={classes.dividerInset}
                            // display="block"
                            component={'span'}
                            variant="h2"
                            color="primary"
                        >
                            Acciones
                        </Typography>
                        <br />
                        <Typography
                            // className={classes.dividerInset}
                            // display="block"
                            component={'span'}
                            variant="body1"
                            color="primary"
                        >
                            Define los códigos promocionales y sus descripción.
                        </Typography>
                        <br /><br />
                        <Typography
                            className={classes.dividerInset}
                            display="block"
                            variant="caption"
                            color="primary"
                        >
                            Agregar nuevo codigo
                        </Typography>
                        <Divider className={classes.divider} />
                        <Input
                            id='input-qr-desc'
                            type='text'
                            label='Descripción'
                            disableAuto={true}
                            submitted={submitted}
                            autoFill={autoFillDesc}
                            name='QRdesc'
                            value={QRdesc}
                            errorMessage={"Por favor ingrese la descripcion/origen"}
                            onChange={this.handleChange}
                        />
                        <Tooltip title={'Por favor, escribe hasta 25 caracteres'} placement="right" arrow>
                            <Input
                                inputProps={{ maxLength: 25 }}
                                id='input-qr-desc'
                                type='text'
                                label='Mensaje del usuário'
                                disableAuto={true}
                                autoFill={autoFillRef2Mens}
                                submitted={submitted}
                                name='QRmsgpre'
                                value={QRmsgpre}
                                errorMessage={"Por favor ingrese el Mensaje del usuário"}
                                onChange={this.handleChange}
                            />
                        </Tooltip>
                        <Tooltip title={'Por favor, Escribe hasta 25 caracteres'} placement="right" arrow>
                            <Input
                                inputProps={{ maxLength: 25 }}
                                id='input-qr-desc'
                                type='text'
                                label='Respuesta Arturito'
                                disableAuto={true}
                                autoFill={autoFillRef2Rta}
                                submitted={submitted}
                                name='QRmsgfinish'
                                value={QRmsgfinish}
                                errorMessage={"Por favor ingrese el mensaje para finalizar"}
                                onChange={this.handleChange}
                            />
                        </Tooltip>
                        <div className={classes.inline}>
                            <InputLabel htmlFor="demo-controlled-open-select">Destino</InputLabel>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <Select
                                value={QRisweb}
                                onChange={(event) => {
                                    this.setState({
                                        [event.target.name]: event.target.value,
                                    });
                                }}
                                name='QRisweb'
                                inputProps={{
                                    name: 'QRisweb',
                                    id: 'select-type',
                                }}
                            >
                                <MenuItem value={true}>Web</MenuItem>
                                <MenuItem value={false}>WhatsApp</MenuItem>
                            </Select>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <FormControlLabel className={classes.fullwidth}
                                control={
                                    <Switch checked={QRactive} value="QRactive" color="secondary" name="QRactive" onChange={this.handleChangeSwitch} />}
                                label="Activo"
                                labelPlacement="end"
                            />
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <Tooltip open={toSaveQR} title={'Por favor, presione para agregarlo'} placement="right" arrow>
                                <Button classes={{ root: this.props.classes.cellButton }}
                                    component="label"
                                    onClick={() => {
                                        let list = this.props.qrs.qrs;
                                        list.push({
                                            "active": QRactive,
                                            "is_web": QRisweb,
                                            "description": QRdesc,
                                            'client_msg_preamble': QRmsgpre,
                                            'use_confirm_finisher': QRmsgfinish,
                                            'reference_type': QRType,
                                        })
                                        const { dispatch } = this.props;
                                        this.setState({ toSaveQR: false });
                                        dispatch(userActions.setStoreQRs(list));
                                    }}
                                >
                                    <AddCircle />&nbsp;Agregar codigo
                                </Button>
                            </Tooltip>
                        </div>
                        <br /><br /><br />
                        <Typography
                            className={classes.dividerInset}
                            display="block"
                            variant="caption"
                            color="primary"
                        >
                            Lista de codigos
                        </Typography>
                        <Divider className={classes.divider} />
                        <br />
                        <div className={classes.inline}>
                            <Button classes={{ root: this.props.classes.cellButton }}
                                component="label"
                                onClick={() => {
                                    const { dispatch } = this.props;
                                    this.setState({ toSaveQRs: false });
                                    ++this.childKey;
                                    if (qrs && qrs.qrs)
                                        dispatch(userActions.setStoreQRs(qrs.qrs));
                                }}
                            >
                                <CheckCircleOutline />&nbsp;Guardar lista
                            </Button>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <Button classes={{ root: this.props.classes.cellButton }}
                                component="label"
                                onClick={() => {
                                    // this.handleChangeTab(e, 8)
                                    this.props.history.push("/options?section=preview")
                                }}
                            >
                                <VisibilityIcon />&nbsp;Vista previa
                            </Button>
                        </div>
                        <br /><br />
                        <Filters
                            key={this.childKey}
                            label='Filtros'
                            reset={true}
                            fullWidth={false}
                            fieldList={[
                                {
                                    label: 'FECHA',
                                    date: true,
                                    field: 'created_at'
                                },
                                {
                                    label: 'Descripcion',
                                    text: true,
                                    field: 'description'
                                }
                            ]}
                            list={qrsList}
                            filteredList={updatedQrsList}
                            updateList={this.updateQrsList}
                        />
                        <ResponsiveTable
                            headCells={[
                                {
                                    id: 'created_at',
                                    label: 'FECHA',
                                    type: 'date'
                                },
                                {
                                    id: 'description',
                                    label: 'Descripción'
                                },
                                {
                                    id: 'client_msg_preamble',
                                    label: 'Mensaje'
                                },
                                {
                                    id: 'use_confirm_finisher',
                                    label: 'Respuesta',
                                    noSortable: true,
                                    render: (item, index) => {
                                        return (<TableCell key={index + 9000} classes={{ root: this.props.classes.cellRows }}>
                                            <Input
                                                id={item.id}
                                                classes={{ root: this.props.classes.cell }}
                                                multiline
                                                maxRows={3}
                                                autoFill={autoFillRef2Rta}
                                                value={(item['use_confirm_finisher']) ? item['use_confirm_finisher'] : ""}
                                                onChange={(event) => {
                                                    event.preventDefault();
                                                    item['use_confirm_finisher'] = event.target.value;
                                                    this.setState({
                                                        toSaveQRs: true,
                                                    });
                                                }} />
                                        </TableCell>)
                                    }
                                },
                                {
                                    id: 'reference_type',
                                    label: 'Tipo',
                                    noSortable: true,
                                    render: (qr, index) => {
                                        let text = 'Simple';
                                        if (qr.reference_type !== '1') {
                                            text = 'Otro'
                                        }
                                        return (<TableCell key={index + 20000} classes={{ root: this.props.classes.cellRows }}>
                                            {text}
                                        </TableCell>)
                                    }
                                },
                                {
                                    id: 'dest',
                                    label: 'DESTINO',
                                    noSortable: true,
                                    render: (qr, index) => {
                                        let text = 'Web';
                                        if (qr.url.indexOf('https://wa') > -1) {
                                            text = 'Whatsapp'
                                        }
                                        return (<TableCell key={index + 10000} classes={{ root: this.props.classes.cellRows }}>
                                            {text}
                                        </TableCell>)
                                    }
                                },
                                {
                                    id: 'activ',
                                    label: 'ACTIVO',
                                    noSortable: true,
                                    render: (qr, index) => {
                                        return (<TableCell key={index + 11000} classes={{ root: this.props.classes.cellRows }}>
                                            <Checkbox id={qr.code}
                                                checked={(qr.active)}
                                                // eslint-disable-next-line react/jsx-no-bind
                                                onChange={(event) => {
                                                    if (event.target.checked) {
                                                        qr.active = true;
                                                        // const { dispatch } = this.props;
                                                        // dispatch(userActions.enable(item.id, `Cuenta ${item.hashtag} activada`));
                                                    }
                                                    else {
                                                        qr.active = false;
                                                        // const { dispatch } = this.props;
                                                        // dispatch(userActions.disable(item.id, `Cuenta ${item.hashtag} desactivada`));
                                                    }
                                                    this.setState({
                                                        toSaveQRs: true,
                                                    });
                                                }}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>)
                                    }
                                },
                                {
                                    id: 'actions',
                                    label: 'ACCIONES',
                                    noSortable: true,
                                    render: (qr, index) => {
                                        return (
                                            <TableCell key={index + 12000} classes={{ root: this.props.classes.cellRows }}>
                                                <Button classes={{ root: this.props.classes.cellButton }}
                                                    component="label"
                                                    onClick={() => {
                                                        this.setState({
                                                            QRPreviewObj: qr,
                                                            QRPreviewIndex: toString(index),
                                                            QRPreviewOpen: true,
                                                        })
                                                    }}
                                                >
                                                    <VisibilityIcon />
                                                </Button>
                                                <Button classes={{ root: this.props.classes.cellButton }}
                                                    component="label"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        this.onImageCownload(`QRPROMO-${index}`, (qr.description ? qr.description.substr(0, 10) : ''))
                                                    }}
                                                >
                                                    <ArrowDownward />
                                                </Button>
                                                <Button classes={{ root: this.props.classes.cellButton }}
                                                    component="label"
                                                    onClick={() => {
                                                        navigator.clipboard.writeText(qr.url);
                                                    }}
                                                >
                                                    <ContentCopy />
                                                </Button>
                                                <Button classes={{ root: this.props.classes.cellButton }}
                                                    component="label"
                                                    onClick={() => {
                                                        this.props.qrs.qrs = this.props.qrs.qrs.filter(item => !(item.code === qr.code));
                                                        this.setQrsPages(this.props.qrs.qrs);
                                                        this.setState({
                                                            qrsList: this.props.qrs.qrs,
                                                            updatedQrsList: this.props.qrs.qrs,
                                                            toSaveQRs: true,
                                                        });
                                                    }}
                                                >
                                                    <DeleteIcon />
                                                </Button>
                                            </TableCell>
                                        )
                                    }
                                },

                            ]}
                            rows={pageQrslist}
                        // rows={(qrs && qrs.qrs) ? qrs.qrs : []}
                        />
                        <Pagination
                            count={npagesQrs}
                            // size="large"
                            // variant="outlined"
                            shape="rounded"
                            page={npageQrs}
                            onChange={this.handleQrsPageChange}
                            color="primary"
                            sx={{ padding: '10px' }}
                        />
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <Typography
                            // className={classes.dividerInset}
                            // display="block"
                            component={'span'}
                            variant="h2"
                            color="primary"
                        >
                            Compartir
                        </Typography>
                        <br />
                        <Typography
                            // className={classes.dividerInset}
                            // display="block"
                            component={'span'}
                            variant="body1"
                            color="primary"
                        >
                            Comparte con tus clientes este link directo a tu Asistente.
                        </Typography>
                        <br /><br />
                        <TextField
                            id="filled-multiline-static1"
                            label="url"
                            multiline
                            maxRows={1}
                            value={`https://go.arturito.chat/${this.props.user.store.hashtag.substring(1)}`}
                            variant="filled"
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                        <Button classes={{ root: this.props.classes.cellButton }}
                            component="label"
                            onClick={() => {
                                navigator.clipboard.writeText(`https://go.arturito.chat/${this.props.user.store.hashtag.substring(1)}`);
                            }}
                        >
                            <Tooltip title="Copiar codigo" placement="right" arrow>
                                <ContentCopy />
                            </Tooltip>
                        </Button>
                        <br /><br />
                        <Divider className={classes.divider} />
                        <br />
                        <Typography
                            // className={classes.dividerInset}
                            // display="block"
                            component={'span'}
                            variant="body1"
                            color="primary"
                        >
                            Comparte con tus clientes este codigo QR, sea por redes o impreso.<br />
                            (al escanearlo los direcionara a {`https://go.arturito.chat/${this.props.user.store.hashtag.substring(1)}`})
                        </Typography>
                        <br /><br />
                        <QRCode id="QRCode" title="QR arturito" value={`https://go.arturito.chat/${this.props.user.store.hashtag.substring(1)}`} />
                        <Button classes={{ root: this.props.classes.cellButton }}
                            component="label"
                            onClick={(e) => {
                                e.preventDefault();
                                this.onImageCownload("QRCode")
                            }}
                        >
                            <Tooltip title="Descargar codigo" placement="right" arrow>
                                <ArrowDownward />
                            </Tooltip>
                        </Button>
                        <br /><br />
                        <Divider className={classes.divider} />
                        <br />
                        <Typography
                            // className={classes.dividerInset}
                            // display="block"
                            component={'span'}
                            variant="body1"
                            color="primary"
                        >
                            Pega este código en una página HTML para publicar el logo con hiperlink.
                            (el logo puede ser configurado en la seccion personalizar widget)
                        </Typography>
                        <br /><br />
                        <TextField
                            id="filled-multiline-static2"
                            label="Codigo"
                            multiline
                            maxRows={6}
                            value={`<a href="https://arturito.chat/s/${this.props.user.store.hashtag.substring(1)}" target="_blank" rel="noopener noreferrer"><img src="${theme_widget_logo}" alt="artu-widget" width="100" height="100" /></a>`
                            }
                            variant="filled"
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                        <Button classes={{ root: this.props.classes.cellButton }}
                            component="label"
                            onClick={() => {
                                navigator.clipboard.writeText(`<a href="https://arturito.chat/s/${this.props.user.store.hashtag.substring(1)}" target="_blank" rel="noopener noreferrer"><img src="${theme_widget_logo}" alt="artu-widget" width="100" height="100" /></a>`);
                            }}
                        >
                            <Tooltip title="Copiar codigo" placement="right" arrow>
                                <ContentCopy />
                            </Tooltip>
                        </Button>
                        <Tooltip title="Ejemplo del logo con hiperlink" placement="right" arrow>
                            <a href={`https://arturito.chat/s/${user.store.hashtag.substring(1)}`} target="_blank" rel="noopener noreferrer">
                                <img src={theme_widget_logo} alt="artu-widget" width="100" height="100" />
                            </a>
                        </Tooltip>
                        <br /><br />
                        <Divider className={classes.divider} />
                        <br />
                        <Typography
                            // className={classes.dividerInset}
                            // display="block"
                            component={'span'}
                            variant="body1"
                            color="primary"
                        >
                            Graba este código en tu página HTML para publicar el logo flotante en la orientación que elijas.
                            (el logo puede ser configurado en la seccion personalizar widget)
                        </Typography>
                        <br /><br />
                        <FormControl component="fieldset">
                            <FormLabel component="legend">Posición del widget</FormLabel>
                            <RadioGroup row aria-label="posicion" name="row-radio-buttons-group"
                                value={codeWidgetSide}
                                onChange={(event) => { this.setState({ codeWidgetSide: event.target.value }) }}>
                                <FormControlLabel value="left" control={<Radio />} label="izquierda" />
                                <FormControlLabel value="center" control={<Radio />} label="centro" />
                                <FormControlLabel value="right" control={<Radio />} label="derecha" />
                            </RadioGroup>
                        </FormControl>
                        <br /><br />
                        <TextField
                            id="filled-multiline-static1"
                            label="Codigo"
                            multiline
                            maxRows={6}
                            value={`<div style="position:fixed; bottom:15px; z-index: 999; ${(codeWidgetSide === 'center') ? 'left:50%' : codeWidgetSide + ':15px;'}"><a href="https://arturito.chat/s/${this.props.user.store.hashtag.substring(1)}" target="_blank" rel="noopener noreferrer"><img src="${theme_widget_logo}" alt="artu-widget" width="100" height="100" /></a></div>`
                            }
                            variant="filled"
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                        <Button classes={{ root: this.props.classes.cellButton }}
                            component="label"
                            onClick={() => { navigator.clipboard.writeText(`<div style="position:fixed; bottom:15px; z-index: 999; ${(codeWidgetSide === 'center') ? 'left:50%' : codeWidgetSide + ':15px;'}"><a href="https://arturito.chat/s/${this.props.user.store.hashtag.substring(1)}" target="_blank" rel="noopener noreferrer"><img src="${theme_widget_logo}" alt="artu-widget" width="100" height="100" /></a></div>`) }}
                        >
                            <Tooltip title="Copiar codigo" placement="right" arrow>
                                <ContentCopy />
                            </Tooltip>
                        </Button>
                        <br /><br />
                        <Divider className={classes.divider} />
                        <br />
                        <Typography
                            // className={classes.dividerInset}
                            // display="block"
                            component={'span'}
                            variant="body1"
                            color="primary"
                        >
                            Graba este código en tu página HTML para publicar el widget de arturito con funcionalidad completa.
                            (el logo puede ser configurado en la seccion personalizar widget)
                        </Typography>
                        <br /><br />
                        <TextField
                            id="filled-multiline-static2"
                            label="Codigo"
                            multiline
                            maxRows={6}
                            value={`<script type="text/javascript" src="https://arturito.chat/scripts/${this.props.user.store.hashtag.substring(1)}/main.js"></script>`}
                            variant="filled"
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                        <Button classes={{ root: this.props.classes.cellButton }}
                            component="label"
                            onClick={() => {
                                navigator.clipboard.writeText(`<script type="text/javascript" src="https://arturito.chat/scripts/${this.props.user.store.hashtag.substring(1)}/main.js"></script>`);
                            }}
                        >
                            <Tooltip title="Copiar codigo" placement="right" arrow>
                                <ContentCopy />
                            </Tooltip>
                        </Button>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <Typography
                            // className={classes.dividerInset}
                            // display="block"
                            component={'span'}
                            variant="h2"
                            color="primary"
                        >
                            Calendario de Promos
                        </Typography>
                        <br />
                        <Typography
                            // className={classes.dividerInset}
                            // display="block"
                            component={'span'}
                            variant="body1"
                            color="primary"
                        >
                            Define los días de promos y sus textos.
                        </Typography>
                        <br />
                        <div className={classes.inline}>
                            <Button classes={{ root: this.props.classes.cellButton }}
                                component="label"
                                onClick={() => {
                                    const { dispatch } = this.props;
                                    this.setState({ toSavePromos: false });
                                    if (promos && promos.promos)
                                        dispatch(userActions.setPromos(promos.promos));
                                }}
                            >
                                <CheckCircleOutline />&nbsp;Guardar lista
                            </Button>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <Button classes={{ root: this.props.classes.cellButton }}
                                component="label"
                                onClick={() => {
                                    this.props.history.push("/options?section=preview")
                                }}
                            >
                                <VisibilityIcon />&nbsp;Vista previa
                            </Button>
                        </div>
                        <br /><br />
                        <ResponsiveTable
                            headCells={[
                                {
                                    id: 'day_of_week',
                                    label: 'DIA',
                                    render: (item, index) => this.renderPromoDay(item, index + 5000)
                                },
                                {
                                    id: 'tag',
                                    label: 'TAG',
                                    noSortable: true,
                                    render: (item, index) => this.renderPromo(item, 'tag', index + 6000)
                                },
                                {
                                    id: 'promo_msg',
                                    label: 'MENSAJE',
                                    noSortable: true,
                                    render: (item, index) => this.renderPromoMsg(item, 'promo_msg', index + 7000)
                                }
                            ]}
                            rows={(promos && promos.promos) ? promos.promos : []}
                        />
                    </TabPanel>
                    {
                        (user.store_role === "admin") &&
                        <TabPanel value={value} index={3}>
                            <Typography
                                // className={classes.dividerInset}
                                // display="block"
                                component={'span'}
                                variant="h2"
                                color="primary"
                            >
                                Preguntas Frequentes
                            </Typography>
                            <br />
                            <Typography
                                // className={classes.dividerInset}
                                // display="block"
                                component={'span'}
                                variant="body1"
                                color="primary"
                            >
                                Define las preguntas frecuentes basicas.
                            </Typography>
                            <br /><br /><br />
                            <ResponsiveTable
                                headCells={[
                                    // {
                                    //     id: 'id',
                                    //     label: 'ID'
                                    // },
                                    {
                                        id: 'faq_type',
                                        label: 'Tipo',
                                        render: (item, index) => this.renderFAQType(item, index + 5000)
                                    },
                                    {
                                        id: 'method_info',
                                        label: 'RESPUESTA AL CLIENTE',
                                        noSortable: true,
                                        render: (item, index) => this.renderFAQ(item, index + 6000)
                                    },
                                    {
                                        id: 'delet',
                                        label: 'ELIMINAR',
                                        noSortable: true,
                                        render: (item, index) => this.renderFAQActions(item, index + 7000)
                                    }
                                ]}
                                rows={(FAQs && FAQs.FAQs) ? FAQs.FAQs : []}
                            />
                            <br />
                            <Button classes={{ root: this.props.classes.cellButton }}
                                component="label"
                                onClick={() => {
                                    const { dispatch } = this.props;
                                    this.setState({ toSaveFAQ: false });
                                    dispatch(userActions.setFAQs(FAQs.FAQs));
                                }}
                            >
                                <Tooltip open={toSaveFAQ} title={'Por favor, presione para guardar los cambios'} placement="right" arrow>
                                    <CheckCircleOutline />
                                </Tooltip>
                            </Button>
                        </TabPanel>
                    }
                    {
                        (user.store_role === "admin") &&
                        <TabPanel value={value} index={4}>
                            <Typography
                                // className={classes.dividerInset}
                                // display="block"
                                component={'span'}
                                variant="h2"
                                color="primary"
                            >
                                Base de Conocimiento
                            </Typography>
                            <br />
                            <Typography
                                // className={classes.dividerInset}
                                // display="block"
                                component={'span'}
                                variant="body1"
                                color="primary"
                            >
                                Este documento no debe contener asuntos relacionados a las FAQ.
                            </Typography>
                            <br /><br /><br />
                            <TextField
                                id="filled-multiline-static"
                                // label="title"
                                multiline
                                maxRows={25}
                                className={classes.tabpanel}
                                value={(FAQsOAI && FAQsOAI.FAQsOAI && FAQsOAI.FAQsOAI.length > 0) ? this.totext(FAQsOAI.FAQsOAI) : " "}
                                inputProps={
                                    { readOnly: true }
                                }
                            />
                        </TabPanel>
                    }
                </div >
                <QRPreview open={QRPreviewOpen}
                    index={toString(QRPreviewIndex)} qr={QRPreviewObj}
                    user={this.props.user.store.hashtag}
                    handleClose={() => {
                        this.setState({
                            QRPreviewOpen: false,
                        });
                    }} />
                <div ref={el => (this.instance = el)} />
            </React.Fragment >
        );
    }

    componentDidMount() {
        if (window.location.href.startsWith('https://arturito.chat') || window.location.href.startsWith('https://arturito.store')) {
            //tracking script from ActiveCampaign
            const s = document.createElement('script');
            s.type = 'text/javascript';
            s.async = true;
            s.innerHTML = '(function(e,t,o,n,p,r,i){e.visitorGlobalObjectAlias = n;e[e.visitorGlobalObjectAlias]=e[e.visitorGlobalObjectAlias]||function(){(e[e.visitorGlobalObjectAlias].q = e[e.visitorGlobalObjectAlias].q || []).push(arguments)};e[e.visitorGlobalObjectAlias].l=(new Date).getTime();r=t.createElement("script");r.src=o;r.async=true;i=t.getElementsByTagName("script")[0];i.parentNode.insertBefore(r,i)})(window,document,"https://diffuser-cdn.app-us1.com/diffuser/diffuser.js","vgo");vgo("setAccount", "476725427");vgo("setTrackByDefault", true);vgo("process");';
            this.instance.appendChild(s);
        }

        const { user, dispatch } = this.props;

        if (!user || !this.props.setWidget) {
            console.log('Error user or session, redirecting')
            localStorage.setItem('lasturl', `/marketing`);
            this.props.history.push('/login');
            return null;
        }

        dispatch(userActions.getPromos());

        if (user.store_role === "admin") {
            dispatch(userActions.getStoreQRs());
            dispatch(userActions.getFAQs());
            dispatch(userActions.getFAQsOAI());
        }
        this.props.setWidget(true, false);

        const queryParams = new URLSearchParams(window.location.search)
        const section = queryParams.get("section")

        if (section === 'faqs1') {
            // eslint-disable-next-line react/no-did-mount-set-state
            setTimeout(() => { this.setState({ value: 3 }); }, 500);
        }

        if (section === 'faqs2') {
            // eslint-disable-next-line react/no-did-mount-set-state
            setTimeout(() => { this.setState({ value: 4 }); }, 500);
        }

        if (section === 'calendario') {
            // eslint-disable-next-line react/no-did-mount-set-state
            setTimeout(() => { this.setState({ value: 1 }); }, 500);
        }
        if (section === 'acciones') {
            // eslint-disable-next-line react/no-did-mount-set-state
            setTimeout(() => { this.setState({ value: 0 }); }, 500);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if ((prevProps.qrs !== this.props.qrs) && (this.props.qrs && this.props.qrs.qrs)) {
            this.setQrsPages(this.props.qrs.qrs);
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({
                qrsList: this.props.qrs.qrs,
                updatedQrsList: this.props.qrs.qrs,
            });
        }
    }

    componentWillUnmount() {
        localStorage.setItem('lasturl', `/options`);
    }
}

function mapStateToProps(state) {
    const { loggingIn, user } = state.authentication;
    const { qrs, FAQs, FAQsOAI, products, promos } = state;

    return {
        loggingIn,
        user,
        FAQs,
        FAQsOAI,
        qrs,
        products,
        promos,
    };
}

export default connect(mapStateToProps)(withStyles(styles)(Marketing));