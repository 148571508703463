/* eslint-disable react/jsx-no-bind */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { userActions, alertActions } from '../_actions';
import Input from '../_components/Input';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';
import Appbar from '../_components/Appbar';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import ReCAPTCHA1 from "react-google-recaptcha";

// eslint-disable-next-line no-unused-vars
const styles = theme => ({
    alert: {
        color: '#f44336',
    },
    dividerInset: {
        'margin-left': '-20px',
    },
    divider: {
        background: '#ececec !important',
        'margin-left': '-30px',
        'margin-right': '-30px',
    },
});

class RecoverPass extends React.Component {
    static propTypes = {
        classes: PropTypes.object.isRequired,
        dispatch: PropTypes.func,
        history: PropTypes.object,
        // setWidget: PropTypes.func,
        user: PropTypes.object,
        // syncAC: PropTypes.func,
    };

    state = {
        submitted: false,
        code: '',
        emailUser: '',
        password: '',
        CAsubmitted: false,
        errorEmailUser: false,
        errorPass: false,
        errorPassMessage: '',
    };

    handleSubmit = event => {
        event.preventDefault();
        this.setState({ submitted: true });
        const { CAsubmitted, emailUser, password, errorPass, code } = this.state;
        const { dispatch } = this.props;

        if (emailUser && password && CAsubmitted && !errorPass) {
            dispatch(userActions.passwordRecover({
                "username": emailUser,
                "token": code,
                "password": password,
            }, 'Contraseña actualizada correctamente'));
            // this.props.syncAC([
            //     {
            //         "field": "24",
            //         "value": hashtag,
            //     },
            //     {
            //         "field": "23",
            //         "value": new Date().toISOString(),
            //     },
            //     {
            //         "field": "27",
            //         "value": "admin",
            //     },
            // ], emailUser);
            this.props.history.push('/login');
        }
    }

    handleCode = event => {
        event.preventDefault();
        this.setState({ submitted: true });
        const { emailUser} = this.state;
        const { dispatch } = this.props;

        if (emailUser) {
            dispatch(userActions.startPasswordRecover({
                "username": emailUser,
            }, 'Codigo enviado, por favor chequee su casilla de email. Luego copie el codigo y peguelo en el campo correspondiente.'));
            // this.props.syncAC([
            //     {
            //         "field": "24",
            //         "value": hashtag,
            //     },
            //     {
            //         "field": "23",
            //         "value": new Date().toISOString(),
            //     },
            //     {
            //         "field": "27",
            //         "value": "admin",
            //     },
            // ], emailUser);
        }
    }

    onBack = event => {
        event.preventDefault();
        this.props.history.push('/login');
    }

    validateEmail = (email) => {
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
    }

    validatePass = (pass) => {
        if (pass.length < 6) {
            return ("La contraseña debe ser mayor a 6 digitos");
        } else if (pass.length > 50) {
            return ("Contraseña muy larga");
        } else if (pass.search(/\d/) === -1) {
            return ("La contraseña debe contener numeros y letras");
        } else if (pass.search(/[a-zA-Z]/) === -1) {
            return ("La contraseña debe contener numeros y letras");
            // eslint-disable-next-line no-useless-escape
        } else if (pass.search(/[^a-zA-Z0-9\!\@\#\$\%\^\&\*\(\)\_\+]/) !== -1) {
            return ("La contraseña posee un caracter invalido");
        }
        return ("ok");
    }

    handleChange = event => {
        event.preventDefault();
        if (event.target.name === 'emailUser') {
            if (!this.validateEmail(event.target.value)) {
                this.setState({
                    errorEmailUser: true,
                });
            } else {
                this.setState({
                    errorEmailUser: false,
                });
            }
        }
        if (event.target.name === 'password') {
            const error = this.validatePass(event.target.value);
            if (error !== 'ok') {
                this.setState({
                    errorPass: true,
                    errorPassMessage: error,
                });
            } else {
                this.setState({
                    errorPass: false,
                    errorPassMessage: '',
                });
            }
        }

        this.setState({
            [event.target.name]: event.target.value,
        });
    }

    onChangeCA = () => {
        // event.preventDefault();
        this.setState({
            CAsubmitted: true,
        });
    }

    render() {
        const { classes, user } = this.props;

        if (user) {
            this.props.history.push('/home');
            return null;
        }

        const { submitted, emailUser, password, CAsubmitted, code,
            errorEmailUser, errorPass, errorPassMessage } = this.state;

        const titulo = 'RECUPERAR CONTRASEÑA'

        return (
            <React.Fragment>
                <Appbar back={true} label={titulo} onBack={this.onBack} />
                <FormControl onSubmit={this.handleSubmit} >
                    <Grid container spacing={3}
                        direction="column"
                        alignItems="center"
                    >
                        <Grid
                            item xs={10} md={5}
                            container
                            direction="column"
                            alignItems="stretch"
                        >
                            <div ref={el => (this.instance = el)} />
                            <br />
                            <Typography
                                className={classes.dividerInset}
                                display="block"
                                variant="body1"
                                color="primary"
                            >
                                PASO 1: ingrese su email de usuario
                            </Typography>
                            <Divider className={classes.divider} />
                            <Input
                                id='input-email-user'
                                type='text'
                                label='Email del usuario'
                                submitted={submitted}
                                disableAuto={true}
                                error={errorEmailUser}
                                name='emailUser'
                                value={emailUser}
                                errorMessage="Por favor ingrese un email valido"
                                onChange={this.handleChange}
                            />
                            <br />
                            <Button variant="contained" color="primary" onClick={this.handleCode}>Solicitar codigo</Button>
                            <br />
                            <br />
                            <Typography
                                className={classes.dividerInset}
                                color="primary"
                                variant="body1"
                            >
                                PASO 2: ingrese el código enviado a su casilla de email.
                            </Typography>
                            <Divider className={classes.divider} />
                            <Input
                                id='input-code'
                                label='Ingrese el código generado'
                                type='text'
                                disableAuto={true}
                                submitted={submitted}
                                name='code'
                                value={code}
                                errorMessage="Por favor ingrese el codigo enviado a su email"
                                onChange={this.handleChange}
                            />
                            <br />
                            <br />
                            <Typography
                                className={classes.dividerInset}
                                color="primary"
                                variant="body1"
                            >
                                PASO 3: ingrese una nueva contraseña
                            </Typography>
                            <Divider className={classes.divider} />
                            <Input
                                id='input-pass'
                                type='password'
                                eye={true}
                                label='Contraseña nueva'
                                disableAuto={true}
                                submitted={submitted}
                                name='password'
                                value={password}
                                error={errorPass}
                                errorMessage={(errorPassMessage ? errorPassMessage : "Por favor ingrese la contraseña")}
                                onChange={this.handleChange}
                            />
                            <br />
                        </Grid>
                        <Grid
                            item xs={10} md={5}
                            container
                            direction="column"
                            alignItems="stretch"
                        >
                            <br />
                            <ReCAPTCHA1
                                sitekey="6LdJYAEbAAAAAApeuN9Wsv1h_52YHwQGzC4JmxxC"
                                onChange={this.onChangeCA}
                            />
                            {!CAsubmitted && submitted &&
                                <div className={classes.alert}>Por favor, complete el CAPTCHA</div>
                            }
                            <br /><br />
                            <Button variant="contained" color="primary" onClick={this.handleSubmit}>Actualizar contraseña</Button>
                            <br /><br />
                        </Grid>
                    </Grid>
                </FormControl>
            </React.Fragment>
        );
    }

    componentDidMount() {

        const { dispatch, user } = this.props;

        if (user) {
            dispatch(alertActions.error('Error, para registrar un usuario primero tiene que cerrar la sesion actual'));
            this.props.history.push('/home');
            return null;
        }

        if (window.location.href.startsWith('https://arturito.chat') || window.location.href.startsWith('https://arturito.store')) {
            //tracking script from ActiveCampaign
            const s = document.createElement('script');
            s.type = 'text/javascript';
            s.async = true;
            s.innerHTML = '(function(e,t,o,n,p,r,i){e.visitorGlobalObjectAlias=n;e[e.visitorGlobalObjectAlias]=e[e.visitorGlobalObjectAlias]||function(){(e[e.visitorGlobalObjectAlias].q=e[e.visitorGlobalObjectAlias].q||[]).push(arguments)};e[e.visitorGlobalObjectAlias].l=(new Date).getTime();r=t.createElement("script");r.src=o;r.async=true;i=t.getElementsByTagName("script")[0];i.parentNode.insertBefore(r,i)})(window,document,"https://diffuser-cdn.app-us1.com/diffuser/diffuser.js","vgo");vgo("setAccount", "476725427");vgo("setTrackByDefault", true);vgo("process");';
            this.instance.appendChild(s);
        }

        // this.props.setWidget(true, false);
    }
}

function mapStateToProps(state) {
    const { loggingIn } = state.authentication;
    const { user } = state.authentication;
    return {
        loggingIn,
        user
    };
}

export default connect(mapStateToProps)(withStyles(styles)(RecoverPass));