/* eslint-disable react/jsx-no-bind */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Appbar from '../_components/Appbar';
import withStyles from '@material-ui/core/styles/withStyles';
import {
    Tabs, Tab, AppBar, Typography, Grid, Divider,
    TableCell, Button, TextField, Checkbox, Tooltip
} from '@material-ui/core';
import Input from '../_components/Input';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputAdornment from '@material-ui/core/InputAdornment';
import Payment from '@material-ui/icons/MonetizationOn';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import { orderActions, alertActions } from '../_actions';
import ResponsiveTable from '../_components/Table';
import TabPanel from '../_components/TabPanel';
import DeleteIcon from '@material-ui/icons/Delete';
import AddCircle from '@material-ui/icons/AddCircle';
import LocalShipping from '@material-ui/icons/LocalShipping';
import { convertNumber, convertDate, convertDateToBE } from '../_helpers';
import Pagination from '@mui/material/Pagination';
import Filters from '../_components/Filters';
// import Avatar from '@material-ui/core/Avatar';
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';


// eslint-disable-next-line no-unused-vars
const styles = theme => ({
    texttitle: {
        color: '#ff8700',
    },
    textred: {
        color: 'red',
    },
    tabpanelHeader: {
        // 'backgroundColor': 'white',
        width: '100%',
        'text-align': 'center',
        'z-index': 0,
        '&.Mui-selected': {
            'backgroundColor': 'black',
        }
    },
    textbox: {
        background: '#eee',
        padding: '35px',
        margin: '10px',
        flex: 1,
        color: 'black',
        // 'margin-top': '-20px',
    },
    tabpanel: {
        width: '100%',
        overflow: 'auto',
        backgroundColor: 'white',
        flexGrow: 1,
    },
    cellButton: {
        padding: '0px',
        margin: '0px',
        'min-width': '0px',
    },
    cellRows: {
        color: '#333 !important',
        borderBottom: '1px solid #b7b7b7',
        boxShadow: 'none',
        textAlign: 'end',
    },
    cell: {
        width: '100%',
    },
    table: {
        overflow: 'auto',
    },
    MuiAccordionroot: {
        'backgroundColor': 'rgba(0, 0, 0, 0.09)',
        "&.MuiAccordion-root.Mui-expanded": {
            margin: '0px 0'
        }
    },
    MuiAccordionDetailsroot: {
        display: 'block',
    },
    searchIcon: {
        float: 'left',
        'font-size': '20px',
        'margin-right': '10px',
        'margin-left': '10px',
    },
    divider: {
        'backgroundColor': '#124e5c',
    },
    link: {
        color: '#ff8700',
        textDecoration: 'none',
        'font-size': '14px',
        "&:hover": {
            cursor: 'pointer',
        },
    },
    icoRed: {
        'font-size': '20px',
        color: '#ff0000',
        'margin-top': '-3px',
        'padding-top': '0px',
        'margin-bottom': '-3px',
        'margin-left': '5px',
        'margin-right': '3px',
    },
    icoGreen: {
        'font-size': '20px',
        color: '#02ff00',
        'margin-top': '-3px',
        'padding-top': '0px',
        'margin-bottom': '-3px',
        'margin-left': '5px',
        'margin-right': '3px',
    },
    icoYellow: {
        'font-size': '20px',
        color: '#ffed00',
        'margin-top': '-3px',
        'padding-top': '0px',
        'margin-bottom': '-3px',
        'margin-left': '5px',
        'margin-right': '3px',
    },
    cellRowsIco: {
        borderBottom: '1px solid #b7b7b7',
        boxShadow: 'none',
        padding: '5px 7px 0px 5px',
        width: '50px',
        'text-align': 'end',
    },
    inline: {
        display: 'inline-flex',
        'align-items': 'center',
        height: '45px',
        width: '100%'
    },
    input: {
        'padding-top': '0px',
        'margin-top': '0px',
        'padding-bottom': '7px',
    },
    input2: {
        'padding-top': '8px',
        'margin-top': '0px',
        'padding-bottom': '0px',
    },
    input3: {
        'padding-top': '8px',
        'margin-top': '0px',
        'padding-bottom': '0px',
        '&::placeholder': {
            textOverflow: 'ellipsis !important',
            color: 'orange !important'
        },
    },
    'MuiFormHelperText-root': {
        // textOverflow: 'ellipsis !important',
        color: 'orange !important',

    },
    buttonsave: {
        'font-size': '14px !important',
    },
    totalAmount: {
        opacity: '1 !important',
        'backgroundColor': 'white !important',
        color: '#ff8700 !important',
        'font-size': '14px !important',
        "&:hover": {
            cursor: 'none',
        },
    },
    clickeable: {
        "&:hover": {
            cursor: 'pointer',
        },
    }
});

var first = true;

class OrderDetail extends React.Component {
    static propTypes = {
        classes: PropTypes.object.isRequired,
        user: PropTypes.object,
        currentOrder: PropTypes.object,
        history: PropTypes.object,
        client: PropTypes.object,
        // sessionId: PropTypes.string,
        setWidget: PropTypes.func,
        dispatch: PropTypes.func,
        orders: PropTypes.oneOfType([
            PropTypes.array,
            PropTypes.object
        ]),
        match: PropTypes.object,
        getData: PropTypes.func,
    };

    totalConcepts = () => {
        let concepts = 0;
        if (this.props.currentOrder && this.props.currentOrder.other_concepts && this.props.currentOrder.other_concepts.length > 0)
            this.props.currentOrder.other_concepts.forEach(item => {
                concepts += +item.amount;
            });
        return concepts;
    }

    totalAmount = () => {
        let amount = 0;
        if (this.props.currentOrder && this.props.currentOrder.items && this.props.currentOrder.items.length > 0)
            this.props.currentOrder.items.forEach(item => {
                const price = ((item.product_denormalized && item.product_denormalized.price_per_unit) ? item.product_denormalized.price_per_unit : 0);
                let total = ((item.revised_price) ? parseFloat(item.revised_price) : ((price) ? item.amount * parseFloat(price) : 0));
                if (!(total >= 0)) {
                    total = 0;
                }
                amount += +total;
            });
        return amount;
    }


    state = {
        statusOrder: this.props.currentOrder.status,
        conceptsOrder: (this.props.currentOrder.other_concepts ? this.props.currentOrder.other_concepts : []),
        conceptsOrderDel: [],
        obsOrder: (this.props.currentOrder.store_observation ? this.props.currentOrder.store_observation : ''),
        infoPay: (this.props.currentOrder.payment_info ? this.props.currentOrder.payment_info : ''),
        toSave: false,
        shippCost: (this.props.currentOrder.shipping_cost ? this.props.currentOrder.shipping_cost : 0),
        total: ((this.props.currentOrder.total_price && this.props.user.store.curr_symbol) ? this.props.currentOrder.total_price : 0),
        value: 0,
        submitted: false,
        concept: '',
        conceptamount: '',
        erroramount: false,
        totalConcepts: this.totalConcepts(),
        desc: '-',
        amount: this.totalAmount(),
        payment_status: (this.props.currentOrder.payment_status ? this.props.currentOrder.payment_status : 'pending'),
        productList: [],
        updatedList: [],
        pagelist: [],
        npages: 0,
        npage: 0,
        shipping_tracking_code: "",
        shipping_transport_co: "",
        shipping_eta: "",
        shipping_link: "",
        shipping_observations: ""
    };

    updateList = (list) => {
        this.setPages(list);
        this.setState({
            updatedList: list,
        })
    }

    handlePageChange = (event, page) => {
        const nitems = 7;
        this.setState({
            pagelist: this.state.updatedList.slice(nitems * (page - 1), nitems * page),
            npage: page
        });
    }

    setPages = (list) => {
        const nitems = 7;
        this.setState({
            pagelist: list.slice(0, 7),
            npages: Math.ceil(list.length / nitems),
            npage: 1
        });
    }

    onBack = event => {
        event.preventDefault();
        first = true;
        const lasturl = localStorage.getItem('lasturl');
        if (lasturl && (window.location.href.indexOf(lasturl) === -1))
            this.props.history.push(lasturl);
        else this.props.history.push('/home');
    }

    handleSave = () => {
        //clean order
        let newOrder = JSON.parse(JSON.stringify(this.props.currentOrder));
        // let newOrder = this.props.currentOrder;
        delete newOrder.client;
        delete newOrder.amount;
        delete newOrder.estado;
        delete newOrder.nitems;
        delete newOrder.colorBlue;
        delete newOrder.colorRed;
        delete newOrder.colorViolet;
        delete newOrder.payment;

        newOrder.items.forEach(item => {
            delete item.price;
            delete item.total;
            delete item.name;
            delete item.brand;
            delete item.kind;
            delete item.color;
            delete item.quantity_unit;
            if (item.revised_price === '') item.revised_price = null;
        });

        newOrder.status = this.state.statusOrder;
        newOrder.store_observation = this.state.obsOrder === '' ? this.state.obsOrder : null;
        newOrder.payment_info = this.state.infoPay === '' ? this.state.infoPay : null;
        newOrder.shipping_cost = this.state.shippCost === '' ? this.state.shippCost : 0;
        newOrder.other_concepts_del = this.state.conceptsOrderDel;
        newOrder.other_concepts = [];

        const { currentOrder } = this.props;
        if (this.state.shipping_tracking_code ||
            this.state.shipping_transport_co ||
            this.state.shipping_eta ||
            this.state.shipping_link ||
            this.state.shipping_observations) {
            if (currentOrder.shipping.length === 0)
                newOrder.shipping.push({});
            newOrder.shipping[0].tracking_code = this.state.shipping_tracking_code !== '' ? this.state.shipping_tracking_code : null;
            newOrder.shipping[0].transport_co = this.state.shipping_transport_co !== '' ? this.state.shipping_transport_co : null;
            // newOrder.shipping[0].eta = this.state.shipping_eta !== '' ? this.state.shipping_eta : null;
            newOrder.shipping[0].eta = this.state.shipping_eta !== '' ? convertDateToBE(this.state.shipping_eta) : null;
            newOrder.shipping[0].link = this.state.shipping_link !== '' ? this.state.shipping_link : null;
            newOrder.shipping[0].observations = this.state.shipping_observations !== '' ? this.state.shipping_observations : null;
        }

        this.state.conceptsOrder.forEach(item => {
            if (item.updated === 'new') delete item.id;
            if (item.updated === 'true' || item.updated === 'new') {
                delete item.updated;
                newOrder.other_concepts.push(item);
            }
        });
        this.setState({
            toSave: false,
        });

        for (var i = currentOrder.items.length - 1; i >= 0; --i) {
            if (!currentOrder.items[i].id) {
                currentOrder.items.splice(i, 1);
            }
        }
        const { dispatch } = this.props;
        dispatch(orderActions.update(newOrder, 'Orden actualizada correctamente'));
        setTimeout(() => {
            this.props.getData(['orders'], this.props.match.params.id);
        }, 500);
    };

    handlePayment = () => {
        this.props.currentOrder.payment_status = 'required';
        this.props.currentOrder.payment_info = this.state.infoPay;
        this.setState({
            payment_status: 'required',
        });
        this.handleSave();
    };

    handleChangeShippCost = (event) => {
        this.setState({
            shippCost: event.target.value,
            toSave: true,
        });
        this.updateAmount(event.target.value);
    }

    handleChangeObs = (event) => {
        this.setState({
            obsOrder: event.target.value,
            toSave: true,
        });
    }

    handleChangeInfoPay = (event) => {
        this.setState({
            infoPay: event.target.value,
        });
    }

    handleChangeStatus = (event) => {
        this.setState({
            statusOrder: event.target.value,
        });
        this.setState({
            toSave: true,
        });
    }

    updateDefaults = () => {
        this.setState({
            conceptsOrder: this.props.currentOrder.other_concepts,
            // conceptsOrderDel: [],
            statusOrder: this.props.currentOrder.status,
            obsOrder: (this.props.currentOrder.store_observation ? this.props.currentOrder.store_observation : ''),
            infoPay: (this.props.currentOrder.payment_info ? this.props.currentOrder.payment_info : ''),
            toSave: false,
            shippCost: (this.props.currentOrder.shipping_cost ? this.props.currentOrder.shipping_cost : 0),
            amount: ((this.props.currentOrder.total_price && this.props.user.store.curr_symbol) ? this.props.currentOrder.total_price : 0),
            payment_status: (this.props.currentOrder.payment_status ? this.props.currentOrder.payment_status : 'pending'),
        })

        const { currentOrder } = this.props;
        const shippingPresent = (currentOrder && currentOrder.shipping && currentOrder.shipping.length > 0) ? true : false;
        const shippingImported = (shippingPresent && currentOrder.shipping[0].original_id) ? true : false;
        if (shippingPresent && !shippingImported) {
            this.setState({
                shipping_tracking_code: currentOrder.shipping[0].tracking_code,
                shipping_transport_co: currentOrder.shipping[0].transport_co,
                shipping_eta: (currentOrder.shipping[0].eta ? convertDate(currentOrder.shipping[0].eta) : ''),
                shipping_link: currentOrder.shipping[0].link,
                shipping_observations: currentOrder.shipping[0].observations
            });
        }
        setTimeout(() => { this.updateAmount() }, 1000);
    }

    updateAmount = (shippcost) => {
        // let amount = this.this.props.currentOrder.shipping_cost ? this.props.currentOrder.shipping_cost : 0;
        let shippc = (shippcost ? parseFloat(shippcost) : this.state.shippCost ? parseFloat(this.state.shippCost) : 0);
        let amount = 0;
        const currOrder = (this.state.conceptsOrder && this.state.conceptsOrder.length > 0) ? this.state.conceptsOrder : [];
        if (this.props.currentOrder && this.props.currentOrder.items && this.props.currentOrder.items.length > 0)
            this.props.currentOrder.items.forEach(item => {
                const price = ((item.product_denormalized && item.product_denormalized.price_per_unit) ? item.product_denormalized.price_per_unit : 0);
                let total = ((item.revised_price) ? parseFloat(item.revised_price) : ((price) ? item.amount * parseFloat(price) : 0));
                if (!(total >= 0)) {
                    total = 0;
                }
                amount += +total;
            });
        let concepts = 0;
        currOrder.forEach(item => {
            concepts += +item.amount;
        });

        this.setState({
            total: amount + concepts + shippc,
            amount: amount,
            totalConcepts: concepts,
            shippcost: shippc,
        });
        return amount;
    }

    toSavePayment = (text) => {
        const { payment_status } = this.state;
        const { currentOrder } = this.props;

        if (text)
            return ((currentOrder.status === 'preparing' || currentOrder.status === 'pending') && payment_status === 'pending' && currentOrder.payment_method && !currentOrder.payment_info)
        return ((currentOrder.status === 'preparing' || currentOrder.status === 'pending') && payment_status === 'pending' && currentOrder.payment_method)
    }

    renderActions = (item, index) => {
        return (<TableCell key={index} classes={{ root: this.props.classes.cellRows }}>
            {// eslint-disable-next-line react/jsx-no-bind
            }
            <Checkbox id={item.id}
                checked={(item.status === 'done')}
                onChange={(event) => {
                    // event.preventDefault();
                    if (event.target.checked)
                        item.status = 'done';
                    else item.status = 'pending';
                    this.setState({
                        toSave: true,
                    });
                }}
                inputProps={{ 'aria-label': 'primary checkbox' }}
            />
        </TableCell>)
    }

    renderComboObs = (item, index) => {
        return (<TableCell key={index} classes={{ root: this.props.classes.cellRows }}>
            {// eslint-disable-next-line react/jsx-no-bind
            }
            <TextField id={item.id} classes={{ root: this.props.classes.cell }} multiline maxRows={3} value={(item.store_observations) ? item.store_observations : ""}
                onChange={(event) => {
                    event.preventDefault();
                    item.store_observations = event.target.value;
                    this.setState({
                        toSave: true,
                    });
                }} />
        </TableCell>)
    }

    renderComboPrice = (item, index) => {
        return (<TableCell key={index} classes={{ root: this.props.classes.cellRows }}>
            {// eslint-disable-next-line react/jsx-no-bind
            }
            <Tooltip title={(this.state.payment_status !== 'pending') ? 'No es posible modificar esto, el pago ya fue fijado.' : ''} placement="right" arrow>
                <TextField color="secondary" type="number"
                    InputProps={{
                        startAdornment: <InputAdornment position="start">{this.props.user.store.curr_symbol}</InputAdornment>,
                        readOnly: (this.state.payment_status !== 'pending'),
                    }}
                    id={item.id} value={(item.revised_price) ? item.revised_price : ""}
                    onChange={(event) => {
                        event.preventDefault();
                        item.revised_price = event.target.value;
                        this.setState({
                            toSave: true,
                        });
                        this.updateAmount();
                    }}
                />
            </Tooltip>
        </TableCell>)
    }

    renderPayment = () => {
        const stat = this.props.currentOrder.payment_status;
        let status = '';

        if (stat === 'pending') status = 'Pendiente';
        if (stat === 'required') status = 'Solicitado';
        if (stat === 'processing') status = 'En proceso';
        if (stat === 'done') status = 'Aprobado'

        return <div classes={this.props.classes.cellRowsIco}>
            <b>Estado del pago:</b>
            <Payment
                classes={
                    ((stat === 'done') ? { root: this.props.classes.icoGreen } :
                        (stat === 'required' || stat === 'processing') ? { root: this.props.classes.icoYellow } :
                            { root: this.props.classes.icoRed })
                }
            />{status}
        </div>
    }

    renderWSurl = (phone) => {
        //format phone
        let wsurl = 'https://wa.me/' + phone;

        return (<a href={wsurl} target="_blank" rel="noopener noreferrer" className={this.props.classes.link}>{phone}
        </a>)
    }

    a11yProps = (index) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    handleChangeTab = (event, newValue) => {
        this.setState({ value: newValue });
    };

    handleChange = (event) => {
        event.preventDefault();
        this.setState({
            [event.target.name]: event.target.value,
        });
    }

    renderActionsConcepts = (item, index) => {
        return (
            <TableCell key={index} classes={{ root: this.props.classes.cellRows }}>
                <Tooltip title={(this.state.payment_status !== 'pending') ? 'No es posible modificar esto, el pago ya fue fijado.' : ''} placement="right" arrow>
                    <Button classes={{ root: this.props.classes.cellButton }}
                        component="label"
                        disabled={this.state.payment_status !== 'pending'}
                        onClick={() => {
                            let conceptsOrder = this.state.conceptsOrder;
                            let conceptsOrderDel = this.state.conceptsOrderDel;
                            // let conceptsOrderNew = this.state.conceptsOrderNew;
                            if (!item.updated || item.updated === 'true') conceptsOrderDel.push(item.id);

                            conceptsOrder = conceptsOrder.filter(x => {
                                return ((x.id !== item.id));
                            });
                            // conceptsOrderNew = conceptsOrderNew.filter(x => {
                            //     return ((x.description !== item.description) && (x.amount !== item.amount));
                            // })
                            this.setState({
                                conceptsOrder: conceptsOrder,
                                conceptsOrderDel: conceptsOrderDel,
                                // conceptsOrderNew: conceptsOrderNew,
                                toSave: true,
                            })
                            this.updateAmount();
                        }}
                    >
                        <DeleteIcon />
                    </Button>
                </Tooltip>
            </TableCell>
        )
    }

    renderDesc = (concept, index) => {
        const text = (Math.sign(concept.amount) === 1) ? 'Interes' : 'Descuento';
        return (
            <TableCell key={index} classes={{ root: this.props.classes.cellRows }}>
                {text}
            </TableCell>
        );
    }

    conditionShipping = (edit) => {

        const { currentOrder } = this.props;
        const { payment_status } = this.state;
        const shippingPresent = (currentOrder && currentOrder.shipping && currentOrder.shipping.length > 0);
        const shippingImported = (currentOrder && currentOrder.shipping && (currentOrder.shipping.length > 0) && currentOrder.shipping[0].original_id);
        const shippinEditable = ((currentOrder.status === 'waiting_delivery' || currentOrder.status === 'preparing') && payment_status === 'pending');
        if (edit)
            return (currentOrder && currentOrder.shipping && !shippingImported && shippinEditable);
        return shippingPresent && (shippingImported || !shippinEditable);
    }

    renderAmount = (concept, index) => {
        const text = (Math.sign(concept.amount) === -1) ? (concept.amount * -1) : concept.amount;
        // const sign = (Math.sign(concept.amount) === -1) ? -1 : 1;
        return (
            <TableCell key={index} classes={{ root: this.props.classes.cellRows }}>
                <Tooltip title={(this.state.payment_status !== 'pending') ? 'No es posible modificar esto, el pago ya fue fijado.' : ''} placement="right" arrow>
                    <TextField color="secondary" type="number"
                        InputProps={{
                            startAdornment: <InputAdornment position="start">{this.props.user.store.curr_symbol}</InputAdornment>,
                        }}
                        disabled={this.state.payment_status !== 'pending'}
                        id={toString(concept.id)} value={text ? text : ""}
                        onChange={(event) => {
                            event.preventDefault();
                            const value = (event.target.value < 1 ? 1 : event.target.value);
                            if (concept.amount < 0 && value !== '0') concept.amount = -value;
                            else concept.amount = value;
                            if (!concept.updated) concept.updated = 'true';
                            // let conceptsOrderNew = this.state.conceptsOrderNew;
                            // conceptsOrderNew.push({
                            //     "description": concept.description,
                            //     "amount": concept.amount,
                            // });
                            this.updateAmount();
                            this.setState({
                                // conceptsOrderNew: conceptsOrderNew,
                                toSave: true,
                            })
                        }} z
                    />
                </Tooltip>
            </TableCell>
        );
    }

    handleSubmitConcept = () => {
        this.setState({ submitted: true });
        if (this.state.concept && this.state.conceptamount > 1) {
            let conceptsOrder = this.state.conceptsOrder;
            // let conceptsOrderNew = this.state.conceptsOrderNew;
            conceptsOrder.push({
                id: Math.random() * (1000000 - 100000) + 100000,
                updated: 'new',
                description: this.state.concept,
                amount: (this.state.desc === '-') ? this.state.conceptamount * -1 : this.state.conceptamount,
            });
            // conceptsOrderNew.push({
            //     "description": this.state.concept,
            //     "amount": (this.state.desc === '-') ? this.state.conceptamount * -1 : this.state.conceptamount,
            // });
            this.updateAmount();
            this.setState({
                conceptsOrder: conceptsOrder,
                // conceptsOrderNew: conceptsOrderNew,
                toSave: true,
                erroramount: false,
            })
        } else {
            this.setState({
                erroramount: true,
            })
        }
    }

    itemPresent = (item) => {
        let present = false;
        this.props.currentOrder.items.forEach(i => {
            if ((i.id) && (i.product_denormalized.id === item.id))
                present = true;
            if ((!i.id) && (item.name === i.name))
                present = true;

        });
        return present;
    }

    render() {
        const { classes, currentOrder, client, user } = this.props;
        const { statusOrder, desc, obsOrder, value, toSave, amount, infoPay, payment_status, shippCost, concept, conceptamount, erroramount, submitted, totalConcepts, total,
            updatedList, productList, pagelist, npages, npage,
            shipping_tracking_code, shipping_transport_co, shipping_eta, shipping_link, shipping_observations } = this.state;
        let titulo = "DETALLE DEL PEDIDO";
        if (!this.props.user) {
            console.log('Error user or session, redirecting')
            localStorage.setItem('lasturl', `/orderdetail/${this.props.match.params.id}`);
            this.props.history.push('/login');
            return null;
        }

        if (!client || !currentOrder) {
            console.log('Error user or session, redirecting')
            localStorage.setItem('lasturl', `/orderdetail/${this.props.match.params.id}`);
            this.props.history.push('/home');
            return null;
        }



        return (
            <React.Fragment >
                <Appbar back={true} label={titulo} onBack={this.onBack} user={user} update={() => this.props.getData(['orders'], this.props.match.params.id)} />
                <Grid
                    item xs={12} md={12}
                    className={classes.tabpanel}
                >
                    <Grid item xs={12} sm={12} container>
                        <Grid item xs={12} sm={6} className={classes.textbox}>
                            <div className={classes.inline}>
                                <Typography variant="h2" color="inherit">
                                    <b>PEDIDO&nbsp;</b>
                                </Typography>
                                <Typography variant="h2" color="inherit" className={classes.texttitle}>
                                    <b> # {currentOrder.alphanum_id}</b>
                                </Typography>
                                <Typography variant="h2" color="inherit" className={classes.texttitle}>
                                    <b> &nbsp;-&nbsp;{convertNumber(total, this.props.user.store.curr_symbol)}</b>
                                </Typography>
                            </div>
                            <br />
                            {currentOrder.original_id &&
                                <div className={classes.inline}>
                                    <Typography variant="h2" color="inherit">
                                        <b>ID Original&nbsp;</b>
                                    </Typography>
                                    <Typography variant="h2" color="inherit" className={classes.texttitle}>
                                        <b> # {currentOrder.original_id}</b>
                                    </Typography>
                                </div>
                            }
                            <div className={classes.inline}>
                                <Typography variant="h3" color="inherit">
                                    <b>CLIENTE</b>
                                </Typography>
                                &nbsp;&nbsp;&nbsp;
                                <Typography
                                    color="primary"
                                    variant="caption"
                                    className={classes.clickeable}
                                    onClick={() => {
                                        this.props.history.push("/clientlist?client=" + client.id)
                                    }}
                                >
                                    Ver info
                                </Typography>
                            </div>
                            <Typography variant="body2" color="inherit">
                                <b>Nombre:</b> {currentOrder.client}<br />
                                <b>Email:</b> {(client) ? client.email : ''}<br />
                                <b>Teléfono:</b> {(client) ? this.renderWSurl(client.phone) : ''}<br />
                                <b>Dirección:</b> {(client) ? client.address : ''}<br />
                                <b>Dirección entrega:</b> {((currentOrder.delivery) ? currentOrder.delivery_address : 'Retira en sucursal')}<br />
                                <b>Método de pago:</b> {((currentOrder.payment_method) ? currentOrder.payment_method : 'No definido')}<br />
                                {this.renderPayment()}
                            </Typography>
                            <Typography variant="body2" color="inherit">
                                <b>Observaciones del cliente:</b>
                            </Typography>
                            <TextField
                                id="filled-multiline-static1"
                                multiline
                                minRows={3}
                                value={(currentOrder.client_observation ? currentOrder.client_observation : '')}
                                variant="filled"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} className={classes.textbox}>
                            <div className={classes.inline}>
                                <Typography variant="h2" color="inherit">
                                    <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>
                                </Typography>
                                <Tooltip open={toSave} title={'Por favor, presione para guardar los cambios'} placement="bottom" arrow>
                                    <Button className={classes.buttonsave} variant="contained" color="secondary" onClick={() => this.handleSave()} disabled={!toSave}>
                                        Guardar Orden
                                    </Button>
                                </Tooltip>
                            </div>
                            <br />
                            <Typography variant="h3" color="inherit">
                                <b>VENDEDOR</b>
                            </Typography>
                            <div className={classes.inline}>
                                <Typography variant="body2" color="inherit">
                                    <b>Estado del pedido:</b>
                                </Typography>
                                &nbsp;&nbsp;
                                <FormControl className={classes.formControl}>
                                    <NativeSelect
                                        className={classes.texttitle}
                                        value={statusOrder}
                                        onChange={this.handleChangeStatus}
                                        inputProps={{
                                            name: 'name',
                                            id: 'combobox',
                                        }}
                                    >
                                        <option value={'provisional'}>Provisorio</option>
                                        <option value={'discarded'}>Descartado</option>
                                        <option value={'done'}>Entregado</option>
                                        <option value={'waiting_delivery'}>A despachar</option>
                                        <option value={'store_canceled'}>Cancelado por store</option>
                                        <option value={'client_canceled'}>Cancelado por cliente</option>
                                        <option value={'preparing'}>En proceso</option>
                                        <option value={'pending'}>Pendiente</option>
                                    </NativeSelect>
                                </FormControl>
                            </div>
                            <Typography variant="body2" color="inherit">
                                <b>Observaciones Vendedor:</b>
                            </Typography>
                            <TextField
                                id="filled-multiline-static"
                                multiline
                                minRows={2}
                                value={obsOrder}
                                onChange={this.handleChangeObs}
                                variant="filled"
                            />
                            <br /><br />
                            <Typography variant="body2" color="inherit">
                                <b>Información de pago:</b>
                            </Typography>
                            <Tooltip title={(this.toSavePayment('text') ? true : false) ? 'Agregar informacion de pago' : 'No es posible modificar esto, solo disponible en estado Pendiente o en Proceso, con un metodo de ya pago definido por el cliente.'} placement="right" arrow>
                                <TextField
                                    id="filled-multiline-static2"
                                    multiline
                                    minRows={2}
                                    value={infoPay}
                                    onChange={this.handleChangeInfoPay}
                                    variant="filled"
                                    InputProps={{
                                        readOnly: !this.toSavePayment('text'),
                                    }}
                                />
                            </Tooltip>
                            &nbsp;
                            <Tooltip open={(this.toSavePayment()) ? true : false} title={'Solicitar pago al cliente'} placement="bottom" arrow>
                                <Button variant="contained" color="primary" onClick={() => this.handlePayment()} disabled={!this.toSavePayment()}>
                                    Solicitar pago
                                </Button>
                            </Tooltip>
                        </Grid>
                    </Grid>
                    <AppBar position="static"
                        sx={{
                            '&.MuiTab-textColorInherit.Mui-selected': {
                                'backgroundColor': 'black',
                            }
                        }} className={classes.tabpanelHeader}>
                        <Tabs value={value} onChange={this.handleChangeTab}
                            variant="scrollable"
                            scrollButtons="auto"
                            aria-label="scrollable auto tabs example" >
                            <Tab label={`PEDIDO ${convertNumber(amount, this.props.user.store.curr_symbol)}`} {...this.a11yProps(0)} />
                            <Tab label={`AGREGAR ITEM`} {...this.a11yProps(1)} />
                            <Tab label={`ENVIO ${convertNumber(shippCost, this.props.user.store.curr_symbol)}`} {...this.a11yProps(2)} />
                            <Tab label={`OTROS ${convertNumber(totalConcepts, this.props.user.store.curr_symbol)}`} {...this.a11yProps(3)} />
                            <Tab label={`MONTO ${convertNumber(total, this.props.user.store.curr_symbol)}`} className={classes.totalAmount} disabled />
                        </Tabs>
                    </AppBar>
                    <TabPanel value={value} index={0}>
                        <Grid item xs={12} sm={12} className={classes.table}>
                            <ResponsiveTable
                                headCells={[
                                    {
                                        id: 'brand',
                                        label: 'MARCA'
                                    },
                                    {
                                        id: 'name',
                                        label: 'PRODUCTO'
                                    },
                                    {
                                        id: 'amount',
                                        label: 'CANTIDAD'
                                    },
                                    {
                                        id: 'quantity_unit',
                                        label: 'MEDIDA'
                                    },
                                    {
                                        id: 'price',
                                        label: 'PRECIO',
                                        type: 'number',
                                    },
                                    {
                                        id: 'total',
                                        label: 'TOTAL',
                                        type: 'number',
                                    },
                                    {
                                        id: 'revised_price',
                                        label: 'TOTAL ACTUALIZADO',
                                        noSortable: true,
                                        render: (item, index) => this.renderComboPrice(item, index + 1000)
                                    },
                                    {
                                        id: 'store_observations',
                                        label: 'OBSERVACIONES',
                                        noSortable: true,
                                        render: (item, index) => this.renderComboObs(item, index + 2000)
                                    },
                                    {
                                        id: 'completado',
                                        label: 'COMPLETADO',
                                        noSortable: true,
                                        render: (item, index) => this.renderActions(item, index + 3000)
                                    },
                                    {
                                        id: 'raw_product',
                                        label: 'PEDIDO ORIG'
                                    },
                                ]}
                                curr_symbol={(this.props.user ? this.props.user.store.curr_symbol : '')}
                                rows={(currentOrder) ? currentOrder.items : []}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <br />
                            {(currentOrder && currentOrder.colorRed) &&
                                <Typography variant="subtitle2" color="inherit" className={classes.textred}>
                                    <b>* Pendiente de revisión por el vendedor</b>
                                </Typography>
                            }
                        </Grid>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <Filters
                            label='Filtros'
                            pantalla='Productos'
                            reset={true}
                            exportXls={false}
                            fullWidth={true}
                            fieldList={[
                                {
                                    label: 'NOMBRE',
                                    text: true,
                                    field: 'name'
                                },
                                {
                                    label: 'TIPO',
                                    combo: true,
                                    field: 'kindname'
                                },
                                {
                                    label: 'MARCA',
                                    combo: true,
                                    field: 'brandname'
                                },
                                {
                                    label: 'ID',
                                    text: true,
                                    field: 'original_id'
                                },
                                {
                                    label: 'TAG',
                                    list: true,
                                    field: 'tags'
                                },
                            ]}
                            list={productList}
                            filteredList={updatedList}
                            updateList={this.updateList}
                        />
                        <ResponsiveTable
                            headCells={[
                                {
                                    id: 'revised_amount',
                                    label: 'CANTIDAD - MEDIDA',
                                    noSortable: true,
                                    render: (item, index) => {
                                        return (<TableCell key={index + item.id} classes={{ root: this.props.classes.cellRows }}>
                                            {// eslint-disable-next-line react/jsx-no-bind
                                            }
                                            <div className={classes.inline}>
                                                <Tooltip title={!((currentOrder.status === 'provisional') || (currentOrder.status === 'pending')) ? 'No es posible modificar esto, solo disponible en estado Pendiente o Provisorio.' : ''} placement="right" arrow>
                                                    <TextField color="secondary" type="number"
                                                        InputProps={{
                                                            endAdornment: <InputAdornment position="end">{item.quantity_unit}</InputAdornment>,
                                                            readOnly: !((currentOrder.status === 'provisional') || (currentOrder.status === 'pending')),
                                                        }}
                                                        id={item.id} value={(item.amount) ? item.amount : ""}
                                                        onChange={(event) => {
                                                            event.preventDefault();
                                                            item.amount = event.target.value;
                                                            item.toSave = true;
                                                            this.forceUpdate();
                                                        }}
                                                    />
                                                </Tooltip>
                                                {(item.toSave === true) &&
                                                    <Button classes={{ root: this.props.classes.cellButton }}
                                                        component="label"
                                                        onClick={() => {
                                                            const an = this.itemPresent(item);
                                                            if (an) {
                                                                const { dispatch } = this.props;
                                                                dispatch(alertActions.warning('El item que desea agregar ya existe en el pedido'));
                                                                item.amount = '';
                                                                item.toSave = false;
                                                            } else {
                                                                item.toSave = false;
                                                                this.setState({ toSave: true });
                                                                currentOrder.items.push({
                                                                    'product_id': item.id,
                                                                    "amount": parseFloat(item.amount),
                                                                    "brand": item.brand.name,
                                                                    "color": "",
                                                                    "kind": item.kind.name,
                                                                    "name": item.name,
                                                                    "price": item.price_per_unit,
                                                                    "total": parseFloat(item.amount) * parseFloat(item.price_per_unit),
                                                                    "quantity_unit": item.quantity_unit,
                                                                    "revised_price": null,
                                                                    "status": "pending",
                                                                    "store_observations": null,
                                                                    "product_denormalized": {
                                                                        "price_per_unit": item.price_per_unit
                                                                    },
                                                                })
                                                                this.updateAmount();
                                                                item.amount = '';
                                                            }

                                                        }}
                                                    >
                                                        &nbsp;&nbsp;&nbsp;&nbsp;<CheckCircleOutline />
                                                    </Button>
                                                }
                                            </div>
                                        </TableCell>)
                                    }
                                },
                                {
                                    id: 'brandname',
                                    label: 'MARCA'
                                },
                                {
                                    id: 'kindname',
                                    label: 'TIPO'
                                },
                                {
                                    id: 'name',
                                    label: 'NOMBRE'
                                },
                                {
                                    id: 'price_per_unit',
                                    label: 'PRECIO',
                                    type: 'number',
                                },
                                {
                                    id: 'quantity_unit',
                                    label: 'UN'
                                },
                                {
                                    id: 'tags',
                                    label: 'Tags'
                                },
                                {
                                    id: 'original_id',
                                    label: 'ID'
                                },
                                {
                                    id: 'description',
                                    label: 'Descripcion'
                                },
                            ]}
                            curr_symbol={(this.props.user ? this.props.user.store.curr_symbol : '')}
                            rows={pagelist}
                        />
                        <Pagination
                            count={npages}
                            // size="large"
                            // variant="outlined"
                            shape="rounded"
                            page={npage}
                            onChange={this.handlePageChange}
                            color="primary"
                            sx={{ padding: '10px' }}
                        />
                        <br /><br />
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <br />
                        <div className={classes.inline}>
                            <Typography
                                // className={classes.dividerInset}
                                // display="block"
                                component={'span'}
                                variant="body2"
                                color="primary"
                            >
                                &nbsp;&nbsp;Costo de envio
                            </Typography>
                            &nbsp;&nbsp;
                            <Input
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">{(this.props.user ? this.props.user.store.curr_symbol : '')}</InputAdornment>,
                                }}
                                id='input-shipp'
                                type='number'
                                fullWidth={false}
                                disableAuto={true}
                                label="Valor"
                                name='conceptamount'
                                value={shippCost}
                                errorMessage="Por favor ingrese valor mayor a 0"
                                onChange={this.handleChangeShippCost}
                                className={classes.input}
                                disabled={(!(currentOrder.status === 'waiting_delivery' || currentOrder.status === 'preparing') || payment_status !== 'pending')}
                            />
                        </div>
                        <br /><br />
                        {
                            this.conditionShipping(false) &&
                            <div>
                                <Typography
                                    className={classes.dividerInset}
                                    display="block"
                                    variant="caption"
                                    color="primary"
                                >
                                    &nbsp;&nbsp;DETALLES DEL ENVIO <LocalShipping className={classes.searchIcon} />
                                </Typography>
                                <Divider className={classes.divider} />
                                {currentOrder.shipping.map((shipp, index) => {
                                    return (
                                        <Grid item xs={12} sm={10} className={classes.textbox} key={`shipp-key-${index}`}>
                                            <Typography variant="subtitle2" color="inherit" className={classes.texttitle}>
                                                <b>CODIGO: {(shipp.tracking_code) ? shipp.tracking_code : "-"}</b>
                                            </Typography>
                                            <br />
                                            <Typography variant="subtitle2" color="inherit">
                                                <b>EMPRESA:</b> {(shipp.transport_co) ? shipp.transport_co : "-"}<br /><br />
                                                <b>DESPACHADO:</b> {convertDate(shipp.departure)}<br /><br />
                                                <b>LLEGADA:</b> {convertDate(shipp.eta)}<br /><br />
                                                <b>ENLACE:</b> <a href={(shipp.link) ? shipp.link : "-"} target="_blank" rel="noopener noreferrer" className={classes.link}>{(shipp.link) ? shipp.link : ""}</a><br /><br />
                                            </Typography>
                                        </Grid>
                                    )
                                })
                                }
                            </div>
                        }
                        {
                            this.conditionShipping(true) &&
                            <div>
                                <Typography
                                    className={classes.dividerInset}
                                    display="block"
                                    variant="caption"
                                    color="primary"
                                >
                                    &nbsp;&nbsp;DETALLES DEL ENVIO <LocalShipping className={classes.searchIcon} />
                                </Typography>
                                <Grid item xs={12} sm={10} className={classes.textbox} key={`shipp-key-00`}>
                                    <div className={classes.inline}>
                                        <Typography component={'span'}
                                            variant="body2"
                                            color="inherit"
                                            className={classes.texttitle}>
                                            <b>CODIGO:</b>
                                        </Typography>
                                        &nbsp;&nbsp;
                                        <Input
                                            id='input-code-index'
                                            type='text'
                                            fullWidth={true}
                                            disableAuto={true}
                                            name='shipping_tracking_code'
                                            value={shipping_tracking_code}
                                            errorMessage="Por favor ingrese un codigo de envio"
                                            onChange={(event) => {
                                                event.preventDefault();
                                                this.setState({
                                                    shipping_tracking_code: event.target.value,
                                                    toSave: true,
                                                });
                                            }}
                                            className={classes.input2}
                                        />
                                    </div>
                                    <br />
                                    <div className={classes.inline}>
                                        <Typography variant="subtitle2" color="inherit">
                                            <b>EMPRESA:</b>
                                        </Typography>
                                        &nbsp;&nbsp;
                                        <Input
                                            id='input-transport-index'
                                            type='text'
                                            fullWidth={true}
                                            disableAuto={true}
                                            name='shipping_transport_co'
                                            value={shipping_transport_co}
                                            errorMessage="Por favor ingrese una empresa de transporte"
                                            onChange={(event) => {
                                                event.preventDefault();
                                                this.setState({
                                                    shipping_transport_co: event.target.value,
                                                    toSave: true,
                                                });
                                            }}
                                            className={classes.input2}
                                        />
                                    </div>
                                    <br />
                                    <div className={classes.inline}>
                                        <Typography variant="subtitle2" color="inherit">
                                            <b>LLEGADA:</b>
                                        </Typography>
                                        &nbsp;&nbsp;
                                        <Input
                                            id='input-eta-index'
                                            type='text'
                                            fullWidth={true}
                                            disableAuto={true}
                                            placeholder={`Ejemplo ${convertDate(new Date())}`}
                                            helperText="Formato dia/mes/año hora:minutos"
                                            name='shipping_eta'
                                            value={shipping_eta}
                                            errorMessage="Por favor ingrese una fecha de llegada"
                                            InputProps={{ classes: { input: this.props.classes['input3'] } }}
                                            onChange={(event) => {
                                                event.preventDefault();
                                                this.setState({
                                                    shipping_eta: event.target.value,
                                                    toSave: true,
                                                });
                                            }}
                                        // className={classes.input3}
                                        />
                                    </div>
                                    <br />
                                    <div className={classes.inline}>
                                        <Typography variant="subtitle2" color="inherit">
                                            <b>ENLACE:</b>
                                        </Typography>
                                        &nbsp;&nbsp;
                                        <Input
                                            id='input-link-index'
                                            type='text'
                                            fullWidth={true}
                                            disableAuto={true}
                                            name='shipping_link'
                                            value={shipping_link}
                                            errorMessage="Por favor ingrese un link"
                                            onChange={(event) => {
                                                event.preventDefault();
                                                this.setState({
                                                    shipping_link: event.target.value,
                                                    toSave: true,
                                                });
                                            }}
                                            className={classes.input2}
                                        />
                                    </div>
                                    <br />
                                    <div className={classes.inline}>
                                        <Typography variant="subtitle2" color="inherit" >
                                            <b>OBSERVACIONES:</b>
                                        </Typography>
                                        &nbsp;&nbsp;
                                        <Input
                                            id='input-transport-index'
                                            type='text'
                                            fullWidth={true}
                                            disableAuto={true}
                                            name='shipping_observations'
                                            value={shipping_observations}
                                            errorMessage="Por favor ingrese una observacion"
                                            onChange={(event) => {
                                                event.preventDefault();
                                                this.setState({
                                                    shipping_observations: event.target.value,
                                                    toSave: true,
                                                });
                                            }}
                                            className={classes.input2}
                                        />
                                    </div>
                                </Grid>
                            </div>
                        }
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                        <Tooltip title={(this.state.payment_status !== 'pending') ? 'No es posible modificar esto, el pago ya fue fijado.' : ''} placement="right" arrow>
                            <div className={classes.inline}>
                                <Input
                                    id='input-concept'
                                    type='text'
                                    fullWidth={false}
                                    disableAuto={true}
                                    label='Descripción'
                                    submitted={submitted}
                                    name='concept'
                                    value={concept}
                                    errorMessage="Por favor ingrese un concepto a definir"
                                    onChange={this.handleChange}
                                    className={classes.input}
                                    disabled={payment_status !== 'pending'}
                                />
                                &nbsp;&nbsp;
                                <Input
                                    id='input-concept-amount'
                                    type='number'
                                    fullWidth={false}
                                    disableAuto={true}
                                    label='Valor'
                                    submitted={submitted}
                                    name='conceptamount'
                                    value={conceptamount}
                                    error={erroramount}
                                    errorMessage="Por favor ingrese valor mayor a 0"
                                    onChange={this.handleChange}
                                    className={classes.input}
                                    disabled={payment_status !== 'pending'}
                                />
                                &nbsp;&nbsp;
                                <Select
                                    value={desc}
                                    onChange={this.handleChange}
                                    name='desc'
                                    inputProps={{
                                        name: 'desc',
                                        id: 'select-desc',
                                    }}
                                    disabled={payment_status !== 'pending'}
                                >
                                    <MenuItem value={'-'}>Descuento</MenuItem>
                                    <MenuItem value={'+'}>Recargo</MenuItem>
                                </Select>
                                &nbsp;&nbsp;
                                <Button classes={{ root: this.props.classes.cellButton }}
                                    component="label"
                                    onClick={this.handleSubmitConcept}
                                    disabled={payment_status !== 'pending'}
                                >
                                    <Tooltip title="Agregar concepto">
                                        <AddCircle />
                                    </Tooltip>
                                </Button>
                                <Typography
                                    // className={classes.dividerInset}
                                    // display="block"
                                    component={'span'}
                                    variant="body2"
                                    color="primary"
                                >
                                    &nbsp;Agregar concepto
                                </Typography>
                            </div>
                        </Tooltip>
                        <br /><br />
                        <Typography
                            className={classes.dividerInset}
                            display="block"
                            variant="caption"
                            color="primary"
                        >
                            {`Lista de conceptos ${toSave ? '(debe guardar los cambios para verlos reflejados en el total)' : ''}`}
                        </Typography>
                        <Divider className={classes.divider} />
                        <ResponsiveTable
                            headCells={[
                                {
                                    id: 'description',
                                    label: 'Descripcion'
                                },
                                {
                                    id: 'amount',
                                    label: 'Valor',
                                    render: (concept, index) => this.renderAmount(concept, index + 2000)
                                },
                                {
                                    id: 'type',
                                    label: 'TIPO',
                                    render: (concept, index) => this.renderDesc(concept, index + 3000)
                                },
                                {
                                    id: 'delet',
                                    label: 'ELIMINAR',
                                    noSortable: true,
                                    render: (concept, index) => this.renderActionsConcepts(concept, index + 4000)
                                },
                            ]}
                            rows={this.state.conceptsOrder}
                        />
                        <br /><br />
                    </TabPanel>
                </Grid >
                <div ref={el => (this.instance = el)} />
            </React.Fragment >
        );
    }

    componentDidMount() {
        if (window.location.href.startsWith('https://arturito.chat') || window.location.href.startsWith('https://arturito.store')) {
            //tracking script from ActiveCampaign
            const s = document.createElement('script');
            s.type = 'text/javascript';
            s.async = true;
            s.innerHTML = '(function(e,t,o,n,p,r,i){e.visitorGlobalObjectAlias=n;e[e.visitorGlobalObjectAlias]=e[e.visitorGlobalObjectAlias]||function(){(e[e.visitorGlobalObjectAlias].q=e[e.visitorGlobalObjectAlias].q||[]).push(arguments)};e[e.visitorGlobalObjectAlias].l=(new Date).getTime();r=t.createElement("script");r.src=o;r.async=true;i=t.getElementsByTagName("script")[0];i.parentNode.insertBefore(r,i)})(window,document,"https://diffuser-cdn.app-us1.com/diffuser/diffuser.js","vgo");vgo("setAccount", "476725427");vgo("setTrackByDefault", true);vgo("process");';
            this.instance.appendChild(s);
        }

        const { user } = this.props;

        if (!user || !this.props.setWidget) {
            console.log('Error user or session, redirecting')
            localStorage.setItem('lasturl', `/orderdetail/${this.props.match.params.id}`);
            this.props.history.push('/login');
            return null;
        }

        if (this.props.match.params && this.props.match.params.id) {
            if (!this.props.orders || !this.props.orders.orders)
                this.props.getData(['orders'], this.props.match.params.id);
        }

        if (this.props.products && !this.props.products.products)
            this.props.getData(['products']);
        else {
            this.setPages(this.props.products.products);
            // eslint-disable-next-line react/no-did-mount-set-state
            this.setState({
                productList: this.props.products.products,
                updatedList: this.props.products.products,
            });
        }

        this.props.setWidget(true, false);
    }

    componentDidUpdate(prevProps, prevState) {
        if (first || (this.props.currentOrder && (prevProps.currentOrder !== this.props.currentOrder))) {
            first = false;
            this.updateDefaults();
        }
        if ((prevProps.products !== this.props.products) && (this.props.products && this.props.products.products)) {
            this.setPages(this.props.products.products);
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({
                productList: this.props.products.products,
                updatedList: this.props.products.products,
            });
        }
    }

    componentWillUnmount() {
        localStorage.setItem('lasturl', `/orderdetail/${this.props.match.params.id}`);
    }
}

function mapStateToProps(state) {
    const { user } = state.authentication;
    const { order, orders, products } = state;
    return {
        user, order, orders, products
    };
}

export default connect(mapStateToProps)(withStyles(styles)(OrderDetail));