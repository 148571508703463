/* eslint-disable react/jsx-no-bind */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import {
    Link
} from '@material-ui/core';
import Appbar from '../_components/Appbar';


// eslint-disable-next-line no-unused-vars
const styles = theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: 'white',
        display: 'flex',
        height: '100%',
    },
    tabpanelHeader: {
        'backgroundColor': '#025e9f',
        width: '100%',
        'text-align': 'center',
        'z-index': 0,
        height: '27px',
        paddingTop: '6px',
        'margin-top': '10px',
    },
    link: {
        color: '#124e5c',
        textDecoration: 'none',
        "&:hover": {
            cursor: 'pointer',
        },
    },
    container: {
        position: 'relative',
        width: '100%',
        height: '80vh',
        // overflow: 'hidden',
    },
    responsiveiframe: {
        // position: 'absolute',
        // top: '0',
        // left: '0',
        // bottom: '0',
        // right: '0',
        width: '100%',
        height: '83vh',
        border: 'none',
    },
});


class Dashboard extends React.Component {
    static propTypes = {
        classes: PropTypes.object,
        onBackComp: PropTypes.func,
        user: PropTypes.object,
        history: PropTypes.object,
    };

    state = {
        value: 0,
    }

    onBack = event => {
        event.preventDefault();
        // this.props.history.push('/home');
        this.props.onBackComp();
    }

    handleChangeTab = (event, newvalue) => {
        this.setState({
            value: newvalue,
        });
    }

    render() {
        const { classes, user } = this.props;

        if (!user) {
            this.props.history.push('/login');
            return null;
        }

        let titulo = "INFORMES";

        return (
            <React.Fragment>
                <Appbar back={true} label={titulo} onBack={this.onBack} user={user} />
                <div className={classes.root}>
                    <div className={classes.container}>
                        <iframe
                            title={'Dashboard'}
                            src={this.props.user.store.urls.report_general}
                            // src="https://lookerstudio.google.com/embed/reporting/0bf04162-d98f-429e-b43d-487c3d34b66e/page/hPZGD"
                            loading="lazy"
                            className={classes.responsiveiframe}
                            sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
                        ></iframe>
                        <Link onClick={() => this.props.history.push('/dashboard')} className={classes.link}>
                            <b>* puede descargar el doc como pdf con el segundo boton.</b>
                        </Link>
                    </div>
                </div>
                <div ref={el => (this.instance = el)} />
            </React.Fragment >
        );
    }

    componentDidMount() {
        if (window.location.href.startsWith('https://arturito.chat') || window.location.href.startsWith('https://arturito.store')) {
            //tracking script from ActiveCampaign
            const s = document.createElement('script');
            s.type = 'text/javascript';
            s.async = true;
            s.innerHTML = '(function(e,t,o,n,p,r,i){e.visitorGlobalObjectAlias = n;e[e.visitorGlobalObjectAlias]=e[e.visitorGlobalObjectAlias]||function(){(e[e.visitorGlobalObjectAlias].q = e[e.visitorGlobalObjectAlias].q || []).push(arguments)};e[e.visitorGlobalObjectAlias].l=(new Date).getTime();r=t.createElement("script");r.src=o;r.async=true;i=t.getElementsByTagName("script")[0];i.parentNode.insertBefore(r,i)})(window,document,"https://diffuser-cdn.app-us1.com/diffuser/diffuser.js","vgo");vgo("setAccount", "476725427");vgo("setTrackByDefault", true);vgo("process");';
            this.instance.appendChild(s);
        }

        const { user } = this.props;

        if (!user) {
            console.log('Error user or session, redirecting')
            localStorage.setItem('lasturl', `/dashboard`);
            this.props.history.push('/login');
            return null;
        }
    }

    componentDidUpdate() {
    }

    componentWillUnmount() {
        localStorage.setItem('lasturl', `/dashboard`);
    }
}

function mapStateToProps(state) {
    const { user } = state.authentication;

    return {
        user,
    };
}

export default connect(mapStateToProps)(withStyles(styles)(Dashboard));