/* eslint-disable react/jsx-no-bind */
import React from 'react';
import PropTypes from 'prop-types';
import { history } from '../_helpers';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Button from '@mui/material/Button';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { userActions } from '../_actions';

// eslint-disable-next-line no-unused-vars
const styles = theme => ({
    main: {
        'margin-top': '16px',
        'margin-bottom': '8px',
    },
    inline: {
        display: 'inline-flex',
        'align-items': 'center',
        height: '45px',
        width: '100%'
    },
    link: {
        textDecoration: 'none',
        cursor: 'pointer',
        "&:hover": {
            color: '#ee8d2d',
        },
    },
    pointer: {
        cursor: 'pointer',
    },
    color1: {
        color: 'red',
        '& .Mui-active': {
            color: '#134859 !important',
        }
    }
});

const steps = ['Mi negocio', 'Carga inicial', 'Personalizar', 'Divulga tu negocio'];

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography component={'span'}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

class WizzarConfig extends React.PureComponent {

    static propTypes = {
        classes: PropTypes.object,
        activeStep: PropTypes.string,
        user: PropTypes.object,
        dispatch: PropTypes.func,
    }

    state = {
        activeStep: this.props.activeStep ? parseInt(this.props.activeStep) : 0,
        completed: {},
        flags: {
            store: false,
            storeTS: "",
            crew: false,
            wsnumber: false,
            products: false,
            sales: false,
            bot: false,
            qrs: false,
            qrsTS: "",
            calendar: false,
            calendarTS: false,
            pms: false,
            pmsTS: false,
            faqs1: false,
            faqs1TS: false,
            faqs2: false,
            faqs2TS: false,
        }
    }

    totalSteps = () => {
        return steps.length;
    };

    completedSteps = () => {
        return Object.keys(this.state.completed).length;
    };

    isLastStep = () => {
        return this.state.activeStep === this.totalSteps() - 1;
    };

    allStepsCompleted = () => {
        return this.completedSteps() === this.totalSteps();
    };

    handleNext = () => {
        const newActiveStep =
            this.isLastStep() && !this.allStepsCompleted()
                ? steps.findIndex((step, i) => !(i in this.state.completed))
                : this.state.activeStep + 1;
        this.setState({
            activeStep: newActiveStep
        });
    };

    handleBack = () => {
        this.setState({
            activeStep: (this.state.activeStep === 0 ? 0 : this.state.activeStep - 1),
        });
    };

    handleStep = (step) => () => {
        this.setState({
            activeStep: step
        });
    };

    handleComplete = () => {
        const newCompleted = this.state.completed;
        newCompleted[this.state.activeStep] = true;
        this.setState({
            completed: newCompleted
        });
        this.handleNext();
    };

    handleCompleteStep = (step) => {

        const flags = this.state.flags;
        if (step === 0) {
            const newCompleted = this.state.completed;
            newCompleted[step] = flags.store && flags.crew && flags.wsnumber;
            this.setState({
                completed: newCompleted

            });
            this.forceUpdate();
            return
        }

        if (step === 1) {
            const newCompleted = this.state.completed;
            newCompleted[step] = flags.products && flags.sales && flags.bot;
            this.setState({
                completed: newCompleted

            });
            this.forceUpdate();
            return
        }

        if (step === 2) {
            const newCompleted = this.state.completed;
            newCompleted[step] = flags.pms && flags.faqs1 && flags.faqs2;
            this.setState({
                completed: newCompleted

            });
            this.forceUpdate();
            return
        }

        if (step === 3) {
            const newCompleted = this.state.completed;
            newCompleted[step] = flags.qrs && flags.calendar;
            this.setState({
                completed: newCompleted

            });
            this.forceUpdate();
            return
        }

        if (flags.store && flags.crew && flags.wsnumber) {
            const newCompleted = this.state.completed;
            newCompleted[0] = true;
            this.setState({
                completed: newCompleted

            });
        }

        if (flags.products && flags.sales && flags.bot) {
            const newCompleted = this.state.completed;
            newCompleted[1] = true;
            this.setState({
                completed: newCompleted

            });
        }

        if (flags.faqs1 && flags.faqs2 && flags.pms) {
            const newCompleted = this.state.completed;
            newCompleted[2] = true;
            this.setState({
                completed: newCompleted

            });
        }

        if (flags.qrs && flags.calendar) {
            const newCompleted = this.state.completed;
            newCompleted[3] = true;
            this.setState({
                completed: newCompleted

            });
        }
        this.forceUpdate();
    };

    handleReset = () => {
        this.setState({
            activeStep: 0,
            completed: {}
        });
    };

    handleSubmit = () => {
        // event.preventDefault();
        const { user, dispatch } = this.props;
        let social = user.store.social_networks;

        if (social) {
            social['flags'] = this.state.flags;

            dispatch(userActions.setUserData(this.props.user.store.id, {
                "social_networks": social,
            }))
        }
    }


    render() {
        const { classes } = this.props;
        const { activeStep, completed } = this.state;

        return (
            <Box sx={{ width: '80%' }}>
                <Stepper nonLinear activeStep={activeStep}>
                    {steps.map((label, index) => (
                        <Step key={label} completed={completed[index]} className={classes.color1}>
                            <StepButton color="inherit" onClick={this.handleStep(index)} className={classes.color1}>
                                <Typography sx={{ color: (index === activeStep) ? '#ed841e' : '#124e5c' }}>
                                    {label}
                                </Typography>
                            </StepButton>
                        </Step>
                    ))}
                </Stepper>
                <div>
                    {/* {this.allStepsCompleted() ? (
                        <React.Fragment>
                            <Typography sx={{ mt: 2, mb: 1 }}>
                                Etapas completadas - terminaste!
                            </Typography>
                            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                <Box sx={{ flex: '1 1 auto' }} />
                                <Button variant="contained" color="primary"
                                    onClick={this.handleReset}>Reset</Button>
                            </Box>
                        </React.Fragment>
                    ) : ( */}
                    <React.Fragment>
                        {/* <Typography sx={{ mt: 2, mb: 1, py: 1 }}>
                                Etapa {activeStep + 1}
                            </Typography> */}
                        <TabPanel className={classes.tabpanel} value={activeStep} index={0}>
                            <div className={classes.pointer} onClick={() => {
                                // // eslint-disable-next-line react/no-direct-mutation-state
                                // this.state.flags['store'] = !this.state.flags['store'];
                                // // eslint-disable-next-line react/no-direct-mutation-state
                                // this.state.flags['storeTS'] = new Date().toISOString();
                                // this.handleSubmit();
                                // this.handleCompleteStep(activeStep);
                                if (!this.state.flags['store']) {
                                    localStorage.setItem('lasturl', `/options`);
                                    localStorage.setItem('lastsection', `wizzard`);
                                    localStorage.setItem('laststep', `0`);
                                    history.push("/options?section=store");
                                } else {
                                    // eslint-disable-next-line react/no-direct-mutation-state
                                    this.state.flags['store'] = false;
                                    // eslint-disable-next-line react/no-direct-mutation-state
                                    this.state.flags['storeTS'] = new Date().toISOString();
                                    this.forceUpdate();
                                }
                            }}>
                                <div className={classes.inline} >
                                    {this.state.flags.store ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
                                    <Typography
                                        component={'span'}
                                        variant="h2"
                                        color="primary"
                                    >
                                        Registrar mi negocio (obligatorio)
                                    </Typography>
                                </div>
                                <br />
                                <Typography
                                    component={'span'}
                                    variant="body1"
                                    color="primary"
                                >
                                    Completa los campos de registro para iniciar la configuración.
                                </Typography>
                            </div>
                            <br /><br />
                            <div className={classes.pointer} onClick={() => {
                                // eslint-disable-next-line react/no-direct-mutation-state
                                this.state.flags['crew'] = !this.state.flags['crew'];
                                this.handleSubmit();
                                this.handleCompleteStep(activeStep);
                                if (this.state.flags['crew']) {
                                    localStorage.setItem('lasturl', `/options`);
                                    localStorage.setItem('lastsection', `wizzard`);
                                    localStorage.setItem('laststep', `0`);
                                    history.push("/options?section=users")
                                }
                            }}>
                                <div className={classes.inline}>
                                    {this.state.flags.crew ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
                                    <Typography
                                        component={'span'}
                                        variant="h2"
                                        color="primary"
                                    >
                                        Dale acceso a tu equipo
                                    </Typography>
                                </div>
                                <br />
                                <Typography
                                    component={'span'}
                                    variant="body1"
                                    color="primary"
                                >
                                    Crea los accesos para las personas de tu equipo. Puedes saltar este paso.
                                </Typography>
                            </div>
                            <br /><br />
                            <div className={classes.pointer} onClick={() => {
                                // eslint-disable-next-line react/no-direct-mutation-state
                                this.state.flags['wsnumber'] = !this.state.flags['wsnumber'];
                                this.handleSubmit();
                                this.handleCompleteStep(activeStep);
                            }}>
                                <div className={classes.inline}>
                                    {this.state.flags.wsnumber ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
                                    <Typography
                                        component={'span'}
                                        variant="h2"
                                        color="primary"
                                    >
                                        Solicita un Número de Whatsapp
                                    </Typography>
                                </div>
                                <br />
                                <Typography
                                    component={'span'}
                                    variant="body1"
                                    color="primary"
                                >
                                    Si prefieres crear un número de Whatsapp dedicado a tu negocio, envía la documentación a <a href="mailto:hola@arturito.online" target="_blank" rel="noopener noreferrer" className={classes.link}>hola@arturito.online</a>. Necesitamos un certificado fiscal y constancia de domicilio para la misma persona del certificado.
                                </Typography>
                            </div>
                            <br /><br />
                        </TabPanel>
                        <TabPanel className={classes.tabpanel} value={activeStep} index={1}>
                            <div className={classes.pointer} onClick={() => {
                                // eslint-disable-next-line react/no-direct-mutation-state
                                this.state.flags['products'] = !this.state.flags['products'];
                                this.handleSubmit();
                                this.handleCompleteStep(activeStep);
                                if (this.state.flags['products']) {
                                    localStorage.setItem('lasturl', `/options`);
                                    localStorage.setItem('lastsection', `wizzard`);
                                    localStorage.setItem('laststep', `1`);
                                    history.push("/productstore");
                                }
                            }}>
                                <div className={classes.inline} >
                                    {this.state.flags.products ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
                                    <Typography
                                        component={'span'}
                                        variant="h2"
                                        color="primary"
                                    >
                                        Registra los Productos
                                    </Typography>
                                </div>
                                <br />
                                <Typography
                                    component={'span'}
                                    variant="body1"
                                    color="primary"
                                >
                                    Para importar los productos elige entre integrarlos desde tu e-commerce o desde un archivo. Video de ayuda:
                                    <iframe width="600" height="300" title="Arturito" src="https://www.loom.com/embed/a83ed5b25dda4cbbb4a4d48ef72e4996?sid=8303a517-15f2-4484-affb-3258d8b3a852?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true"
                                        allowFullScreen></iframe>
                                </Typography>
                            </div>
                            <div className={classes.pointer} onClick={() => {
                                // eslint-disable-next-line react/no-direct-mutation-state
                                this.state.flags['sales'] = !this.state.flags['sales'];
                                this.handleSubmit();
                                this.handleCompleteStep(activeStep);
                                if (this.state.flags['sales']) {
                                    localStorage.setItem('lasturl', `/options`);
                                    localStorage.setItem('lastsection', `wizzard`);
                                    localStorage.setItem('laststep', `1`);
                                    history.push("/options?section=sales")
                                }
                            }}>
                                <div className={classes.inline}>
                                    {this.state.flags.sales ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
                                    <Typography
                                        component={'span'}
                                        variant="h2"
                                        color="primary"
                                    >
                                        Habilita las condiciones de venta</Typography>
                                </div>
                                <br />
                                <Typography
                                    component={'span'}
                                    variant="body1"
                                    color="primary"
                                >
                                    Puedes elegir una o ambas opciones, entre retiro en tu negocio y entrega en el domicilio del cliente. Para los medios de pago completa las opciones por cada tipo listado.
                                </Typography>
                            </div>
                            <br />
                            <div className={classes.pointer} onClick={() => {
                                // eslint-disable-next-line react/no-direct-mutation-state
                                this.state.flags['bot'] = !this.state.flags['bot'];
                                this.handleSubmit();
                                this.handleCompleteStep(activeStep);
                                if (this.state.flags['bot']) {
                                    localStorage.setItem('lasturl', `/options`);
                                    localStorage.setItem('lastsection', `wizzard`);
                                    localStorage.setItem('laststep', `1`);
                                    history.push("/options?section=preview")
                                }
                            }}>
                                <div className={classes.inline}>
                                    {this.state.flags.bot ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
                                    <Typography
                                        component={'span'}
                                        variant="h2"
                                        color="primary"
                                    >
                                        Personaliza tu marca
                                    </Typography>
                                </div>
                                <br />
                                <Typography
                                    component={'span'}
                                    variant="body1"
                                    color="primary"
                                >
                                    Puedes cargar tu propio avatar, configurar los colores de tu marca y crear contenido para preguntas frecuentes.
                                </Typography>
                            </div>
                        </TabPanel>
                        <TabPanel className={classes.tabpanel} value={activeStep} index={2}>
                            <div className={classes.pointer} onClick={() => {
                                // eslint-disable-next-line react/no-direct-mutation-state
                                this.state.flags['faqs1'] = !this.state.flags['faqs1'];
                                // eslint-disable-next-line react/no-direct-mutation-state
                                this.state.flags['faqs1TS'] = new Date().toISOString();
                                this.handleSubmit();
                                this.handleCompleteStep(activeStep);
                                if (this.state.flags['faqs1']) {
                                    localStorage.setItem('lasturl', `/options`);
                                    localStorage.setItem('lastsection', `wizzard`);
                                    localStorage.setItem('laststep', `2`);
                                    history.push("/marketing?section=faqs1");
                                }
                            }}>
                                <div className={classes.inline} >
                                    {this.state.flags.faqs1 ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
                                    <Typography
                                        component={'span'}
                                        variant="h2"
                                        color="primary"
                                    >
                                        Preguntas Frecuentes
                                    </Typography>
                                </div>
                                <br />
                                <Typography
                                    component={'span'}
                                    variant="body1"
                                    color="primary"
                                >
                                    Completa la información sobre los asuntos más demandados y específicos del comercio.
                                </Typography>
                            </div>
                            <br /><br />
                            <div className={classes.pointer} onClick={() => {
                                // eslint-disable-next-line react/no-direct-mutation-state
                                this.state.flags['faqs2'] = !this.state.flags['faqs2'];
                                // eslint-disable-next-line react/no-direct-mutation-state
                                this.state.flags['faqs2TS'] = new Date().toISOString();
                                this.handleSubmit();
                                this.handleCompleteStep(activeStep);
                                if (this.state.flags['faqs2']) {
                                    localStorage.setItem('lasturl', `/options`);
                                    localStorage.setItem('lastsection', `wizzard`);
                                    localStorage.setItem('laststep', `2`);
                                    history.push("/marketing?section=faqs2")
                                }
                            }}>
                                <div className={classes.inline}>
                                    {this.state.flags.calendar ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
                                    <Typography
                                        component={'span'}
                                        variant="h2"
                                        color="primary"
                                    >
                                        Demás preguntas
                                    </Typography>
                                </div>
                                <br />
                                <Typography
                                    component={'span'}
                                    variant="body1"
                                    color="primary"
                                >
                                    Entrena al asistente sobre tus productos, sus usos, tu procesos de compra y devolución, garantías, entregas y otras particularidades de tu caso. Envía un email a <a href="mailto:hola@arturito.online" target="_blank" rel="noopener noreferrer" className={classes.link}>hola@arturito.online</a>  con el texto en el cuerpo del mensaje o archivo adjunto.
                                </Typography>
                            </div>
                            <br /><br />
                            <div className={classes.pointer} onClick={() => {
                                // eslint-disable-next-line react/no-direct-mutation-state
                                this.state.flags['pms'] = !this.state.flags['pms'];
                                // eslint-disable-next-line react/no-direct-mutation-state
                                this.state.flags['pmsTS'] = new Date().toISOString();
                                this.handleSubmit();
                                this.handleCompleteStep(activeStep);
                                if (this.state.flags['pms']) {
                                    history.push("/options?section=preview")
                                }
                            }}>
                                <div className={classes.inline}>
                                    {this.state.flags.pms ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
                                    <Typography
                                        component={'span'}
                                        variant="h2"
                                        color="primary"
                                    >
                                        Mensajes especiales
                                    </Typography>
                                </div>
                                <br />
                                <Typography
                                    component={'span'}
                                    variant="body1"
                                    color="primary"
                                >
                                    Registra mensajes puntuales que quieres reforzar en momentos específicos de la conversación.
                                </Typography>
                            </div>
                            <br /><br />
                        </TabPanel>
                        <TabPanel className={classes.tabpanel} value={activeStep} index={3}>
                            <div className={classes.pointer} onClick={() => {
                                // eslint-disable-next-line react/no-direct-mutation-state
                                this.state.flags['qrs'] = !this.state.flags['qrs'];
                                // eslint-disable-next-line react/no-direct-mutation-state
                                this.state.flags['qrsTS'] = new Date().toISOString();
                                this.handleSubmit();
                                this.handleCompleteStep(activeStep);
                                if (this.state.flags['qrs']) {
                                    localStorage.setItem('lasturl', `/options`);
                                    localStorage.setItem('lastsection', `wizzard`);
                                    localStorage.setItem('laststep', `3`);
                                    history.push("/marketing?section=acciones");
                                }
                            }}>
                                <div className={classes.inline} >
                                    {this.state.flags.qrs ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
                                    <Typography
                                        component={'span'}
                                        variant="h2"
                                        color="primary"
                                    >
                                        Divulga tu negocio
                                    </Typography>
                                </div>
                                <br />
                                <Typography
                                    component={'span'}
                                    variant="body1"
                                    color="primary"
                                >
                                    Graba las diferentes acciones comerciales para divulgar links y QRs que los clientes usen para registrarse en Arturito.
                                </Typography>
                            </div>
                            <br /><br />
                            <div className={classes.pointer} onClick={() => {
                                // eslint-disable-next-line react/no-direct-mutation-state
                                this.state.flags['calendar'] = !this.state.flags['calendar'];
                                // eslint-disable-next-line react/no-direct-mutation-state
                                this.state.flags['calendarTS'] = new Date().toISOString();
                                // this.handleSubmit();
                                // this.handleCompleteStep(activeStep);
                                if (this.state.flags['calendar']) {
                                    localStorage.setItem('lasturl', `/options`);
                                    localStorage.setItem('lastsection', `wizzard`);
                                    localStorage.setItem('laststep', `3`);
                                    history.push("/marketing?section=calendario")
                                }
                            }}>
                                <div className={classes.inline}>
                                    {this.state.flags.calendar ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
                                    <Typography
                                        component={'span'}
                                        variant="h2"
                                        color="primary"
                                    >Mantiene un calendario</Typography>
                                </div>
                                <br />
                                <Typography
                                    component={'span'}
                                    variant="body1"
                                    color="primary"
                                >
                                    Anota las promociones vinculadas a productos o genéricas, para que tus clientes tengan una novedad según el día de la semana.
                                </Typography>
                            </div>
                            <br /><br />
                        </TabPanel>
                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                            <Button
                                variant="contained" color="primary"
                                disabled={activeStep === 0}
                                onClick={this.handleBack}
                                sx={{ mr: 1 }}
                            >
                                Anterior
                            </Button>
                            <Box sx={{ flex: '1 1 auto' }} />
                            <Button variant="contained" color="primary"
                                disabled={activeStep > 2}
                                onClick={this.handleNext} sx={{ mr: 1 }}>
                                Siguiente
                            </Button>
                            {/* {activeStep !== steps.length &&
                                    (completed[activeStep] ? (
                                        <Typography variant="caption" sx={{ display: 'inline-block' }}>
                                            Etapa {activeStep + 1} completada
                                        </Typography>
                                    ) : (
                                        <Button variant="contained" color="secondary" onClick={this.handleComplete}>
                                            {this.completedSteps() === this.totalSteps() - 1
                                                ? 'Terminar'
                                                : 'Completar'}
                                        </Button>
                                    ))} */}
                        </Box>
                    </React.Fragment>
                    {/* )} */}
                </div>
            </Box >
        );
    }
    componentDidMount() {
        if (this.props.user && this.props.user.store.social_networks && this.props.user.store.social_networks.flags) {
            // eslint-disable-next-line react/no-did-mount-set-state
            this.setState({
                flags: this.props.user.store.social_networks.flags,
            })
        }
        setTimeout(() => {
            this.handleCompleteStep();
        }, 200);
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.activeStep !== this.props.activeStep
        )
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({
                activeStep: parseInt(this.props.activeStep),
            })

    }
}

WizzarConfig.defaultProps = {
};

export default (withStyles(styles)(WizzarConfig));