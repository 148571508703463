import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

ProductPreview.propTypes = {
  //  True if the back button is on
  open: PropTypes.bool,
  product: PropTypes.object,
  handleClose: PropTypes.func,
}

ProductPreview.defaultProps = {
  open: false,
  product: null,
};


function ProductPreview({ open, product, handleClose }) {

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="Error"
        aria-describedby="description"
      >
        {(product) &&
          <div>
            <DialogTitle id="alert-dialog-title">{product.name}</DialogTitle>
            <DialogContent>
              <img src={product.image_url} alt='' height="350px"></img>
            </DialogContent>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {(product.brandname) && `Marca: ${product.brandname}`}<br />
                {(product.kindname) && `Tipo: ${product.kindname}`}<br />
                {(product.curr_symbol) && `Valor: ${product.curr_symbol} ${product.price_per_unit} $(product.unit)`}<br />
                {(product.tags) && `Tags: ${product.tags}`}<br />
                {(product.description) && `${product.description}`}<br />
              </DialogContentText>
            </DialogContent>
          </div>
        }
        <DialogActions>
          <Button onClick={handleClose} variant="contained" color="primary" autoFocus>
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ProductPreview;
