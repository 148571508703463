import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

AlertDialog.propTypes = {
  //  True if the back button is on
  open: PropTypes.bool,
  // The label to show over the bar
  title: PropTypes.string,
  //  True if the menu button is on
  text: PropTypes.string,
  // The function to call when input changes
  handleClose: PropTypes.func,
  accept: PropTypes.bool,
  handleAccept: PropTypes.func
}

AlertDialog.defaultProps = {
  open: false,
  title: '',
  text: '',
};

function AlertDialog({ open, title, text, handleClose, accept, handleAccept }) {

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="Error"
        aria-describedby="description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {text}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {accept && <Button onClick={handleAccept} variant="contained" color="primary" autoFocus>
            Aceptar
          </Button>}
          <Button onClick={handleClose} variant="contained" color="primary" autoFocus>
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default AlertDialog;
