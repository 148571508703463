/* eslint-disable react/jsx-no-bind */
import React, { Component } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { history } from './_helpers';
// eslint-disable-next-line import/named
import { PrivateRoute } from './_components';
import Widget from './_components/Widget';
import Home from './_screens/Home';
import HomeClient from './_screens/HomeClient';
import Info from './_screens/Info';
import Login from './_screens/Login';
import ClientList from './_screens/ClientList';
import UserList from './_screens/UserList';
import OrderList from './_screens/OrderList';
import PaymentList from './_screens/PaymentList';
import Options from './_screens/Options';
import Marketing from './_screens/Marketing';
import OrderDetail from './_screens/OrderDetail';
import ClientDetail from './_screens/ClientDetail';
import ProductList from './_screens/ProductList';
import ProductStore from './_screens/ProductStore';
import OrderStatus from './_screens/OrderStatus';
import Register from './_screens/Register';
import RecoverPass from './_screens/RecoverPass';
import Default404 from './_screens/Default404';
import withStyles from '@material-ui/core/styles/withStyles';
import CssBaseline from '@material-ui/core/CssBaseline';
// import AlertDialog from './_components/AlertDialog';
import InfoAlert from './_components/InfoAlert';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import { Grid, Hidden } from '@material-ui/core';
import config from './config';
import './App.css';
import { alertActions, userActions, productActions, orderActions, clientActions } from './_actions';
import { dicUnits, convertNumber } from './_helpers/utils';
import moment from 'moment';
import Dashboard from './_screens/Dashboard';


// eslint-disable-next-line no-unused-vars
const styles = theme => ({
  root1: {
    flexGrow: 1,
    flex: 1,
    display: 'flex',
    overflow: 'hidden',
    'flex-direction': 'column',
    padding: '0px',
    margin: '0px',
    // height: '93vh',
    'overflow-y': 'auto',
    background: '#fff',
    'padding-bottom': '60px',
  },
  versionDiv: {
    'text-align': 'end',
    position: 'fixed',
    right: '75px',
    width: '128px',
    bottom: '0px',
    'z-index': 1,
    color: '#aaa',
    'font-size': '12px',
  },
  versionDiv2: {
    'text-align': 'end',
    position: 'fixed',
    right: '75px',
    width: '128px',
    bottom: '0px',
    'z-index': 1,
    color: '#777',
    'font-size': '10px',
  },
  footer: {
    height: '34px',
    background: '#025e9f',
    position: 'fixed',
    width: '100%',
    bottom: 0,
  },
  nolink: {
    'text-decoration': 'none',
    color: 'white !important',
  },
  footer1: {
    'padding-left': '20px',
    'padding-top': '5px',
    'justify-content': 'left',
    width: "100px !important",
  },
  footer2: {
    'padding-top': '8px',
    'padding-left': '15px',
    'justify-content': 'left',
  },
  backdropico: {
    position: 'absolute',
    overflow: 'hidden',
    'border-radius': '50%',
    height: '75px',
    width: '75px',
  }
});


var production = (window.location.href.startsWith('https://arturito.chat') || window.location.href.startsWith('https://arturito.store')); //process.env.NODE_ENV


class App extends Component {

  generateSessionId = () => {
    var sessionId = localStorage.getItem('sessionid');

    if (sessionId) {
      console.log('SID -------- :', sessionId);
      return sessionId;
    }

    var dt = new Date().getTime();

    sessionId = 'dfmessenger-xxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c === 'x' ? r : ((r & 0x3) | 0x8)).toString(16);
    });

    console.log('SID -------- :', sessionId);
    localStorage.setItem('sessionid', sessionId);

    return sessionId;
  }

  checkVerWidgetBE = () => {
    console.log('CV --------- :', config.version);
    if (process.env.REACT_APP_API_URL.includes('staging'))
      console.log('BE --------- : staging');
    else console.log('BE --------- : production');

    if (process.env.REACT_APP_AGENT_ID.includes('8ec1c'))
      console.log('WG --------- : staging');
    else console.log('WG --------- : production');
  }

  syncAC = (fields, user) => {
    if (production)
      if (this.props.user || user) {
        var contact = {
          email: (user ? user : this.props.user.user),
        };
        fields.forEach(element => {
          contact[`field[${element.field},0]`] = element.value;
        });
        userActions.syncAC(contact);
      }
  }

  state = {
    serverVersion: '1.00',
    user: {
      id: '',
      email: '',
      password: '',
    },
    currentOrder: {},
    currentClient: {},
    entrantesList: [],
    enprocesoList: [],
    adespacharList: [],
    entregadasList: [],
    clientMetricRergisterM: 0,
    clientMetricRergister30: 0,
    clientMetricActiveM: 0,
    clientMetricActive30: 0,
    clientDic: {},
    clientList: [],
    numPedidos: 0,
    amountPedidos: 0,
    numEntregas: 0,
    amountEntregas: 0,
    entrantesAmount: 0,
    enprocesoAmount: 0,
    adespacharAmount: 0,
    entregadasAmount: 0,
    sessionId: this.generateSessionId(),
    widgetHidden: true,
    widgetTitle: '',
    widgetTooltip: '',
    widgetExpand: false,
    widgetWelcome: false,
    widgetIco: '/logo.png',
    updated: true,
    getParam: '',
    loadingLogo: 'https://arturito.chat/logo.png',
  }

  updateSessionId = (sessionId) => {
    localStorage.setItem('sessionid', sessionId);
    this.setState({
      sessionId: sessionId,
    });
  };

  handleCloseDialog = () => {
    const { dispatch } = this.props;
    dispatch(alertActions.clear());
  }

  checkloading = () => {
    const { FAQs, FAQsOAI, loggingIn, promos, qrs, actions, registering, products, product, clients, orders, users, integrations, csvstatus, paymentMethods, session, theme, store } = this.props;
    return (loggingIn || registering || (FAQs && FAQs.loading) || (FAQsOAI && FAQsOAI.loading) || (orders && orders.loading) || (session && session.loading) ||
      (clients && clients.loading) || (product && product.loading) || (products && products.loading) || (users && users.loading) || (paymentMethods && paymentMethods.loading) ||
      (actions && actions.loading) || (integrations && integrations.loading) || (csvstatus && csvstatus.loading) || (promos && promos.loading) || (theme && theme.loading) || (store && store.loading) || (qrs && qrs.loading))
  }

  setCurrentOrder = (order) => {
    this.setState({
      currentOrder: order,
    });
  }

  setLoadingLogo = (logo) => {
    this.setState({
      loadingLogo: logo,
    })
  }

  setCurrentClient = (client) => {
    this.setState({
      currentClient: client,
    });
  }

  getData = (dataToLoad, param) => {
    // dataToLoad = ['clients', 'orders'...]
    // param = 'xxx' , ex xxx = orderid = '22R'

    const dusers = dataToLoad.find(e => e === 'users');
    const dproducts = dataToLoad.find(e => e === 'products');
    const dorders = dataToLoad.find(e => e === 'orders');
    const dorder = dataToLoad.find(e => e === 'order');
    const dclients = dataToLoad.find(e => e === 'clients');

    const { dispatch, user } = this.props;

    if (!user) {
      history.push('/login');
      dispatch(alertActions.success('Error en sesion, por favor ingrese sus credenciales.'));
      return;
    }

    // admin arturito
    if (user.is_admin && dusers) {
      dispatch(userActions.getAll());
      dispatch(userActions.getCSVStatus());
      this.setState({
        updated: false,
      });
      return;
    }

    //user store
    if (!user.enabled) {
      history.push('/login');
      dispatch(alertActions.success('El usuario esta inactivo, por favor envie un mail a hola@arturito.online para solicitar la activacion de su cuenta.'));
      return;
    }

    if (dclients || dorders) {
      dispatch(clientActions.getAll());
    }
    if (dorders) {
      dispatch(orderActions.getAll());
    }
    if (dorder) {
      dispatch(orderActions.getOrder(param));
    }
    if (dproducts) {
      dispatch(productActions.getAll());
    }
    this.setState({
      updated: false,
      getParam: param,
    });
  }

  onBackComp = () => {
    const lasturl = localStorage.getItem('lasturl');
    const lastsection = localStorage.getItem('lastsection');
    const laststep = localStorage.getItem('laststep');

    let url = '';

    if (lasturl) url = url + lasturl;
    if (lastsection) url = url + '?section=' + lastsection;
    if (laststep) url = url + '&step=' + laststep;

    if (url && (window.location.href.indexOf(url) === -1)) {
      localStorage.removeItem('lasturl');
      localStorage.removeItem('lastsection');
      localStorage.removeItem('laststep');
      history.push(url);
    }
    else
      history.push('/home');
    // if (document.referrer === "") {
    //   console.log('go home');
    // } else {
    //   console.log('go back', document.referrer);
    //   window.history.back();
    // }
  }

  cleanData = (full) => {
    const { dispatch } = this.props;
    this.props.orders.orders = null;
    this.props.users.users = null;
    this.props.clients.clients = null;
    this.props.products.products = null;
    localStorage.removeItem('user');

    if (full) {
      localStorage.removeItem('sessionid');
      dispatch(userActions.logout());
      // this.props.history.push('/login');
    }
  }

  processData = (dataToLoad) => {
    // dataToLoad = ['clients', 'orders'...]

    const dusers = dataToLoad.find(e => e === 'users');
    const dproducts = dataToLoad.find(e => e === 'products');
    const dorders = dataToLoad.find(e => e === 'orders');
    const dorder = dataToLoad.find(e => e === 'order');
    const dclients = dataToLoad.find(e => e === 'clients');
    const param = this.state.getParam;

    // admin arturito
    const { user } = this.props;

    if (!user) {
      console.log('Error user or session, redirecting')
      history.push('/login');
      return null;
    }

    if (user.is_admin && dusers) {
      this.setState({
        updated: true,
      });
      return;
    }

    //user store
    let clientDic = {};
    let clientList = [];
    let clientMetricRergisterM = 0;
    let clientMetricRergister30 = 0;
    let clientMetricActiveM = 0;
    let clientMetricActive30 = 0;

    if (dclients || dorders) {
      const { clients } = this.props;
      if (clients && clients.clients && (clients.clients.length > 0)) {
        clients.clients.forEach(client => {
          clientDic[client.id] = client;
          if (!client.email) client.email = "-";
          if (!client.phone) client.phone = "-";

          if (clientList.length < 5) clientList.push(client);

          const now = moment(new Date());
          const register = moment(client.first_seen);
          const last = moment(client.last_seen);

          if (now.month() === register.month() && now.year() === register.year()) {
            clientMetricRergisterM++;
          }
          if (now.month() === last.month() && now.year() === last.year()) {
            clientMetricActiveM++;
          }

          if (now.diff(register, 'days') < 30) {
            clientMetricRergister30++;
          }
          if (now.diff(last, 'days') < 30) {
            clientMetricActive30++;
          }
        });
      }

      this.setState({
        clientMetricRergisterM: clientMetricRergisterM,
        clientMetricRergister30: clientMetricRergister30,
        clientMetricActiveM: clientMetricActiveM,
        clientMetricActive30: clientMetricActive30,
        clientDic: clientDic,
        clientList: clientList,
        updated: !this.checkloading(),
      });
    }

    if (dproducts) {
      const { products } = this.props;

      if (products && products.products && (products.products.length > 0)) {
        products.products.forEach(product => {
          product.brandname = (product.brand ? product.brand.name : '');
          product.kindname = (product.kind ? product.kind.name : '');
          product.new_price = '';
        })
      }
      this.setState({
        updated: !this.checkloading(),
      });
    }

    if (dorders || dorder) {
      const { orders } = this.props;
      let entrantesList = [],
        enprocesoList = [],
        adespacharList = [],
        entregadasList = [];

      let numPedidos = 0,
        amountPedidos = 0,
        numEntregas = 0,
        amountEntregas = 0,
        entrantesAmount = 0,
        enprocesoAmount = 0,
        adespacharAmount = 0,
        entregadasAmount = 0;

      if (orders && orders.orders && (orders.orders.length > 0)) {
        orders.orders.forEach(order => {
          order.client = (clientDic[order.client_id] ? clientDic[order.client_id].first_name + " " + (clientDic[order.client_id].last_name ? clientDic[order.client_id].last_name : "") : "");
          order.amount = 0;

          order.colorBlue = false;
          order.colorRed = false;
          order.colorViolet = false;

          const stat = order.payment_status;

          let status = '';

          if (stat === 'pending') status = 'No solicitado';
          if (stat === 'required') status = 'Solicitado';
          if (stat === 'processing') status = 'En proceso';
          if (stat === 'done') status = 'Aprobado'
          order.payment = status;

          order.payment_method = order.payment_method ? order.payment_method : 'No definido';

          order.items.forEach(item => {
            item.price = ((item.product_denormalized && item.product_denormalized.price_per_unit) ? item.product_denormalized.price_per_unit : 0);
            item.total = ((item.revised_price) ? parseFloat(item.revised_price) : ((item.price) ? item.amount * parseFloat(item.price) : 0));
            item.name = ((item.product_denormalized && item.product_denormalized.name) ? item.product_denormalized.name : "");
            item.brand = ((item.product_denormalized && item.product_denormalized.brand) ? item.product_denormalized.brand : "");
            item.kind = ((item.product_denormalized && item.product_denormalized.kind) ? item.product_denormalized.kind : "");
            item.quantity_unit = dicUnits((item.product_denormalized && item.product_denormalized.quantity_unit) ? item.product_denormalized.quantity_unit : "");

            if (!(item.total >= 0)) {
              item.total = 0;
            }

            item.color = "";

            // if ((item.revised_price) && (item.name !== '')) {
            //     item.color = 'blue';
            //     order.colorBlue = true;
            // }
            if (item.name === '') {
              item.name = item.raw_product;
              item.color = 'red';
              order.colorRed = true;
            }
            // if ((item.name === '') && (item.revised_price)) {
            //     item.name = item.raw_product;
            //     item.color = 'violet';
            //     order.colorViolet = true;
            // }

            order.amount += item.total;
          });

          order.nitems = order.items.length;
          switch (order.status) {
            case 'pending':
              order.estado = 'Pendiente';
              break;
            case 'provisional':
              order.estado = 'Provisorio';
              break;
            case 'done':
              order.estado = 'Entregado';
              break;
            case 'preparing':
              order.estado = 'Proceso';
              break;
            case 'charge':
              order.estado = 'Cobrar';
              break;
            case 'waiting_delivery':
              order.estado = 'Entregar';
              break;
            case 'discarded':
              order.estado = 'Descartado';
              break;
            case 'store_canceled':
              order.estado = 'Cancelado por store';
              break;
            case 'client_canceled':
              order.estado = 'Cancelado por cliente';
              break;
            default:
              order.estado = order.state;
          }

          order.amount = parseFloat(order.amount.toFixed(2));

          if (order.status === 'done') {
            amountEntregas += order.total_price;
            numEntregas++;
            entregadasAmount += order.total_price;
            if (entregadasList.length < 12) entregadasList.push(order);
          }
          if (order.status === 'waiting_delivery') {
            amountEntregas += order.total_price;
            numEntregas++;
            adespacharAmount += order.total_price;
            if (adespacharList.length < 12) adespacharList.push(order);
          }
          // if (order.status === 'charge') {
          //     amountEntregas += order.amount;
          //     numEntregas++;
          //     entregadasAmount += order.amount;
          //     entregadasList.push(order);
          // }
          if (order.status === 'preparing') {
            amountPedidos += order.total_price;
            numPedidos++;
            enprocesoAmount += order.total_price;
            if (enprocesoList.length < 12) enprocesoList.push(order);
          }
          if (order.status === 'pending') {
            amountPedidos += order.total_price;
            numPedidos++;
            entrantesAmount += order.total_price;
            if (entrantesList.length < 12) entrantesList.push(order);
          }
          // if (order.status === 'provisional') {
          //     // amountPedidos += order.amount;
          //     // numPedidos++;
          //     // entrantesAmount += order.amount;
          //     // entrantesList.push(order);
          // }

          if (order.alphanum_id === param) {
            this.setState({
              currentOrder: order,
            });
          }
        });

        this.setState({
          entrantesList: entrantesList,
          enprocesoList: enprocesoList,
          adespacharList: adespacharList,
          entregadasList: entregadasList,
          numPedidos: numPedidos,
          amountPedidos: convertNumber(amountPedidos, this.props.user.store.curr_symbol),
          numEntregas: numEntregas,
          amountEntregas: convertNumber(amountEntregas, this.props.user.store.curr_symbol),
          entrantesAmount: convertNumber(entrantesAmount, this.props.user.store.curr_symbol),
          enprocesoAmount: convertNumber(enprocesoAmount, this.props.user.store.curr_symbol),
          adespacharAmount: convertNumber(adespacharAmount, this.props.user.store.curr_symbol),
          entregadasAmount: convertNumber(entregadasAmount, this.props.user.store.curr_symbol),
          updated: !this.checkloading(),
        });
      }
      // save to local storage
      // localStorage.setItem('orders', JSON.stringify(orders.orders));

      // save to local storage
      // localStorage.setItem('clientDic', JSON.stringify(clientDic));
      // localStorage.setItem('productList', JSON.stringify(productList));
      // localStorage.setItem('products', JSON.stringify(products.products));
    }
  }

  deactivateTooltip = () => {
    this.setState({
      widgetTooltip: '',
    });
  }

  setWidget = (hidden, expand, tooltip, welcome, title, ico) => {

    if (expand)
      expand = Math.floor(Math.random() * 32000)
    this.setState({
      widgetHidden: hidden,
      widgetExpand: expand,
    });
    if (title)
      this.setState({
        widgetTitle: title,
      });
    if (tooltip)
      this.setState({
        widgetTooltip: tooltip,
      });
    if (welcome !== undefined)
      this.setState({
        widgetWelcome: welcome,
      });
    if (ico)
      this.setState({
        widgetIco: ico,
      });
    if (tooltip)
      setTimeout(() => {
        this.setState({
          widgetTooltip: '',
        });
      }, 4000)
  }


  updateWidgetStyle = (color_set) => {
    const dfMessenger = document.querySelector('df-messenger');
    dfMessenger.style.setProperty('--df-messenger-bot-message', (color_set.df_messenger_bot_message ? color_set.df_messenger_bot_message : '#ffffff'));
    dfMessenger.style.setProperty('--df-messenger-button-titlebar-color', (color_set.df_messenger_button_titlebar_color ? color_set.df_messenger_button_titlebar_color : '#134859'));
    dfMessenger.style.setProperty('--df-messenger-button-titlebar-font-color', (color_set.df_messenger_button_titlebar_font_color ? color_set.df_messenger_button_titlebar_font_color : '#ed841e'));
    dfMessenger.style.setProperty('--df-messenger-input-box-color', (color_set.df_messenger_input_box_color ? color_set.df_messenger_input_box_color : '#f0f0f0'));
    dfMessenger.style.setProperty('--df-messenger-input-font-color', (color_set.df_messenger_input_font_color ? color_set.df_messenger_input_font_color : '#000000'));
    dfMessenger.style.setProperty('--df-messenger-chat-background-color', (color_set.df_messenger_chat_background_color ? color_set.df_messenger_chat_background_color : '#e5ddd5'));
    dfMessenger.style.setProperty('--df-messenger-font-color', (color_set.df_messenger_font_color ? color_set.df_messenger_font_color : '#000000'));
    dfMessenger.style.setProperty('--df-messenger-send-icon', (color_set.df_messenger_send_icon ? color_set.df_messenger_send_icon : '#134859'));
    dfMessenger.style.setProperty('--df-messenger-user-message', (color_set.df_messenger_user_message ? color_set.df_messenger_user_message : '#dcf8c6'));
    dfMessenger.style.setProperty('--df-messenger-chip-color', (color_set.df_messenger_chip_color ? color_set.df_messenger_chip_color : '#f5ddb9'));
  }

  checkroute = (expected) => {
    if (!production)
      return true;
    if (window.location.href.startsWith(expected))
      return true;

    return false;
  }

  // checkOldData = () => {
  //   const orders = localStorage.getItem('orders');
  //   const products = localStorage.getItem('products');
  //   const clientDic = localStorage.getItem('clientDic');
  //   const productList = localStorage.getItem('productList');

  //   if (orders)
  //     // if (this.props.orders && this.props.orders.orders.length < 1)
  //     this.props.orders.orders = JSON.parse(localStorage.getItem('orders'));

  //   if (products)
  //     // if (this.props.products && this.props.products.products.length < 1)
  //     this.props.products.products = JSON.parse(localStorage.getItem('products'));

  //   if (clientDic && this.state.clientDic && this.state.clientDic.length < 1)
  //     this.setState({
  //       clientDic: JSON.parse(localStorage.getItem('clientDic'))
  //     });

  //   if (productList && this.state.productList && this.state.productList.length < 1)
  //     this.setState({
  //       productList: JSON.parse(localStorage.getItem('productList'))
  //     });
  // }

  render() {
    const { alert, classes } = this.props;
    const { sessionId, widgetHidden, widgetTitle, serverVersion, widgetTooltip,
      entrantesList, enprocesoList, adespacharList, entregadasList, numPedidos, amountPedidos, numEntregas, amountEntregas, entrantesAmount,
      enprocesoAmount, adespacharAmount, entregadasAmount, widgetExpand, widgetWelcome, widgetIco, clientList,
      clientMetricActive30, clientMetricActiveM, clientMetricRergister30, clientMetricRergisterM } = this.state;
    const { checkroute } = this;

    return (
      <React.Fragment>
        <CssBaseline />
        <Router history={history}>
          <div className={classes.root1}>
            <Switch>
              {checkroute(config.url_chat) && <PrivateRoute exact path="/" component={HomeClient} base='client' setWidget={this.setWidget} updateWidgetStyle={this.updateWidgetStyle} />}
              {checkroute(config.url_store) && <PrivateRoute exact path="/" component={Home} base='store' setWidget={this.setWidget} syncAC={this.syncAC} getData={this.getData} />}

              {checkroute(config.url_chat) &&
                <Route exact
                  path="/s/:id"
                  render={(props) => <HomeClient {...props} sessionId={sessionId} setWidget={this.setWidget} updateWidgetStyle={this.updateWidgetStyle} />}
                />}

              {checkroute(config.url_chat) &&
                <Route exact
                  path="/homeclient/:id"
                  render={(props) => <HomeClient {...props} sessionId={sessionId} setWidget={this.setWidget} updateWidgetStyle={this.updateWidgetStyle} />}
                />}

              {checkroute(config.url_chat) &&
                <Route exact
                  path="/homeclient"
                  render={(props) => <HomeClient {...props} sessionId={sessionId} setWidget={this.setWidget} updateWidgetStyle={this.updateWidgetStyle} />}
                />}

              {checkroute(config.url_chat) &&
                <Route
                  path="/info"
                  render={(props) => <Info {...props} sessionId={sessionId} setWidget={this.setWidget} />}
                />}

              {checkroute(config.url_store) &&
                <Route
                  path="/login"
                  render={(props) => <Login {...props} setWidget={this.setWidget} syncAC={this.syncAC} cleanData={this.cleanData} />}
                />}

              {checkroute(config.url_store) &&
                <Route
                  path="/home"
                  render={(props) => <Home {...props} sessionId={sessionId} setWidget={this.setWidget} syncAC={this.syncAC}
                    entrantesList={entrantesList}
                    enprocesoList={enprocesoList}
                    adespacharList={adespacharList}
                    entregadasList={entregadasList}
                    numPedidos={numPedidos}
                    amountPedidos={amountPedidos}
                    numEntregas={numEntregas}
                    amountEntregas={amountEntregas}
                    entrantesAmount={entrantesAmount}
                    enprocesoAmount={enprocesoAmount}
                    adespacharAmount={adespacharAmount}
                    entregadasAmount={entregadasAmount}
                    setCurrentOrder={this.setCurrentOrder}
                    getData={this.getData}
                    clientList={clientList}
                    setCurrentClient={this.setCurrentClient}
                    clientMetricRergisterM={clientMetricRergisterM}
                    clientMetricRergister30={clientMetricRergister30}
                    clientMetricActiveM={clientMetricActiveM}
                    clientMetricActive30={clientMetricActive30}
                    onBackComp={this.onBackComp}
                    cleanData={this.cleanData}
                  />}
                />}

              {checkroute(config.url_store) &&
                <Route
                  path="/register"
                  render={(props) => <Register {...props} setWidget={this.setWidget} syncAC={this.syncAC} />}
                />}

              {checkroute(config.url_store) &&
                <Route
                  path="/recoverpass"
                  render={(props) => <RecoverPass {...props} setWidget={this.setWidget} syncAC={this.syncAC} />}
                />}

              {checkroute(config.url_store) &&
                <Route
                  path="/clientlist"
                  render={(props) => <ClientList {...props} setCurrentClient={this.setCurrentClient} sessionId={sessionId} setWidget={this.setWidget} getData={this.getData} onBackComp={this.onBackComp} />}
                />}

              {checkroute(config.url_store) &&
                <Route
                  path="/userlist"
                  render={(props) => <UserList {...props} setWidget={this.setWidget} />}
                />}

              {checkroute(config.url_store) &&
                <Route
                  path="/orderlist"
                  render={(props) => <OrderList {...props} setCurrentOrder={this.setCurrentOrder} sessionId={sessionId} setWidget={this.setWidget} getData={this.getData} onBackComp={this.onBackComp} />}
                />}

              {checkroute(config.url_store) &&
                <Route
                  path="/paymentlist"
                  render={(props) => <PaymentList {...props} setCurrentOrder={this.setCurrentOrder} sessionId={sessionId} setWidget={this.setWidget} getData={this.getData} onBackComp={this.onBackComp} />}
                />}

              {checkroute(config.url_store) &&
                <Route
                  path="/orderdetail/:id"
                  render={(props) => <OrderDetail {...props} currentOrder={this.state.currentOrder} setCurrentOrder={this.setCurrentOrder}
                    client={this.state.clientDic[this.state.currentOrder.client_id]} sessionId={sessionId} setWidget={this.setWidget} getData={this.getData}
                    onBackComp={this.onBackComp} />}
                />}

              {checkroute(config.url_store) &&
                <Route
                  path="/orderdetail"
                  render={(props) => <OrderDetail {...props} currentOrder={this.state.currentOrder} setCurrentOrder={this.setCurrentOrder}
                    client={this.state.clientDic[this.state.currentOrder.client_id]} sessionId={sessionId} setWidget={this.setWidget} getData={this.getData}
                    onBackComp={this.onBackComp} />}
                />}

              {checkroute(config.url_store) &&
                <Route
                  path="/clientdetail"
                  render={(props) => <ClientDetail {...props}
                    setCurrentOrder={this.setCurrentOrder}
                    clientDic={this.state.clientDic}
                    currentClient={this.state.currentClient}
                    sessionId={sessionId} s
                    setWidget={this.setWidget}
                    getData={this.getData}
                    onBackComp={this.onBackComp}
                  />}
                />}

              <Route
                path="/orderstatus/:token"
                render={(props) => <OrderStatus {...props} sessionId={sessionId} updateSessionId={this.updateSessionId} setWidget={this.setWidget} updateWidgetStyle={this.updateWidgetStyle} />}
              />

              {checkroute(config.url_store) &&
                <Route
                  path="/productstore"
                  render={(props) => <ProductStore {...props} sessionId={sessionId} setWidget={this.setWidget} syncAC={this.syncAC} getData={this.getData}
                    onBackComp={this.onBackComp} />}
                />}

              {checkroute(config.url_store) &&
                <Route
                  path="/options"
                  render={(props) => <Options {...props} sessionId={sessionId} setWidget={this.setWidget} syncAC={this.syncAC} getData={this.getData}
                    onBackComp={this.onBackComp} />}
                />}

              {checkroute(config.url_store) &&
                <Route
                  path="/marketing"
                  render={(props) => <Marketing {...props} sessionId={sessionId} setWidget={this.setWidget} syncAC={this.syncAC} getData={this.getData}
                    onBackComp={this.onBackComp} />}
                />}

              {checkroute(config.url_store) &&
                <Route
                  path="/dashboard"
                  render={(props) => <Dashboard {...props} sessionId={sessionId} setWidget={this.setWidget} syncAC={this.syncAC} getData={this.getData}
                    onBackComp={this.onBackComp} />}
                />}

              <Route
                path="/productlist/:token"
                render={(props) => <ProductList {...props} sessionId={sessionId} updateSessionId={this.updateSessionId} setWidget={this.setWidget} updateWidgetStyle={this.updateWidgetStyle} />}
              />

              {checkroute(config.url_chat) &&
                <Route
                  render={(props) => <Default404 {...props} userType="client" sessionId={sessionId} setWidget={this.setWidget} />}
                />}

              {checkroute(config.url_store) &&
                <Route
                  render={(props) => <Default404 {...props} userType="store" setWidget={this.setWidget} />}
                />}
            </Switch>
            {alert.message &&
              <InfoAlert
                open={(alert.message !== undefined)}
                text={alert.message}
                type={alert.type}
                handleClose={this.handleCloseDialog}
              />
              // <AlertDialog open={(alert.message !== undefined)} title="" text={alert.message} handleClose={this.handleCloseDialog}></AlertDialog>
            }

            {/* {(this.checkloading()) &&
              <Backdrop
                sx={{ color: '#fff', 'backgroundColor': 'rgba(0, 0, 0, 0.8)', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={true}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
            } */}
            {this.checkloading() &&
              <Backdrop
                sx={{ color: '#ff4', 'backgroundColor': 'rgba(254, 254, 254, 0.9)', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={true}
              >
                <img src={this.state.loadingLogo} alt='' height="75px" className={classes.backdropico}></img>
                <CircularProgress color="inherit" sx={{ width: '80px !important', height: '80px !important' }} />
              </Backdrop>
            }
            {(!production) &&
              <div className={classes.versionDiv}>Staging c{config.version} + s{serverVersion}</div>
            }
            {(production) &&
              <div className={classes.versionDiv2}>{config.version} + {serverVersion}</div>
            }
          </div>
          <div className={classes.footer}>
            <Grid container>
              <Grid className={classes.footer1}>
                <a href='https://www.instagram.com/arturito.online/' target="_blank" rel="noopener noreferrer" className={classes.nolink}>
                  <InstagramIcon />
                </a>
                <a href='https://www.facebook.com/arturito.online ' target="_blank" rel="noopener noreferrer" className={classes.nolink}>
                  <FacebookIcon />
                </a>
                <a href='https://www.linkedin.com/company/arturito-online' target="_blank" rel="noopener noreferrer" className={classes.nolink}>
                  <LinkedInIcon />
                </a>
              </Grid>
              {/* <Hidden only={['xs']}> */}
              <Grid className={classes.footer2}>
                <a href='https://arturito.online/politica-de-privacidad/' target="_blank" rel="noopener noreferrer" className={classes.nolink}>
                  <Hidden only={['xs']}>Todos los derechos reservados © 2021 / </Hidden>PRIVACY POLICY
                </a>
              </Grid>
              <Grid className={classes.footer2}>
                <a href='https://arturito.online' target="_blank" rel="noopener noreferrer" className={classes.nolink}>
                  Powered by Arturito
                </a>
              </Grid>
              {/* </Hidden> */}

              {/* <Grid item xs={6} sm={4} className={classes.top}>
                <a href='http://arturito.chat/' target="_blank" rel="noopener noreferrer" className={classes.nolink}>
                  Comprador
                </a>
                <a href='http://arturito.store/' target="_blank" rel="noopener noreferrer" className={classes.nolink}>
                  --Vendedor
                </a>
              </Grid>
              <Hidden only={['xs']}>
                <Grid item xs={6} sm={4} className={classes.top}>
                  <a href='https://arturito.online/politica-de-privacidad/' target="_blank" rel="noopener noreferrer" className={classes.nolink}>
                    Privacy policy
                </a>
                </Grid>
              </Hidden> */}
            </Grid>
          </div>

          {!widgetHidden &&
            <Widget id="widget_id"
              widgetHidden={widgetHidden}
              widgetTitle={widgetTitle}
              sessionId={sessionId}
              widgetTooltip={widgetTooltip}
              widgetExpand={widgetExpand}
              widgetWelcome={widgetWelcome}
              widgetIco={widgetIco}
              history={history}
            />
          }
        </Router>
      </React.Fragment>
    );
  }

  componentDidMount() {

    this.cleanData();
    this.checkVerWidgetBE();

    // get server version
    // fetch(`${process.env.REACT_APP_API_URL}/version`)
    //   .then(res => { return res.json(); })
    //   .then(data => {
    //     this.setState({ serverVersion: data.data.version });
    //     if (data.data.version > config.version) {
    //       const { dispatch } = this.props;
    //       dispatch(alertActions.success('Cliente desactualizado, por favor cierre la aplicacion y vuelva a abrirla.'));
    //     }
    //   });

  }

  componentDidUpdate() {
    if (this.state.updated !== undefined && this.state.updated === false && !this.checkloading()) {
      if (this.props.users && this.props.users.users && (this.props.users.users.length > 0)) {
        this.processData(['users']);
      }
      if (this.props.orders && this.props.orders.orders && (this.props.orders.orders.length > 0)) {
        this.processData(['orders']);
      }
      if (this.props.clients && this.props.clients.clients && (this.props.clients.clients.length > 0)) {
        this.processData(['clients']);
      }
      if (this.props.products && this.props.products.products && (this.props.products.products.length > 0)) {
        this.processData(['products']);
      }
    }
    if (this.props.theme && this.props.theme.theme && this.props.theme.theme.widget_logo && (this.props.theme.theme.widget_logo !== this.state.loadingLogo)) {
      this.setLoadingLogo(this.props.theme.theme.widget_logo);
    }
    if (this.props.store && this.props.store.store && this.props.store.store.widget_logo && (this.props.store.store.widget_logo !== this.state.loadingLogo)) {
      this.setLoadingLogo(this.props.store.store.widget_logo);
    }
  }

  componentWillUnmount() {
    history.unlisten();
  }
}

function mapStateToProps(state) {
  const { alert, FAQs, FAQsOAI, products, product, qrs, actions, clients, orders, users, integrations, csvstatus, paymentMethods, session, promos, logs, theme, store } = state;
  const { loggingIn, user } = state.authentication;
  const { registering } = state.registration;
  return {
    alert,
    loggingIn,
    user,
    clients,
    orders,
    products,
    product,
    promos,
    users,
    registering,
    integrations,
    csvstatus,
    paymentMethods,
    FAQs,
    FAQsOAI,
    session,
    logs,
    theme,
    store,
    qrs,
    actions
  };
}

export default connect(mapStateToProps)(withStyles(styles)(App));