import { userConstants } from '../_constants';

export function users(state = {}, action) {
  switch (action.type) {
    case userConstants.REGISTER_REQUEST:
      return {
        loading: true
      };
    case userConstants.REGISTER_SUCCESS:
      return {
        user: action.user
      };
    case userConstants.REGISTER_FAILURE:
      return {
        error: action.error
      };
      case userConstants.UPDATE_REQUEST:
      return {
        loading: true
      };
    case userConstants.UPDATE_SUCCESS:
      return {
        user: action.user
      };
    case userConstants.UPDATE_FAILURE:
      return {
        error: action.error
      };
    case userConstants.GETALL_REQUEST:
      return {
        loading: true
      };
    case userConstants.GETALL_SUCCESS:
      return {
        users: action.users
      };
    case userConstants.GETALL_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}

export function actions(state = {}, action) {
  switch (action.type) {
    case userConstants.ACTIONS_REQUEST:
      return {
        loading: true
      };
    case userConstants.ACTIONS_SUCCESS:
      return {
        actions: action.actions
      };
    case userConstants.ACTIONS_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}

export function integrations(state = {}, action) {
  switch (action.type) {
    case userConstants.GETINTEGRATIONS_REQUEST:
      return {
        loading: true
      };
    case userConstants.GETINTEGRATIONS_SUCCESS:
      return {
        integrations: action.integrations
      };
    case userConstants.GETINTEGRATIONS_FAILURE:
      return {
        error: action.error
      };
    case userConstants.SETINTEGRATIONS_REQUEST:
      return {
        loading: true
      };
    case userConstants.SETINTEGRATIONS_SUCCESS:
      return {
        integrations: action.integrations
      };
    case userConstants.SETINTEGRATIONS_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}

export function logs(state = {}, action) {
  switch (action.type) {
    case userConstants.GETLOGS_REQUEST:
      return {
        loading: true
      };
    case userConstants.GETLOGS_SUCCESS:
      return {
        logs: action.logs
      };
    case userConstants.GETLOGS_FAILURE:
      return {
        error: action.error
      };
    case userConstants.SETLOGS_REQUEST:
      return {
        loading: true
      };
    case userConstants.SETLOGS_SUCCESS:
      return {
        logs: action.logs
      };
    case userConstants.SETLOGS_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}

export function FAQsOAI(state = {}, action) {
  switch (action.type) {
    case userConstants.GETFAQOAI_REQUEST:
      return {
        loading: true
      };
    case userConstants.GETFAQOAI_SUCCESS:
      return {
        FAQsOAI: action.FAQsOAI
      };
    case userConstants.GETFAQOAI_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}

export function FAQs(state = {}, action) {
  switch (action.type) {
    case userConstants.GETFAQ_REQUEST:
      return {
        loading: true
      };
    case userConstants.GETFAQ_SUCCESS:
      return {
        FAQs: action.FAQs
      };
    case userConstants.GETFAQ_FAILURE:
      return {
        error: action.error
      };
    case userConstants.SETFAQ_REQUEST:
      return {
        loading: true
      };
    case userConstants.SETFAQ_SUCCESS:
      return {
        FAQs: action.FAQs
      };
    case userConstants.SETFAQ_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}

export function theme(state = {}, action) {
  switch (action.type) {
    case userConstants.GETTHEME_REQUEST:
      return {
        loading: true
      };
    case userConstants.GETTHEME_SUCCESS:
      return {
        theme: action.theme
      };
    case userConstants.GETTHEME_FAILURE:
      return {
        error: action.error
      };
    case userConstants.SETTHEME_REQUEST:
      return {
        loading: true
      };
    case userConstants.SETTHEME_SUCCESS:
      return {
        theme: action.theme
      };
    case userConstants.SETTHEME_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}

export function promos(state = {}, action) {
  switch (action.type) {
    case userConstants.GETPROMOS_REQUEST:
      return {
        loading: true
      };
    case userConstants.GETPROMOS_SUCCESS:
      return {
        promos: action.promos
      };
    case userConstants.GETPROMOS_FAILURE:
      return {
        error: action.error
      };
    case userConstants.SETPROMOS_REQUEST:
      return {
        loading: true
      };
    case userConstants.SETPROMOS_SUCCESS:
      return {
        promos: action.promos
      };
    case userConstants.SETPROMOS_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}

export function qrs(state = {}, action) {
  switch (action.type) {
    case userConstants.GETQRS_REQUEST:
      return {
        loading: true
      };
    case userConstants.GETQRS_SUCCESS:
      return {
        qrs: action.qrs
      };
    case userConstants.GETQRS_FAILURE:
      return {
        error: action.error
      };
    case userConstants.SETQRS_REQUEST:
      return {
        loading: true
      };
    case userConstants.SETQRS_SUCCESS:
      return {
        qrs: action.qrs
      };
    case userConstants.SETQRS_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}

export function paymentMethods(state = {}, action) {
  switch (action.type) {
    case userConstants.GETPM_REQUEST:
      return {
        loading: true
      };
    case userConstants.GETPM_SUCCESS:
      return {
        paymentMethods: action.paymentMethods
      };
    case userConstants.GETPM_FAILURE:
      return {
        error: action.error
      };
    case userConstants.SETPM_REQUEST:
      return {
        loading: true
      };
    case userConstants.SETPM_SUCCESS:
      return {
        paymentMethods: action.paymentMethods
      };
    case userConstants.SETPM_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}

export function csvstatus(state = {}, action) {
  switch (action.type) {
    case userConstants.UPLOADCSV_REQUEST:
      return {
        loading: true
      };
    case userConstants.UPLOADCSV_SUCCESS:
      return {
      };
    case userConstants.UPLOADCSV_FAILURE:
      return {
        error: action.error
      };
    case userConstants.GETCSVSTATUS_REQUEST:
      return {
        loading: true
      };
    case userConstants.GETCSVSTATUS_SUCCESS:
      return {
        csvstatus: action.csvstatus
      };
    case userConstants.GETCSVSTATUS_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}

export function session(state = {}, action) {
  switch (action.type) {
    case userConstants.SESSION_REQUEST:
      return {
        loading: true
      };
    case userConstants.SESSION_SUCCESS:
      return {
        session: action.session
      };
    case userConstants.SESSION_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}