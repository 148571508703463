/* eslint-disable react/jsx-no-bind */
import React from 'react';
import PropTypes from 'prop-types';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import withStyles from '@material-ui/core/styles/withStyles';
import { Typography, Tooltip } from '@material-ui/core';




// eslint-disable-next-line no-unused-vars
const styles = theme => ({
    fullWidth: {
        minWidth: '30px',
        width: '160px',
        'margin-bottom': '-10px',
        'margin-top': '-13px',
    },
    inline: {
        display: 'inline-flex',
        'align-items': 'center',
        height: '45px',
        width: '100%'
    },
    link: {
        "&:hover": {
            cursor: 'pointer',
        },
    },
});

class Notifications extends React.PureComponent {

    static propTypes = {
        classes: PropTypes.object,
        listWarning: PropTypes.array,
        listOk: PropTypes.array,
        listInfo: PropTypes.array,
        listError: PropTypes.array,
        history: PropTypes.object,
        update: PropTypes.number,
    }

    state = {
        listWarning: [],
        listOk: [],
        listInfo: [],
        listError: [],
        update: 0,
    }

    update = () => {
        this.setState({
            listWarning: this.props.listWarning,
            listOk: this.props.listOk,
            listInfo: this.props.listInfo,
            listError: this.props.listError,
            history: this.props.history,
            update: this.props.update + 1,
        })
    }

    render() {
        // eslint-disable-next-line no-unused-vars
        const { classes } = this.props;
        const { listWarning, listOk, listInfo, listError } = this.state;

        return (
            <Stack sx={{ width: '98%', 'padding': '10px 0px 0px 30px' }} spacing={1}>
                {listError.map((notif, index) => {
                    return (
                        <Collapse in={notif.open} key={'a' + index} >
                            <Alert severity="error"
                                variant="filled"
                                action={
                                    <IconButton
                                        aria-label="close"
                                        color="inherit"
                                        size="small"
                                        onClick={() => {
                                            notif['open'] = false;
                                            this.forceUpdate();
                                        }}
                                    >
                                        <CloseIcon fontSize="inherit" />
                                    </IconButton>
                                }

                            >
                                <Tooltip key={`notiff-a-${index}`} title={notif.desc} placement="right" arrow>

                                    <Typography variant="body1" color="inherit"
                                        onClick={
                                            () => {
                                                if (notif.link)
                                                    this.props.history.push(notif.link);
                                            }
                                        }
                                        className={classes.link}
                                    >
                                        <b>{notif.title}</b>
                                    </Typography>
                                </Tooltip>
                            </Alert>
                        </Collapse>
                    )
                })}

                {listWarning.map((notif, index) => {
                    return (
                        <Collapse in={notif.open} key={'b' + index}>
                            <Alert severity="warning"
                                variant="filled"
                                action={
                                    <IconButton
                                        aria-label="close"
                                        color="inherit"
                                        size="small"
                                        onClick={() => {
                                            notif['open'] = false;
                                            this.forceUpdate();
                                        }}
                                    >
                                        <CloseIcon fontSize="inherit" />
                                    </IconButton>
                                }

                            >
                                <Tooltip key={`notiff-b-${index}`} title={notif.desc} placement="right" arrow>

                                    <Typography variant="body1" color="inherit"
                                        onClick={
                                            () => {
                                                if (notif.link)
                                                    this.props.history.push(notif.link);
                                            }
                                        }
                                        className={classes.link}
                                    >
                                        <b>{notif.title}</b>
                                    </Typography>
                                </Tooltip>
                            </Alert>
                        </Collapse>
                    )
                })}

                {listInfo.map((notif, index) => {
                    return (
                        <Collapse in={notif.open} key={'c' + index}>
                            <Alert severity="info"
                                variant="filled"
                                action={
                                    <IconButton
                                        aria-label="close"
                                        color="inherit"
                                        size="small"
                                        onClick={() => {
                                            notif['open'] = false;
                                            this.forceUpdate();
                                        }}
                                    >
                                        <CloseIcon fontSize="inherit" />
                                    </IconButton>
                                }
                            // sx={{height:'30px'}}

                            >
                                <Tooltip key={`notiff-c-${index}`} title={notif.desc} placement="right" arrow>

                                    <Typography variant="body1" color="inherit"
                                        onClick={
                                            () => {
                                                if (notif.link)
                                                    this.props.history.push(notif.link);
                                            }
                                        }
                                        className={classes.link}
                                    >
                                        <b>{notif.title}</b>
                                    </Typography>
                                </Tooltip>
                            </Alert>
                        </Collapse>
                    )
                })}

                {listOk.map((notif, index) => {
                    return (
                        <Collapse in={notif.open} key={'d' + index}>
                            <Alert severity="success"
                                variant="filled"
                                action={
                                    <IconButton
                                        aria-label="close"
                                        color="inherit"
                                        size="small"
                                        onClick={() => {
                                            notif['open'] = false;
                                            this.forceUpdate();
                                        }}
                                    >
                                        <CloseIcon fontSize="inherit" />
                                    </IconButton>
                                }

                            >
                                <Tooltip key={`notiff-d-${index}`} title={notif.desc} placement="right" arrow>

                                    <Typography variant="body1" color="inherit"
                                        onClick={
                                            () => {
                                                if (notif.link)
                                                    this.props.history.push(notif.link);
                                            }
                                        }
                                        className={classes.link}
                                    >
                                        <b>{notif.title}</b>
                                    </Typography>
                                </Tooltip>
                            </Alert>
                        </Collapse>
                    )
                })}
                {/* {this.props.update} */}
            </Stack>
        );
    }

    componentDidMount() {
        this.update();
    }

    componentDidUpdate(prevProps) {
        if (this.props.update !== prevProps.update) {
            this.update();
        }
    }
}

Notifications.defaultProps = {
    disabled: false,
    list: [],
    listWarning: [],
    listOk: [],
    listInfo: [],
    listError: [],
    update: 0,
};

export default (withStyles(styles)(Notifications));