import React from 'react';
import PropTypes from 'prop-types';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import SelectM from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import withStyles from '@material-ui/core/styles/withStyles';

// eslint-disable-next-line no-unused-vars
const styles = theme => ({
    alert: {
        color: '#f44336',
    },
    formControl: {
        minWidth: 100,
        width: '100%'
    },
    main: {
        'margin-top': '16px',
        'margin-bottom': '8px',
    }
});

class Select extends React.PureComponent {

    static propTypes = {
        //  True if the form was submited
        submitted: PropTypes.bool,

        // The label to show over the input
        id: PropTypes.string,

        // The label to show over the input
        label: PropTypes.string,

        // The name of the variable to update
        name: PropTypes.string,

        // The text to show in the placeholder
        placeholder: PropTypes.string,

        // The value to update
        value: PropTypes.string,
        disabled: PropTypes.bool,

        // The options listo to show in the select
        options: PropTypes.array,

        // The error message to show
        errorMessage: PropTypes.string,

        // The function to call when input changes
        onChange: PropTypes.func.isRequired,
        onClick: PropTypes.func,
        classes: PropTypes.object,
        inputLabelProps: PropTypes.string,
    }

    render() {
        const { id, submitted, label, name, value, disabled, placeholder, options, errorMessage, onChange, onClick, inputLabelProps, classes } = this.props;
        return (
            <FormControl className={classes.formControl}>
                <InputLabel shrink className={inputLabelProps} htmlFor={`${name}-label-placeholder`}>{label}</InputLabel>
                <SelectM
                    disabled={disabled}
                    id={id}
                    value={value}
                    onChange={onChange}
                    onClick={onClick}
                    input={<Input name={name} id={`${name}-label-placeholder`} />}
                    name={name}
                    displayEmpty
                    className={classes.main}
                >
                    <MenuItem value="" disabled>{placeholder}</MenuItem>
                    {options.map((options, index) => (
                        <MenuItem key={index} value={options.key}>{options.value}</MenuItem>
                    ))}
                </SelectM>
                {submitted && !value &&
                    <div className={classes.alert}>{errorMessage}</div>
                }
            </FormControl>
        );
    }
}

Select.defaultProps = {
    submitted: false,
    id: 'ninguno',
    disabled: false,
    placeholder: '',
    label: '',
    name: '',
    value: '',
    options: []
};

export default (withStyles(styles)(Select));