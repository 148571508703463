/* eslint-disable react/jsx-no-bind */
import React from 'react';
import PropTypes from 'prop-types';
import {
    Grid, Button, Accordion, AccordionDetails, AccordionSummary,
    Typography, Tooltip, MenuItem, InputLabel
} from '@material-ui/core';
import Select from '@material-ui/core/Select';
import Input from '../_components/Input';
import withStyles from '@material-ui/core/styles/withStyles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddCircle from '@material-ui/icons/AddCircle';
// import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import PlaylistAddCheckCircleIcon from '@mui/icons-material/PlaylistAddCheckCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import FormControl from '@material-ui/core/FormControl';
import { productActions } from '../_actions';
import { dicUnits } from '../_helpers/utils';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell1, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import { userActions } from '../_actions';
import DescriptionIcon from '@material-ui/icons/DescriptionOutlined';
import PublishIcon from '@material-ui/icons/Publish';
import ShoppingCartOutlined from '@material-ui/icons/ShoppingCartOutlined';
import Cached from '@material-ui/icons/Cached';
import { convertDate } from '../_helpers';
import TextInputPopUp from '../_components/TextInputPopUp';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import Chip from '@mui/material/Chip';
import Select2 from '@mui/material/Select';


// eslint-disable-next-line no-unused-vars
const styles = theme => ({
    MuiAccordionroot: {
        "&.MuiAccordion-root.Mui-expanded": {
            margin: '0px 0'
        },
    },
    MuiAccordionrootSummary: {
        height: '40px',
        'min-height': '40px',
    },
    searchIcon: {
        float: 'left',
        'font-size': '22px !important',
        'margin-right': '10px',
    },
    filterList: {
        'padding-left': '7px',
        'padding-top': '8px',
    },
    miniInput: {
        width: '80px',
    },
    miniSelect: {
        width: '80px',
        'margin-bottom': '10px',
        'margin-top': '16px',
    },
    cellButton: {
        padding: '0px',
        margin: '0px',
        'min-width': '0px',
    },
    link: {
        textDecoration: 'none',
        cursor: 'pointer',
        "&:hover": {
            color: '#ee8d2d',
        },
    },
    icoRed: {
        color: '#ff0000',
    },
    icoGreen: {
        color: '#02ff00',
    },
    icoYellow: {
        color: '#ffed00',
    },
    icoBlack: {
        color: '#000',
    },
});

let click = 0;

const StyledTableRow = styled(TableRow)(() => ({
    backgroundColor: '#fff',
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));
const StyledTableCell = styled(TableCell1)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

class AddProduct extends React.PureComponent {

    static propTypes = {
        classes: PropTypes.object,
        dispatch: PropTypes.func,
        list: PropTypes.array,
        syncAC: PropTypes.func,
        user: PropTypes.object,
        csvstatus: PropTypes.object,
        products: PropTypes.oneOfType([
            PropTypes.array,
            PropTypes.object
        ]),
    }

    state = {
        expanded: false,
        submitted: false,
        name: '',
        brand: '',
        kind: '',
        price: '',
        unit: 'unit',
        tags: '',
        desc: '',
        saved: false,
        toSave: false,
        tagList: [],
        tagSelected: [],
        saved2: false,
        toSave2: false,
    }

    deactivateTooltip = () => {
        setTimeout(() => {
            this.setState({
                saved: false,
            });
        }, 4000)
    }

    deactivateTooltip2 = () => {
        setTimeout(() => {
            this.setState({
                saved2: false,
            });
        }, 4000)
    }

    handleExpand = (event) => {
        event.preventDefault();
        this.setState({
            expanded: !this.state.expanded,
        });
    }

    handleChange = (event) => {
        event.preventDefault();
        this.setState({
            [event.target.name]: event.target.value,
            toSave: true,
        });
    }

    handleSubmit = event => {
        event.preventDefault();
        this.setState({ submitted: true });
        const { name, brand, kind, price, unit, tags, desc } = this.state;

        const { dispatch } = this.props;

        if (name && kind) {
            dispatch(productActions.create({
                "brand": {
                    "name": brand
                },
                "kind": {
                    "name": kind
                },
                "name": name,
                "quantity_unit": unit,
                "price_per_unit": price,
                "description": desc,
                "tags": tags.split(','),
            }), 'Producto creado');
            this.props.list.push({
                "brand": {
                    "name": brand
                },
                "kind": {
                    "name": kind
                },
                "name": name,
                "quantity_unit": unit,
                "price_per_unit": price,
                "description": desc,
                "tags": tags.split(','),
                active: true,
                brandname: brand,
                curr_symbol: "usd",
                id: "0",
                kindname: kind,
                new_price: "",
                original_id: null,
            });
            this.setState({
                name: '',
                brand: '',
                kind: '',
                price: '',
                unit: 'unit',
                tags: '',
                desc: '',
                toSave: false,
                saved: true,
            });
            this.deactivateTooltip();
        }
    }

    handleSubmit2 = event => {
        event.preventDefault();
        // const { dispatch } = this.props;

        // if (name && kind) {
        //     dispatch(productActions.create({
        //         "brand": {
        //             "name": brand
        //         },
        //         "kind": {
        //             "name": kind
        //         },
        //         "name": name,
        //         "quantity_unit": unit,
        //         "price_per_unit": price,
        //         "description": desc,
        //         "tags": tags.split(','),
        //     }), 'Producto creado');
        //     this.props.list.push({
        //         "brand": {
        //             "name": brand
        //         },
        //         "kind": {
        //             "name": kind
        //         },
        //         "name": name,
        //         "quantity_unit": unit,
        //         "price_per_unit": price,
        //         "description": desc,
        //         "tags": tags.split(','),
        //         active: true,
        //         brandname: brand,
        //         curr_symbol: "usd",
        //         id: "0",
        //         kindname: kind,
        //         new_price: "",
        //         original_id: null,
        //     });
        this.setState({
            toSave2: false,
            saved2: true,
        });
        this.deactivateTooltip2();
    }


    handleCSVPrices = (event) => {
        const { dispatch } = this.props;
        this.props.syncAC([
            {
                "field": "30",
                "value": new Date().toISOString(),
            }
        ], this.props.user.user);
        dispatch(userActions.uploadPricesCSV(event.target.files[0], 'Actualización de precios enviada correctamente'));
        event.target.value = ''
    }

    handleCSVList = (event) => {
        const { dispatch } = this.props;
        this.props.syncAC([
            {
                "field": "30",
                "value": new Date().toISOString(),
            }
        ], this.props.user.user);
        dispatch(userActions.uploadListCSV(event.target.files[0], 'Actualización de lista de productos enviada correctamente'));
        event.target.value = ''
    }

    handleCSVUpdate = (event) => {
        const { dispatch } = this.props;
        this.props.syncAC([
            {
                "field": "30",
                "value": new Date().toISOString(),
            }
        ], this.props.user.user);
        dispatch(userActions.uploadUpdateCSV(event.target.files[0], 'Actualización de lista de productos enviada correctamente'));
        event.target.value = ''
    }

    handleCSVDelete = (event) => {
        const { dispatch } = this.props;
        this.props.syncAC([
            {
                "field": "30",
                "value": new Date().toISOString(),
            }
        ], this.props.user.user);
        dispatch(userActions.uploadDeleteCSV(event.target.files[0], 'Lista de productos a eliminar enviada correctamente'));
        event.target.value = ''
    }

    handleCSVStock = (event) => {
        const { dispatch } = this.props;
        this.props.syncAC([
            {
                "field": "31",
                "value": new Date().toISOString(),
            }
        ], this.props.user.user);
        dispatch(userActions.uploadStockCSV(event.target.files[0], 'Actualización de stock enviada correctamente'));
        event.target.value = ''
    }

    handleGetStockCSV = () => {
        const { dispatch } = this.props;
        dispatch(userActions.getStockCSV());
    }

    handleCloseCsvDialog = () => {
        this.setState({
            csvDialog: false,
            csvDialogText: '',
        });
    }

    getCsvLogs = (kind) => {
        let result = [];
        if (this.props.csvstatus && this.props.csvstatus.csvstatus && this.props.csvstatus.csvstatus.length > 0) {
            this.props.csvstatus.csvstatus.forEach(status => {
                if (status.kind === kind) {
                    result.push(status);
                }
            });
        }
        return result;
    }

    convertToLog = (array) => {
        let resultText = '';
        array.forEach(status => {
            // resultText += `Creado: ${convertDate(status.created_at)}\n`
            resultText += `Actualizado: ${convertDate(status.updated_at)}\n`
            resultText += `Tipo: ${status.kind}\n`
            resultText += `Status: ${status.status}\n`
            // resultText += `RutaArchivo: ${status.filepath}\n`
            resultText += `Error: ${status.error_msg}\n\n`
        });
        return resultText;
    }

    getLastStatus = (array) => {
        if (array.length < 1)
            return 'white';
        if (array[0].status === 'complete')
            return 'green';
        if (array[0].status === 'failed')
            return 'red';
        if (array[0].status === 'pending' || array[0].status === 'processing')
            return 'yellow';
    }

    renderCSV = (kind) => {
        const logs = this.getCsvLogs(kind);
        const stat = this.getLastStatus(logs);
        const txt = this.convertToLog(logs);

        return (
            <Button classes={{ root: this.props.classes.cellButton }}
                component="label"
                onClick={() => {
                    this.setState({
                        csvDialog: true,
                        csvDialogText: txt,
                    });
                }}
            >
                <DescriptionIcon
                    classes={
                        ((stat === 'red') ? { root: this.props.classes.icoRed } :
                            (stat === 'green') ? { root: this.props.classes.icoGreen } :
                                (stat === 'yellow') ? { root: this.props.classes.icoYellow } :
                                    { root: this.props.classes.cellFAQ })
                    }
                />
            </Button>
        )
    }

    renderTags = () => {
        const products = this.props.products.products;
        let tags = [];
        if (products) {
            products.forEach((elem) => {
                elem.tags.forEach(tag => {
                    tags.push(tag);
                })
            });
        }
        tags = [...new Set(tags)];
        tags.sort();
        this.setState({
            tagList: tags,
        })
    }

    // ["h1","h2","h3","h4","h5","h6","subtitle1","subtitle2","body1","body2","caption","button","overline","srOnly","inherit"].

    render() {
        // eslint-disable-next-line no-unused-vars
        const { classes } = this.props;
        const { expanded, tagList, submitted, toSave, saved, toSave2, saved2, name, brand, kind, price, unit, tags, desc, csvDialog, tagSelected } = this.state;

        return (
            <div>
                <Accordion expanded={expanded} className={classes.MuiAccordionroot}>
                    <AccordionSummary className={classes.MuiAccordionrootSummary} onClick={this.handleExpand}
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel-content"
                        id="panel-header"
                    >
                        <Typography color={((click !== 1 || !this.state.expanded) ? 'primary' : 'secondary')} onClick={(event) => {
                            event.preventDefault();
                            click = 1
                        }} variant="button"><AddCircle className={classes.searchIcon} />Agregar</Typography>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <Typography color={((click !== 2 || !this.state.expanded) ? 'primary' : 'secondary')} onClick={(event) => {
                            event.preventDefault();
                            click = 2
                        }} variant="button"><PlaylistAddCheckCircleIcon className={classes.searchIcon} />Actualizar</Typography>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <Typography color={((click !== 3 || !this.state.expanded) ? 'primary' : 'secondary')} onClick={(event) => {
                            event.preventDefault();
                            click = 3
                        }} variant="button"><RemoveCircleIcon className={classes.searchIcon} />Modificar</Typography>
                    </AccordionSummary>
                    {(click === 1) &&
                        <AccordionDetails>
                            <div>
                                <FormControl onSubmit={this.handleSubmit} >
                                    <Grid container direction="column">
                                        <Grid container spacing={1}>
                                            <Grid item key={`input-key1`}>
                                                <Input
                                                    id={`input-1`}
                                                    type='text'
                                                    disableAuto={true}
                                                    name={`brand`}
                                                    value={brand}
                                                    label={'Marca'}
                                                    onChange={this.handleChange}
                                                />
                                            </Grid>
                                            <Grid item key={`input-key2`}>
                                                <Input
                                                    submitted={submitted}
                                                    id={`input2`}
                                                    type='text'
                                                    disableAuto={true}
                                                    name={`kind`}
                                                    value={kind}
                                                    label={'Tipo'}
                                                    onChange={this.handleChange}
                                                    errorMessage="Por favor ingrese un tipo valido"
                                                />
                                            </Grid>
                                            <Grid item key={`input-key3`}>
                                                <Input
                                                    submitted={submitted}
                                                    id={`input3`}
                                                    type='text'
                                                    disableAuto={true}
                                                    name={`name`}
                                                    value={name}
                                                    label={'Nombre'}
                                                    onChange={this.handleChange}
                                                    errorMessage="Por favor ingrese un nombre valido"
                                                />
                                            </Grid>
                                            <Grid item key={`input-key4`}>
                                                <Input
                                                    id={`input4`}
                                                    type='number'
                                                    disableAuto={true}
                                                    name={`price`}
                                                    value={price}
                                                    label={'Precio'}
                                                    onChange={this.handleChange}
                                                    className={classes.miniInput}
                                                />
                                            </Grid>
                                            <Grid item key={`input-key5`}>
                                                <FormControl className={classes.miniSelect}>
                                                    <InputLabel htmlFor="demo-controlled-open-select">Unidad</InputLabel>
                                                    <Select
                                                        value={unit}
                                                        onChange={this.handleChange}
                                                        name='unit'
                                                        inputProps={{
                                                            name: 'unit',
                                                            id: `input5`
                                                        }}
                                                    >
                                                        <MenuItem value={'unit'}>{dicUnits('unit')}</MenuItem>
                                                        <MenuItem value={'kg'}>{dicUnits('kg')}</MenuItem>
                                                        <MenuItem value={'dozen'}>{dicUnits('dozen')}</MenuItem>
                                                        <MenuItem value={'meter'}>{dicUnits('meter')}</MenuItem>
                                                        <MenuItem value={'gr'}>{dicUnits('gr')}</MenuItem>
                                                        <MenuItem value={'lt'}>{dicUnits('lt')}</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item key={`input-key6`}>
                                                <Input
                                                    id={`input6`}
                                                    type='text'
                                                    name={`tags`}
                                                    disableAuto={true}
                                                    value={tags}
                                                    label={'Tags (sep. por comas)'}
                                                    onChange={this.handleChange}
                                                />
                                            </Grid>
                                            <Grid item key={`input-key8`}>
                                                <Input
                                                    id={`input8`}
                                                    type='text'
                                                    name={`desc`}
                                                    disableAuto={true}
                                                    value={desc}
                                                    label={'Descripcion'}
                                                    onChange={this.handleChange}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={1}>
                                            <Grid item >
                                                <br />
                                                <Tooltip open={toSave || saved} title={(toSave ? 'Por favor, presione para guardar los cambios' : 'Cambios guardados!')} placement="right" arrow>
                                                    <span>
                                                        <Button variant="contained" color="primary" onClick={this.handleSubmit} disabled={!toSave}>Agregar</Button>
                                                    </span>
                                                </Tooltip>
                                            </Grid>
                                        </Grid >
                                    </Grid>
                                </FormControl>
                            </div>
                        </AccordionDetails >
                    }
                    {(click === 2) &&
                        <AccordionDetails>
                            <div>
                                <Typography
                                    // className={classes.dividerInset}
                                    // display="block"
                                    component={'span'}
                                    variant="h2"
                                    color="primary"
                                >
                                    Acciones y plantillas para operar sobre la lista de productos.
                                </Typography>
                                <br /><br />
                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableCell>Función masiva</StyledTableCell>
                                                <StyledTableCell>Modelo</StyledTableCell>
                                                <StyledTableCell>Descripción</StyledTableCell>
                                                <StyledTableCell>Subir archivo</StyledTableCell>
                                                <StyledTableCell>Estado&nbsp;&nbsp;
                                                    <Button classes={{ root: this.props.classes.cellButton }}
                                                        component="label"
                                                        onClick={() => {
                                                            const { dispatch } = this.props;
                                                            dispatch(userActions.getCSVStatusStore());
                                                        }}
                                                    >
                                                        <Tooltip title="Refrescar estado">
                                                            <Cached color="secondary" />
                                                        </Tooltip>
                                                    </Button>
                                                </StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <StyledTableRow key={0}>
                                                <StyledTableCell>Agregar o actualizar productos</StyledTableCell>
                                                <StyledTableCell>
                                                    <a href="modelolistado.csv" target="_blank" className={classes.link}>
                                                        <Typography
                                                            // className={classes.dividerInset}
                                                            // display="block"
                                                            component={'span'}
                                                            variant="body1"
                                                            color="primary"
                                                            className={classes.link}
                                                        >
                                                            CSV <DescriptionIcon />
                                                        </Typography>
                                                    </a>
                                                    <br />
                                                    <a href="modelolistado.xlsx" target="_blank" className={classes.link}>
                                                        <Typography
                                                            // className={classes.dividerInset}
                                                            // display="block"
                                                            component={'span'}
                                                            variant="body1"
                                                            color="primary"
                                                            className={classes.link}
                                                        >
                                                            EXCEL <DescriptionIcon />
                                                        </Typography>
                                                    </a>
                                                </StyledTableCell>
                                                <StyledTableCell>Crea productos nuevos o actualiza los que ya existen.<br />
                                                    Para actualizar un producto existente debes usar todos los campos<br />
                                                    y respetar los nombres de columnas del ejemplo.
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    <Button component="label" className={classes.link}>
                                                        <PublishIcon />
                                                        <input
                                                            type="file"
                                                            hidden
                                                            onChange={(event) => {
                                                                // endp /store/products_file
                                                                this.handleCSVList(event);
                                                            }}
                                                        />
                                                    </Button>
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    {this.renderCSV('products')}
                                                </StyledTableCell>
                                            </StyledTableRow>
                                            <StyledTableRow key={1}>
                                                <StyledTableCell>Actualizar precios</StyledTableCell>
                                                <StyledTableCell>
                                                    <a href="modeloprecios.csv" target="_blank" className={classes.link}>
                                                        <Typography
                                                            // className={classes.dividerInset}
                                                            // display="block"
                                                            component={'span'}
                                                            variant="body1"
                                                            color="primary"
                                                            className={classes.link}
                                                        >
                                                            CSV <DescriptionIcon />
                                                        </Typography>
                                                    </a>
                                                    <br />
                                                    <a href="modeloprecios.xlsx" target="_blank" className={classes.link}>
                                                        <Typography
                                                            // className={classes.dividerInset}
                                                            // display="block"
                                                            component={'span'}
                                                            variant="body1"
                                                            color="primary"
                                                            className={classes.link}
                                                        >
                                                            EXCEL <DescriptionIcon />
                                                        </Typography>
                                                    </a>
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    Cambia los precios de productos ya existentes.<br />
                                                    Utiliza la tabla de ejemplo, poniendo el codigo y el nuevo precio del producto.
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    <Button component="label" className={classes.link}>
                                                        <PublishIcon />
                                                        <input
                                                            type="file"
                                                            hidden
                                                            onChange={(event) => {
                                                                // endp /store/product_prices
                                                                this.handleCSVPrices(event);
                                                            }}
                                                        />
                                                    </Button>
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    {this.renderCSV('products-price')}
                                                </StyledTableCell>
                                            </StyledTableRow>
                                            <StyledTableRow key={2}>
                                                <StyledTableCell>Agregar unidades de stock</StyledTableCell>
                                                <StyledTableCell>
                                                    <a href="modelostock.csv" target="_blank" className={classes.link}>
                                                        <Typography
                                                            // className={classes.dividerInset}
                                                            // display="block"
                                                            component={'span'}
                                                            variant="body1"
                                                            color="primary"
                                                            className={classes.link}
                                                        >
                                                            CSV <DescriptionIcon />
                                                        </Typography>
                                                    </a>
                                                    <br />
                                                    <a href="modelostock.xlsx" target="_blank" className={classes.link}>
                                                        <Typography
                                                            // className={classes.dividerInset}
                                                            // display="block"
                                                            component={'span'}
                                                            variant="body1"
                                                            color="primary"
                                                            className={classes.link}
                                                        >
                                                            EXCEL <DescriptionIcon />
                                                        </Typography>
                                                    </a>
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    Agregar cantidades de stock a las ya existentes. <br />
                                                    Descarga aqui el stock actual de todos tus productos (CSV):
                                                    <Button component="label" className={classes.link} onClick={() => this.handleGetStockCSV()}>
                                                        <ShoppingCartOutlined />
                                                    </Button>
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    <Button component="label" className={classes.link}>
                                                        <PublishIcon />
                                                        <input
                                                            type="file"
                                                            hidden
                                                            onChange={(event) => {
                                                                // endp /store/products_stock
                                                                this.handleCSVStock(event);
                                                            }}
                                                        />
                                                    </Button>
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    {this.renderCSV('products-stock')}
                                                </StyledTableCell>
                                            </StyledTableRow>
                                            <StyledTableRow key={3}>
                                                <StyledTableCell>Actualización selectiva de productos</StyledTableCell>
                                                <StyledTableCell>
                                                    <a href="modeloupdate.csv" target="_blank" className={classes.link}>
                                                        <Typography
                                                            // className={classes.dividerInset}
                                                            // display="block"
                                                            component={'span'}
                                                            variant="body1"
                                                            color="primary"
                                                            className={classes.link}
                                                        >
                                                            CSV <DescriptionIcon />
                                                        </Typography>
                                                    </a>
                                                    <br />
                                                    <a href="modeloupdate.xlsx" target="_blank" className={classes.link}>
                                                        <Typography
                                                            // className={classes.dividerInset}
                                                            // display="block"
                                                            component={'span'}
                                                            variant="body1"
                                                            color="primary"
                                                            className={classes.link}
                                                        >
                                                            EXCEL <DescriptionIcon />
                                                        </Typography>
                                                    </a>
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    Actualización de productos ya existentes.<br />
                                                    En esta operación actualiza sólo los campos que te interesan.<br />
                                                    la única columna indispensable es la de ID, <br />
                                                    el resto es opcional según que atributos se quieran actualizar.
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    <Button component="label" className={classes.link}>
                                                        <PublishIcon />
                                                        <input
                                                            type="file"
                                                            hidden
                                                            onChange={(event) => {
                                                                // endp /store/products_update
                                                                this.handleCSVUpdate(event);
                                                            }}
                                                        />
                                                    </Button>
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    {this.renderCSV('products-update')}
                                                </StyledTableCell>
                                            </StyledTableRow>
                                            <StyledTableRow key={4}>
                                                <StyledTableCell>Eliminar productos</StyledTableCell>
                                                <StyledTableCell>
                                                    <a href="modeloeliminar.csv" target="_blank" className={classes.link}>
                                                        <Typography
                                                            // className={classes.dividerInset}
                                                            // display="block"
                                                            component={'span'}
                                                            variant="body1"
                                                            color="primary"
                                                            className={classes.link}
                                                        >
                                                            CSV <DescriptionIcon />
                                                        </Typography>
                                                    </a>
                                                    <br />
                                                    <a href="modeloeliminar.xlsx" target="_blank" className={classes.link}>
                                                        <Typography
                                                            // className={classes.dividerInset}
                                                            // display="block"
                                                            component={'span'}
                                                            variant="body1"
                                                            color="primary"
                                                            className={classes.link}
                                                        >
                                                            EXCEL <DescriptionIcon />
                                                        </Typography>
                                                    </a>
                                                </StyledTableCell>
                                                <StyledTableCell>Elimina productos de forma masiva.</StyledTableCell>
                                                <StyledTableCell>
                                                    <Button component="label" className={classes.link}>
                                                        <PublishIcon />
                                                        <input
                                                            type="file"
                                                            hidden
                                                            onChange={(event) => {
                                                                // endp /store/products_delete
                                                                this.handleCSVDelete(event);
                                                            }}
                                                        />
                                                    </Button>
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    {this.renderCSV('products-delete')}
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <br /> <br />
                                <Typography
                                    // className={classes.dividerInset}
                                    // display="block"
                                    component={'span'}
                                    variant="body1"
                                    color="primary"
                                >
                                    * Instrucciones:
                                </Typography>
                                <br /><br />
                                <Typography
                                    // className={classes.dividerInset}
                                    // display="block"
                                    component={'span'}
                                    variant="body1"
                                    color="primary"
                                >
                                    - Preparar un archivo con el listado de productos y sus atributos correspondientes a cada accion. <br />
                                    - Puedes usar una planilla de MS Excel o CSV previstas en la tabla. En caso de CSV, puedes exportar desde Excel<br />
                                    &nbsp;&nbsp;usando la función Exportar/Cambiar formato de archivo y elegir CSV (comma separated value en UTF8)<br />
                                    - Luego de subir un archivo, puedes refrescar el estado y ver si se subio correctamente. <br />
                                    &nbsp;&nbsp;El color del icono representa el estado, verde correcto, amarillo en proceso, rojo error.<br />
                                    <br />
                                    - Cuidados sobre la planilla:<br />
                                    A. Mantener las hojas en el orden del modelo, quiere decir que la hoja Productos debe ser la primera.<br />
                                    B. En la hoja Productos mantener los títulos de columna en la fila 1 y comenzar en la columna A, tal como figura en el modelo.<br />
                                </Typography>
                            </div>
                        </AccordionDetails>
                    }
                    {(click === 3) &&
                        <AccordionDetails>
                            <div>
                                <Typography
                                    // className={classes.dividerInset}
                                    // display="block"
                                    component={'span'}
                                    variant="h4"
                                    color="primary"
                                >
                                    Eliminar tags en todos los productos.
                                </Typography>
                                <br /><br />
                                <FormControl sx={{ m: 1, width: 300 }}>
                                    <Select2
                                        multiple
                                        name='selectTags'
                                        value={tagSelected}
                                        onChange={(event) => {
                                            const {
                                                target: { value },
                                            } = event;
                                            this.setState({
                                                toSave2: true,
                                                tagSelected: (typeof value === 'string' ? value.split(',') : value),
                                            });
                                        }}
                                        inputProps={{
                                            name: 'selectTags',
                                            id: `selectTags`
                                        }}
                                        input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                                        renderValue={(selected) => (
                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                {selected.map((value) => (
                                                    <Chip key={value} label={value} />
                                                ))}
                                            </Box>
                                        )}
                                    >
                                        <MenuItem disabled value="">
                                            <em>Seleccione un tag</em>
                                        </MenuItem>
                                        {tagList.map((name, index) => (
                                            <MenuItem
                                                key={name + index}
                                                value={name}
                                            >
                                                {name}
                                            </MenuItem>
                                        ))}
                                    </Select2>
                                    <Tooltip open={toSave2 || saved2} title={(toSave2 ? 'Por favor, presione para guardar los cambios' : 'Cambios guardados!')} placement="right" arrow>
                                        <span>
                                            <Button variant="contained" color="primary" onClick={this.handleSubmit2} disabled={!toSave2}>Eliminar</Button>
                                        </span>
                                    </Tooltip>
                                </FormControl>
                            </div>
                        </AccordionDetails>
                    }
                </Accordion >
                <TextInputPopUp
                    open={csvDialog}
                    title="logs"
                    text={"Lista logs upload CSV_______________________________________________________"}
                    handleClose={this.handleCloseCsvDialog}
                    accept={false}
                    textField={this.state.csvDialogText}
                />
            </div>
        );
    }
    componentDidMount() {
        this.renderTags();
    }

    componentDidUpdate() {
    }
}

AddProduct.defaultProps = {
};

export default (withStyles(styles)(AddProduct));