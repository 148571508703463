import React from 'react';
import PropTypes from 'prop-types';
import {
    Grid, Button, Select, InputLabel, MenuItem,
    FormControl, Accordion, AccordionDetails, AccordionSummary,
    Typography
} from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SearchIcon from '@material-ui/icons/Search';
import exportFromJSON from 'export-from-json';
import Input from '../_components/Input';
import { convertDate } from '../_helpers/utils';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';


// eslint-disable-next-line no-unused-vars
const styles = theme => ({
    fullWidth: {
        minWidth: '30px',
        width: '160px',
        'margin-bottom': '-10px',
        'margin-top': '-13px',
    },
    MuiAccordionroot: {
        "&.MuiAccordion-root.Mui-expanded": {
            margin: '0px 0'
        },
    },
    MuiAccordionrootSummary: {
        height: '38px',
        'min-height': '0px',
        "&.Mui-expanded": {
            'min-height': '0px',
        },
    },
    searchIcon: {
        float: 'left',
        'font-size': '30px',
        'margin-right': '10px',
    },
    clearIcon: {
        float: 'left',
        'font-size': '30px',
        'margin-right': '10px',
        'margin-top': '5px',
    },
    filterList: {
        'padding-left': '7px',
        'padding-top': '13px',
    },
    smallbtn: {
        width: '45px',
    },
    inline: {
        display: 'inline-flex',
        'align-items': 'center',
        height: '45px',
        width: '100%'
    },
});

var tableClick = false;

class Filters extends React.PureComponent {

    static propTypes = {
        reset: PropTypes.bool,
        exportXls: PropTypes.bool,
        // dispatch: PropTypes.func,
        // The label to show over the Filters
        classes: PropTypes.object,
        list: PropTypes.array,
        filteredList: PropTypes.array,
        updateList: PropTypes.func,
        fieldList: PropTypes.array,
        onbarfieldlist: PropTypes.array,
        pantalla: PropTypes.string,
        selectedTag: PropTypes.string,
        selectedStatus: PropTypes.string,
    }

    state = {
        expanded: false,
        onbarvalues: [],
        values: [], // element format: {field: string, value: string, combo = [items..]}
        valuesString: [],
        updated: false,
        updatedPredefTag: false,
        updatedPredefStatus: false,
    }

    handleExpand = (event) => {
        event.preventDefault();
        if (!tableClick)
            this.setState({
                expanded: !this.state.expanded,
            });
    }

    handleClear = () => {
        tableClick = true;
        this.setState({
            valuesString: ``,
        })
        this.processData(true);
        this.props.updateList(this.props.list);
        setTimeout(() => { tableClick = false; }, 100);
    }

    handleExport = () => {
        this.processData();
        this.props.updateList(this.props.list);
    }
    handleChange = (event) => {
        event.preventDefault();
        // eslint-disable-next-line react/no-direct-mutation-state
        this.state.values[event.target.name].value = event.target.value;
        // this.forceUpdate();
        this.handleSearch();
    }

    handleChangeOnbar = (event) => {
        event.preventDefault();
        tableClick = true;
        // eslint-disable-next-line react/no-direct-mutation-state
        this.state.onbarvalues[event.target.name].value = event.target.value;
        // this.forceUpdate();
        this.handleSearch(this.state.onbarvalues);
    }

    fuzzySearch = (contentIn, fieldIn, date) => {
        let pass = true;
        if (contentIn && fieldIn) {
            const content = contentIn.normalize("NFD").replace(/[\u0300-\u036f]/g, "").split(/[\s,.!@#$%^&*()_+=:;"'<>?/]+/);
            const field = fieldIn.normalize("NFD").replace(/[\u0300-\u036f]/g, "").split(/[\s,.!@#$%^&*()_+=:;"'<>?/]+/)

            if (date) {
                if (contentIn.toUpperCase().startsWith(fieldIn.toUpperCase())) {
                    pass = true;
                } else pass = false
            } else {
                field.forEach((itemfield) => {
                    let pass2 = false;
                    content.forEach((itemcontent) => {
                        if ((itemcontent.toUpperCase().indexOf(itemfield.toUpperCase())) !== -1) {
                            pass2 = pass2 || true;
                        }
                    });
                    if (pass2) pass = pass && true;
                    else pass = false;
                });
            }
        }
        return pass;
    }

    handleSearch = (valuesIn) => {
        let result = [];
        let resultString1 = '';
        let resultString2 = '';
        let resultString3 = '';
        let values = valuesIn;

        if (!values || values.length === 0)
            values = this.state.values;

        this.props.list.forEach((item) => {
            let pass = true;

            values.forEach((field) => {
                if (field.value !== '') {
                    if (field.text)
                        // if ((item[field.field]) && (item[field.field].toUpperCase().indexOf(field.value.toUpperCase())) !== -1) {
                        //     pass = pass && true;
                        // } else pass = false;
                        if ((item[field.field]) && this.fuzzySearch(item[field.field], field.value)) {
                            pass = pass && true;
                            if (resultString1.indexOf(field.value) === -1)
                                resultString1 += `${field.value} `;
                        } else pass = false;

                    if (field.date)
                        // if ((item[field.field]) && (item[field.field].toUpperCase().indexOf(field.value.toUpperCase())) !== -1) {
                        //     pass = pass && true;
                        // } else pass = false;
                        if ((item[field.field]) && this.fuzzySearch(convertDate(item[field.field]), field.value, true)) {
                            pass = pass && true;
                            if (resultString1.indexOf(field.value) === -1)
                                resultString1 += `${field.value} `;
                        } else pass = false;

                    if (field.combo) {
                        if ((item[field.field]) && (field.value) && (item[field.field].toUpperCase().indexOf(field.value.toUpperCase())) !== -1) {
                            pass = pass && true;
                            if (resultString2.indexOf(field.value) === -1)
                                resultString2 += `${field.value} `;
                        } else pass = false;
                    }

                    if (field.list) {
                        let pass2 = false;
                        item[field.field].forEach((itemfield) => {
                            // if ((itemfield.toUpperCase().indexOf(field.value.toUpperCase())) !== -1) {
                            if (itemfield.toUpperCase() === field.value.toUpperCase()) {
                                pass2 = pass2 || true;
                                if (resultString3.indexOf(field.value) === -1)
                                    resultString3 += `${field.value} `;
                            } else pass2 = pass2 || false;
                        });
                        if (pass2) pass = pass && true;
                        else pass = false;
                    }
                } else pass = pass && true;
            });
            if (pass) result.push(item);
        });
        if (resultString1 || resultString2 || resultString3)
            this.setState({
                valuesString: `- ${resultString1}  ${resultString2}  ${resultString3}`,
            })
        else this.setState({
            valuesString: '',
        })

        this.props.updateList(result);
    }

    processPreTag = () => {
        if (this.props.selectedTag) {
            this.handleSearch();
        }
        this.setState({
            updatedPredefTag: false,
        });
    }

    processPreStatus = () => {
        if (this.props.selectedStatus) {
            this.handleSearch();
        }
        this.setState({
            updatedPredefStatus: false,
        });
    }

    processData = (clean) => {
        const { list, fieldList, onbarfieldlist } = this.props;

        if (fieldList && (fieldList.length > 0) && list && (list.length > 0)) {
            let fl = [];
            fieldList.forEach((field) => {
                let items = [];
                let presentT = false;
                let presentS = false;
                if (field.combo) {
                    list.forEach((elem) => {
                        items.push(elem[field.field]);
                        if (this.props.selectedTag && (elem[field.field] === this.props.selectedTag)) {
                            presentT = true;
                        }
                        if (this.props.selectedStatus && (elem[field.field] === this.props.selectedStatus)) {
                            presentS = true;
                        }

                    });
                    items = [...new Set(items)];
                    items.sort();
                }

                if (field.list) {
                    list.forEach((elem) => {
                        if (elem.tags !== "") {
                            if (!Array.isArray(elem.tags))
                                elem.tags = elem.tags.split(',');

                            if (!Array.isArray(elem.tags))
                                elem.tags = [elem.tags];

                        } else elem.tags = [];

                        elem.tags.forEach(tag => {
                            items.push(tag);
                            if (this.props.selectedTag && tag === this.props.selectedTag) {
                                presentT = true;
                            }
                            if (this.props.selectedStatus && tag === this.props.selectedStatus) {
                                presentS = true;
                            }
                        })
                    });
                    items = [...new Set(items)];
                    items.sort();
                }

                const item = {
                    field: field.field,
                    label: field.label,
                    combos: items,
                    text: field.text,
                    date: field.date,
                    combo: field.combo,
                    list: field.list,
                    value: "",
                }
                fl.push(item);

                if (!clean && (this.props.selectedTag && item.field === 'tags')) {
                    if (presentT) {
                        item.value = this.props.selectedTag;
                        this.setState({
                            updatedPredefTag: true,
                        });
                    } else {
                        this.setState({
                            valuesString: ['Productos disponibles']
                        });
                        // const { dispatch } = this.props;
                        // dispatch(alertActions.success('Promocion o tag no valido'));
                    }
                }
                if (!clean && (this.props.selectedStatus && item.field === 'estado')) {
                    if (presentS) {
                        item.value = this.props.selectedStatus;
                        this.setState({
                            updatedPredefStatus: true,
                        });
                    } else {
                        this.setState({
                            valuesString: ['Productos disponibles']
                        });
                        // const { dispatch } = this.props;
                        // dispatch(alertActions.success('Promocion o tag no valido'));
                    }
                }
            })
            this.setState({
                values: fl,
                updated: true,
            });
        }
        if (onbarfieldlist && (onbarfieldlist.length > 0) && list && (list.length > 0)) {
            let fl = [];
            onbarfieldlist.forEach((field) => {
                let items = [];
                let presentT = false;
                let presentS = false;
                if (field.combo) {
                    list.forEach((elem) => {
                        items.push(elem[field.field]);
                        if (this.props.selectedTag && (elem[field.field] === this.props.selectedTag)) {
                            presentT = true;
                        }
                        if (this.props.selectedStatus && (elem[field.field] === this.props.selectedStatus)) {
                            presentS = true;
                        }

                    });
                    items = [...new Set(items)];
                    items.sort();
                }

                if (field.list) {
                    list.forEach((elem) => {
                        if (elem.tags !== "") {
                            if (!Array.isArray(elem.tags))
                                elem.tags = elem.tags.split(',');

                            if (!Array.isArray(elem.tags))
                                elem.tags = [elem.tags];

                        } else elem.tags = [];

                        elem.tags.forEach(tag => {
                            items.push(tag);
                            if (this.props.selectedTag && tag === this.props.selectedTag) {
                                presentT = true;
                            }
                            if (this.props.selectedStatus && tag === this.props.selectedStatus) {
                                presentS = true;
                            }
                        })
                    });
                    items = [...new Set(items)];
                    items.sort();
                }

                const item = {
                    field: field.field,
                    label: field.label,
                    combos: items,
                    text: field.text,
                    date: field.date,
                    combo: field.combo,
                    list: field.list,
                    value: "",
                }
                fl.push(item);

                if (!clean && (this.props.selectedTag && item.field === 'tags')) {
                    if (presentT) {
                        item.value = this.props.selectedTag;
                        this.setState({
                            updatedPredefTag: true,
                        });
                    } else {
                        this.setState({
                            valuesString: ['Productos disponibles']
                        });
                        // const { dispatch } = this.props;
                        // dispatch(alertActions.success('Promocion o tag no valido'));
                    }
                }
                if (!clean && (this.props.selectedStatus && item.field === 'estado')) {
                    if (presentS) {
                        item.value = this.props.selectedStatus;
                        this.setState({
                            updatedPredefStatus: true,
                        });
                    } else {
                        this.setState({
                            valuesString: ['Productos disponibles']
                        });
                        // const { dispatch } = this.props;
                        // dispatch(alertActions.success('Promocion o tag no valido'));
                    }
                }
            })
            this.setState({
                onbarvalues: fl,
                updated: true,
            });
        }
    }

    handleExport = () => {
        const fileName = (this.props.pantalla ? `datosExportados-${this.props.pantalla}` : 'datosExportados');
        const exportType = exportFromJSON.types.xls;
        const data = this.props.filteredList;
        exportFromJSON({ data, fileName, exportType });
    }


    render() {
        // eslint-disable-next-line no-unused-vars
        const { classes, reset, exportXls } = this.props;
        const { expanded, values, valuesString, onbarvalues } = this.state;

        return (
            <Accordion expanded={expanded} className={classes.MuiAccordionroot}>
                <AccordionSummary className={classes.MuiAccordionrootSummary} onClick={this.handleExpand}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel-content"
                    id="panel-header"
                >
                    <Typography color='secondary' variant="h6">
                        <div className={classes.inline}>
                            <SearchIcon className={classes.searchIcon} />
                            <ExpandMoreIcon className={classes.searchIcon} />
                            {(onbarvalues && onbarvalues.length > 0) && onbarvalues.map((field, index) => {
                                if (field.combos.length > 0) {
                                    return (
                                        <FormControl className={classes.fullWidth}>
                                            <InputLabel htmlFor="">{field.label}</InputLabel>
                                            <Select
                                                // eslint-disable-next-line react/jsx-no-bind
                                                onClick={() => {
                                                    tableClick = true;
                                                }}
                                                // eslint-disable-next-line react/jsx-no-bind
                                                onBlur={() => {
                                                    tableClick = false;
                                                }}
                                                name={`${index}`}
                                                value={this.state.onbarvalues[index].value}
                                                onChange={this.handleChangeOnbar}
                                                inputProps={{
                                                    name: `${index}`,
                                                    id: `select-type-${index}`,
                                                }}
                                            >
                                                {field.combos.map((item, index) => (
                                                    <MenuItem key={index} value={item}>{item}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    )
                                }
                                if (field.text | field.date) {
                                    return (
                                        <Input
                                            className={classes.fullWidth}
                                            disableAuto={true}
                                            key={`input2-${index}`}
                                            id={`input2-${index}`}
                                            name={`${index}`}
                                            value={this.state.onbarvalues[index].value}
                                            label={field.label}
                                            // eslint-disable-next-line react/jsx-no-bind
                                            onClick={() => {
                                                tableClick = true;
                                            }}
                                            // eslint-disable-next-line react/jsx-no-bind
                                            onBlur={() => {
                                                tableClick = false;
                                            }}
                                            onChange={this.handleChangeOnbar}
                                        />
                                    )
                                } else
                                    return (
                                        <Grid item key={`input-key2-${index}`}>
                                        </Grid>
                                    )
                            })}
                            {(onbarvalues && onbarvalues.length > 0) &&
                                <FilterAltOffIcon onClick={this.handleClear}
                                    // eslint-disable-next-line react/jsx-no-bind
                                    onBlur={() => {
                                        tableClick = false;
                                    }}
                                    className={classes.clearIcon} />
                            }
                        </div>
                    </Typography>
                    {(valuesString) && <Typography color='primary' variant="body2" className={classes.filterList} >{valuesString}</Typography>}
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={3}>
                        {(values && values.length > 0) && values.map((field, index) => {
                            if (field.combos.length > 0) {
                                return (
                                    <Grid item xs={6} sm={3} lg={2} key={`select-key-${index}`}>
                                        <FormControl className={classes.fullWidth}>
                                            <InputLabel htmlFor="">{field.label}</InputLabel>
                                            <Select
                                                name={`${index}`}
                                                value={this.state.values[index].value}
                                                onChange={this.handleChange}
                                                inputProps={{
                                                    name: `${index}`,
                                                    id: `select-type-${index}`,
                                                }}
                                            >
                                                {field.combos.map((item, index) => (
                                                    <MenuItem key={index} value={item}>{item}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                )
                            } else {
                                if (field.text | field.date) {
                                    return (
                                        <Grid item xs={6} sm={3} lg={2} key={`input-key-${index}`}>
                                            <Input
                                                className={classes.fullWidth}
                                                disableAuto={true}
                                                id={`input-${index}`}
                                                name={`${index}`}
                                                value={this.state.values[index].value}
                                                label={field.label}
                                                onChange={this.handleChange}
                                            />
                                        </Grid>
                                    )
                                } else
                                    return (
                                        <Grid item key={`input-key-${index}`}>
                                        </Grid>
                                    )
                            }
                        })}
                        <Grid item >
                            {/* <Button variant="contained" color="primary" disabled={disabled} onClick={this.handleSearch}>Buscar</Button> */}
                            {(reset) &&
                                <Button variant="contained" color="primary" className={classes.smallbtn} onClick={this.handleClear}>Limpiar</Button>
                            }
                            &nbsp;
                            {(exportXls) &&
                                <Button variant="contained" color="primary" className={classes.smallbtn} onClick={this.handleExport}>Exportar</Button>
                            }
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion >
        );
    }
    componentDidMount() {
        if (!this.state.updated) {
            this.processData(this);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (!this.state.updated|| (prevProps.selectedStatus !== this.props.selectedStatus)) {
            this.processData();
        }
        if (this.state.updatedPredefTag) {
            this.processPreTag();
        }
        if (this.state.updatedPredefStatus) {
            this.processPreStatus();
        }
    }
}

Filters.defaultProps = {
    disabled: false,
    label: '',
    list: [],
    fieldList: [], // {label: string, combo: bool, field: string}
    reset: true,
    selectedTag: '',
    selectedStatus: ''
};

export default (withStyles(styles)(Filters));