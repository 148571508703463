/* eslint-disable react/jsx-no-bind */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Appbar from '../_components/Appbar';
import withStyles from '@material-ui/core/styles/withStyles';
import {
    Tabs, Tab, AppBar, Typography, Grid, TableCell, TextField, Button,
    Divider, Tooltip, Accordion, AccordionDetails, AccordionSummary,
} from '@material-ui/core';
import Payment from '@material-ui/icons/Payment';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import LocalShipping from '@material-ui/icons/LocalShipping';
// import DescriptionIcon from '@material-ui/icons/DescriptionOutlined';
import { orderActions, userActions, clientActions } from '../_actions';
// import { history } from '../_helpers';
import ResponsiveTable from '../_components/Table';
import TabPanel from '../_components/TabPanel';
import { dicUnits, convertNumber, convertDate } from '../_helpers/utils';
import ImagePopUp from '../_components/ImagePopUp';
import Avatar from '@material-ui/core/Avatar';
import InputAdornment from '@material-ui/core/InputAdornment';
// import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Input from '../_components/Input';
import Fade from '@mui/material/Fade';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';
import Filters from '../_components/Filters';
import Pagination from '@mui/material/Pagination';


// eslint-disable-next-line no-unused-vars
const styles = theme => ({
    texttitle: {
        color: '#ff8700',
    },
    textblue: {
        color: 'blue',
    },
    tabpanelHeader: {
        width: '100%',
        'text-align': 'center',
        'z-index': 0,
    },
    textbox: {
        background: '#eee',
        padding: '25px',
        margin: '10px',
        flex: 1,
        color: 'black',
        // 'margin-top': '-20px',
    },
    tabpanel: {
        width: '100%',
        overflow: 'auto',
        backgroundColor: 'white',
        flexGrow: 1,
    },
    cellRows: {
        color: '#333 !important',
        borderBottom: '1px solid #b7b7b7',
        boxShadow: 'none',
        justifyContent: 'flex-end',
        'text-align': 'end',
        // display: 'inline-flex',
        // 'border-bottom': '0px',
        'padding-top': '2px',
        'padding-bottom': '2px',
    },
    cell: {
        width: '100%',
    },
    table: {
        overflow: 'auto',
    },
    MuiAccordionroot: {
        'backgroundColor': 'rgba(0, 0, 0, 0.09)',
        width: '100%',
        'margin-bottom': "0 !important",
        'margin-top': "0 !important",
    },
    MuiAccordionDetailsroot: {
        display: 'block',

    },
    searchIcon: {
        float: 'left',
        'font-size': '20px',
        'margin-right': '10px',
    },
    divider: {
        'backgroundColor': '#124e5c',
    },
    link: {
        color: '#ff8700',
        textDecoration: 'none',
        'font-size': '12px',
        "&:hover": {
            cursor: 'pointer',
        },
    },
    widgetAddExpand: {
        'z-index': '100',
        'backgroundColor': '#12B16F',
        'border-radius': '20%',
        bottom: '195px',
        height: '130px',
        position: 'fixed',
        right: '2px',
        width: '130px',
        border: '10px solid #fff',
        "&:hover": {
            cursor: 'pointer',
            'backgroundColor': '#62B16F',
        },
    },
    widgetCart: {
        'z-index': '100',
        'backgroundColor': '#025e9f',
        'border-radius': '20%',
        bottom: '96px',
        height: '95px',
        position: 'fixed',
        right: '2px',
        width: '95px',
        border: '10px solid #fff',
        "&:hover": {
            cursor: 'pointer',
            'backgroundColor': '#134859',
        },
    },
    icoButton: {
        // position: 'absolute',
        // left: '-5%',
        // top: '15%',
        color: 'white',
        width: '100%',
        height: '100%'
    },
    ico: {
        'font-size': '30px',
        color: '#fff'
    },
    avatar: {
        width: '60px',
        height: '60px',
    },
    inline: {
        display: 'inline-flex',
        'align-items': 'center',
        height: '45px',
    },
    totalAmount: {
        opacity: '1 !important',
        'backgroundColor': 'white !important',
        color: '#ff8700 !important',
        'font-size': '14px !important',
        "&:hover": {
            cursor: 'none',
        },
    },
    input: {
        'padding-top': '0px',
        'margin-top': '0px',
        'padding-bottom': '7px',
    },
    textUpdate: {
        'min-width': '78px',
        'width': '78px',
    },
    cellButton: {
        position: 'absolute',
        'padding-left': '65px',
    }
});

// var dicOrders = {};
var checkout = null;
var mp = null;
var updatedSession = false;
// var lastPageLocal = true;
var oneTimeWelcome = false;

class OrderStatus extends React.Component {
    static propTypes = {
        classes: PropTypes.object.isRequired,
        // eslint-disable-next-line react/no-unused-prop-types
        history: PropTypes.object,
        dispatch: PropTypes.func,
        match: PropTypes.object,
        setWidget: PropTypes.func,
        orders: PropTypes.object,
        orderId: PropTypes.object,
        session: PropTypes.object,
        error: PropTypes.object,
        updateSessionId: PropTypes.func,
        sessionId: PropTypes.string,
        updateWidgetStyle: PropTypes.func,
    };

    state = {
        selectedOrder: {},
        currentOrder: { items: [] },
        // token: '',
        updated: false,
        curr_symbol: '',
        mpStatus: '',
        mpText: '-',
        value: 0,
        openImagePopUp: false,
        urlImagePopUp: '',
        amount: '',
        totalConcepts: '',
        shippCost: 0,
        total: '',
        conceptsOrder: [],
        toSave: false,
        itemList: [],
        updatedList: [],
        pagelist: [],
        npages: 0,
        npage: 0,
    };

    checkSession = () => {
        const { dispatch } = this.props;
        let welcome = false;
        const exitOrder = localStorage.getItem('exitOrder');

        if (this.props.session.session) {
            if ((this.props.session.session.session_id !== null) && (this.props.session.session.session_id !== 'null')) {
                this.props.updateSessionId(this.props.session.session.session_id);
                const { dispatch } = this.props;
                if (this.props.sessionId) {
                    const log = {
                        "activity_name": "Ingreso resumen pedido",
                        "payload": { 'nota': 'Sesion recuperada' },
                        "session_id": this.props.sessionId
                    };
                    dispatch(clientActions.setClientLog(log));
                }
                const { color_set, widget_logo } = this.props.session.session.store_public_data;
                this.props.updateWidgetStyle(color_set);
                if (oneTimeWelcome) {
                    welcome = 'MENU'
                }

                // this.props.setWidget(false, (lastPageLocal ? false : true), '', welcome, color_set.df_messenger_button_titlebar_text, widget_logo);
                if (!exitOrder) {
                    this.props.setWidget(false, false, '', welcome, color_set.df_messenger_button_titlebar_text, widget_logo);
                    localStorage.removeItem('exitOrder');
                }

                oneTimeWelcome = false;
            }
            dispatch(orderActions.getByIdNoToken(this.props.match.params.token, this.props.sessionId));
            updatedSession = true;
            this.setState({
                updated: false
            });
        }
        if (this.props.session.error) {
            if (this.props.sessionId) {
                dispatch(orderActions.getByIdNoToken(this.props.match.params.token, this.props.sessionId));
                updatedSession = true;
                this.setState({
                    updated: false
                });
            }
            else {
                dispatch(orderActions.getByIdNoToken(this.props.match.params.token, null));
                updatedSession = true;
                this.setState({
                    updated: false
                });
            }
            welcome = false;
            if (oneTimeWelcome) {
                welcome = 'MENU'
            }
            // this.props.setWidget(false, (lastPageLocal ? false : true), '', welcome, '');
            // this.props.setWidget(false, (lastPageLocal ? false : true), '', welcome, color_set.df_messenger_button_titlebar_text, widget_logo);
            if (!exitOrder) {
                this.props.setWidget(false, false, '', welcome, '');
                localStorage.removeItem('exitOrder');

            }
            oneTimeWelcome = false;
        }
    }

    countTimer = (time) => {

        if (time === 0) {
            this.setState({
                token: this.props.match.params.token,
                mpStatus: '',
                mpText: '-',
                updated: false,
            });
            this.checkSession();
            return;
        }
        this.setState({
            mpText: `Procesando ${time}`,
        });
        time = time - 1;
        setTimeout(() => { this.countTimer(time) }, 1000);
    }

    processData = () => {
        const { orders } = this.props;

        let currentOrder = {};
        if (orders && orders.orders && (orders.orders.length > 0)) {
            orders.orders.forEach(order => {
                order.amount = 0;

                order.colorBlue = false;
                order.colorRed = false;
                order.colorViolet = false;

                if (order.status === 'pending') order.estado = 'Pendiente';

                if (order.status === 'preparing') order.estado = 'En proceso';

                if (order.status === 'waiting_delivery') order.estado = 'A despachar';

                if (order.status === 'done') order.estado = 'Entregado';

                if (order.status === 'provisional') order.estado = 'Provisorio';

                if (order.status === 'store_canceled') order.estado = 'Cancelar';

                if (order.status === 'discarded') order.estado = 'Descartado';

                order.items.forEach(item => {
                    item.price = ((item.product_denormalized && item.product_denormalized.price_per_unit) ? item.product_denormalized.price_per_unit : 0);
                    item.total = ((item.revised_price) ? parseFloat(item.revised_price) : ((item.price) ? item.amount * parseFloat(item.price) : 0));
                    item.name = ((item.product_denormalized && item.product_denormalized.name) ? item.product_denormalized.name : "");
                    item.brand = ((item.product_denormalized && item.product_denormalized.brand) ? item.product_denormalized.brand : "");
                    item.kind = ((item.product_denormalized && item.product_denormalized.kind) ? item.product_denormalized.kind : "");
                    item.quantity_unit = dicUnits((item.product_denormalized && item.product_denormalized.quantity_unit) ? item.product_denormalized.quantity_unit : "");

                    if (!(item.total >= 0)) {
                        item.total = 0;
                    }

                    item.color = "";

                    if (item.revised_price) {
                        item.color = 'blue';
                        item.price = '--';
                        item.amount = '--';
                        order.colorBlue = true;
                    }

                    if (item.name === '') {
                        item.name = item.raw_product;
                    }

                    // if ((item.name === '') && (item.revised_price)) {
                    //     item.name = item.raw_product;
                    //     item.price = '';
                    //     item.amount = '';
                    //     item.color = 'violet';
                    //     order.colorViolet = true;
                    // }

                    order.amount += item.total;
                });

                // order.amount = convertNumber(order.amount, this.state.currentOrder.curr_symbol);
                // order.total_price = convertNumber(order.total_price, this.state.currentOrder.curr_symbol);
                currentOrder = order;
            });

            const currOrder = currentOrder.other_concepts

            let concepts = 0;
            currOrder.forEach(item => {
                concepts += +item.amount;
            });
            this.setState({
                total: currentOrder.total_price,
                amount: currentOrder.amount,
                totalConcepts: concepts,
                shippcost: currentOrder.shippcost,
            });

            let mpStatus = '';
            let mpText = '';

            if (currentOrder && currentOrder.payment_method === 'Mercadopago' && currentOrder.payment_data && currentOrder.payment_data.payment_id && currentOrder.payment_data.public_key) {
                if (currentOrder.payment_status === 'required') {
                    mpStatus = 'enabled';
                    mpText = 'Pagar';
                    this.renderMP(currentOrder);
                }
                if (currentOrder.payment_status === 'processing') {
                    mpStatus = 'count';
                    mpText = 'Procesando';
                    this.countTimer(15);
                }
                if (currentOrder.payment_status === 'done') {
                    mpStatus = 'disabled';
                    mpText = 'Pago recibido';
                }
            }

            if (currentOrder && currentOrder.payment_method === 'Mercadopago' && mpStatus === '') {
                console.log('MP error------------------: (payment_data): ', currentOrder.payment_data, '----(payment_status):', currentOrder.payment_status);
            }

            this.setPages(currentOrder.items);
            this.setState({
                currentOrder: currentOrder,
                conceptsOrder: currentOrder.other_concepts,
                updated: true,
                mpText: mpText,
                mpStatus: mpStatus,
                itemList: currentOrder.items,
                updatedList: currentOrder.items,
            });
            // this.forceUpdate();
        }
    }

    onBack = event => {
        event.preventDefault();
        // this.props.history.push('/homeclient');
        window.history.back();
    }

    handleGoToCatalog = (event) => {
        event.preventDefault();
        if (this.props.session && this.props.session.session && this.props.session.session.product_list_token) {
            const { dispatch } = this.props;
            const log = {
                "activity_name": "Lista de productos, click en ver catalogo",
                "payload": null,
                "session_id": this.props.sessionId
            };
            dispatch(clientActions.setClientLog(log));
            this.props.history.push('/productlist/' + this.props.session.session.product_list_token);
        }
        // else if (this.state.oneItemAdd) {
        //     var text = document.querySelector("#df-messenger").shadowRoot.querySelector("div > df-messenger-chat").shadowRoot.querySelector("div > df-messenger-user-input").shadowRoot.querySelector("div > div.input-box-wrapper.valid > input[type=text]");
        //     var text1 = document.querySelector("#df-messenger").shadowRoot.querySelector("div > df-messenger-chat").shadowRoot.querySelector("div > df-messenger-user-input").shadowRoot.querySelector("div > div.input-box-wrapper > input[type=text]")
        //     if (text) text.value = `Ver estado del pedido`;
        //     if (text1) text1.value = `Ver estado del pedido`;
        //     var button = document.querySelector("#df-messenger").shadowRoot.querySelector("div > df-messenger-chat").shadowRoot.querySelector("div > df-messenger-user-input").shadowRoot.querySelector("#sendIcon");
        //     button.dispatchEvent(new Event('click'));
        //     this.props.setWidget(false, false, '', false, 'Nuevo mensaje, haz click para conversar');

        //     setTimeout(() => {
        //         this.runEvent(1);
        //     }, 500);
        // }
    }

    renderComboObs = (item, index) => {
        return (<TableCell key={index} classes={{ root: this.props.classes.cellRows }}>
            {// eslint-disable-next-line react/jsx-no-bind
            }
            <TextField id={item.id} classes={{ root: this.props.classes.cell }} multiline maxRows={3} value={(item.store_observations) ? item.store_observations : ""}
                InputProps={{
                    readOnly: true,
                }} />
        </TableCell>)
    }

    renderMP = (currentOrder) => {
        if (window.MercadoPago && !mp) {
            mp = new window.MercadoPago('TEST-fd8f572f-a022-449f-86fd-46ac62b56ec5', {
                locale: 'es-AR' // The most common are: 'pt-BR', 'es-AR' and 'en-US'
            });
            checkout = mp.checkout({
                preference: {
                    id: currentOrder.payment_data.payment_id,
                },
            });
            var thisob = this;
            var in_dom = document.getElementById('mercadopago-checkout');
            var observer = new MutationObserver(function () {
                if (document.getElementById('mercadopago-checkout')) {
                    if (!in_dom) {
                        thisob.setState({
                            mpText: 'Esperando',
                            mpStatus: 'disabled',
                        });
                    }
                    in_dom = true;
                } else if (in_dom) {
                    in_dom = false;
                    thisob.setState({
                        mpText: 'Procesando',
                        mpStatus: 'count',
                    });
                    thisob.countTimer(15);
                }

            });
            observer.observe(document.body, { childList: true, subtree: true });
        }
    }

    renderImage = (item, index) => {
        var url = (item && item.product_denormalized && item.product_denormalized.image_url) ? item.product_denormalized.image_url : '../images/default.png';
        return (
            <TableCell key={index} classes={{ root: this.props.classes.cellRows }}>
                <Button
                    component="label"
                    onClick={() => {
                        if (item.product_denormalized.image_url) {
                            this.setState({
                                openImagePopUp: true,
                                urlImagePopUp: url,
                            });
                        }
                    }}
                >
                    <Avatar
                        alt="Imagen"
                        src={url}
                        classes={{ root: this.props.classes.avatar }}
                        variant="square" />
                </Button>
            </TableCell>
        )
    }

    a11yProps = (index) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    updateList = (list) => {
        this.setPages(list);
        this.setState({
            updatedList: list,
        })
    }

    handlePageChange = (event, page) => {
        const nitems = 5;
        this.setState({
            pagelist: this.state.updatedList.slice(nitems * (page - 1), nitems * page),
            npage: page
        });
    }

    setPages = (list) => {
        const nitems = 5;
        this.setState({
            pagelist: list.slice(0, 5),
            npages: Math.ceil(list.length / nitems),
            npage: 1
        });
    }

    handleChangeTab = (event, newValue) => {
        this.setState({ value: newValue });
    };

    handleCloseImageDialog = () => {
        this.setState({
            openImagePopUp: false,
            urlImagePopUp: ''
        });
    }

    renderDesc = (concept, index) => {
        const text = (Math.sign(concept.amount) === 1) ? 'Interes' : 'Descuento';
        return (
            <TableCell key={index} classes={{ root: this.props.classes.cellRows }}>
                {text}
            </TableCell>
        );
    }

    renderAmount = (concept, index) => {
        const text = (Math.sign(concept.amount) === -1) ? (concept.amount * -1) : concept.amount;
        // const sign = (Math.sign(concept.amount) === -1) ? -1 : 1;
        return (
            <TableCell key={index} classes={{ root: this.props.classes.cellRows }}>
                {text}
            </TableCell>
        );
    }

    render() {
        const { classes } = this.props;
        const { currentOrder, mpStatus, amount, mpText, value, openImagePopUp, urlImagePopUp,
            totalConcepts, total, shippCost, itemList, updatedList,
            pagelist, npages, npage,
            expandedPad1, expandedPad2 } = this.state;
        let titulo = "RESUMEN DEL PEDIDO";

        return (
            <React.Fragment >
                <Appbar back={true} label={titulo} onBack={this.onBack} xs={true}
                    update={() => {
                        const { dispatch } = this.props;
                        dispatch(orderActions.getByIdNoToken(this.props.match.params.token, this.props.sessionId));
                        updatedSession = true;
                        this.setState({
                            updated: false
                        });
                    }} />
                <Grid
                    item xs={12} md={12}
                    className={classes.tabpanel}
                >
                    <Grid item xs={12} sm={12} container>
                        {/* <Grid item xs={12} sm={6} className={classes.textbox}> */}
                        <Accordion expanded={expandedPad1} className={classes.MuiAccordionroot}>
                            <AccordionSummary className={classes.MuiAccordionrootSummary} onClick={this.handleExpand}
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel-content"
                                id="panel-header"
                            >
                                <div className={classes.inline}>
                                    <Typography variant="h2" color="inherit">
                                        <b>PEDIDO&nbsp;</b>
                                    </Typography>
                                    <Typography variant="h2" color="inherit" className={classes.texttitle}>
                                        <b> # {currentOrder.alphanum_id}</b>
                                    </Typography>
                                    <Typography variant="h2" color="inherit" className={classes.texttitle}>
                                        <b> &nbsp;-&nbsp;{convertNumber(total, (this.state.currentOrder ? this.state.currentOrder.curr_symbol : ''))}</b>
                                    </Typography>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid item className={classes.textbox}>
                                    {/* <div className={classes.inline}>
                                        <Typography variant="h2" color="inherit">
                                            <b>PEDIDO&nbsp;</b>
                                        </Typography>
                                        <Typography variant="h2" color="inherit" className={classes.texttitle}>
                                            <b> # {currentOrder.alphanum_id}</b>
                                        </Typography>
                                    </div> */}
                                    {/* <br /> */}
                                    {currentOrder.original_id &&
                                        <div className={classes.inline}>
                                            <Typography variant="h2" color="inherit">
                                                <b>ID Original&nbsp;</b>
                                            </Typography>
                                            <Typography variant="h2" color="inherit" className={classes.texttitle}>
                                                <b> # {currentOrder.original_id}</b>
                                            </Typography>
                                        </div>
                                    }
                                    <div className={classes.inline}>
                                        <Typography variant="subtitle2" color="inherit">
                                            <b>Total: </b>
                                        </Typography>
                                        <Typography variant="subtitle2" color="inherit" className={classes.texttitle}>
                                            <b> &nbsp;&nbsp;{convertNumber(total, this.state.currentOrder.curr_symbol)}</b>
                                        </Typography>
                                        <Typography variant="subtitle2" color="inherit">
                                            &nbsp;-&nbsp;{`${currentOrder.items.length} items`}
                                        </Typography>
                                    </div>
                                    <br />
                                    <Typography variant="subtitle2" color="inherit">
                                        <b>Estado:</b> <b className={classes.texttitle}>{currentOrder.estado}</b><br /><br />
                                        {`Dirección de entrega: ` + ((currentOrder.delivery) ? currentOrder.delivery_address : 'Retira en sucursal')}<br /><br />
                                        {`Método de pago: ` + ((currentOrder.payment_method) ? currentOrder.payment_method : 'No definido')}<br /><br />
                                        {((currentOrder.payment_info) ? `INFORMACION DE PAGO: ` + currentOrder.payment_info : '')}<br />
                                        {(mpStatus === 'enabled' || mpStatus === 'disabled' || mpStatus === 'count') &&
                                            <Button variant="contained" color="primary"
                                                disabled={(!checkout || mpStatus !== 'enabled')}
                                                id="checkout-btn"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    checkout.open();
                                                    const { dispatch } = this.props;
                                                    if (this.props.sessionId) {
                                                        const log = {
                                                            "activity_name": "Click Mpago, resumen pedido",
                                                            "payload": null,
                                                            "session_id": this.props.sessionId
                                                        };
                                                        dispatch(clientActions.setClientLog(log));
                                                    }
                                                    this.setState({ mpStatus: 'clicked', mpText: 'Ver estado' });
                                                }
                                                }
                                            >
                                                <Payment className={classes.ico} />&nbsp;{mpText}
                                            </Button>
                                        }
                                        {(mpStatus === 'clicked') &&
                                            <Button variant="contained" color="primary"
                                                id="checkout-btn"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    this.setState({
                                                        token: this.props.match.params.token,
                                                        mpStatus: '',
                                                        mpText: '-',
                                                        updated: false,
                                                    });
                                                    this.checkSession();
                                                }
                                                }
                                            >
                                                <Payment className={classes.ico} />&nbsp;{mpText}
                                            </Button>
                                        }
                                    </Typography>
                                </Grid>
                            </AccordionDetails>
                        </Accordion >
                        <Accordion expanded={expandedPad2} className={classes.MuiAccordionroot}>
                            <AccordionSummary className={classes.MuiAccordionrootSummary} onClick={this.handleExpand}
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel-content"
                                id="panel-header"
                            >
                                <b className={classes.texttitle}>{(currentOrder.store) ? currentOrder.store.hashtag : ""}</b>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid item xs={12} sm={6} className={classes.textbox}>
                                    <Typography variant="subtitle2" color="inherit">
                                        <br />
                                        {(currentOrder.store) ? currentOrder.store.name : ""}<br /><br />
                                        {(currentOrder.store) ? currentOrder.store.address : ""}
                                    </Typography>
                                </Grid>
                            </AccordionDetails>
                        </Accordion >
                    </Grid>
                    <AppBar position="static" className={classes.tabpanelHeader}>
                        <Tabs value={value} onChange={this.handleChangeTab}
                            variant="scrollable"
                            scrollButtons="auto"
                            aria-label="scrollable auto tabs example" >
                            <Tab label={`PEDIDO ${convertNumber(amount, this.state.currentOrder.curr_symbol)}`} {...this.a11yProps(0)} />
                            <Tab label={`ENVIO ${convertNumber(shippCost, this.state.currentOrder.curr_symbol)}`} {...this.a11yProps(2)} />
                            <Tab label={`OTROS ${convertNumber(totalConcepts, this.state.currentOrder.curr_symbol)}`} {...this.a11yProps(1)} />
                            <Tab label={`MONTO ${convertNumber(total, this.state.currentOrder.curr_symbol)}`} className={classes.totalAmount} disabled />
                        </Tabs>
                    </AppBar>
                    <TabPanel value={value} index={0}>
                        <Grid item xs={12} sm={12} className={classes.table}>
                            <Filters
                                label='Filtros'
                                reset={true}
                                fullWidth={false}
                                fieldList={[
                                    {
                                        label: 'NOMBRE',
                                        text: true,
                                        field: 'name'
                                    },
                                    {
                                        label: 'MARCA',
                                        text: true,
                                        field: 'brand'
                                    }
                                ]}
                                list={itemList}
                                filteredList={updatedList}
                                updateList={this.updateList}
                            />
                            <ResponsiveTable
                                headCells={[
                                    {
                                        id: 'image',
                                        label: 'FOTO',
                                        noSortable: true,
                                        render: (item, index) => this.renderImage(item, index + 1000)
                                    },
                                    {
                                        id: 'name',
                                        label: 'PRODUCTO'
                                    },
                                    // {
                                    //     id: 'amount',
                                    //     label: 'CANTIDAD'
                                    // },
                                    {
                                        id: 'revised_amount',
                                        label: 'CANTIDAD - MEDIDA',
                                        noSortable: true,
                                        render: (item, index) => {
                                            return (<TableCell key={index + item.id} classes={{ root: this.props.classes.cellRows }}>
                                                {// eslint-disable-next-line react/jsx-no-bind
                                                }
                                                <div className={classes.inline}>
                                                    <Tooltip title={(currentOrder.status !== 'provisional') ? 'No es posible modificar esto, el pedido ya esta confirmado.' : ''} placement="right" arrow>
                                                        <TextField color="secondary" type="number"
                                                            classes={{ root: this.props.classes.textUpdate }}
                                                            InputProps={{
                                                                endAdornment: <InputAdornment position="end">{item.quantity_unit}</InputAdornment>,
                                                                readOnly: (currentOrder.status !== 'provisional'),
                                                            }}
                                                            id={item.id} value={(item.amount) ? item.amount : ""}
                                                            onChange={(event) => {
                                                                event.preventDefault();
                                                                item.amount = event.target.value;
                                                                item.toSave = true;
                                                                this.forceUpdate();
                                                            }}
                                                        />
                                                    </Tooltip>
                                                    {(item.toSave === true) &&
                                                        <Button classes={{ root: this.props.classes.cellButton }}
                                                            component="label"
                                                            onClick={() => {
                                                                const { dispatch } = this.props;
                                                                this.setState({ toSave: false });
                                                                if (this.props.sessionId) {
                                                                    const { dispatch } = this.props;
                                                                    const log = {
                                                                        "activity_name": "Estado del pedido, click en cambiar cantidad",
                                                                        "payload": {
                                                                            "Producto": {
                                                                                'nombre': item.name,
                                                                                'original_id': item.original_id,
                                                                                'id': item.id
                                                                            }
                                                                        },
                                                                        "session_id": this.props.sessionId
                                                                    };
                                                                    dispatch(clientActions.setClientLog(log));
                                                                }
                                                                dispatch(orderActions.updateItemClient(
                                                                    {
                                                                        amount: parseFloat(item.amount),
                                                                        session_id: this.props.sessionId
                                                                    },
                                                                    this.state.currentOrder.id, item.id));
                                                                setTimeout(() => {
                                                                    dispatch(orderActions.getByIdNoToken(this.props.match.params.token, this.props.sessionId))
                                                                    updatedSession = true;
                                                                    this.setState({
                                                                        updated: false
                                                                    });
                                                                }, 300);
                                                            }}
                                                        >
                                                            <CheckCircleOutline />
                                                        </Button>
                                                    }
                                                </div>
                                            </TableCell>)
                                        }
                                    },
                                    {
                                        id: 'price',
                                        label: 'PRECIO',
                                        type: 'number',
                                    },
                                    {
                                        id: 'total',
                                        label: 'TOTAL',
                                        type: 'number',
                                    },
                                    {
                                        id: 'actions',
                                        label: 'ACCIONES',
                                        noSortable: true,
                                        render: (item, index) => {
                                            return (
                                                <TableCell key={index + 12000} classes={{ root: this.props.classes.cellRows }}>
                                                    <Button
                                                        component="label"
                                                        onClick={() => {
                                                            const { dispatch } = this.props;
                                                            if (this.props.sessionId) {
                                                                const { dispatch } = this.props;
                                                                const log = {
                                                                    "activity_name": "Estado del pedido, click en eliminar item",
                                                                    "payload": {
                                                                        "Producto": {
                                                                            'nombre': item.name,
                                                                            'original_id': item.original_id,
                                                                            'id': item.id
                                                                        }
                                                                    },
                                                                    "session_id": this.props.sessionId
                                                                };
                                                                dispatch(clientActions.setClientLog(log));
                                                            }
                                                            dispatch(orderActions.deleteItemClient(
                                                                {
                                                                    session_id: this.props.sessionId
                                                                },
                                                                this.state.currentOrder.id, item.id));
                                                            setTimeout(() => {
                                                                dispatch(orderActions.getByIdNoToken(this.props.match.params.token, this.props.sessionId))
                                                                updatedSession = true;
                                                                this.setState({
                                                                    updated: false
                                                                });
                                                            }, 300);
                                                        }}
                                                    >
                                                        <DeleteIcon />
                                                    </Button>
                                                </TableCell>
                                            )
                                        }
                                    },
                                    {
                                        id: 'brand',
                                        label: 'MARCA'
                                    },
                                    {
                                        id: 'store_observations',
                                        label: 'OBSERVACIONES',
                                        noSortable: true,
                                        render: (item, index) => this.renderComboObs(item, index + 2000)
                                    },
                                ]}
                                curr_symbol={this.state.currentOrder.curr_symbol}
                                rows={pagelist}
                            />
                            <Pagination
                                count={npages}
                                // size="large"
                                // variant="outlined"
                                shape="rounded"
                                page={npage}
                                onChange={this.handlePageChange}
                                color="primary"
                                sx={{ padding: '10px' }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <br />
                            {(currentOrder && currentOrder.colorBlue) &&
                                <Typography variant="subtitle2" color="inherit" className={classes.textblue}>
                                    <b>* Editado por el vendedor</b>
                                </Typography>
                            }
                        </Grid>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <br />
                        <div className={classes.inline}>
                            <Typography
                                // className={classes.dividerInset}
                                // display="block"
                                component={'span'}
                                variant="body2"
                                color="primary"
                            >
                                &nbsp;&nbsp;Costo de envio
                            </Typography>
                            &nbsp;&nbsp;
                            <Input
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">{(this.props.user ? this.props.user.store.curr_symbol : '')}</InputAdornment>,
                                }}
                                id='input-shipp'
                                type='number'
                                fullWidth={false}
                                disableAuto={true}
                                label="Valor"
                                name='conceptamount'
                                value={shippCost}
                                disabled={true}
                                className={classes.input}
                            />
                        </div>
                        <br /><br />
                        {(currentOrder && currentOrder.shipping && (currentOrder.shipping.length > 0)) &&
                            <div>
                                <Typography
                                    className={classes.dividerInset}
                                    display="block"
                                    variant="caption"
                                    color="primary"
                                >
                                    DETALLES DEL ENVIO <LocalShipping className={classes.searchIcon} />
                                </Typography>
                                <Divider className={classes.divider} />
                                {currentOrder.shipping.map((shipp, index) => {
                                    return (
                                        <Grid item xs={12} sm={10} className={classes.textbox} key={`shipp-key-${index}`}>
                                            <Typography variant="subtitle2" color="inherit" className={classes.texttitle}>
                                                <b>CODIGO: {(shipp.tracking_code) ? shipp.tracking_code : "-"}</b>
                                            </Typography>
                                            <br />
                                            <Typography variant="subtitle2" color="inherit">
                                                <b>EMPRESA:</b> {(shipp.transport_co) ? shipp.transport_co : "-"}<br /><br />
                                                <b>DESPACHADO:</b> {convertDate(shipp.departure)}<br /><br />
                                                <b>LLEGADA:</b> {convertDate(shipp.eta)}<br /><br />
                                                <b>ENLACE:</b> <a href={(shipp.link) ? shipp.link : "-"} target="_blank" rel="noopener noreferrer" className={classes.link}>{(shipp.link) ? shipp.link : ""}</a><br /><br />
                                            </Typography>
                                        </Grid>
                                    )
                                })}
                            </div>
                        }
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <ResponsiveTable
                            headCells={[
                                {
                                    id: 'description',
                                    label: 'Descripcion'
                                },
                                {
                                    id: 'amount',
                                    label: 'Valor',
                                    render: (concept, index) => this.renderAmount(concept, index + 2000)
                                },
                                {
                                    id: 'type',
                                    label: 'TIPO',
                                    render: (concept, index) => this.renderDesc(concept, index + 3000)
                                },
                            ]}
                            rows={this.state.conceptsOrder}
                        />
                        <br /><br />
                    </TabPanel>
                </Grid >
                <div ref={el => (this.instance = el)} />
                {
                    ((this.props.session && this.props.session.session && this.props.session.session.product_list_token) ? true : false) &&
                    <Tooltip title={'Ver lista de precios'} placement="right" arrow>
                        <div className={classes.widgetCart}>
                            <Button
                                className={classes.icoButton}
                                onClick={this.handleGoToCatalog}
                            >
                                Ver catálogo
                            </Button>
                        </div>
                    </Tooltip>
                }
                <ImagePopUp
                    open={openImagePopUp}
                    url={urlImagePopUp}
                    handleClose={this.handleCloseImageDialog}
                />
                <Fade in={((this.props.orderId && this.props.orderId.orderId) ? true : false) && currentOrder.status === 'provisional'}>
                    <div className={classes.widgetAddExpand}>
                        <Button
                            className={classes.icoButton}
                            onClick={(e) => {
                                e.preventDefault();
                                if (this.props.sessionId) {
                                    const { dispatch } = this.props;
                                    const log = {
                                        "activity_name": "Estado del pedido, click en finalizar pedido",
                                        "payload": null,
                                        "session_id": this.props.sessionId
                                    };
                                    dispatch(clientActions.setClientLog(log));
                                }
                                this.props.setWidget(false, true, '', false);
                            }}
                        >
                            Finalizar pedido
                            {/* <AddShoppingCart className={this.state.toAdd ? classes.ico : ''} /> */}
                        </Button>
                    </div>
                </Fade>
            </React.Fragment >
        );
    }

    componentDidMount() {
        if (window.location.href.startsWith('https://arturito.chat') || window.location.href.startsWith('https://arturito.store')) {
            //tracking script from ActiveCampaign
            const s = document.createElement('script');
            s.type = 'text/javascript';
            s.async = true;
            s.innerHTML = '(function(e,t,o,n,p,r,i){e.visitorGlobalObjectAlias=n;e[e.visitorGlobalObjectAlias]=e[e.visitorGlobalObjectAlias]||function(){(e[e.visitorGlobalObjectAlias].q=e[e.visitorGlobalObjectAlias].q||[]).push(arguments)};e[e.visitorGlobalObjectAlias].l=(new Date).getTime();r=t.createElement("script");r.src=o;r.async=true;i=t.getElementsByTagName("script")[0];i.parentNode.insertBefore(r,i)})(window,document,"https://diffuser-cdn.app-us1.com/diffuser/diffuser.js","vgo");vgo("setAccount", "476725427");vgo("setTrackByDefault", true);vgo("process");';
            this.instance.appendChild(s);
        }

        if (!this.props.setWidget) {
            console.log('Error user or session, redirecting')
            this.props.history.push('/homeclient');
            return null;
        }

        // lastPageLocal = sessionStorage.getItem("homeStart");
        // var lastPageUrl = window.frames.top.document.referrer;
        // lastPageLocal = (lastPageUrl.indexOf('arturito.chat') > 0 ||
        //     lastPageUrl.indexOf('arturito-staging') > 0 ||
        //     lastPageUrl.indexOf('localhost') > 0);
        oneTimeWelcome = !sessionStorage.getItem("homeStart");

        const { dispatch } = this.props;
        if (this.props.sessionId) {
            const log = {
                "activity_name": "Ingreso resumen pedido",
                "payload": null,
                "session_id": this.props.sessionId
            };
            dispatch(clientActions.setClientLog(log));
        }

        const smp = document.createElement("script");
        smp.src = "https://sdk.mercadopago.com/js/v2";
        smp.async = true;
        document.head.appendChild(smp);
        // eslint-disable-next-line react/no-did-mount-set-state

        if (this.props.match.params.token) {
            // eslint-disable-next-line react/no-did-mount-set-state
            this.setState({
                updated: false
            });
            updatedSession = false;
            dispatch(userActions.recoverSession(this.props.match.params.token));
        }

        const exitOrder = localStorage.getItem('exitOrder');

        if (!exitOrder) {
            this.props.setWidget(false, false);
            localStorage.removeItem('exitOrder');
        }


        // eslint-disable-next-line react/no-did-mount-set-state
        // this.setState({ token: this.props.match.params.token });
    }

    componentWillUnmount() {
        window.removeEventListener('df-response-received', () => { });
    }

    componentDidUpdate() {
        const updatedorder = localStorage.getItem('updatedorder');
        const updateOrder = localStorage.getItem('updateOrder');
        const exitHome = localStorage.getItem('exitHome');
        const exitOrder = localStorage.getItem('exitOrder');

        if (exitOrder) {
            localStorage.removeItem('exitHome');
            // localStorage.removeItem('exitOrder');
            // localStorage.removeItem('sessionid');
            localStorage.removeItem('updateOrder');
            localStorage.removeItem('itemAdded');
            localStorage.removeItem('updatedlist');
            localStorage.removeItem('updatedorder');
            // this.props.orderId.orderId = null;
        }

        if (exitHome) {
            localStorage.removeItem('exitHome');
            localStorage.removeItem('exitOrder');
            localStorage.removeItem('sessionid');
            localStorage.removeItem('updateOrder');
            localStorage.removeItem('itemAdded');
            localStorage.removeItem('updatedlist');
            localStorage.removeItem('updatedorder');
            this.props.orderId.orderId = null;
            this.props.history.push('/homeclient');
            return null
        }

        if (updateOrder) {
            const { dispatch } = this.props;
            dispatch(orderActions.getByIdNoToken(this.props.match.params.token, this.props.sessionId));
            updatedSession = true;
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({
                updated: false
            });
            localStorage.removeItem('updateOrder');
        }

        if ((updatedorder) && (this.props.orders && this.props.orders.orders && (this.props.orders.orders.length > 0))) {
            localStorage.removeItem('updatedorder');
            this.processData();
            updatedSession = true;
        }

        if ((this.state.updated !== undefined && this.state.updated === false) &&
            (this.props.orders && this.props.orders.orders && (this.props.orders.orders.length > 0))) {
            this.processData();
        }

        if (this.props.session && !updatedSession) {
            this.checkSession();
        }
    }
}

function mapStateToProps(state) {
    const { order, orders, orderId, session } = state;

    return {
        order,
        orders,
        orderId,
        session
    };
}

export default connect(mapStateToProps)(withStyles(styles)(OrderStatus));