import React from 'react';
import PropTypes from 'prop-types';

class Checkbox extends React.PureComponent {

    static propTypes = {
        // The order of the checkbox list
        styleInline: PropTypes.bool,

        // The list of checkboxes to show
        options: PropTypes.array,

        // The function to call when input changes
        onChange: PropTypes.func.isRequired
    }

    render() {
        const { styleInline, options, onChange } = this.props;
        const style = styleInline ?  'form-check-inline' : '';
        return (
            <div className={'form-group'}>
                {options.map((options, index) => {
                    const id = `checkbox-${options.name}-${index}`;
                    return <div className={`form-check ${style}`} key={index} >
                        <input className={'form-check-input'} type="checkbox" id={id} name={options.name} value={options.value} onChange={onChange} />
                        <label className={'form-check-label'} htmlFor={id}>{options.text}</label>
                    </div>
                })}
            </div>
        );
    }
}

Checkbox.defaultProps = {
    styleInline: false,
    options: []
};

export default Checkbox;