import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// eslint-disable-next-line no-undef
// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles(theme => ({
  root: {
    overflow: 'hidden',
  },
  tabpanel: {
    width: '100%',
    overflow: 'auto',
  },
}));

ImagePopUp.propTypes = {
  //  True if the back button is on
  open: PropTypes.bool,
  // The label to show over the bar
  title: PropTypes.string,
  //  True if the menu button is on
  url: PropTypes.string,
  // The function to call when input changes
  handleClose: PropTypes.func,
}

ImagePopUp.defaultProps = {
  open: false,
  title: '',
};

function ImagePopUp({ open, title, url, handleClose }) {
  const classes = useStyles();

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby=" "
        aria-describedby="description"
        className={classes.tabpanel}
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <img src={url} alt='' height="350px"></img>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary" autoFocus>
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ImagePopUp;
