/* eslint-disable react/jsx-no-bind */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    Grid, TableCell, Checkbox, Button, Typography, TextField
} from '@material-ui/core';
import PostAddIcon from '@material-ui/icons/PostAdd';
import DescriptionIcon from '@material-ui/icons/DescriptionOutlined';
import withStyles from '@material-ui/core/styles/withStyles';
import { userActions } from '../_actions';
import ResponsiveTable from '../_components/Table';
import TextInputPopUp from '../_components/TextInputPopUp';
import PublishIcon from '@material-ui/icons/Publish';
import { convertDate } from '../_helpers';
import AddTaskIcon from '@mui/icons-material/Done';

// eslint-disable-next-line no-unused-vars
const styles = theme => ({
    cellWs: {
        width: '100px',
    },
    cellRows: {
        color: '#333 !important',
        borderBottom: '1px solid #b7b7b7',
        boxShadow: 'none',
    },
    cellRowsCsv: {
        color: '#333 !important',
        borderBottom: '1px solid #b7b7b7',
        boxShadow: 'none',
    },
    tabpanel: {
        width: '100%',
        overflow: 'auto',
        backgroundColor: 'white',
    },
    cellFAQ: {
        "&:hover": {
            cursor: 'pointer',
            'backgroundColor': '#ee8d2d',
            color: '#FFF',
        },
    },
    cellButton: {
        padding: '0px',
        margin: '0px',
        'min-width': '0px',
    },
    icoRed: {
        color: '#ff0000',
    },
    icoGreen: {
        color: '#02ff00',
    },
    icoYellow: {
        color: '#ffed00',
    },
    icoBlack: {
        color: '#000',
    },
});


class UserList extends React.Component {
    static propTypes = {
        classes: PropTypes.object.isRequired,
        dispatch: PropTypes.func,
        users: PropTypes.oneOfType([
            PropTypes.array,
            PropTypes.object
        ]),
        csvstatus: PropTypes.object,
        FAQsOAI: PropTypes.object,
        syncAC: PropTypes.func,
    };

    state = {
        faqDialog: false,
        faqDialogOAI: false,
        faqDialogText: '',
        faqDialogItem: {},
        csvDialog: false,
        csvDialogText: '',
        toSaveStoreWS: 0,
        toSaveStoreHR: 0,
        toSaveStoreIR: 0,
    }

    renderActions = (item, index) => {
        return (<TableCell key={index} classes={{ root: this.props.classes.cellRows }}>
            <Checkbox id={item.id}
                checked={(item.enabled)}
                // eslint-disable-next-line react/jsx-no-bind
                onChange={(event) => {
                    if (event.target.checked) {
                        item.enabled = true;
                        this.props.syncAC([
                            {
                                "field": "25",
                                "value": new Date().toISOString(),
                            }
                        ], item.creatoremail);
                        const { dispatch } = this.props;
                        dispatch(userActions.enable(item.id, `Cuenta ${item.hashtag} activada`));
                    }
                    else {
                        item.enabled = false;
                        const { dispatch } = this.props;
                        dispatch(userActions.disable(item.id, `Cuenta ${item.hashtag} desactivada`));
                    }
                }}
                inputProps={{ 'aria-label': 'primary checkbox' }}
            />
        </TableCell>)
    }

    handleCloseFaqDialog = () => {
        this.setState({
            faqDialog: false,
            faqDialogText: '',
            faqDialogItem: {},
        });
    }

    handleCloseFaqOAIDialog = () => {
        this.setState({
            faqDialogOAI: false,
        });
    }

    handleCloseCsvDialog = () => {
        this.setState({
            csvDialog: false,
            csvDialogText: '',
        });
    }

    handleOpenFaqDialog = (item) => {
        this.setState({
            faqDialog: true,
            faqDialogText: '',
            faqDialogItem: item,
        });
    }

    handleOpenFaqOAIDialog = (item) => {
        const { dispatch } = this.props;
        dispatch(userActions.getadminFAQsOAI(item.id));
        this.setState({
            faqDialogOAI: true,
        });
    }

    totext = (array) => {
        var text = "";

        array.forEach(item => {
            text += item;
            text += '\n';
        });

        return text;
    }

    handleAccepteFaqDialog = () => {
        const response = this.state.faqDialogText.split(/[\r\n]+/);
        const { dispatch } = this.props;
        this.props.syncAC([
            {
                "field": "28",
                "value": new Date().toISOString(),
            }
        ], this.state.faqDialogItem.creatoremail);
        dispatch(userActions.uploadFaq(this.state.faqDialogItem.id, response, 'FAQ actualizadas correctamente'));
        this.setState({
            faqDialog: false,
            faqDialogText: '',
            faqDialogItem: {},
        });
    }

    handleCSV = (event, item) => {
        const { dispatch } = this.props;
        dispatch(userActions.uploadCSV(item.id, event.target.files[0], 'CSV enviado correctamente'));
    }

    handleChangeTextField = (event) => {
        this.setState({ faqDialogText: event.target.value });
    }

    renderFAQ = (item, index) => {
        return (<TableCell key={index} classes={{ root: this.props.classes.cellRows }}>
            <PostAddIcon
                classes={{ root: this.props.classes.cellFAQ }}
                onClick={() => {
                    this.handleOpenFaqDialog(item);
                }}
            />
        </TableCell>)
    }

    renderWS = (item, index) => {
        return (<TableCell key={index} classes={{ root: this.props.classes.cellRows }}>
            {// eslint-disable-next-line react/jsx-no-bind
            }
            <TextField color="secondary" type="text"
                classes={{ root: this.props.classes.cellWs }}
                id={item.id}
                value={(item.identifiers.exclusive_whatsapp) ? item.identifiers.exclusive_whatsapp : ""}
                onChange={(event) => {
                    event.preventDefault();
                    item.identifiers.exclusive_whatsapp = event.target.value;
                    this.setState({
                        toSaveStoreWS: this.state.toSaveStoreWS + 1,
                    });
                }} />
            <AddTaskIcon
                classes={{ root: this.props.classes.cellFAQ }}
                onClick={() => {
                    const { dispatch } = this.props;
                    dispatch(userActions.updateStore(item.id,
                        {
                            identifiers: { "exclusive_whatsapp": item.identifiers.exclusive_whatsapp },
                        }));
                }}
            />
        </TableCell>)
    }

    renderHR = (item, index) => {
        return (<TableCell key={index} classes={{ root: this.props.classes.cellRows }}>
            {// eslint-disable-next-line react/jsx-no-bind
            }
            <TextField color="secondary" type="text"
                classes={{ root: this.props.classes.cellWs }}
                id={item.id}
                value={(item.urls.report_slim) ? item.urls.report_slim : ""}
                onChange={(event) => {
                    event.preventDefault();
                    item.urls.report_slim = event.target.value;
                    this.setState({
                        toSaveStoreIR: this.state.toSaveStoreIR + 1,
                    });
                }} />
            <AddTaskIcon
                classes={{ root: this.props.classes.cellFAQ }}
                onClick={() => {
                    const { dispatch } = this.props;
                    dispatch(userActions.updateStore(item.id,
                        {
                            urls: { report_slim: item.urls.report_slim, report_general: item.urls.report_general }
                        }));
                }}
            />
        </TableCell>)
    }

    renderIR = (item, index) => {
        return (<TableCell key={index} classes={{ root: this.props.classes.cellRows }}>
            {// eslint-disable-next-line react/jsx-no-bind
            }
            <TextField color="secondary" type="text"
                classes={{ root: this.props.classes.cellWs }}
                id={item.id}
                value={(item.urls.report_general) ? item.urls.report_general : ""}
                onChange={(event) => {
                    event.preventDefault();
                    item.urls.report_general = event.target.value;
                    this.setState({
                        toSaveStoreHR: this.state.toSaveStoreHR + 1,
                    });
                }} />
            <AddTaskIcon
                classes={{ root: this.props.classes.cellFAQ }}
                onClick={() => {
                    const { dispatch } = this.props;
                    dispatch(userActions.updateStore(item.id,
                        {
                            urls: { report_slim: item.urls.report_slim, report_general: item.urls.report_general }
                        }));
                }}
            />
        </TableCell>)
    }

    renderFAQOAI = (item, index) => {
        return (<TableCell key={index} classes={{ root: this.props.classes.cellRows }}>
            <DescriptionIcon
                classes={{ root: this.props.classes.cellFAQ }}
                onClick={() => {
                    this.handleOpenFaqOAIDialog(item);
                }}
            />
        </TableCell>)
    }

    getCsvLogs = (storeID) => {
        let result = [];
        if (this.props.csvstatus.csvstatus && this.props.csvstatus.csvstatus.length > 0) {
            this.props.csvstatus.csvstatus.forEach(status => {
                if (status.store_id === storeID) {
                    result.push(status);
                }
            });
        }
        return result;
    }

    convertToLog = (array) => {
        let resultText = '';
        array.forEach(status => {
            resultText += `Creado: ${convertDate(status.created_at)}\n`
            resultText += `Actualizado: ${status.updated_at}\n`
            resultText += `Tipo: ${status.kind}\n`
            resultText += `Status: ${status.status}\n`
            resultText += `RutaArchivo: ${status.filepath}\n`
            resultText += `Error: ${status.error_msg}\n\n`
        });
        return resultText;
    }

    getLastStatus = (array) => {
        if (array.length < 1)
            return 'white';
        if (array[0].status === 'complete')
            return 'green';
        if (array[0].status === 'failed')
            return 'red';
        if (array[0].status === 'pending' || array[0].status === 'processing')
            return 'yellow';
    }

    renderCSV = (item, index) => {
        const logs = this.getCsvLogs(item.id);
        const stat = this.getLastStatus(logs);
        const txt = this.convertToLog(logs);

        return (<TableCell key={index} classes={{ root: this.props.classes.cellRowsCsv }}>
            <Button classes={{ root: this.props.classes.cellButton }}
                component="label"
            >
                <PublishIcon
                    classes={{ root: this.props.classes.cellFAQ }}
                />
                <input
                    type="file"
                    hidden
                    onChange={(event) => {
                        this.props.syncAC([
                            {
                                "field": "26",
                                "value": new Date().toISOString(),
                            }
                        ], item.creatoremail);
                        this.handleCSV(event, item);
                    }}
                />
            </Button>
            <Button classes={{ root: this.props.classes.cellButton }}
                component="label"
                onClick={() => {
                    this.setState({
                        csvDialog: true,
                        csvDialogText: txt,
                    });
                }}
            >
                <DescriptionIcon
                    classes={
                        ((stat === 'red') ? { root: this.props.classes.icoRed } :
                            (stat === 'green') ? { root: this.props.classes.icoGreen } :
                                (stat === 'yellow') ? { root: this.props.classes.icoYellow } :
                                    { root: this.props.classes.cellFAQ })
                    }
                />
            </Button>
        </TableCell>)
    }

    renderLOGS = (item, index) => {
        return (<TableCell key={index} classes={{ root: this.props.classes.cellRowsCsv }}>
            <Button classes={{ root: this.props.classes.cellButton }}
                component="label"
                onClick={() => {
                    const { dispatch } = this.props;
                    dispatch(userActions.getLogsStore(item.id, item.name));
                }}
            >
                <DescriptionIcon />
            </Button>
        </TableCell>)
    }

    render() {
        const { classes } = this.props;
        const { faqDialog, csvDialog, faqDialogOAI } = this.state;

        return (
            <React.Fragment>
                <Grid
                    item xs={12} md={12} container
                    className={classes.tabpanel}
                >
                    <ResponsiveTable
                        headCells={[
                            {
                                id: 'created_at',
                                label: 'Creada',
                                type: 'date'
                            },
                            {
                                id: 'name',
                                label: 'Nombre y Apellido'
                            },
                            {
                                id: 'creatoremail',
                                label: 'UserEmail'
                            },
                            {
                                id: 'hashtag',
                                label: '#hashtag'
                            },
                            {
                                id: 'email',
                                label: 'StoreEmail'
                            },
                            {
                                id: 'active',
                                label: 'Activa',
                                noSortable: true,
                                render: (item, index) => this.renderActions(item, index + 1000)
                            },
                            {
                                id: 'faq',
                                label: 'FAQ',
                                noSortable: true,
                                render: (item, index) => this.renderFAQ(item, index + 2000)
                            },
                            {
                                id: 'faqOAI',
                                label: 'FAQOAI',
                                noSortable: true,
                                render: (item, index) => this.renderFAQOAI(item, index + 3000)
                            },
                            {
                                id: 'csv',
                                label: 'CSV + Status',
                                noSortable: true,
                                render: (item, index) => this.renderCSV(item, index + 4000)
                            },
                            {
                                id: 'logs',
                                label: 'Store Logs',
                                noSortable: true,
                                render: (item, index) => this.renderLOGS(item, index + 5000)
                            },
                            {
                                id: 'store WS twilio',
                                label: 'Store WS',
                                noSortable: true,
                                render: (item, index) => this.renderWS(item, index + 6000)
                            },
                            {
                                id: 'store home report',
                                label: 'Home report',
                                noSortable: true,
                                render: (item, index) => this.renderHR(item, index + 7000)
                            },
                            {
                                id: 'store informes report',
                                label: 'Informes report',
                                noSortable: true,
                                render: (item, index) => this.renderIR(item, index + 8000)
                            },
                            {
                                id: 'tax_code',
                                label: 'CUIT'
                            },
                            {
                                id: 'phone',
                                label: 'Telefono'
                            },
                            {
                                id: 'address',
                                label: 'Direccion'
                            },
                            {
                                id: 'city',
                                label: 'Ciudad'
                            },
                            {
                                id: 'postcode',
                                label: 'CP'
                            },
                            {
                                id: 'legal_name',
                                label: 'Empresa'
                            },
                            {
                                id: 'id',
                                label: 'StoreId'
                            },
                        ]}
                        rows={this.props.users.users}
                    />
                </Grid>
                <br />
                <Grid item xs={12} sm={12} container>
                    <DescriptionIcon className={classes.icoBlack} />
                    <Typography variant="body1" color="inherit" className={classes.icoBlack}>
                        * Nologs
                    </Typography>
                    <Typography variant="body1" color="inherit" className={classes.icoGreen}>
                        * Complete
                    </Typography>
                    <Typography variant="body1" color="inherit" className={classes.icoYellow}>
                        * Pending/processing
                    </Typography>
                    <Typography variant="body1" color="inherit" className={classes.icoRed}>
                        * Failed
                    </Typography>
                </Grid>
                <TextInputPopUp
                    open={faqDialog}
                    title="Lista FAQ"
                    text={"Ingrese cada FAQ con el formato:___¿Pregunta?<espacio>Respuesta<enter>."}
                    handleClose={this.handleCloseFaqDialog}
                    handleAccept={this.handleAccepteFaqDialog}
                    accept={true}
                    textField={this.state.faqDialogText}
                    changeTextField={this.handleChangeTextField}
                />
                <TextInputPopUp
                    open={faqDialogOAI}
                    title="Lista FAQOAI"
                    text={"Listado de FAQs en OpenAI______________________________________________"}
                    handleClose={this.handleCloseFaqOAIDialog}
                    textField={(this.props.FAQsOAI && this.props.FAQsOAI.FAQsOAI && this.props.FAQsOAI.FAQsOAI.length > 0) ? this.totext(this.props.FAQsOAI.FAQsOAI) : " "}
                />
                <TextInputPopUp
                    open={csvDialog}
                    title="logs"
                    text={"Lista logs upload CSV_______________________________________________________"}
                    handleClose={this.handleCloseCsvDialog}
                    accept={false}
                    textField={this.state.csvDialogText}
                />
            </React.Fragment >
        );
    }

    componentDidMount() {
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.users !== this.props.users) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({
                orderList: this.props.users.users,
                updatedList: this.props.users.users,
            });
        }
    }
}

function mapStateToProps(state) {
    const { user } = state.authentication;
    const { users, csvstatus, FAQsOAI } = state;

    return {
        user,
        users,
        csvstatus,
        FAQsOAI
    };
}

export default connect(mapStateToProps)(withStyles(styles)(UserList));