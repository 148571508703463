/* eslint-disable react/jsx-no-bind */
import React from 'react';
import PropTypes from 'prop-types';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

InfoAlert.propTypes = {
  open: PropTypes.bool,
  text: PropTypes.string,
  type: PropTypes.string,
  handleClose: PropTypes.func,
}

InfoAlert.defaultProps = {
  open: false,
  text: '',
};

function InfoAlert({ open, text, type, handleClose }) {

  return (
    <div>
      <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={type}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {text}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default InfoAlert;
