import { authHeader } from '../_helpers';

export const userService = {
    login,
    logout,
    register,
    getAll,
    enable,
    disable,
    setUserData,
    changePassword,
    startPasswordRecover,
    passwordRecover,
    getLogs,
    getLogsStore,
    uploadFaq,
    uploadCSV,
    uploadPricesCSV,
    uploadStockCSV,
    uploadListCSV,
    uploadDeleteCSV,
    uploadUpdateCSV,
    getStockCSV,
    // setStore,
    setIntegrations,
    getIntegrations,
    getCSVStatus,
    getCSVStatusStore,
    getStoreUsers,
    deleteStoreUser,
    addStoreUser,
    getStorePaymentMethods,
    setStorePaymentMethods,
    setFAQs,
    getFAQs,
    setTheme,
    getTheme,
    getadminFAQsOAI,
    getFAQsOAI,
    setPromos,
    getPromos,
    recoverSession,
    syncAC,
    getClientLog,
    getStoreQRs,
    setStoreQRs,
    updateStore,
};

const url = process.env.REACT_APP_API_URL;
// const urlAC3 = 'https://trippe.api-us1.com/api/3/contact/sync';
const urlAC1 = 'https://trippe.api-us1.com';
const tokenAC = '78e22ac897bf8415583a907d4ffcca3e745b007d4ab47c4cbf4731d7a839b908f66455ba';

async function syncAC(contact) {

    var ActiveCampaign = require("activecampaign");

    var ac = new ActiveCampaign(urlAC1, tokenAC);

    // TEST API credentials
    ac.credentials_test().then(function (result) {
        // successful request
        if (result.success) {
            console.log('AC Api------ : Api account ok');
        } else {
            console.log('AC Api------ : Invalid account');
        }
    }, function (result) {
        console.log('AC Api------ : account error');
        console.log(result);
    });

    ac.api("contact/sync", contact).then(function (result) {
        // successful request
        if (result.success) {
            console.log('AC Api------ : valid log');
        } else {
            console.log('AC Api------ : fail log');
        }
    }, function (result) {
        console.log('AC Api------ : req error ');
        console.log(result);
    });


    // use post, not working in FE
    // const requestOptions = {
    //     method: 'POST',
    //     headers: { 'Api-Token': tokenAC, 'Content-Type': 'application/json', 'Accept': '*/*' },
    //     body: JSON.stringify({ "contact": contact }),
    // };

    // await fetch(urlAC, requestOptions)
    //     .then(res => console.log(res))
    //     .catch(err => console.error('error:' + err));
}

async function setUserData(storeId, body) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
    };

    const response = await fetch(`${url}/store/${storeId}`, requestOptions);
    return handleResponse(response);
}

async function updateStore(storeId, body) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
    };

    const response = await fetch(`${url}/admin/stores/${storeId}`, requestOptions);
    return handleResponse(response);
}

async function changePassword(body) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
    };

    const response = await fetch(`${url}/user/changepassword`, requestOptions);
    return handleResponse(response);
}

async function startPasswordRecover(body) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
    };

    const response = await fetch(`${url}/user/startpasswordrecover`, requestOptions);
    return handleResponse(response);
}

async function passwordRecover(body) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
    };

    const response = await fetch(`${url}/user/passwordrecover`, requestOptions);
    return handleResponse(response);
}

async function setStorePaymentMethods(data) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };

    const response = await fetch(`${url}/store/payment_methods/`, requestOptions);
    return handleResponse(response);
}

async function getStorePaymentMethods() {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
    };

    const response = await fetch(`${url}/store/payment_methods/`, requestOptions);
    return handleResponse(response);
}

async function getClientLog(id) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
    };

    const response = await fetch(`${url}/store/clients/${id}/activity`, requestOptions);
    return handleResponse(response);
}

async function setFAQs(data) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };

    const response = await fetch(`${url}/store/standard_faqs/`, requestOptions);
    return handleResponse(response);
}

async function getFAQs() {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
    };

    const response = await fetch(`${url}/store/standard_faqs/`, requestOptions);
    return handleResponse(response);
}

async function setStoreQRs(data) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };

    const response = await fetch(`${url}/store/refcodes/`, requestOptions);
    return handleResponse(response);
}

async function getStoreQRs() {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
    };

    const response = await fetch(`${url}/store/refcodes/`, requestOptions);
    return handleResponse(response);
}


async function setTheme(data) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };

    const response = await fetch(`${url}/store/theme`, requestOptions);
    return handleResponse(response);
}

async function getTheme() {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
    };

    const response = await fetch(`${url}/store/theme`, requestOptions);
    return handleResponse(response);
}

async function getadminFAQsOAI(id) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
    };

    const response = await fetch(`${url}/admin/stores/${id}/faqs`, requestOptions);
    return handleResponse(response);
}

async function getFAQsOAI() {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
    };

    const response = await fetch(`${url}/store/faqs`, requestOptions);
    return handleResponse(response);
}

async function setPromos(data) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };

    const response = await fetch(`${url}/store/promos/`, requestOptions);
    return handleResponse(response);
}

async function getPromos() {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
    };

    const response = await fetch(`${url}/store/promos/`, requestOptions);
    return handleResponse(response);
}

async function recoverSession(token) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    };

    const response = await fetch(`${url}/client/recover_session/${token}`, requestOptions);
    return handleResponse(response);
}


async function setIntegrations(data) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };

    const response = await fetch(`${url}/store/integration_settings`, requestOptions);
    return handleResponse(response);
}

async function getIntegrations() {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
    };

    const response = await fetch(`${url}/store/integration_settings`, requestOptions);
    return handleResponse(response);
}

async function uploadFaq(storeId, texts) {
    const body = {
        "texts": texts,
    };

    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
    };

    const response = await fetch(`${url}/admin/stores/${storeId}/upload_faq`, requestOptions);
    return handleResponse(response);
}

async function getStoreUsers() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    const response = await fetch(`${url}/store/users/`, requestOptions);
    return handleResponse(response);
}

async function deleteStoreUser(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    const response = await fetch(`${url}/store/users/${id}`, requestOptions);
    if (response.status === 200)
        return handleResponseNoJson(response);
    else return handleResponse(response);
}

async function addStoreUser(user) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(user)
    };

    const response = await fetch(`${url}/store/users/`, requestOptions);
    if (response.status === 200)
        return handleResponseNoJson(response);
    else return handleResponse(response);
}

async function getStockCSV() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    const response = await fetch(`${url}/store/products_stock`, requestOptions);
    if (response.status === 200)
        return handleResponseNoJson(response);
    else return handleResponse(response);
}

async function uploadStockCSV(file) {
    const formData = new FormData()
    formData.append('file', file, file.name);

    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader() },
        body: formData
    };

    const response = await fetch(`${url}/store/products_stock`, requestOptions);
    return handleResponse(response);
}

async function uploadPricesCSV(file) {
    const formData = new FormData()
    formData.append('file', file, file.name);

    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader() },
        body: formData
    };

    const response = await fetch(`${url}/store/product_prices`, requestOptions);
    return handleResponse(response);
}

async function uploadListCSV(file) {
    const formData = new FormData()
    formData.append('file', file, file.name);

    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader() },
        body: formData
    };

    const response = await fetch(`${url}/store/products_file`, requestOptions);
    return handleResponse(response);
}

async function uploadUpdateCSV(file) {
    const formData = new FormData()
    formData.append('file', file, file.name);

    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader() },
        body: formData
    };

    const response = await fetch(`${url}/store/products_update`, requestOptions);
    return handleResponse(response);
}

async function uploadDeleteCSV(file) {
    const formData = new FormData()
    formData.append('file', file, file.name);

    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader() },
        body: formData
    };

    const response = await fetch(`${url}/store/products_delete`, requestOptions);
    return handleResponse(response);
}

async function uploadCSV(storeId, file) {
    const formData = new FormData()
    formData.append('file', file, file.name);

    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader() },
        body: formData
    };

    const response = await fetch(`${url}/admin/stores/${storeId}/products_import_file`, requestOptions);
    return handleResponse(response);
}

async function getCSVStatusStore() {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
    };

    const response = await fetch(`${url}/store/data_imports`, requestOptions);
    return handleResponse(response);
}

async function getCSVStatus() {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
    };

    const response = await fetch(`${url}/admin/data_imports`, requestOptions);
    return handleResponse(response);
}

// async function setStore(sessionid, storeid) {
//     const body = {
//         session: sessionid,
//         store: storeid
//     };
//     const requestOptions = {
//         method: 'POST',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify(body)
//     };
//     const response = await fetch(`${url}/presetstore`, requestOptions);
//     return handleResponse(response);
// }

function postFunction(path, data) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };
    return fetch(path, requestOptions);
}

async function register(user) {
    const response = await postFunction(`${url}/store/register`, user);
    return handleResponse(response);
}

async function getLogs() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    const response = await fetch(`${url}/admin/logs`, requestOptions);
    return handleResponse(response);
}

async function getLogsStore(storeId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    const response = await fetch(`${url}/admin/logs?store_id=${storeId}`, requestOptions);
    return handleResponse(response);
}

async function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    const response = await fetch(`${url}/admin/stores/`, requestOptions);
    return handleResponse(response);
}

async function enable(id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };

    const response = await fetch(`${url}/admin/stores/${id}/enable`, requestOptions);
    return handleResponse(response);
}
async function disable(id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };

    const response = await fetch(`${url}/admin/stores/${id}/disable`, requestOptions);
    return handleResponse(response);
}

async function login(email, password) {
    const response = await postFunction(`${url}/user/login`, { email, password });
    const { data } = await handleResponse(response);

    if (data.jwt_token) {
        localStorage.setItem('user', JSON.stringify(data));
    }
    return data;
}

function logout() {
    localStorage.removeItem('user');
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                logout();
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}

function handleResponseNoJson(response) {
    return response.text().then(text => {
        const data = text;
        if (!response.ok) {
            if (response.status === 401) {
                logout();
            }

            const error = response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}