/* eslint-disable react/jsx-no-bind */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import {
    Grid, Hidden
} from '@material-ui/core';
import Typography2 from '@mui/material/Typography'
import { clientActions } from '../_actions';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import YouTubeIcon from '@mui/icons-material/YouTube';
import YouTube from 'react-youtube';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';


// eslint-disable-next-line no-unused-vars
const styles = theme => ({
    subheader: {
        width: '100%',
        textAlign: 'end',
        color: '#718096',
        paddingTop: '25px !important',
        paddingRight: '35px !important',
        background: 'linear-gradient(#eee 0%, white 20%)',
    },
    header: {
        width: '100%',
        padding: '0 0 0 0 !important',
    },
    logo: {
        width: "200px",
        margin: '30px 0px 10px 0px',
    },
    link: {
        textDecoration: 'none',
        color: '#124e5c',
        "&:hover": {
            color: '#ee8d2d',
        },
    },
    textbox: {
        margin: '15px',
        textAlign: 'justify',
        width: '100%',
    },
    imageTitle: {
        border: '#ccc 3px solid',
        width: "100%",
        maxWidth: "550px",
        // 'border-radius': '10px',
    },
    inline: {
        display: 'inline-flex',
        'align-items': 'baseline',
    }
});

var message = false;

class HomeClient extends React.Component {
    static propTypes = {
        //  object types
        classes: PropTypes.object,
        sessionId: PropTypes.string,
        dispatch: PropTypes.func,
        match: PropTypes.object,
        setWidget: PropTypes.func,
        history: PropTypes.object,
        store: PropTypes.object,
        updateWidgetStyle: PropTypes.func,
    };

    state = {
        social_networks_whatsapp: "",
        social_networks_yt: "",
        social_networks_facebook: "",
        social_networks_instagram: "",
        social_networks_maps: "",
        theme_header_logo: "https://arturito.chat/images/logotext.png",
        theme_header_bg: "#ffffff",
        theme_photo_url: "https://arturito.chat/images/vector4.png",
        theme_photo_url1: "",
        theme_photo_url2: "",
        theme_photo_url3: "",
        theme_photo_url4: "",
        theme_photo_url5: "",
        theme_photo_url6: "",
        theme_photo_url1_desc: "",
        theme_photo_url2_desc: "",
        theme_photo_url3_desc: "",
        theme_photo_url4_desc: "",
        theme_photo_url5_desc: "",
        theme_photo_url6_desc: "",
        theme_photo_url1_link: "",
        theme_photo_url2_link: "",
        theme_photo_url3_link: "",
        theme_photo_url4_link: "",
        theme_photo_url5_link: "",
        theme_photo_url6_link: "",
        theme_video_url: "",
        theme_title: "QUIERO COMPRAR",
        theme_subtitle: `¡Bienvenido a tu asistente de compra digital! Podemos tomar tu pedido, responder tus preguntas sobre productos y tiendas. El equipo de ventas está observando nuestra conversación y entrarán en contacto contigo si es necesario.
                        Podemos conversar por la plataforma que más te convenga: <b>
                        <a target="_blank" rel="noopener noreferrer" href="https://www.google.com/url?q=https%3A%2F%2Fapi.whatsapp.com%2Fsend%3Fphone%3D%2B541135318986&sa=D" className={classes.link}> whatsapp, </a>
                        <a target="_blank" rel="noopener noreferrer" href="https://www.google.com/url?q=http%3A%2F%2Fm.me%2F109750163905024&sa=D" className={classes.link}>facebook messenger, </a></b>
                        además de la web de tu tienda favorita.<br />
                        Tus datos quedarán grabados en tu perfil, sin necesidad de repetirlos en futuras compras. No venderemos tus datos ni los usaremos de ninguna manera que no sea ayudarte a comprar mejor
                        <a href='https://arturito.online/politica-de-privacidad/' target="_blank" rel="noopener noreferrer" className={classes.link}> (conoce nuestra política de privacidad).</a> Esperamos que disfrutes tu experiencia. No dudes en escribirnos a
                        <a href="mailto:hola@arturito.online" target="_blank" rel="noopener noreferrer" className={classes.link}> hola@arturito.online.</a><br/>
                        <b>El equipo de arturito.online y el de tu tienda favorita.</b>`,
        theme_title_color: "#0263bb",
        theme_widget_logo: "https://arturito.chat/logo.png",
    }


    updateStoreData = (store) => {
        const { color_set, media, widget_logo, header_logo, social_networks, subtitle, title } = store;
        this.props.setWidget(false, true, '', (message? false: true), color_set.df_messenger_button_titlebar_text, widget_logo);
        this.props.updateWidgetStyle(color_set);


        this.setState({
            theme_header_logo: (header_logo ? header_logo : "https://arturito.chat/images/logotext.png"),
            theme_widget_logo: (widget_logo ? widget_logo : "https://arturito.chat/logo.png"),
            theme_title: (title ? title : "QUIERO COMPRAR"),
            theme_subtitle: subtitle,
            social_networks_whatsapp: social_networks.whatsapp,
            social_networks_yt: social_networks.yt,
            social_networks_facebook: social_networks.facebook,
            social_networks_instagram: social_networks.instagram,
            social_networks_maps: social_networks.maps,
            theme_header_bg: (color_set.theme_header_bg ? color_set.theme_header_bg : "#ffffff"),
            theme_title_color: (color_set.theme_title_color ? color_set.theme_title_color : "#0263bb"),
            theme_photo_url: (media[0] && media[0].media_url ? media[0].media_url : ""),
            theme_video_url: (media[1] && media[1].media_url ? media[1].media_url : ""),
            theme_photo_url1: (media[2] && media[2].media_url ? media[2].media_url : ""),
            theme_photo_url2: (media[3] && media[3].media_url ? media[3].media_url : ""),
            theme_photo_url3: (media[4] && media[4].media_url ? media[4].media_url : ""),
            theme_photo_url4: (media[5] && media[5].media_url ? media[5].media_url : ""),
            theme_photo_url5: (media[6] && media[6].media_url ? media[6].media_url : ""),
            theme_photo_url6: (media[7] && media[7].media_url ? media[7].media_url : ""),
            theme_photo_url1_desc: (media[2] && media[2].media_url ? media[2].description : ""),
            theme_photo_url2_desc: (media[3] && media[3].media_url ? media[3].description : ""),
            theme_photo_url3_desc: (media[4] && media[4].media_url ? media[4].description : ""),
            theme_photo_url4_desc: (media[5] && media[5].media_url ? media[5].description : ""),
            theme_photo_url5_desc: (media[6] && media[6].media_url ? media[6].description : ""),
            theme_photo_url6_desc: (media[7] && media[7].media_url ? media[7].description : ""),
            theme_photo_url1_link: (media[2] && media[2].media_url ? media[2].link : ""),
            theme_photo_url2_link: (media[3] && media[3].media_url ? media[3].link : ""),
            theme_photo_url3_link: (media[4] && media[4].media_url ? media[4].link : ""),
            theme_photo_url4_link: (media[5] && media[5].media_url ? media[5].link : ""),
            theme_photo_url5_link: (media[6] && media[6].media_url ? media[6].link : ""),
            theme_photo_url6_link: (media[7] && media[7].media_url ? media[7].link : ""),
        });
    }

    _onReady(event) {
        // access to player in all event handlers via event.target
        event.target.pauseVideo();
    }

    render() {
        const { classes } = this.props;
        const { social_networks_facebook, social_networks_instagram, social_networks_whatsapp, social_networks_maps, social_networks_yt,
            theme_header_logo, theme_header_bg, theme_photo_url,
            theme_photo_url1, theme_photo_url2,
            theme_photo_url3, theme_photo_url4, theme_photo_url5,
            theme_photo_url6, theme_photo_url1_desc, theme_photo_url2_desc,
            theme_photo_url3_desc, theme_photo_url4_desc, theme_photo_url5_desc,
            theme_photo_url6_desc, theme_photo_url1_link, theme_photo_url2_link,
            theme_photo_url3_link, theme_photo_url4_link, theme_photo_url5_link,
            theme_photo_url6_link, theme_video_url,
            theme_title, theme_subtitle, theme_title_color,
        } = this.state;

        const opts = {
            width: '100%',
            playerVars: {
                // https://developers.google.com/youtube/player_parameters
                autoplay: 0,
            },
        };

        return (
            <React.Fragment>
                <Grid container
                    direction="column"
                    alignItems="center"
                >
                    <Grid item xs={12} md={12} lg={12} className={classes.header}>
                        <Box sx={{
                            width: '100%',
                            background: theme_header_bg ? theme_header_bg : 'white',
                            'textAlign': 'center'
                        }}>
                            <a target="_blank" rel="noopener noreferrer" href="https://arturito.online" className={classes.link}>
                                <img src={theme_header_logo} alt="Logo" className={classes.logo} />
                            </a>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12} className={classes.subheader}>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12} sm={6} lg={4} className={classes.textbox}>
                            {theme_photo_url &&
                                <img src={theme_photo_url} alt="Foto" className={classes.imageTitle} />
                            }
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4} className={classes.textbox}>
                            <Typography2 variant="h3" sx={{ color: theme_title_color ? theme_title_color : 'black' }}>
                                {theme_title}
                            </Typography2>
                            <br />
                            <Typography2 component={'span'} variant="body1">
                                <div dangerouslySetInnerHTML={{ __html: theme_subtitle }} />
                                <br />
                                {(social_networks_whatsapp) && <WhatsAppIcon fontSize='large' sx={{ cursor: 'pointer' }} onClick={(() => { window.open(social_networks_whatsapp, '_blank').focus(); })} />}
                                {(social_networks_instagram) && <InstagramIcon fontSize='large' sx={{ cursor: 'pointer' }} onClick={(() => { window.open(social_networks_instagram, '_blank').focus(); })} />}
                                {(social_networks_facebook) && <FacebookRoundedIcon fontSize='large' sx={{ cursor: 'pointer' }} onClick={(() => { window.open(social_networks_facebook, '_blank').focus(); })} />}
                                {(social_networks_maps) && <FmdGoodIcon fontSize='large' sx={{ cursor: 'pointer' }} onClick={(() => { window.open(social_networks_maps, '_blank').focus(); })} />}
                                {(social_networks_yt) && <YouTubeIcon fontSize='large' sx={{ cursor: 'pointer' }} onClick={(() => { window.open(social_networks_yt, '_blank').focus(); })} />}
                            </Typography2>
                        </Grid>
                        <Grid item xs={false} sm={3} lg={5}>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12} sm={9} lg={7} className={classes.textbox}>
                            <Hidden only={['xs']}>
                                <ImageList cols={3} gap={5} sx={{ width: '100%', color: 'black' }}>
                                    {(theme_photo_url1) &&
                                        <ImageListItem key={'img1'}
                                            onClick={(() => {
                                                if (theme_photo_url1_link) window.open(theme_photo_url1_link, '_blank').focus();
                                            })} >
                                            <img
                                                src={`${theme_photo_url1}?w=248&fit=crop&auto=format`}
                                                srcSet={`${theme_photo_url1}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                                alt={'promo 1'}
                                                loading="lazy"
                                            />
                                            <ImageListItemBar
                                                title={theme_photo_url1_desc}
                                                subtitle={''}
                                                position="below"
                                                color='primary'
                                            />
                                        </ImageListItem>
                                    }
                                    {(theme_photo_url2) &&
                                        <ImageListItem key={'img2'}
                                            onClick={(() => {
                                                if (theme_photo_url2_link) window.open(theme_photo_url2_link, '_blank').focus();
                                            })} >
                                            <img
                                                src={`${theme_photo_url2}?w=248&fit=crop&auto=format`}
                                                srcSet={`${theme_photo_url2}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                                alt={'promo 2'}
                                                loading="lazy"
                                            />
                                            <ImageListItemBar
                                                title={theme_photo_url2_desc}
                                                subtitle={''}
                                                position="below"
                                                color='primary'
                                            />
                                        </ImageListItem>
                                    }
                                    {(theme_photo_url3) &&
                                        <ImageListItem key={'img3'}
                                            onClick={(() => {
                                                if (theme_photo_url3_link) window.open(theme_photo_url3_link, '_blank').focus();
                                            })} >
                                            <img
                                                src={`${theme_photo_url3}?w=248&fit=crop&auto=format`}
                                                srcSet={`${theme_photo_url3}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                                alt={'promo 3'}
                                                loading="lazy"
                                            />
                                            <ImageListItemBar
                                                title={theme_photo_url3_desc}
                                                subtitle={''}
                                                position="below"
                                            />
                                        </ImageListItem>
                                    }
                                    {(theme_photo_url4) &&
                                        <ImageListItem key={'img4'}
                                            onClick={(() => {
                                                if (theme_photo_url4_link) window.open(theme_photo_url4_link, '_blank').focus();
                                            })} >
                                            <img
                                                src={`${theme_photo_url4}?w=248&fit=crop&auto=format`}
                                                srcSet={`${theme_photo_url4}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                                alt={'promo 4'}
                                                loading="lazy"
                                            />
                                            <ImageListItemBar
                                                title={theme_photo_url4_desc}
                                                subtitle={''}
                                                position="below"
                                            />
                                        </ImageListItem>
                                    }
                                    {(theme_photo_url5) &&
                                        <ImageListItem key={'img5'}
                                            onClick={(() => {
                                                if (theme_photo_url5_link) window.open(theme_photo_url5_link, '_blank').focus();
                                            })} >
                                            <img
                                                src={`${theme_photo_url5}?w=248&fit=crop&auto=format`}
                                                srcSet={`${theme_photo_url5}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                                alt={'promo 5'}
                                                loading="lazy"
                                            />
                                            <ImageListItemBar
                                                title={theme_photo_url5_desc}
                                                subtitle={''}
                                                position="below"
                                            />
                                        </ImageListItem>
                                    }
                                    {(theme_photo_url6) &&
                                        <ImageListItem key={'img6'}
                                            onClick={(() => {
                                                if (theme_photo_url6_link) window.open(theme_photo_url6_link, '_blank').focus();
                                            })} >
                                            <img
                                                src={`${theme_photo_url6}?w=248&fit=crop&auto=format`}
                                                srcSet={`${theme_photo_url6}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                                alt={'promo 6'}
                                                loading="lazy"
                                            />
                                            <ImageListItemBar
                                                title={theme_photo_url6_desc}
                                                subtitle={''}
                                                position="below"
                                            />
                                        </ImageListItem>
                                    }
                                </ImageList>
                            </Hidden>
                            <Hidden only={['sm', 'md', 'lg', 'xl']}>
                                <ImageList cols={1} gap={5} sx={{ width: '100%', color: 'black' }}>
                                    {(theme_photo_url1) &&
                                        <ImageListItem key={'img1'}
                                            onClick={(() => {
                                                if (theme_photo_url1_link) window.open(theme_photo_url1_link, '_blank').focus();
                                            })} >
                                            <img
                                                src={`${theme_photo_url1}?w=248&fit=crop&auto=format`}
                                                srcSet={`${theme_photo_url1}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                                alt={'promo 1'}
                                                loading="lazy"
                                            />
                                            <ImageListItemBar
                                                title={theme_photo_url1_desc}
                                                subtitle={''}
                                                position="below"
                                                color='primary'
                                            />
                                        </ImageListItem>
                                    }
                                    {(theme_photo_url2) &&
                                        <ImageListItem key={'img2'}
                                            onClick={(() => {
                                                if (theme_photo_url2_link) window.open(theme_photo_url2_link, '_blank').focus();
                                            })} >
                                            <img
                                                src={`${theme_photo_url2}?w=248&fit=crop&auto=format`}
                                                srcSet={`${theme_photo_url2}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                                alt={'promo 2'}
                                                loading="lazy"
                                            />
                                            <ImageListItemBar
                                                title={theme_photo_url2_desc}
                                                subtitle={''}
                                                position="below"
                                                color='primary'
                                            />
                                        </ImageListItem>
                                    }
                                    {(theme_photo_url3) &&
                                        <ImageListItem key={'img3'}
                                            onClick={(() => {
                                                if (theme_photo_url3_link) window.open(theme_photo_url3_link, '_blank').focus();
                                            })} >
                                            <img
                                                src={`${theme_photo_url3}?w=248&fit=crop&auto=format`}
                                                srcSet={`${theme_photo_url3}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                                alt={'promo 3'}
                                                loading="lazy"
                                            />
                                            <ImageListItemBar
                                                title={theme_photo_url3_desc}
                                                subtitle={''}
                                                position="below"
                                            />
                                        </ImageListItem>
                                    }
                                    {(theme_photo_url4) &&
                                        <ImageListItem key={'img4'}
                                            onClick={(() => {
                                                if (theme_photo_url4_link) window.open(theme_photo_url4_link, '_blank').focus();
                                            })} >
                                            <img
                                                src={`${theme_photo_url4}?w=248&fit=crop&auto=format`}
                                                srcSet={`${theme_photo_url4}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                                alt={'promo 4'}
                                                loading="lazy"
                                            />
                                            <ImageListItemBar
                                                title={theme_photo_url4_desc}
                                                subtitle={''}
                                                position="below"
                                            />
                                        </ImageListItem>
                                    }
                                    {(theme_photo_url5) &&
                                        <ImageListItem key={'img5'}
                                            onClick={(() => {
                                                if (theme_photo_url5_link) window.open(theme_photo_url5_link, '_blank').focus();
                                            })} >
                                            <img
                                                src={`${theme_photo_url5}?w=248&fit=crop&auto=format`}
                                                srcSet={`${theme_photo_url5}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                                alt={'promo 5'}
                                                loading="lazy"
                                            />
                                            <ImageListItemBar
                                                title={theme_photo_url5_desc}
                                                subtitle={''}
                                                position="below"
                                            />
                                        </ImageListItem>
                                    }
                                    {(theme_photo_url6) &&
                                        <ImageListItem key={'img6'}
                                            onClick={(() => {
                                                if (theme_photo_url6_link) window.open(theme_photo_url6_link, '_blank').focus();
                                            })} >
                                            <img
                                                src={`${theme_photo_url6}?w=248&fit=crop&auto=format`}
                                                srcSet={`${theme_photo_url6}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                                alt={'promo 6'}
                                                loading="lazy"
                                            />
                                            <ImageListItemBar
                                                title={theme_photo_url6_desc}
                                                subtitle={''}
                                                position="below"
                                            />
                                        </ImageListItem>
                                    }
                                </ImageList>
                            </Hidden>
                        </Grid>
                        <Grid item xs={false} sm={false} lg={1}>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12} sm={9} lg={7} className={classes.textbox}>
                            {theme_video_url &&
                                <YouTube videoId={theme_video_url} opts={opts} onReady={this._onReady} />
                            }
                        </Grid>
                        <Grid item xs={false} sm={3} lg={5}>
                        </Grid>
                    </Grid>
                </Grid>
                <div ref={el => (this.instance = el)} />
            </React.Fragment >
        );
    }

    componentDidMount() {
        if (window.location.href.startsWith('https://arturito.chat') || window.location.href.startsWith('https://arturito.store')) {
            //tracking script from ActiveCampaign
            const s = document.createElement('script');
            s.type = 'text/javascript';
            s.async = true;
            s.innerHTML = '(function(e,t,o,n,p,r,i){e.visitorGlobalObjectAlias=n;e[e.visitorGlobalObjectAlias]=e[e.visitorGlobalObjectAlias]||function(){(e[e.visitorGlobalObjectAlias].q=e[e.visitorGlobalObjectAlias].q||[]).push(arguments)};e[e.visitorGlobalObjectAlias].l=(new Date).getTime();r=t.createElement("script");r.src=o;r.async=true;i=t.getElementsByTagName("script")[0];i.parentNode.insertBefore(r,i)})(window,document,"https://diffuser-cdn.app-us1.com/diffuser/diffuser.js","vgo");vgo("setAccount", "476725427");vgo("setTrackByDefault", true);vgo("process");';
            this.instance.appendChild(s);
            console.log('AC --------- : step 2');

            //tracking script from GA
            if (typeof window.gtag !== 'undefined')
                window.gtag('config', 'G-Q64F793B3C');
            console.log('GA --------- : CH');
        } else {
            console.log('AC --------- : off');
            console.log('GA --------- : off');
        }

        if (!this.props.setWidget) {
            console.log('Error user or session, redirecting')
            this.props.history.push('/homeclient');
            return null;
        }

        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code')
        if (code) {
            const { dispatch } = this.props;
            dispatch(clientActions.setClientQR({
                "ref_code": code,
                "session_id": this.props.sessionId,
            }));
        }

        sessionStorage.setItem("homeStart", true);

        message = urlParams.get('text');
        if (message) {
            const code = message.split(" ").slice(-1)[0];
            const { dispatch } = this.props;
            dispatch(clientActions.setClientQR({
                "ref_code": code,
                "session_id": this.props.sessionId,
            }));
            setTimeout(() => {
                var text = document.querySelector("#df-messenger").shadowRoot.querySelector("div > df-messenger-chat").shadowRoot.querySelector("div > df-messenger-user-input").shadowRoot.querySelector("div > div.input-box-wrapper.valid > input[type=text]");
                var text1 = document.querySelector("#df-messenger").shadowRoot.querySelector("div > df-messenger-chat").shadowRoot.querySelector("div > df-messenger-user-input").shadowRoot.querySelector("div > div.input-box-wrapper > input[type=text]")
                if (text) text.value = message;
                if (text1) text1.value = message;
                var button = document.querySelector("#df-messenger").shadowRoot.querySelector("div > df-messenger-chat").shadowRoot.querySelector("div > df-messenger-user-input").shadowRoot.querySelector("#sendIcon");
                button.dispatchEvent(new Event('click'));
                this.props.setWidget(false, true, "", false);
            }, 1000);
        }

        let hash = '';
        if (this.props.match.params.id) {
            this.props.setWidget(false, false, "", false);
            const { dispatch } = this.props;
            hash = this.props.match.params.id.toUpperCase();
            dispatch(clientActions.setStore(this.props.sessionId, "#" + hash));
        } else {
            this.props.setWidget(false, true, '', (message? false: true));
        }

        if (this.props.sessionId) {
            const { dispatch } = this.props;
            const log = {
                "activity_name": "Ingreso home",
                "payload": (code ? { "code": code } : null),
                "session_id": this.props.sessionId
            };
            dispatch(clientActions.setClientLog(log));
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.store && this.props.store.store && (prevProps.store.store !== this.props.store.store)) {
            this.updateStoreData(this.props.store.store);
        }
    }
}

function mapStateToProps(state) {
    const { loggingIn } = state.authentication;
    const { store } = state;
    return {
        loggingIn,
        store
    };
}

export default connect(mapStateToProps)(withStyles(styles)(HomeClient));