/* eslint-disable react/jsx-no-bind */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import {
    Typography, Tab, Tabs, Box, Switch, Grid,
    Tooltip, Button, Divider, TableCell, Checkbox, TextField,
    FormControlLabel, FormControl,
} from '@material-ui/core';
import Appbar from '../_components/Appbar';
import { userActions } from '../_actions';
import Input from '../_components/Input';
import DescriptionIcon from '@material-ui/icons/DescriptionOutlined';
// import PublishIcon from '@material-ui/icons/Publish';
// import ShoppingCartOutlined from '@material-ui/icons/ShoppingCartOutlined';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import ResponsiveTable from '../_components/Table';
import DeleteIcon from '@material-ui/icons/Delete';
import AddCircle from '@material-ui/icons/AddCircle';
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';
import Cached from '@material-ui/icons/Cached';
import { ColorPicker } from 'material-ui-color';
// import Backdrop from '@mui/material/Backdrop';
// import Table from '@mui/material/Table';
// import TableBody from '@mui/material/TableBody';
// import { styled } from '@mui/material/styles';
// import TableCell1, { tableCellClasses } from '@mui/material/TableCell';
// import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
// import TableRow from '@mui/material/TableRow';
// import Paper from '@mui/material/Paper';
import { convertDate } from '../_helpers';
import TextInputPopUp from '../_components/TextInputPopUp';
import WidgetPreview from '../_components/WidgetPreview';
import WizzarConfig from '../_components/WizzarConfig';


// eslint-disable-next-line no-unused-vars
const styles = theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: 'white',
        display: 'flex',
        height: '100%',
    },
    tabs: {
        borderRight: `1px solid #ed841e`,
        backgroundColor: 'white',
        color: 'black',
        width: '160px',
        'min-width': '160px',
        paddingTop: '20px',
    },
    tabs2: {
        borderBottom: `1px solid #ed841e`,
        backgroundColor: 'white',
        color: 'black',
        width: '100%',
        'min-width': '360px',
        paddingTop: '0px',
    },
    link: {
        textDecoration: 'none',
        cursor: 'pointer',
        "&:hover": {
            color: '#ee8d2d',
        },
    },
    divider: {
        background: '#e0ecec !important',
    },
    cellRows: {
        color: '#333 !important',
        borderBottom: '1px solid #b7b7b7',
        boxShadow: 'none',
    },
    cellButton: {
        padding: '0px',
        margin: '0px',
        'min-width': '0px',
    },
    tabpanel: {
        width: '100%',
        overflow: 'auto',
    },
    inline: {
        display: 'inline-flex',
        'align-items': 'center',
        height: '55px',
    },
    icoRed: {
        color: '#ff0000',
    },
    icoGreen: {
        color: '#02ff00',
    },
    icoYellow: {
        color: '#ffed00',
    },
    icoBlack: {
        color: '#000',
    },
    tab: {
        width: '1100px',
    },
});

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography component={'span'}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

function a11yProps2(index) {
    return {
        id: `horizontal-tab-${index}`,
        'aria-controls': `horizontal-tabpanel-${index}`,
    };
}

const autoFillWelcome = ['Nuestra compra mínima es de $5.000 🛵', 'Para consultas llamanos +54911 11223344 ☎️',
    'Aprovecha el descuento pagando con tarjeta de crédito Santander 🎯', 'Los retiros se hacen por Calle 23, 123 🚶'];
const autoFillOrder = ['Aprovechá la compra en 3 cuotas pagando con tarjeta de crédito 🛒', 'Entregamos en el día los pedidos recibidos hasta las 12hs 🚚',
    'No olvides de informar tu dirección de entrega con las intersecciones de calles 🌍'];
const autoFillEnd = ['¡Queremos saber tu opinión! Por favor completá el formulario en este link 😊', '¡Gracias por tu compra! 😃 Ayudanos a mejorar con esta consulta rápida',
    '¿Cómo fue tu experiencia? Dinos en esta encuesta rápida 👍'];

class Options extends React.Component {
    static propTypes = {
        //  object types
        classes: PropTypes.object,
        setWidget: PropTypes.func,
        children: PropTypes.node,
        csvstatus: PropTypes.object,
        rootPage: PropTypes.string,
        onBackComp: PropTypes.func,
    };

    state = {
        value: 0,
        value2: 0,
        toSave: false,
        toSavePM: false,
        toSaveFAQ: false,
        toSavePromos: false,
        checkDelivery: true,
        checkTakeaway: true,
        woo_auto_orders: false,
        woo_auto_products: false,
        woo_key: '',
        woo_url: '',
        woo_secret: '',
        updated: false,
        updated2: false,
        password: '',
        old_pass: '',
        new_pass: '',
        emailUser: '',
        submitted: false,
        codeWidgetSide: 'right',
        //datos del usuario
        submittedPass: false,
        submittedRegister: false,
        curr_symbol: ((this.props.user && this.props.user.store && this.props.user.store.curr_symbol) ? this.props.user.store.curr_symbol : '$'),
        lang: (this.props.user && this.props.user.store.prefered_language ? this.props.user.store.prefered_language : 'es'),
        legal_name: (this.props.user && this.props.user.store.legal_name ? this.props.user.store.legal_name : ''),
        tax_code: (this.props.user && this.props.user.store.tax_code ? this.props.user.store.tax_code : ''),
        address: (this.props.user && this.props.user.store.address ? this.props.user.store.address : ''),
        city: (this.props.user && this.props.user.store.city ? this.props.user.store.city : ''),
        postcode: (this.props.user && this.props.user.store.postcode ? this.props.user.store.postcode : ''),
        country: (this.props.user && this.props.user.store.country ? this.props.user.store.country : ''),
        state: (this.props.user && this.props.user.store.state ? this.props.user.store.state : ''),
        phone: (this.props.user && this.props.user.store.phone ? this.props.user.store.phone : ''),
        emailStore: (this.props.user && this.props.user.store.email ? this.props.user.store.email : ''),

        social_networks_whatsapp: (this.props.user && this.props.user.store.social_networks.whatsapp ? this.props.user.store.social_networks.whatsapp : ""),
        social_networks_yt: (this.props.user && this.props.user.store.social_networks.yt ? this.props.user.store.social_networks.yt : ""),
        social_networks_facebook: (this.props.user && this.props.user.store.social_networks.facebook ? this.props.user.store.social_networks.facebook : ""),
        social_networks_instagram: (this.props.user && this.props.user.store.social_networks.instagram ? this.props.user.store.social_networks.instagram : ""),
        social_networks_maps: (this.props.user && this.props.user.store.social_networks.maps ? this.props.user.store.social_networks.maps : ""),

        theme_header_logo: "https://arturito.chat/images/logotext.png",
        theme_header_bg: "#ffffff",
        theme_photo_url: "https://arturito.chat/images/vector4.png",
        theme_photo_url1: "",
        theme_photo_url2: "",
        theme_photo_url3: "",
        theme_photo_url4: "",
        theme_photo_url5: "",
        theme_photo_url6: "",
        theme_photo_url1_desc: "",
        theme_photo_url2_desc: "",
        theme_photo_url3_desc: "",
        theme_photo_url4_desc: "",
        theme_photo_url5_desc: "",
        theme_photo_url6_desc: "",
        theme_photo_url1_link: "",
        theme_photo_url2_link: "",
        theme_photo_url3_link: "",
        theme_photo_url4_link: "",
        theme_photo_url5_link: "",
        theme_photo_url6_link: "",
        theme_video_url: "JLmOteqmDYc",
        theme_title: "QUIERO COMPRAR",
        theme_subtitle: `¡Bienvenido a tu asistente de compra digital! Podemos tomar tu pedido, responder tus preguntas sobre productos y tiendas. El equipo de ventas está observando nuestra conversación y entrarán en contacto contigo si es necesario.
                        Podemos conversar por la plataforma que más te convenga: <b>
                        <a target="_blank" rel="noopener noreferrer" href="https://www.google.com/url?q=https%3A%2F%2Fapi.whatsapp.com%2Fsend%3Fphone%3D%2B541135318986&sa=D" className={classes.link}> whatsapp, </a>
                        <a target="_blank" rel="noopener noreferrer" href="https://www.google.com/url?q=http%3A%2F%2Fm.me%2F109750163905024&sa=D" className={classes.link}>facebook messenger, </a></b>
                        además de la web de tu tienda favorita.<br />
                        Tus datos quedarán grabados en tu perfil, sin necesidad de repetirlos en futuras compras. No venderemos tus datos ni los usaremos de ninguna manera que no sea ayudarte a comprar mejor
                        <a href='https://arturito.online/politica-de-privacidad/' target="_blank" rel="noopener noreferrer" className={classes.link}> (conoce nuestra política de privacidad).</a> Esperamos que disfrutes tu experiencia. No dudes en escribirnos a
                        <a href="mailto:hola@arturito.online" target="_blank" rel="noopener noreferrer" className={classes.link}> hola@arturito.online.</a><br/>
                        <b>El equipo de arturito.online y el de tu tienda favorita.</b>`,
        theme_title_color: "#0263bb",
        theme_widget_logo: "https://arturito.chat/logo.png",
        df_messenger_button_titlebar_text: `Tienda ${(this.props.user) ? this.props.user.store.hashtag.substring(1) : ''}`,
        df_messenger_bot_message: '#ffffff',
        df_messenger_button_titlebar_color: '#134859',
        df_messenger_button_titlebar_font_color: '#ed841e',
        df_messenger_input_box_color: '#f0f0f0',
        df_messenger_input_font_color: '#000000',
        df_messenger_chat_background_color: '#e5ddd5',
        df_messenger_font_color: '#000000',
        df_messenger_send_icon: '#134859',
        df_messenger_user_message: '#dcf8c6',
        df_messenger_chip_color: '#f5ddb9',

        errorEmailStore: false,
        errorCurrStore: false,
        //qrs
        botDialogInit: (this.props.user && this.props.user.store.dialog_messages.dialog_init ? this.props.user.store.dialog_messages.dialog_init : ''),
        botOrderInit: (this.props.user && this.props.user.store.dialog_messages.pre_order ? this.props.user.store.dialog_messages.pre_order : ''),
        botOrderFinish: (this.props.user && this.props.user.store.dialog_messages.order_close ? this.props.user.store.dialog_messages.order_close : ''),
        csvDialog: false,
        csvDialogText: '',
        qrsList: this.props.qrs.qrs,
        activeStep: '0',
    }

    handleSubmitChangePass = event => {
        event.preventDefault();
        this.setState({ submittedPass: true });
        const { old_pass, new_pass } = this.state;
        const { dispatch } = this.props;

        if (old_pass && new_pass) {
            dispatch(userActions.changePassword({
                "old_password": old_pass,
                "new_password": new_pass,
            }, 'Datos del usuario guardados'));
        }
    }

    handleSubmitRegister = event => {
        event.preventDefault();
        this.setState({ submittedRegister: true });
        const { lang, emailStore, legal_name, tax_code, address,
            city, state, postcode, phone, country, curr_symbol } = this.state;
        const { dispatch } = this.props;

        this.props.user.store.prefered_language = lang;
        this.props.user.store.legal_name = legal_name;
        this.props.user.store.tax_code = tax_code;
        this.props.user.store.address = address;
        this.props.user.store.city = city;
        this.props.user.store.postcode = postcode;
        this.props.user.store.country = country;
        this.props.user.store.state = state;
        this.props.user.store.phone = phone;
        this.props.user.store.email = emailStore;
        this.props.user.store.curr_symbol = curr_symbol;

        if (tax_code) {
            dispatch(userActions.setUserData(this.props.user.store.id, {
                "legal_name": legal_name,
                "tax_code": tax_code,
                "address": address,
                "city": city,
                "postcode": postcode,
                "country": country,
                "state": state,
                "phone": phone,
                "email": (emailStore ? emailStore : null),
                "prefered_language": lang,
                "curr_symbol": curr_symbol,
            }));

            const { user } = this.props;
            let social = user.store.social_networks;

            if (social) {
                social.flags['store'] = true;
                social.flags['storeTS'] = new Date().toISOString();
                dispatch(userActions.setUserData(this.props.user.store.id, {
                    "social_networks": social,
                }, 'Datos del negocio guardados'));
                this.props.onBackComp();
            }
        }
    }

    onBack = event => {
        event.preventDefault();
        // this.props.history.push('/home');
        this.props.onBackComp();
    }

    handleChangeTab = (event, newvalue) => {
        this.setState({
            value: newvalue,
        });
    }

    handleChangeTab2 = (event, newvalue) => {
        this.setState({
            value2: newvalue,
        });
    }

    handleChangeSwitchDelivery = event => {
        const { dispatch } = this.props;
        let checkDelivery = this.state.checkDelivery;
        let checkTakeaway = this.state.checkTakeaway;

        if (event.target.name === "checkDelivery") {
            checkDelivery = event.target.checked;
        }
        if (event.target.name === "checkTakeaway") {
            checkTakeaway = event.target.checked;
        }
        if ((checkDelivery === false) && (checkTakeaway === false)) {
            if (event.target.name === "checkDelivery") {
                checkTakeaway = true;
            }
        }
        if ((checkDelivery === false) && (checkTakeaway === false)) {
            if (event.target.name === "checkTakeaway") {
                checkDelivery = true;
            }
        }

        this.setState({
            checkDelivery: checkDelivery,
            checkTakeaway: checkTakeaway,
        });
        this.props.user.store.delivery_enabled = checkDelivery;
        this.props.user.store.takeaway_enabled = checkTakeaway;
        dispatch(userActions.setUserData(this.props.user.store.id, {
            "delivery_enabled": checkDelivery,
            "takeaway_enabled": checkTakeaway,
        }));
    }

    handleChangeSwitch = event => {
        this.setState({
            [event.target.name]: event.target.checked,
            toSave: true,
        });
    }

    validateEmail = (email) => {
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
    }

    validateCurr = (curr) => {
        return (curr.length < 4);
    }

    handleChange = event => {
        event.preventDefault();
        if (event.target.name === 'emailStore') {
            if (!this.validateEmail(event.target.value)) {
                this.setState({
                    errorEmailStore: true,
                });
            } else {
                this.setState({
                    errorEmailStore: false,
                });
            }
        }
        if (event.target.name === 'QRdesc') {
            this.setState({
                toSaveQR: true,
            });
        }
        if (event.target.name === 'curr_symbol') {
            if (!this.validateCurr(event.target.value)) {
                this.setState({
                    errorCurrStore: true,
                });
            } else {
                this.setState({
                    errorCurrStore: false,
                });
            }
        }
        this.setState({
            [event.target.name]: event.target.value,
            toSave: true,
        });
    }

    handleChangeLang = event => {
        event.preventDefault();
        this.setState({
            [event.target.name]: event.target.value,
        });
    }


    handleSave = () => {
        let data = {
            "woo_base_url": this.state.woo_url,
            "woo_api_key": this.state.woo_key,
            "woo_secret": this.state.woo_secret,
            "woo_auto_products": this.state.woo_auto_products,
            "woo_auto_orders": this.state.woo_auto_orders,
        };

        this.setState({
            toSave: false,
        });

        const { dispatch } = this.props;
        dispatch(userActions.setIntegrations(data, 'Integraciones guardadas correctamente'));
    };

    processData = () => {
        const { integrations } = this.props;

        if (integrations && integrations.integrations) {
            this.setState({
                woo_auto_orders: integrations.integrations.woo_auto_orders,
                woo_auto_products: integrations.integrations.woo_auto_products,
                woo_key: (integrations.integrations.woo_api_key ? integrations.integrations.woo_api_key : ''),
                woo_url: (integrations.integrations.woo_base_url ? integrations.integrations.woo_base_url : ''),
                woo_secret: (integrations.integrations.woo_secret ? integrations.integrations.woo_secret : ''),
                updated: true,
            });
            // this.forceUpdate();
        }
    }

    handleCSVPrices = (event) => {
        const { dispatch } = this.props;
        this.props.syncAC([
            {
                "field": "30",
                "value": new Date().toISOString(),
            }
        ], this.props.user.user);
        dispatch(userActions.uploadPricesCSV(event.target.files[0], 'Actualización de precios enviada correctamente'));
        event.target.value = ''
    }

    handleCSVList = (event) => {
        const { dispatch } = this.props;
        this.props.syncAC([
            {
                "field": "30",
                "value": new Date().toISOString(),
            }
        ], this.props.user.user);
        dispatch(userActions.uploadListCSV(event.target.files[0], 'Actualización de lista de productos enviada correctamente'));
        event.target.value = ''
    }

    handleCSVUpdate = (event) => {
        const { dispatch } = this.props;
        this.props.syncAC([
            {
                "field": "30",
                "value": new Date().toISOString(),
            }
        ], this.props.user.user);
        dispatch(userActions.uploadUpdateCSV(event.target.files[0], 'Actualización de lista de productos enviada correctamente'));
        event.target.value = ''
    }

    handleCSVDelete = (event) => {
        const { dispatch } = this.props;
        this.props.syncAC([
            {
                "field": "30",
                "value": new Date().toISOString(),
            }
        ], this.props.user.user);
        dispatch(userActions.uploadDeleteCSV(event.target.files[0], 'Lista de productos a eliminar enviada correctamente'));
        event.target.value = ''
    }

    handleCSVStock = (event) => {
        const { dispatch } = this.props;
        this.props.syncAC([
            {
                "field": "31",
                "value": new Date().toISOString(),
            }
        ], this.props.user.user);
        dispatch(userActions.uploadStockCSV(event.target.files[0], 'Actualización de stock enviada correctamente'));
        event.target.value = ''
    }

    handleGetStockCSV = () => {
        const { dispatch } = this.props;
        dispatch(userActions.getStockCSV());
    }

    handleSubmit = event => {
        event.preventDefault();
        this.setState({ submitted: true });
        const { emailUser, password } = this.state;
        const { dispatch } = this.props;

        if (emailUser && password) {
            this.props.syncAC([
                {
                    "field": "24",
                    "value": this.props.user.store.hashtag,
                },
                {
                    "field": "27",
                    "value": "user",
                },
            ], emailUser);
            dispatch(userActions.addStoreUser({
                "email": emailUser,
                "password": password
            }));
            this.setState({
                password: '',
                emailUser: '',
                submitted: false,
            })
        }
    }

    resetWidgetOptions = () => {
        this.setState({
            theme_widget_logo: "https://arturito.chat/logo.png",
            df_messenger_button_titlebar_text: `Tienda ${this.props.user.store.hashtag.substring(1)}`,
            df_messenger_bot_message: '#ffffff',
            df_messenger_button_titlebar_color: '#134859',
            df_messenger_button_titlebar_font_color: '#ed841e',
            df_messenger_input_box_color: '#f0f0f0',
            df_messenger_input_font_color: '#000000',
            df_messenger_chat_background_color: '#e5ddd5',
            df_messenger_font_color: '#000000',
            df_messenger_send_icon: '#134859',
            df_messenger_user_message: '#dcf8c6',
            df_messenger_chip_color: '#f5ddb9',
        });
    }

    updateWidgetStyle = () => {
        this.props.setWidget(false, false);
        setTimeout(() => {
            const dfMessenger = document.querySelector('df-messenger');
            dfMessenger.style.setProperty('--df-messenger-bot-message', this.state.df_messenger_bot_message);
            dfMessenger.style.setProperty('--df-messenger-button-titlebar-color', this.state.df_messenger_button_titlebar_color);
            dfMessenger.style.setProperty('--df-messenger-button-titlebar-font-color', this.state.df_messenger_button_titlebar_font_color);
            dfMessenger.style.setProperty('--df-messenger-input-box-color', this.state.df_messenger_input_box_color);
            dfMessenger.style.setProperty('--df-messenger-input-font-color', this.state.df_messenger_input_font_color);
            dfMessenger.style.setProperty('--df-messenger-chat-background-color', this.state.df_messenger_chat_background_color);
            dfMessenger.style.setProperty('--df-messenger-font-color', this.state.df_messenger_font_color);
            dfMessenger.style.setProperty('--df-messenger-send-icon', this.state.df_messenger_send_icon);
            dfMessenger.style.setProperty('--df-messenger-user-message', this.state.df_messenger_user_message);
            dfMessenger.style.setProperty('--df-messenger-chip-color', this.state.df_messenger_chip_color);
            this.props.setWidget(false, false, 'Probar cambios en el chat', true, this.state.df_messenger_button_titlebar_text, this.state.theme_widget_logo);
        }, 1000);
    }

    updateThemeData = () => {
        const theme = this.props.theme.theme;
        const { color_set, media } = theme;

        this.setState({
            theme_header_logo: (theme.header_logo ? theme.header_logo : "https://arturito.chat/images/logotext.png"),
            theme_widget_logo: (theme.widget_logo ? theme.widget_logo : "https://arturito.chat/logo.png"),
            theme_title: (theme.title ? theme.title : "QUIERO COMPRAR"),
            theme_subtitle: (theme.subtitle ? theme.theme_subtitle : this.state.theme_subtitle),

            theme_header_bg: (color_set.theme_header_bg ? color_set.theme_header_bg : "#ffffff"),
            theme_title_color: (color_set.theme_title_color ? color_set.theme_title_color : "#0263bb"),
            df_messenger_bot_message: (color_set.df_messenger_bot_message ? color_set.df_messenger_bot_message : '#ffffff'),
            df_messenger_button_titlebar_color: (color_set.df_messenger_button_titlebar_color ? color_set.df_messenger_button_titlebar_color : '#134859'),
            df_messenger_button_titlebar_font_color: (color_set.df_messenger_button_titlebar_font_color ? color_set.df_messenger_button_titlebar_font_color : '#ed841e'),
            df_messenger_button_titlebar_text: (color_set.df_messenger_button_titlebar_text ? color_set.df_messenger_button_titlebar_text : `Tienda ${this.props.user.store.hashtag.substring(1)}`),
            df_messenger_input_box_color: (color_set.df_messenger_input_box_color ? color_set.df_messenger_input_box_color : '#f0f0f0'),
            df_messenger_input_font_color: (color_set.df_messenger_input_font_color ? color_set.df_messenger_input_font_color : '#000000'),
            df_messenger_chat_background_color: (color_set.df_messenger_chat_background_color ? color_set.df_messenger_chat_background_color : '#e5ddd5'),
            df_messenger_font_color: (color_set.df_messenger_font_color ? color_set.df_messenger_font_color : '#000000'),
            df_messenger_send_icon: (color_set.df_messenger_send_icon ? color_set.df_messenger_send_icon : '#134859'),
            df_messenger_user_message: (color_set.df_messenger_user_message ? color_set.df_messenger_user_message : '#dcf8c6'),
            df_messenger_chip_color: (color_set.df_messenger_chip_color ? color_set.df_messenger_chip_color : '#f5ddb9'),

            theme_photo_url: (media[0] && media[0].media_url ? media[0].media_url : ""),
            theme_video_url: (media[1] && media[1].media_url ? media[1].media_url : ""),
            theme_photo_url1: (media[2] && media[2].media_url ? media[2].media_url : ""),
            theme_photo_url2: (media[3] && media[3].media_url ? media[3].media_url : ""),
            theme_photo_url3: (media[4] && media[4].media_url ? media[4].media_url : ""),
            theme_photo_url4: (media[5] && media[5].media_url ? media[5].media_url : ""),
            theme_photo_url5: (media[6] && media[6].media_url ? media[6].media_url : ""),
            theme_photo_url6: (media[7] && media[7].media_url ? media[7].media_url : ""),
            theme_photo_url1_desc: (media[2] && media[2].media_url ? media[2].description : ""),
            theme_photo_url2_desc: (media[3] && media[3].media_url ? media[3].description : ""),
            theme_photo_url3_desc: (media[4] && media[4].media_url ? media[4].description : ""),
            theme_photo_url4_desc: (media[5] && media[5].media_url ? media[5].description : ""),
            theme_photo_url5_desc: (media[6] && media[6].media_url ? media[6].description : ""),
            theme_photo_url6_desc: (media[7] && media[7].media_url ? media[7].description : ""),
            theme_photo_url1_link: (media[2] && media[2].media_url ? media[2].link : ""),
            theme_photo_url2_link: (media[3] && media[3].media_url ? media[3].link : ""),
            theme_photo_url3_link: (media[4] && media[4].media_url ? media[4].link : ""),
            theme_photo_url4_link: (media[5] && media[5].media_url ? media[5].link : ""),
            theme_photo_url5_link: (media[6] && media[6].media_url ? media[6].link : ""),
            theme_photo_url6_link: (media[7] && media[7].media_url ? media[7].link : ""),
            updated2: true,
        });
    }

    resetHomeOptions = () => {
        this.setState({
            theme_header_logo: "https://arturito.chat/images/logotext.png",
            theme_header_bg: "#ffffff",
            theme_photo_url: "https://arturito.chat/images/vector4.png",
            theme_video_url: "",
            theme_title: "QUIERO COMPRAR",
            theme_subtitle: `¡Bienvenido a tu asistente de compra digital! Podemos tomar tu pedido, responder tus preguntas sobre productos y tiendas. El equipo de ventas está observando nuestra conversación y entrarán en contacto contigo si es necesario.
                        Podemos conversar por la plataforma que más te convenga: <b>
                        <a target="_blank" rel="noopener noreferrer" href="https://www.google.com/url?q=https%3A%2F%2Fapi.whatsapp.com%2Fsend%3Fphone%3D%2B541135318986&sa=D" className={classes.link}> whatsapp, </a>
                        <a target="_blank" rel="noopener noreferrer" href="https://www.google.com/url?q=http%3A%2F%2Fm.me%2F109750163905024&sa=D" className={classes.link}>facebook messenger, </a></b>
                        además de la web de tu tienda favorita.<br />
                        Tus datos quedarán grabados en tu perfil, sin necesidad de repetirlos en futuras compras. No venderemos tus datos ni los usaremos de ninguna manera que no sea ayudarte a comprar mejor
                        <a href='https://arturito.online/politica-de-privacidad/' target="_blank" rel="noopener noreferrer" className={classes.link}> (conoce nuestra política de privacidad).</a> Esperamos que disfrutes tu experiencia. No dudes en escribirnos a
                        <a href="mailto:hola@arturito.online" target="_blank" rel="noopener noreferrer" className={classes.link}> hola@arturito.online.</a><br/>
                        <b>El equipo de arturito.online y el de tu tienda favorita.</b>`,
            theme_title_color: "#0263bb",
            theme_photo_url1: "",
            theme_photo_url2: "",
            theme_photo_url3: "",
            theme_photo_url4: "",
            theme_photo_url5: "",
            theme_photo_url6: "",
            theme_photo_url1_desc: "",
            theme_photo_url2_desc: "",
            theme_photo_url3_desc: "",
            theme_photo_url4_desc: "",
            theme_photo_url5_desc: "",
            theme_photo_url6_desc: "",
            theme_photo_url1_link: "",
            theme_photo_url2_link: "",
            theme_photo_url3_link: "",
            theme_photo_url4_link: "",
            theme_photo_url5_link: "",
            theme_photo_url6_link: "",
        });
    }

    handleSubmitHomeData = (event) => {
        event.preventDefault();
        const { social_networks_whatsapp, social_networks_facebook, social_networks_instagram, social_networks_maps, social_networks_yt,
            botDialogInit, botOrderInit, botOrderFinish } = this.state;
        const { dispatch } = this.props;

        this.props.user.store.social_networks.whatsapp = social_networks_whatsapp;
        this.props.user.store.social_networks.facebook = social_networks_facebook;
        this.props.user.store.social_networks.instagram = social_networks_instagram;
        this.props.user.store.social_networks.maps = social_networks_maps;
        this.props.user.store.social_networks.yt = social_networks_yt;

        this.props.user.store.dialog_messages.dialog_init = botDialogInit;
        this.props.user.store.dialog_messages.pre_order = botOrderInit;
        this.props.user.store.dialog_messages.order_close = botOrderFinish;

        dispatch(userActions.setUserData(this.props.user.store.id, {
            "social_networks": {
                "whatsapp": social_networks_whatsapp,
                "facebook": social_networks_facebook,
                "instagram": social_networks_instagram,
                "maps": social_networks_maps,
                "yt": social_networks_yt,
            },
            "dialog_messages": {
                "dialog_init": botDialogInit,
                "pre_order": botOrderInit,
                "order_close": botOrderFinish,
            }
        }, 'Datos del usuario guardados'));
    }


    handleSubmitTheme = (event) => {
        event.preventDefault();
        event.preventDefault();
        const { theme_header_logo, theme_header_bg, theme_photo_url, theme_video_url,
            theme_title, theme_subtitle, theme_title_color, theme_widget_logo,
            theme_photo_url1, theme_photo_url2, theme_photo_url3,
            theme_photo_url4, theme_photo_url5, theme_photo_url6,
            theme_photo_url1_desc, theme_photo_url2_desc, theme_photo_url3_desc,
            theme_photo_url4_desc, theme_photo_url5_desc, theme_photo_url6_desc,
            theme_photo_url1_link, theme_photo_url2_link, theme_photo_url3_link,
            theme_photo_url4_link, theme_photo_url5_link, theme_photo_url6_link,
            df_messenger_bot_message,
            df_messenger_button_titlebar_color,
            df_messenger_button_titlebar_text,
            df_messenger_button_titlebar_font_color,
            df_messenger_input_box_color,
            df_messenger_input_font_color,
            df_messenger_chat_background_color,
            df_messenger_font_color,
            df_messenger_send_icon,
            df_messenger_user_message,
            df_messenger_chip_color } = this.state;
        const { dispatch } = this.props;

        let save = {
            "header_logo": theme_header_logo,
            "widget_logo": theme_widget_logo,
            "title": theme_title,
            "subtitle": theme_subtitle,
            "color_set": {
                "theme_header_bg": theme_header_bg,
                "theme_title_color": theme_title_color,
                "df_messenger_bot_message": df_messenger_bot_message,
                "df_messenger_button_titlebar_color": df_messenger_button_titlebar_color,
                "df_messenger_button_titlebar_font_color": df_messenger_button_titlebar_font_color,
                "df_messenger_button_titlebar_text": df_messenger_button_titlebar_text,
                "df_messenger_input_box_color": df_messenger_input_box_color,
                "df_messenger_input_font_color": df_messenger_input_font_color,
                "df_messenger_chat_background_color": df_messenger_chat_background_color,
                "df_messenger_font_color": df_messenger_font_color,
                "df_messenger_send_icon": df_messenger_send_icon,
                "df_messenger_user_message": df_messenger_user_message,
                "df_messenger_chip_color": df_messenger_chip_color,
            },
            "media": [
                { "media_url": theme_photo_url, "media_type": "image", "link": '', "description": "", "highlighted": false },
                { "media_url": theme_video_url, "media_type": "video", "link": '', "description": "", "highlighted": false },
                { "media_url": theme_photo_url1, "media_type": "image", "link": theme_photo_url1_link, "description": theme_photo_url1_desc, "highlighted": false },
                { "media_url": theme_photo_url2, "media_type": "image", "link": theme_photo_url2_link, "description": theme_photo_url2_desc, "highlighted": false },
                { "media_url": theme_photo_url3, "media_type": "image", "link": theme_photo_url3_link, "description": theme_photo_url3_desc, "highlighted": false },
                { "media_url": theme_photo_url4, "media_type": "image", "link": theme_photo_url4_link, "description": theme_photo_url4_desc, "highlighted": false },
                { "media_url": theme_photo_url5, "media_type": "image", "link": theme_photo_url5_link, "description": theme_photo_url5_desc, "highlighted": false },
                { "media_url": theme_photo_url6, "media_type": "image", "link": theme_photo_url6_link, "description": theme_photo_url6_desc, "highlighted": false },
            ]
        }
        dispatch(userActions.setTheme(save, 'Datos del estilo guardados'));
    }

    renderActions = (item, index) => {
        return (
            <TableCell key={index} classes={{ root: this.props.classes.cellRows }}>
                <Tooltip title={(item.role === 'admin') ? 'No es posible eliminar usuario' : "Eliminar usuario"}>
                    <Button classes={{ root: this.props.classes.cellButton }}
                        component="label"
                        disabled={(item.role === 'admin')}
                        onClick={() => {
                            const { dispatch } = this.props;
                            dispatch(userActions.deleteStoreUser(item.id, item.name));
                        }}
                    >
                        <DeleteIcon />
                    </Button>
                </Tooltip>
            </TableCell>
        )
    }

    renderFAQActions = (item, index) => {
        return (
            <TableCell key={index} classes={{ root: this.props.classes.cellRows }}>
                <Button classes={{ root: this.props.classes.cellButton }}
                    component="label"
                    onClick={() => {
                        item.response = null;
                        this.setState({
                            toSaveFAQ: true,
                        });
                    }}
                >
                    <DeleteIcon />
                </Button>
            </TableCell>
        )
    }

    renderFAQ = (item, index) => {
        return (<TableCell key={index} classes={{ root: this.props.classes.cellRows }}>
            {// eslint-disable-next-line react/jsx-no-bind
            }
            <TextField classes={{ root: this.props.classes.cell }} multiline maxRows={3} value={(item.response) ? item.response : ""}
                onChange={(event) => {
                    event.preventDefault();
                    item.response = event.target.value;
                    this.setState({
                        toSaveFAQ: true,
                    });
                }} />
        </TableCell>)
    }

    renderFAQType = (item, index) => {
        let text = '';
        if (item.faq_type === 'payment_methods') text = 'Métodos de pago'
        if (item.faq_type === 'store_location') text = 'Ubicación de la tienda'
        if (item.faq_type === 'delivery') text = 'Envíos'
        if (item.faq_type === 'freigt_cost') text = 'Costo de envío'
        if (item.faq_type === 'minimum_purchase') text = 'Cómpra mínima'

        return (<TableCell key={index} classes={{ root: this.props.classes.cellRows }}>
            {text}
        </TableCell>)
    }

    // renderPromoDay = (item, index) => {
    //     let day = '';
    //     if (item.day_of_week === "0") day = 'Por Defecto';
    //     if (item.day_of_week === "1") day = 'Lunes';
    //     if (item.day_of_week === "2") day = 'Martes';
    //     if (item.day_of_week === "3") day = 'Miercoles';
    //     if (item.day_of_week === "4") day = 'Jueves';
    //     if (item.day_of_week === "5") day = 'Viernes';
    //     if (item.day_of_week === "6") day = 'Sabado';
    //     if (item.day_of_week === "7") day = 'Domingo';
    //     return (<TableCell key={index} classes={{ root: this.props.classes.cellRows }}>
    //         {
    //             day
    //         }
    //     </TableCell>)
    // }

    // renderPromo = (item, param, index) => {

    //     const products = this.props.products.products;
    //     let items = [];
    //     item.selectedTag = item.tag;
    //     let found = false;
    //     if (products) {
    //         products.forEach((elem) => {
    //             elem.tags.forEach(tag => {
    //                 items.push(tag);
    //                 if (tag === item.tag)
    //                     found = true;
    //             })
    //         });
    //     }
    //     const promos = this.props.promos.promos;
    //     if (promos) {
    //         promos.forEach((elem) => {
    //             items.push(elem.tag);
    //             if (elem.tag === item.tag)
    //                 found = true;

    //         });
    //     }
    //     items = [...new Set(items)];
    //     items.sort();
    //     items.push("Ninguna");
    //     if (!found || item.selectedTag === '') item.selectedTag = 'Ninguna';


    //     return (<TableCell key={index} classes={{ root: this.props.classes.cellRows }}>
    //         <Select
    //             name={`${item.id}`}
    //             value={item.selectedTag}
    //             onChange={(event) => {
    //                 item.selectedTag = event.target.value;
    //                 if (event.target.value === 'Ninguna')
    //                     item.tag = '';
    //                 else item.tag = event.target.value;
    //             }}
    //             inputProps={{
    //                 name: `${item.id}`,
    //                 id: `select-type-${item.id}`,
    //             }}
    //         >
    //             {items.map((item, index) => (
    //                 <MenuItem key={index} value={item}>{item}</MenuItem>
    //             ))}
    //         </Select>
    //     </TableCell >)
    // }

    // renderPromoMsg = (item, param, index) => {
    //     return (<TableCell key={index} classes={{ root: this.props.classes.cellRows }}>


    //         {// eslint-disable-next-line react/jsx-no-bind
    //         }
    //         <Input
    //             id={item.id}
    //             classes={{ root: this.props.classes.cell }}
    //             multiline
    //             maxRows={3}
    //             autoFill={autoFillCal}
    //             value={(item[param]) ? item[param] : ""}
    //             onChange={(event) => {
    //                 event.preventDefault();
    //                 item[param] = event.target.value;
    //                 this.setState({
    //                     toSavePromos: true,
    //                 });
    //             }} />
    //     </TableCell>)
    // }

    getRandomString = () => {
        var randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var result = '';
        for (var i = 0; i < 8; i++) {
            result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
        }
        this.setState({
            password: result
        });
    }

    renderMethodInfo = (item, param, index) => {
        if (item.name === 'Mercadopago' && !item.method_info) {
            item.method_info = "Termina tu compra con el botón Pagar";
            const { dispatch } = this.props;
            this.setState({ toSavePM: false });
            dispatch(userActions.setStorePaymentMethods(this.props.paymentMethods.paymentMethods));
        }
        return (<TableCell key={index} classes={{ root: this.props.classes.cellRows }}>
            {// eslint-disable-next-line react/jsx-no-bind
            }
            <TextField id={item.id} classes={{ root: this.props.classes.cell }} multiline maxRows={3} value={(item[param]) ? item[param] : ""}
                onChange={(event) => {
                    event.preventDefault();
                    item[param] = event.target.value;
                    this.setState({
                        toSavePM: true,
                    });
                }} />
        </TableCell>)
    }

    renderMethodConfig = (item, param, index) => {
        if ((typeof item[param] !== 'object') || (Object.keys(item[param]).length === 0)) {
            return (<TableCell key={index + 10000} classes={{ root: this.props.classes.cellRows }}> - </TableCell>)
        }
        else {
            const comps = [];
            Object.keys(item[param]).forEach(key => {
                comps.push(
                    <Input id={key + index}
                        key={key + index}
                        multiline
                        maxRows={3}
                        value={(item[param][key]) ? item[param][key] : ""}
                        disableAuto={true}
                        label={key}
                        onChange={(event) => {
                            event.preventDefault();
                            item[param][key] = event.target.value;
                            this.setState({
                                toSavePM: true,
                            });
                        }} />)
            })
            return (<TableCell key={index + 11000} classes={{ root: this.props.classes.cellRows }}>
                {comps}
            </TableCell>)
        }
    }

    renderCheckbox = (item, param, index) => {
        return (<TableCell key={index} classes={{ root: this.props.classes.cellRows }}>
            <Checkbox id={item.id}
                checked={(item[param])}
                // eslint-disable-next-line react/jsx-no-bind
                onChange={(event) => {
                    if (event.target.checked) {
                        item[param] = true;
                        // const { dispatch } = this.props;
                        // dispatch(userActions.enable(item.id, `Cuenta ${item.hashtag} activada`));
                    }
                    else {
                        item[param] = false;
                        // const { dispatch } = this.props;
                        // dispatch(userActions.disable(item.id, `Cuenta ${item.hashtag} desactivada`));
                    }
                    this.setState({
                        toSavePM: true,
                    });
                }}
                inputProps={{ 'aria-label': 'primary checkbox' }}
            />
        </TableCell>)
    }

    totext = (array) => {
        var text = "";

        array.forEach(item => {
            text += item;
            text += '\n';
        });

        return text;
    }

    onImageCownload = (id, text) => {
        const svg = document.getElementById(id);
        const svgData = new XMLSerializer().serializeToString(svg);
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        const img = new Image();
        img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
        img.onload = () => {
            canvas.width = img.width + 100;
            canvas.height = img.height + 100;
            ctx.fillStyle = "#FFFFFF";
            ctx.fillRect(0, 0, canvas.width, canvas.height);
            ctx.drawImage(img, 50, 50);
            ctx.lineWidth = 1;
            ctx.fillStyle = "#000000";
            ctx.font = "17px sans-serif";
            ctx.fillText(this.props.user.store.hashtag, 50, 43);
            ctx.font = "11px sans-serif";
            ctx.fillText("ARTURITO.COM", 217, 320);
            const pngFile = canvas.toDataURL("image/png");
            const downloadLink = document.createElement("a");
            downloadLink.download = `QR_${text}${this.props.user.store.hashtag}`;
            downloadLink.href = `${pngFile}`;
            downloadLink.click();
        };
    };

    handleCloseCsvDialog = () => {
        this.setState({
            csvDialog: false,
            csvDialogText: '',
        });
    }

    getCsvLogs = (kind) => {
        let result = [];
        if (this.props.csvstatus.csvstatus && this.props.csvstatus.csvstatus.length > 0) {
            this.props.csvstatus.csvstatus.forEach(status => {
                if (status.kind === kind) {
                    result.push(status);
                }
            });
        }
        return result;
    }

    convertToLog = (array) => {
        let resultText = '';
        array.forEach(status => {
            // resultText += `Creado: ${convertDate(status.created_at)}\n`
            resultText += `Actualizado: ${convertDate(status.updated_at)}\n`
            resultText += `Tipo: ${status.kind}\n`
            resultText += `Status: ${status.status}\n`
            // resultText += `RutaArchivo: ${status.filepath}\n`
            resultText += `Error: ${status.error_msg}\n\n`
        });
        return resultText;
    }

    getLastStatus = (array) => {
        if (array.length < 1)
            return 'white';
        if (array[0].status === 'complete')
            return 'green';
        if (array[0].status === 'failed')
            return 'red';
        if (array[0].status === 'pending' || array[0].status === 'processing')
            return 'yellow';
    }

    renderCSV = (kind) => {
        const logs = this.getCsvLogs(kind);
        const stat = this.getLastStatus(logs);
        const txt = this.convertToLog(logs);

        return (
            <Button classes={{ root: this.props.classes.cellButton }}
                component="label"
                onClick={() => {
                    this.setState({
                        csvDialog: true,
                        csvDialogText: txt,
                    });
                }}
            >
                <DescriptionIcon
                    classes={
                        ((stat === 'red') ? { root: this.props.classes.icoRed } :
                            (stat === 'green') ? { root: this.props.classes.icoGreen } :
                                (stat === 'yellow') ? { root: this.props.classes.icoYellow } :
                                    { root: this.props.classes.cellFAQ })
                    }
                />
            </Button>
        )
    }

    render() {
        const { classes, user, users, paymentMethods, promos } = this.props;

        if (!user) {
            this.props.history.push('/login');
            return null;
        }

        const { value, value2, checkDelivery, checkTakeaway, woo_auto_products, woo_auto_orders,
            woo_key, woo_url, woo_secret, toSave, toSavePM,
            lang, password, emailUser, old_pass, new_pass, submittedPass,
            submitted, curr_symbol, errorCurrStore,
            submittedRegister, emailStore, tax_code, address, legal_name, postcode,
            phone, city, country, state, errorEmailStore,
            social_networks_facebook, social_networks_instagram, social_networks_whatsapp, social_networks_maps, social_networks_yt,
            theme_header_logo, theme_header_bg, theme_photo_url, theme_video_url,
            theme_title, theme_subtitle, theme_title_color, theme_widget_logo,
            theme_photo_url1, theme_photo_url2, theme_photo_url3,
            theme_photo_url4, theme_photo_url5, theme_photo_url6,
            theme_photo_url1_desc, theme_photo_url2_desc, theme_photo_url3_desc,
            theme_photo_url4_desc, theme_photo_url5_desc, theme_photo_url6_desc,
            theme_photo_url1_link, theme_photo_url2_link, theme_photo_url3_link,
            theme_photo_url4_link, theme_photo_url5_link, theme_photo_url6_link,
            df_messenger_bot_message,
            df_messenger_button_titlebar_color,
            df_messenger_button_titlebar_text,
            df_messenger_button_titlebar_font_color,
            df_messenger_input_box_color,
            df_messenger_input_font_color,
            df_messenger_chat_background_color,
            df_messenger_font_color,
            df_messenger_send_icon,
            df_messenger_user_message,
            df_messenger_chip_color,
            botDialogInit, botOrderInit, botOrderFinish,
            csvDialog, qrsList,
        } = this.state;
        const { handleChangeTab, handleChangeTab2 } = this;

        let titulo = "CONFIGURACION";

        return (
            <React.Fragment>
                <Appbar back={true} label={titulo} onBack={this.onBack} user={user} />
                <div className={classes.root}>
                    <Tabs
                        orientation="vertical"
                        variant="scrollable"
                        value={value}
                        onChange={handleChangeTab}
                        aria-label="CONFIGURACION"
                        className={classes.tabs}
                    >
                        <Tab label="ASISTENTE" {...a11yProps(0)} />
                        <Tab label="MI NEGOCIO" {...a11yProps(1)} />
                        <Tab label="ORDENES" {...a11yProps(2)} />
                        <Tab label="INTEGRACIONES" {...a11yProps(3)} />
                        <Tab label="PERSONALIZAR" {...a11yProps(4)} />
                        {(user.store_role === "admin") &&
                            <Tab label="PAGOS" {...a11yProps(5)} />
                        }
                        {(user.store_role === "admin") &&
                            <Tab label="SEGURIDAD" {...a11yProps(6)} />
                        }
                    </Tabs>
                    <TabPanel className={classes.tabpanel} value={value} index={0}>
                        <Typography
                            // className={classes.dividerInset}
                            // display="block"
                            component={'span'}
                            variant="h2"
                            color="primary"
                        >
                            Asistente de Configuración
                        </Typography>
                        <br /><br />
                        <Typography
                            className={classes.dividerInset}
                            color="primary"
                            variant="caption"
                        >
                            Pasos para completar la configuracion basica
                        </Typography>
                        <Divider className={classes.divider} />
                        <br />
                        <WizzarConfig user={this.props.user} activeStep={this.state.activeStep} dispatch={this.props.dispatch} />
                    </TabPanel>
                    <TabPanel className={classes.tabpanel} value={value} index={1}>
                        <Typography
                            // className={classes.dividerInset}
                            // display="block"
                            component={'span'}
                            variant="h2"
                            color="primary"
                        >
                            Perfil del Negocio
                        </Typography>
                        <br /><br />
                        <Typography
                            className={classes.dividerInset}
                            color="primary"
                            variant="caption"
                        >
                            Idioma y moneda
                        </Typography>
                        <Divider className={classes.divider} />
                        <br />
                        <FormControl className={classes.formControl}>
                            <InputLabel htmlFor="demo-controlled-open-select">Idioma</InputLabel>
                            <Select
                                value={lang}
                                onChange={this.handleChangeLang}
                                name='lang'
                                inputProps={{
                                    name: 'lang',
                                    id: 'select-type',
                                }}
                            >
                                <MenuItem value={'es'}>Español</MenuItem>
                                <MenuItem value={'po'}>Português</MenuItem>
                                <MenuItem value={'en'}>English</MenuItem>
                            </Select>
                            <Input
                                id='input-curr'
                                type='text'
                                label='Simbolo de la moneda'
                                // submitted={submitted}
                                error={errorCurrStore}
                                name='curr_symbol'
                                value={curr_symbol}
                                errorMessage="Por favor ingrese el simbolo de la moneda (menos de 3 caracteres)"
                                onChange={this.handleChange}
                            />
                        </FormControl>
                        <br />
                        {(user.store_role === "admin") &&
                            <FormControl className={classes.formControl}>
                                <Typography
                                    className={classes.dividerInset}
                                    color="primary"
                                    variant="caption"
                                >
                                    Datos de la empresa (solo los marcados * son obligatorios)
                                </Typography>
                                <Divider className={classes.divider} />
                                <Input
                                    id='input-legalname'
                                    type='text'
                                    label='Razón social'
                                    // submitted={submitted}
                                    name='legal_name'
                                    value={legal_name}
                                    errorMessage="Por favor ingrese la empresa"
                                    onChange={this.handleChange}
                                />
                                <Input
                                    id='input-cuit'
                                    type='text'
                                    label='Identificación fiscal (CUIT, CNPJ, NIT, RFC, etc) *'
                                    submitted={submittedRegister}
                                    name='tax_code'
                                    value={tax_code}
                                    errorMessage="Por favor ingrese identificacion fiscal"
                                    onChange={this.handleChange}
                                />
                                <Input
                                    id='input-email-store'
                                    type='text'
                                    label='Email de la empresa'
                                    // submitted={submitted}
                                    error={errorEmailStore}
                                    name='emailStore'
                                    value={emailStore}
                                    errorMessage="Por favor ingrese un email valido"
                                    onChange={this.handleChange}
                                />
                                <Input
                                    id='input-phone'
                                    type='text'
                                    label='Telefono'
                                    // submitted={submitted}
                                    name='phone'
                                    value={phone}
                                    errorMessage="Por favor ingrese un telefono valido"
                                    onChange={this.handleChange}
                                />
                                <Input
                                    id='input-address'
                                    type='text'
                                    label='Dirección'
                                    // submitted={submitted}
                                    name='address'
                                    value={address}
                                    errorMessage="Por favor ingrese la direccion"
                                    onChange={this.handleChange}
                                />
                                <Input
                                    id='input-city'
                                    type='text'
                                    label='Ciudad'
                                    // submitted={submitted}
                                    name='city'
                                    value={city}
                                    errorMessage="Por favor ingrese la ciudad"
                                    onChange={this.handleChange}
                                />
                                <Input
                                    id='input-postcode'
                                    type='text'
                                    label='CPostal'
                                    // submitted={submitted}
                                    name='postcode'
                                    value={postcode}
                                    errorMessage="Por favor ingrese el cod postal"
                                    onChange={this.handleChange}
                                />
                                <Input
                                    id='input-state'
                                    type='text'
                                    label='Provincia'
                                    // submitted={submitted}
                                    name='state'
                                    value={state}
                                    errorMessage="Por favor ingrese la provincia"
                                    onChange={this.handleChange}
                                />
                                <Input
                                    id='input-country'
                                    type='text'
                                    label='Pais'
                                    // submitted={submitted}
                                    name='country'
                                    value={country}
                                    errorMessage="Por favor ingrese el pais"
                                    onChange={this.handleChange}
                                />
                                <br />
                                <Tooltip title={'Por favor, presione para guardar los cambios'} placement="right" arrow>
                                    <Button variant="contained" color="primary" onClick={this.handleSubmitRegister}>
                                        Guardar
                                    </Button>
                                </Tooltip>
                            </FormControl>
                        }
                    </TabPanel>
                    <TabPanel className={classes.tabpanel} value={value} index={2}>
                        <Typography
                            // className={classes.dividerInset}
                            // display="block"
                            component={'span'}
                            variant="h2"
                            color="primary"
                        >
                            Ordenes
                        </Typography>
                        <br />
                        <Typography
                            // className={classes.dividerInset}
                            // display="block"
                            component={'span'}
                            variant="body1"
                            color="primary"
                        >
                            Configuración del medio de entrega del producto.
                        </Typography>
                        <br /><br />
                        <FormControlLabel className={classes.fullwidth}
                            control={<Switch checked={checkDelivery} value="checkDelivery" color="secondary" name="checkDelivery" onChange={this.handleChangeSwitchDelivery} />}
                            label="Ofrecer delivery"
                            labelPlacement="end"
                        />
                        <br /><br />
                        <FormControlLabel className={classes.fullwidth}
                            control={<Switch checked={checkTakeaway} value="checkTakeaway" color="secondary" name="checkTakeaway" onChange={this.handleChangeSwitchDelivery} />}
                            label="Ofrecer Takeaway"
                            labelPlacement="end"
                        />
                    </TabPanel>
                    <TabPanel className={classes.tabpanel} value={value} index={3}>
                        <Typography
                            // className={classes.dividerInset}
                            // display="block"
                            component={'span'}
                            variant="h2"
                            color="primary"
                        >
                            Integraciones
                        </Typography>
                        <br />
                        <Typography
                            // className={classes.dividerInset}
                            // display="block"
                            component={'span'}
                            variant="body1"
                            color="primary"
                        >
                            Configuración de la integración con woocommerce,  para actualización automática de datos.
                        </Typography>
                        <Input
                            id='input-woo-url'
                            label='WooCommerce Base URL'
                            type='text'
                            // submitted={submitted}
                            name='woo_url'
                            value={woo_url}
                            // errorMessage="Por favor ingrese la misma contraseña"
                            onChange={this.handleChange}
                        />
                        <Input
                            id='input-woo_key'
                            label='WooCommerce Api Key'
                            type='text'
                            // submitted={submitted}
                            name='woo_key'
                            value={woo_key}
                            // errorMessage="Por favor ingrese la misma contraseña"
                            onChange={this.handleChange}
                        />
                        <Input
                            id='input-woo-secret'
                            label='WooCommerce Secret'
                            type='text'
                            // submitted={submitted}
                            name='woo_secret'
                            value={woo_secret}
                            // errorMessage="Por favor ingrese la misma contraseña"
                            onChange={this.handleChange}
                        />
                        <br /><br />
                        <FormControlLabel className={classes.fullwidth}
                            control={<Switch checked={woo_auto_products} value="woo_auto_products" color="secondary" name="woo_auto_products" onChange={this.handleChangeSwitch} />}
                            label="Productos Auto"
                            labelPlacement="end"
                        />
                        <br /><br />
                        <FormControlLabel className={classes.fullwidth}
                            control={<Switch checked={woo_auto_orders} value="woo_auto_orders" color="secondary" name="woo_auto_orders" onChange={this.handleChangeSwitch} />}
                            label="Ordenes Auto"
                            labelPlacement="end"
                        />
                        <br /><br /><br /><br />
                        <Tooltip open={toSave} title={'Por favor, presione para guardar los cambios'} placement="right" arrow>
                            <Button variant="contained" color="primary" onClick={() => this.handleSave()} disabled={!toSave}>
                                Guardar
                            </Button>
                        </Tooltip>
                    </TabPanel>
                    <TabPanel className={classes.tabpanel} value={value} index={4}>
                        <Tabs
                            orientation="horizontal"
                            variant="scrollable"
                            value={value2}
                            onChange={handleChangeTab2}
                            aria-label="Personalizar"
                            className={classes.tabs2}
                        >
                            <Tab label="Asistente" {...a11yProps2(0)} />
                            <Tab label="Home" {...a11yProps2(1)} />
                            <Tab label="Widget" {...a11yProps2(2)} />
                        </Tabs>
                        <TabPanel className={classes.tabpanel} value={value2} index={0}>
                            <Grid container>
                                <Grid item xs={12} sm={6} lg={6}>
                                    <Typography
                                        // className={classes.dividerInset}
                                        // display="block"
                                        component={'span'}
                                        variant="h2"
                                        color="primary"
                                    >
                                        Personalizar BOT
                                    </Typography>
                                    <br /><br />
                                    <FormControl className={classes.formControl}>
                                        <Typography
                                            className={classes.dividerInset}
                                            color="primary"
                                            variant="caption"
                                        >
                                            Configuración mensajes del agente
                                        </Typography>
                                        <Divider className={classes.divider} />
                                        <Input
                                            id='input-initdialogbot'
                                            type='text'
                                            multiline
                                            maxRows={3}
                                            label='Saludo inicial'
                                            name='botDialogInit'
                                            value={botDialogInit}
                                            onChange={this.handleChange}
                                            autoFill={autoFillWelcome}
                                        />
                                        <Input
                                            id='input-initorderbot'
                                            type='text'
                                            multiline
                                            maxRows={3}
                                            autoFill={autoFillOrder}
                                            label='Empezar un pedido'
                                            name='botOrderInit'
                                            value={botOrderInit}
                                            onChange={this.handleChange}
                                        />
                                        <Input
                                            id='input-initdialogbot'
                                            type='text'
                                            multiline
                                            maxRows={3}
                                            autoFill={autoFillEnd}
                                            label='Finalizar un pedido'
                                            name='botOrderFinish'
                                            value={botOrderFinish}
                                            onChange={this.handleChange}
                                        />
                                        <Tooltip title={'Por favor, presione para guardar los cambios'} placement="right" arrow>
                                            <Button variant="contained" color="primary" onClick={this.handleSubmitHomeData}>
                                                Guardar datos agente
                                            </Button>
                                        </Tooltip>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6} lg={6}>
                                    <WidgetPreview
                                        weekMessages={(promos && promos.promos) ? promos.promos : []}
                                        refCodes={qrsList}
                                        welcomeMessage={botDialogInit}
                                        orderMessage={botOrderInit}
                                        endMessage={botOrderFinish}
                                        handleChangeTab={this.handleChangeTab}
                                        storeHash={this.props.user.store.hashtag}
                                        storeName={this.props.user.store.name}
                                    />
                                </Grid>
                            </Grid>
                        </TabPanel>
                        <TabPanel className={classes.tabpanel} value={value2} index={1}>
                            <Typography
                                // className={classes.dividerInset}
                                // display="block"
                                component={'span'}
                                variant="h2"
                                color="primary"
                            >
                                Personalizar Home de la empresa
                            </Typography>
                            <br /><br />
                            <FormControl className={classes.formControl}>
                                <Typography
                                    className={classes.dividerInset}
                                    color="primary"
                                    variant="caption"
                                >
                                    Datos de redes sociales
                                </Typography>
                                <Divider className={classes.divider} />
                                <Input
                                    id='input-urlws'
                                    type='text'
                                    label='url whatsapp'
                                    name='social_networks_whatsapp'
                                    value={social_networks_whatsapp}
                                    onChange={this.handleChange}
                                />
                                <Input
                                    id='input-urlface'
                                    type='text'
                                    label='url facebook'
                                    name='social_networks_facebook'
                                    value={social_networks_facebook}
                                    onChange={this.handleChange}
                                />
                                <Input
                                    id='input-urlinsta'
                                    type='text'
                                    label='url instagram'
                                    name='social_networks_instagram'
                                    value={social_networks_instagram}
                                    onChange={this.handleChange}
                                />
                                <Input
                                    id='input-urlmaps'
                                    type='text'
                                    label='url google maps'
                                    name='social_networks_maps'
                                    value={social_networks_maps}
                                    onChange={this.handleChange}
                                />
                                <Input
                                    id='input-urlyt'
                                    type='text'
                                    label='url canal Youtube'
                                    name='social_networks_yt'
                                    value={social_networks_yt}
                                    onChange={this.handleChange}
                                />
                                <br />
                                <Tooltip title={'Por favor, presione para guardar los cambios'} placement="right" arrow>
                                    <Button variant="contained" color="primary" onClick={this.handleSubmitHomeData}>
                                        Guardar datos redes sociales
                                    </Button>
                                </Tooltip>
                                <br /><br />
                                <Typography
                                    className={classes.dividerInset}
                                    color="primary"
                                    variant="caption"
                                >
                                    Datos de la pantalla home
                                </Typography>
                                <Divider className={classes.divider} />
                                <br />
                                <div className={classes.inline}>
                                    <Input
                                        id='input-urllogo'
                                        type='text'
                                        label='url Logo'
                                        name='theme_header_logo'
                                        value={theme_header_logo}
                                        onChange={this.handleChange}
                                    />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <img src={theme_header_logo} alt='' height="40px"></img>
                                </div>
                                <Typography
                                    color="primary"
                                    variant="caption"
                                >
                                    Tamaño minimo 1000x200 px, PNG o JPG. Formato recomendado 5:1.
                                </Typography>
                                <br />
                                <div className={classes.inline}>
                                    <ColorPicker
                                        value={theme_header_bg}
                                        onChange={color => {
                                            this.setState({
                                                theme_header_bg: `#${color.hex}`,
                                            })
                                        }}
                                    />
                                    &nbsp;&nbsp;
                                    <Typography
                                        color="primary"
                                        variant="caption"
                                    >
                                        Color de fondo encabezado
                                    </Typography>
                                </div>
                                <div className={classes.inline}>
                                    <ColorPicker
                                        value={theme_title_color}
                                        onChange={color => {
                                            this.setState({
                                                theme_title_color: `#${color.hex}`,
                                            })
                                        }} />
                                    &nbsp;&nbsp;
                                    <Typography
                                        color="primary"
                                        variant="caption"
                                    >
                                        Color de titulo
                                    </Typography>
                                </div>
                                <Input
                                    id='input-titlehome'
                                    type='text'
                                    label='titulo home'
                                    name='theme_title'
                                    value={theme_title}
                                    onChange={this.handleChange}
                                />
                                <br />
                                <Typography
                                    color="primary"
                                    variant="caption"
                                >
                                    Subtitulo home
                                </Typography>
                                <TextField
                                    id='input-subtitlehome'
                                    multiline
                                    maxRows={10}
                                    name='theme_subtitle'
                                    value={theme_subtitle}
                                    onChange={this.handleChange}
                                    variant="filled"
                                />
                                <br />
                                <div className={classes.inline}>
                                    <Input
                                        id='input-urlimage'
                                        type='text'
                                        label='url imagen principal'
                                        name='theme_photo_url'
                                        value={theme_photo_url}
                                        onChange={this.handleChange}
                                    />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <img src={theme_photo_url} alt='' height="40px"></img>
                                </div>
                                <Typography
                                    color="primary"
                                    variant="caption"
                                >
                                    Tamaño minimo 800x800 px, PNG o JPG. Formato recomendado 1:1 o 4:3.
                                </Typography>
                                <br />
                                <div className={classes.inline}>
                                    <Input
                                        id='input-urlvideo'
                                        type='text'
                                        label='id video principal'
                                        name='theme_video_url'
                                        value={theme_video_url}
                                        onChange={this.handleChange}
                                    />
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <Typography
                                        color="primary"
                                        variant="caption"
                                    >
                                        (ID de video de youtube)
                                    </Typography>
                                </div>
                                <br /><br />
                                <Typography
                                    className={classes.dividerInset}
                                    color="primary"
                                    variant="caption"
                                >
                                    Categorias/promos del home <br />
                                </Typography>
                                <Divider className={classes.divider} />
                                <Typography
                                    className={classes.dividerInset}
                                    color="primary"
                                    variant="caption"
                                >
                                    urls: deben ser completas, incluyendo el https://, ejemplo: https://arturito.chat)
                                </Typography>
                                <Typography
                                    color="primary"
                                    variant="caption"
                                >
                                    Imagen: Tamaño minimo 800x800 px, PNG o JPG. Formato recomendado 1:1 o 4:3.
                                </Typography>
                                <br />
                                <div className={classes.inline}>
                                    <Input
                                        id='input-urlimage1'
                                        type='text'
                                        label='url imagen 1'
                                        name='theme_photo_url1'
                                        value={theme_photo_url1}
                                        onChange={this.handleChange}
                                    />
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <img src={theme_photo_url1} alt='' height="40px"></img>
                                </div>
                                <br />
                                <div className={classes.inline}>
                                    <Input
                                        id='input-urlimage12'
                                        type='text'
                                        label='descripción imagen 1'
                                        name='theme_photo_url1_desc'
                                        value={theme_photo_url1_desc}
                                        onChange={this.handleChange}
                                    />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <Input
                                        id='input-urlimage13'
                                        type='text'
                                        label='link imagen 1'
                                        name='theme_photo_url1_link'
                                        value={theme_photo_url1_link}
                                        onChange={this.handleChange}
                                    />
                                </div>
                                <br />
                                <div className={classes.inline}>
                                    <Input
                                        id='input-urlimage2'
                                        type='text'
                                        label='url imagen 2'
                                        name='theme_photo_url2'
                                        value={theme_photo_url2}
                                        onChange={this.handleChange}
                                    />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <img src={theme_photo_url2} alt='' height="40px"></img>
                                </div>
                                <br />
                                <div className={classes.inline}>
                                    <Input
                                        id='input-urlimage22'
                                        type='text'
                                        label='descripción imagen 2'
                                        name='theme_photo_url2_desc'
                                        value={theme_photo_url2_desc}
                                        onChange={this.handleChange}
                                    />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <Input
                                        id='input-urlimage23'
                                        type='text'
                                        label='link imagen 2'
                                        name='theme_photo_url2_link'
                                        value={theme_photo_url2_link}
                                        onChange={this.handleChange}
                                    />
                                </div>
                                <br />
                                <div className={classes.inline}>
                                    <Input
                                        id='input-urlimage3'
                                        type='text'
                                        label='url imagen 3'
                                        name='theme_photo_url3'
                                        value={theme_photo_url3}
                                        onChange={this.handleChange}
                                    />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <img src={theme_photo_url3} alt='' height="40px"></img>
                                </div>
                                <br />
                                <div className={classes.inline}>
                                    <Input
                                        id='input-urlimage32'
                                        type='text'
                                        label='descripción imagen 3'
                                        name='theme_photo_url3_desc'
                                        value={theme_photo_url3_desc}
                                        onChange={this.handleChange}
                                    />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <Input
                                        id='input-urlimage33'
                                        type='text'
                                        label='link imagen 3'
                                        name='theme_photo_url3_link'
                                        value={theme_photo_url3_link}
                                        onChange={this.handleChange}
                                    />
                                </div>
                                <br />
                                <div className={classes.inline}>
                                    <Input
                                        id='input-urlimage4'
                                        type='text'
                                        label='url imagen 4'
                                        name='theme_photo_url4'
                                        value={theme_photo_url4}
                                        onChange={this.handleChange}
                                    />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <img src={theme_photo_url4} alt='' height="40px"></img>
                                </div>
                                <br />
                                <div className={classes.inline}>
                                    <Input
                                        id='input-urlimage42'
                                        type='text'
                                        label='descripción imagen 4'
                                        name='theme_photo_url4_desc'
                                        value={theme_photo_url4_desc}
                                        onChange={this.handleChange}
                                    />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <Input
                                        id='input-urlimage43'
                                        type='text'
                                        label='link imagen 4'
                                        name='theme_photo_url4_link'
                                        value={theme_photo_url4_link}
                                        onChange={this.handleChange}
                                    />
                                </div>
                                <br />
                                <div className={classes.inline}>
                                    <Input
                                        id='input-urlimage5'
                                        type='text'
                                        label='url imagen 5'
                                        name='theme_photo_url5'
                                        value={theme_photo_url5}
                                        onChange={this.handleChange}
                                    />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <img src={theme_photo_url5} alt='' height="40px"></img>
                                </div>
                                <br />
                                <div className={classes.inline}>
                                    <Input
                                        id='input-urlimage52'
                                        type='text'
                                        label='descripción imagen 5'
                                        name='theme_photo_url5_desc'
                                        value={theme_photo_url5_desc}
                                        onChange={this.handleChange}
                                    />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <Input
                                        id='input-urlimage53'
                                        type='text'
                                        label='link imagen 5'
                                        name='theme_photo_url5_link'
                                        value={theme_photo_url5_link}
                                        onChange={this.handleChange}
                                    />
                                </div>
                                <br />
                                <div className={classes.inline}>
                                    <Input
                                        id='input-urlimage6'
                                        type='text'
                                        label='url imagen 6'
                                        name='theme_photo_url6'
                                        value={theme_photo_url6}
                                        onChange={this.handleChange}
                                    />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <img src={theme_photo_url6} alt='' height="40px"></img>
                                </div>
                                <br />
                                <div className={classes.inline}>
                                    <Input
                                        id='input-urlimage62'
                                        type='text'
                                        label='descripción imagen 6'
                                        name='theme_photo_url6_desc'
                                        value={theme_photo_url6_desc}
                                        onChange={this.handleChange}
                                    />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <Input
                                        id='input-urlimage63'
                                        type='text'
                                        label='link imagen 6'
                                        name='theme_photo_url6_link'
                                        value={theme_photo_url6_link}
                                        onChange={this.handleChange}
                                    />
                                </div>
                                <br />
                                <div className={classes.inline}>
                                    <Tooltip title={'Debe guardar los cambios para probarlos'} placement="right" arrow>
                                        <Button color="primary" onClick={(() => { window.open(`https://arturito.chat/s/${this.props.user.store.hashtag.substring(1)}`, '_blank').focus(); })}>
                                            Probar cambios home
                                        </Button>
                                    </Tooltip>
                                    &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
                                    <Button color="primary" onClick={this.resetHomeOptions}>
                                        Reiniciar cambios
                                    </Button>
                                </div>
                                <Tooltip title={'Por favor, presione para guardar los cambios'} placement="right" arrow>
                                    <Button variant="contained" color="primary" onClick={this.handleSubmitTheme}>
                                        Guardar datos home
                                    </Button>
                                </Tooltip>
                            </FormControl>
                        </TabPanel>
                        <TabPanel className={classes.tabpanel} value={value2} index={2}>
                            <Typography
                                // className={classes.dividerInset}
                                // display="block"
                                component={'span'}
                                variant="h2"
                                color="primary"
                            >
                                Personalizar Widget
                            </Typography>
                            <br /> <br />
                            <FormControl className={classes.formControl}>
                                <Typography
                                    className={classes.dividerInset}
                                    color="primary"
                                    variant="caption"
                                >
                                    Datos del widget
                                </Typography>
                                <Divider className={classes.divider} />
                                <br />
                                <div className={classes.inline}>
                                    <Input
                                        id='input-urlwidget'
                                        type='text'
                                        label='url avatar widget'
                                        name='theme_widget_logo'
                                        value={theme_widget_logo}
                                        onChange={this.handleChange}
                                    />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <img src={theme_widget_logo} alt='' height="40px"></img>
                                </div>
                                <Typography
                                    color="primary"
                                    variant="caption"
                                >
                                    Tamaño minimo 640x640 px, PNG o JPG(recomendado). Formato cuadrado. Sin fondo transparente.
                                </Typography>
                                <br />
                                <Input
                                    id='input-chattitle'
                                    type='text'
                                    label='Titulo barra widget'
                                    name='df_messenger_button_titlebar_text'
                                    value={df_messenger_button_titlebar_text}
                                    onChange={this.handleChange}
                                />
                                <br />
                                <div className={classes.inline}>
                                    <ColorPicker
                                        value={df_messenger_button_titlebar_color}
                                        onChange={color => {
                                            this.setState({
                                                df_messenger_button_titlebar_color: `#${color.hex}`,
                                            })
                                        }} />
                                    &nbsp;&nbsp;
                                    <Typography
                                        color="primary"
                                        variant="caption"
                                    >
                                        Color barra titulo
                                    </Typography>
                                </div>
                                <div className={classes.inline}>
                                    <ColorPicker
                                        value={df_messenger_button_titlebar_font_color}
                                        onChange={color => {
                                            this.setState({
                                                df_messenger_button_titlebar_font_color: `#${color.hex}`,
                                            })
                                        }} />
                                    &nbsp;&nbsp;
                                    <Typography
                                        color="primary"
                                        variant="caption"
                                    >
                                        Color texto barra titulo
                                    </Typography>
                                </div>
                                <div className={classes.inline}>
                                    <ColorPicker
                                        value={df_messenger_chat_background_color}
                                        onChange={color => {
                                            this.setState({
                                                df_messenger_chat_background_color: `#${color.hex}`,
                                            })
                                        }} />
                                    &nbsp;&nbsp;
                                    <Typography
                                        color="primary"
                                        variant="caption"
                                    >
                                        Color fondo chat
                                    </Typography>
                                </div>
                                <div className={classes.inline}>
                                    <ColorPicker
                                        value={df_messenger_font_color}
                                        onChange={color => {
                                            this.setState({
                                                df_messenger_font_color: `#${color.hex}`,
                                            })
                                        }} />
                                    &nbsp;&nbsp;
                                    <Typography
                                        color="primary"
                                        variant="caption"
                                    >
                                        Color fuente chat
                                    </Typography>
                                </div>
                                <div className={classes.inline}>
                                    <ColorPicker
                                        value={df_messenger_bot_message}
                                        onChange={color => {
                                            this.setState({
                                                df_messenger_bot_message: `#${color.hex}`,
                                            })
                                        }} />
                                    &nbsp;&nbsp;
                                    <Typography
                                        color="primary"
                                        variant="caption"
                                    >
                                        Color mensaje bot
                                    </Typography>
                                </div>
                                <div className={classes.inline}>
                                    <ColorPicker
                                        value={df_messenger_user_message}
                                        onChange={color => {
                                            this.setState({
                                                df_messenger_user_message: `#${color.hex}`,
                                            })
                                        }} />
                                    &nbsp;&nbsp;
                                    <Typography
                                        color="primary"
                                        variant="caption"
                                    >
                                        Color mensaje usuario
                                    </Typography>
                                </div>
                                <div className={classes.inline}>
                                    <ColorPicker
                                        value={df_messenger_input_box_color}
                                        onChange={color => {
                                            this.setState({
                                                df_messenger_input_box_color: `#${color.hex}`,
                                            })
                                        }} />
                                    &nbsp;&nbsp;
                                    <Typography
                                        color="primary"
                                        variant="caption"
                                    >
                                        Color fondo cuadro ingreso
                                    </Typography>
                                </div>
                                <div className={classes.inline}>
                                    <ColorPicker
                                        value={df_messenger_chip_color}
                                        onChange={color => {
                                            this.setState({
                                                df_messenger_chip_color: `#${color.hex}`,
                                            })
                                        }} />
                                    &nbsp;&nbsp;
                                    <Typography
                                        color="primary"
                                        variant="caption"
                                    >
                                        Color botones (opciones) chat
                                    </Typography>
                                </div>
                                <div className={classes.inline}>
                                    <ColorPicker
                                        value={df_messenger_input_font_color}
                                        onChange={color => {
                                            this.setState({
                                                df_messenger_input_font_color: `#${color.hex}`,
                                            })
                                        }} />
                                    &nbsp;&nbsp;
                                    <Typography
                                        color="primary"
                                        variant="caption"
                                    >
                                        Color texto cuadro ingreso
                                    </Typography>
                                </div>
                                <div className={classes.inline}>
                                    <ColorPicker
                                        value={df_messenger_send_icon}
                                        onChange={color => {
                                            this.setState({
                                                df_messenger_send_icon: `#${color.hex}`,
                                            })
                                        }} />
                                    &nbsp;&nbsp;
                                    <Typography
                                        color="primary"
                                        variant="caption"
                                    >
                                        Color boton enviar mensaje
                                    </Typography>
                                </div>
                                <br />
                                <div className={classes.inline}>
                                    <Tooltip title={'NO es necesario guardar los cambios para probarlos'} placement="right" arrow>
                                        <Button color="primary" onClick={this.updateWidgetStyle}>
                                            Probar cambios
                                        </Button>
                                    </Tooltip>
                                    &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
                                    <Button color="primary" onClick={this.resetWidgetOptions}>
                                        Reiniciar cambios
                                    </Button>
                                </div>
                                <Tooltip title={'Por favor, presione para guardar los cambios'} placement="right" arrow>
                                    <Button variant="contained" color="primary" onClick={this.handleSubmitTheme}>
                                        Guardar cambios widget
                                    </Button>
                                </Tooltip>
                            </FormControl>
                        </TabPanel>
                    </TabPanel>
                    {
                        (user.store_role === "admin") &&
                        <TabPanel className={classes.tabpanel} value={value} index={6}>
                            <Typography
                                // className={classes.dividerInset}
                                // display="block"
                                component={'span'}
                                variant="h2"
                                color="primary"
                            >
                                Seguridad de la cuenta
                            </Typography>
                            <br /><br />
                            <Typography
                                className={classes.dividerInset}
                                color="primary"
                                variant="caption"
                            >
                                Cambiar contraseña
                            </Typography>
                            <Divider className={classes.divider} />
                            <br />
                            <FormControl className={classes.formControl}>
                                <Input
                                    id='input-oldpass'
                                    label='Contraseña actual'
                                    type='password'
                                    eye={true}
                                    submitted={submittedPass}
                                    name='old_pass'
                                    value={old_pass}
                                    errorMessage="Por favor ingrese la contraseña actual valida"
                                    onChange={this.handleChange}
                                />
                                <Input
                                    id='input-newpass'
                                    type='password'
                                    eye={true}
                                    label='Contraseña nueva'
                                    submitted={submittedPass}
                                    name='new_pass'
                                    value={new_pass}
                                    errorMessage="Por favor ingrese la nueva contraseña"
                                    onChange={this.handleChange}
                                />
                                <br />
                                <Tooltip title={'Por favor, presione para guardar los cambios'} placement="right" arrow>
                                    <Button variant="contained" color="primary" onClick={this.handleSubmitChangePass}>
                                        Guardar
                                    </Button>
                                </Tooltip>
                            </FormControl>
                            <br /> <br />
                            <Typography
                                // className={classes.dividerInset}
                                // display="block"
                                component={'span'}
                                variant="h2"
                                color="primary"
                            >
                                Usuarios
                            </Typography>
                            <br />
                            <Typography
                                // className={classes.dividerInset}
                                // display="block"
                                component={'span'}
                                variant="body1"
                                color="primary"
                            >
                                Alta y baja de usuarios del store.
                            </Typography>
                            <br /><br /><br />
                            <Typography
                                className={classes.dividerInset}
                                display="block"
                                variant="caption"
                                color="primary"
                            >
                                Agregar usuario
                            </Typography>
                            <Divider className={classes.divider} />
                            <Input
                                id='input-email-user2'
                                type='text'
                                label='Email del usuario'
                                submitted={submitted}
                                fullWidth={false}
                                disableAuto={true}
                                name='emailUser'
                                value={emailUser}
                                errorMessage="Por favor ingrese un email"
                                onChange={this.handleChange}
                            />
                            <Input
                                id='input-pass2'
                                type='password'
                                eye={true}
                                label='Contraseña'
                                disableAuto={true}
                                fullWidth={false}
                                submitted={submitted}
                                name='password'
                                value={password}
                                errorMessage={"Por favor ingrese la contraseña"}
                                onChange={this.handleChange}
                            />

                            <Button classes={{ root: this.props.classes.cellButton }}
                                component="label"
                                onClick={this.getRandomString}
                            >
                                <Tooltip title="Generar Contraseña">
                                    <Cached />
                                </Tooltip>
                            </Button>
                            <Button classes={{ root: this.props.classes.cellButton }}
                                component="label"
                                onClick={this.handleSubmit}
                            >
                                <Tooltip title="Agregar usuario">
                                    <AddCircle />
                                </Tooltip>
                            </Button>
                            <br /><br /><br />
                            <Typography
                                className={classes.dividerInset}
                                display="block"
                                variant="caption"
                                color="primary"
                            >
                                Lista de usuarios activos
                            </Typography>
                            <Divider className={classes.divider} />
                            <ResponsiveTable
                                headCells={[
                                    // {
                                    //     id: 'id',
                                    //     label: 'ID'
                                    // },
                                    {
                                        id: 'email',
                                        label: 'EMAIL'
                                    },
                                    {
                                        id: 'role',
                                        label: 'ROL'
                                    },
                                    {
                                        id: 'delet',
                                        label: 'ELIMINAR',
                                        noSortable: true,
                                        render: (user, index) => this.renderActions(user, index + 4000)
                                    },
                                ]}
                                rows={(users && users.users) ? users.users : []}
                            />
                        </TabPanel>
                    }
                    {
                        (user.store_role === "admin") &&
                        <TabPanel className={classes.tabpanel} value={value} index={5}>
                            <Typography
                                // className={classes.dividerInset}
                                // display="block"
                                component={'span'}
                                variant="h2"
                                color="primary"
                            >
                                Pagos
                            </Typography>
                            <br />
                            <Typography
                                // className={classes.dividerInset}
                                // display="block"
                                component={'span'}
                                variant="body1"
                                color="primary"
                            >
                                Define los pagos aceptados por la tienda y su información.
                            </Typography>
                            <br /><br /><br />
                            <ResponsiveTable
                                headCells={[
                                    // {
                                    //     id: 'id',
                                    //     label: 'ID'
                                    // },
                                    {
                                        id: 'name',
                                        label: 'METODO'
                                    },
                                    {
                                        id: 'enabled_delivery',
                                        label: 'Delivery',
                                        noSortable: true,
                                        render: (item, index) => this.renderCheckbox(item, 'enabled_delivery', index + 1000)
                                    },
                                    {
                                        id: 'enabled_pickup',
                                        label: 'Pickup',
                                        noSortable: true,
                                        render: (item, index) => this.renderCheckbox(item, 'enabled_pickup', index + 2000)
                                    },
                                    {
                                        id: 'method_info',
                                        label: 'MENSAJE AL CLIENTE',
                                        noSortable: true,
                                        render: (item, index) => this.renderMethodInfo(item, 'method_info', index + 3000)
                                    },
                                    {
                                        id: 'config',
                                        label: 'CONFIGURACION',
                                        noSortable: true,
                                        render: (item, index) => this.renderMethodConfig(item, 'config', index + 14000)
                                    },
                                ]}
                                rows={(paymentMethods && paymentMethods.paymentMethods) ? paymentMethods.paymentMethods : []}
                            />
                            <br />
                            <Button classes={{ root: this.props.classes.cellButton }}
                                component="label"
                                onClick={() => {
                                    const { dispatch } = this.props;
                                    this.setState({ toSavePM: false });
                                    dispatch(userActions.getStorePaymentMethods());
                                }}
                            >
                                <Tooltip title="Recargar datos">
                                    <Cached />
                                </Tooltip>
                            </Button>
                            <Button classes={{ root: this.props.classes.cellButton }}
                                component="label"
                                onClick={() => {
                                    const { dispatch } = this.props;
                                    this.setState({ toSavePM: false });
                                    dispatch(userActions.setStorePaymentMethods(paymentMethods.paymentMethods));
                                }}
                            >
                                <Tooltip open={toSavePM} title={'Por favor, presione para guardar los cambios'} placement="right" arrow>
                                    <CheckCircleOutline />
                                </Tooltip>
                            </Button>
                        </TabPanel>
                    }
                    {/* <TabPanel value={value} index={3}>
                        <Typography
                            // className={classes.dividerInset}
                            // display="block"
                            component={'span'}
                            variant="h2"
                            color="primary"
                        >
                            Productos
                        </Typography>
                        <br /><br />
                        <Typography
                            // className={classes.dividerInset}
                            // display="block"
                            component={'span'}
                            variant="h2"
                            color="primary"
                        >
                            Acciones y plantillas para operar sobre la lista de productos.
                        </Typography>
                        <br /><br />
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>Función masiva</StyledTableCell>
                                        <StyledTableCell>Modelo</StyledTableCell>
                                        <StyledTableCell>Descripción</StyledTableCell>
                                        <StyledTableCell>Subir archivo</StyledTableCell>
                                        <StyledTableCell>Estado&nbsp;&nbsp;
                                            <Button classes={{ root: this.props.classes.cellButton }}
                                                component="label"
                                                onClick={() => {
                                                    const { dispatch } = this.props;
                                                    dispatch(userActions.getCSVStatusStore());
                                                }}
                                            >
                                                <Tooltip title="Refrescar estado">
                                                    <Cached color="secondary" />
                                                </Tooltip>
                                            </Button>
                                        </StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <StyledTableRow key={0}>
                                        <StyledTableCell>Agregar o actualizar productos</StyledTableCell>
                                        <StyledTableCell>
                                            <a href="modelolistado.csv" target="_blank" className={classes.link}>
                                                <Typography
                                                    // className={classes.dividerInset}
                                                    // display="block"
                                                    component={'span'}
                                                    variant="body1"
                                                    color="primary"
                                                    className={classes.link}
                                                >
                                                    CSV <DescriptionIcon />
                                                </Typography>
                                            </a>
                                            <br />
                                            <a href="modelolistado.xlsx" target="_blank" className={classes.link}>
                                                <Typography
                                                    // className={classes.dividerInset}
                                                    // display="block"
                                                    component={'span'}
                                                    variant="body1"
                                                    color="primary"
                                                    className={classes.link}
                                                >
                                                    EXCEL <DescriptionIcon />
                                                </Typography>
                                            </a>
                                        </StyledTableCell>
                                        <StyledTableCell>Crea productos nuevos o actualiza los que ya existen.<br />
                                            Para actualizar un producto existente debes usar todos los campos<br />
                                            y respetar los nombres de columnas del ejemplo.
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            <Button component="label" className={classes.link}>
                                                <PublishIcon />
                                                <input
                                                    type="file"
                                                    hidden
                                                    onChange={(event) => {
                                                        // endp /store/products_file
                                                        this.handleCSVList(event);
                                                    }}
                                                />
                                            </Button>
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            {this.renderCSV('products')}
                                        </StyledTableCell>
                                    </StyledTableRow>
                                    <StyledTableRow key={1}>
                                        <StyledTableCell>Actualizar precios</StyledTableCell>
                                        <StyledTableCell>
                                            <a href="modeloprecios.csv" target="_blank" className={classes.link}>
                                                <Typography
                                                    // className={classes.dividerInset}
                                                    // display="block"
                                                    component={'span'}
                                                    variant="body1"
                                                    color="primary"
                                                    className={classes.link}
                                                >
                                                    CSV <DescriptionIcon />
                                                </Typography>
                                            </a>
                                            <br />
                                            <a href="modeloprecios.xlsx" target="_blank" className={classes.link}>
                                                <Typography
                                                    // className={classes.dividerInset}
                                                    // display="block"
                                                    component={'span'}
                                                    variant="body1"
                                                    color="primary"
                                                    className={classes.link}
                                                >
                                                    EXCEL <DescriptionIcon />
                                                </Typography>
                                            </a>
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            Cambia los precios de productos ya existentes.<br />
                                            Utiliza la tabla de ejemplo, poniendo el codigo y el nuevo precio del producto.
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            <Button component="label" className={classes.link}>
                                                <PublishIcon />
                                                <input
                                                    type="file"
                                                    hidden
                                                    onChange={(event) => {
                                                        // endp /store/product_prices
                                                        this.handleCSVPrices(event);
                                                    }}
                                                />
                                            </Button>
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            {this.renderCSV('products-price')}
                                        </StyledTableCell>
                                    </StyledTableRow>
                                    <StyledTableRow key={2}>
                                        <StyledTableCell>Agregar unidades de stock</StyledTableCell>
                                        <StyledTableCell>
                                            <a href="modelostock.csv" target="_blank" className={classes.link}>
                                                <Typography
                                                    // className={classes.dividerInset}
                                                    // display="block"
                                                    component={'span'}
                                                    variant="body1"
                                                    color="primary"
                                                    className={classes.link}
                                                >
                                                    CSV <DescriptionIcon />
                                                </Typography>
                                            </a>
                                            <br />
                                            <a href="modelostock.xlsx" target="_blank" className={classes.link}>
                                                <Typography
                                                    // className={classes.dividerInset}
                                                    // display="block"
                                                    component={'span'}
                                                    variant="body1"
                                                    color="primary"
                                                    className={classes.link}
                                                >
                                                    EXCEL <DescriptionIcon />
                                                </Typography>
                                            </a>
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            Agregar cantidades de stock a las ya existentes. <br />
                                            Descarga aqui el stock actual de todos tus productos (CSV):
                                            <Button component="label" className={classes.link} onClick={() => this.handleGetStockCSV()}>
                                                <ShoppingCartOutlined />
                                            </Button>
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            <Button component="label" className={classes.link}>
                                                <PublishIcon />
                                                <input
                                                    type="file"
                                                    hidden
                                                    onChange={(event) => {
                                                        // endp /store/products_stock
                                                        this.handleCSVStock(event);
                                                    }}
                                                />
                                            </Button>
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            {this.renderCSV('products-stock')}
                                        </StyledTableCell>
                                    </StyledTableRow>
                                    <StyledTableRow key={3}>
                                        <StyledTableCell>Actualización selectiva de productos</StyledTableCell>
                                        <StyledTableCell>
                                            <a href="modeloupdate.csv" target="_blank" className={classes.link}>
                                                <Typography
                                                    // className={classes.dividerInset}
                                                    // display="block"
                                                    component={'span'}
                                                    variant="body1"
                                                    color="primary"
                                                    className={classes.link}
                                                >
                                                    CSV <DescriptionIcon />
                                                </Typography>
                                            </a>
                                            <br />
                                            <a href="modeloupdate.xlsx" target="_blank" className={classes.link}>
                                                <Typography
                                                    // className={classes.dividerInset}
                                                    // display="block"
                                                    component={'span'}
                                                    variant="body1"
                                                    color="primary"
                                                    className={classes.link}
                                                >
                                                    EXCEL <DescriptionIcon />
                                                </Typography>
                                            </a>
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            Actualización de productos ya existentes.<br />
                                            En esta operación actualiza sólo los campos que te interesan.<br />
                                            la única columna indispensable es la de ID, <br />
                                            el resto es opcional según que atributos se quieran actualizar.
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            <Button component="label" className={classes.link}>
                                                <PublishIcon />
                                                <input
                                                    type="file"
                                                    hidden
                                                    onChange={(event) => {
                                                        // endp /store/products_update
                                                        this.handleCSVUpdate(event);
                                                    }}
                                                />
                                            </Button>
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            {this.renderCSV('products-update')}
                                        </StyledTableCell>
                                    </StyledTableRow>
                                    <StyledTableRow key={4}>
                                        <StyledTableCell>Eliminar productos</StyledTableCell>
                                        <StyledTableCell>
                                            <a href="modeloeliminar.csv" target="_blank" className={classes.link}>
                                                <Typography
                                                    // className={classes.dividerInset}
                                                    // display="block"
                                                    component={'span'}
                                                    variant="body1"
                                                    color="primary"
                                                    className={classes.link}
                                                >
                                                    CSV <DescriptionIcon />
                                                </Typography>
                                            </a>
                                            <br />
                                            <a href="modeloeliminar.xlsx" target="_blank" className={classes.link}>
                                                <Typography
                                                    // className={classes.dividerInset}
                                                    // display="block"
                                                    component={'span'}
                                                    variant="body1"
                                                    color="primary"
                                                    className={classes.link}
                                                >
                                                    EXCEL <DescriptionIcon />
                                                </Typography>
                                            </a>
                                        </StyledTableCell>
                                        <StyledTableCell>Elimina productos de forma masiva.</StyledTableCell>
                                        <StyledTableCell>
                                            <Button component="label" className={classes.link}>
                                                <PublishIcon />
                                                <input
                                                    type="file"
                                                    hidden
                                                    onChange={(event) => {
                                                        // endp /store/products_delete
                                                        this.handleCSVDelete(event);
                                                    }}
                                                />
                                            </Button>
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            {this.renderCSV('products-delete')}
                                        </StyledTableCell>
                                    </StyledTableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <br /> <br />
                        <Typography
                            // className={classes.dividerInset}
                            // display="block"
                            component={'span'}
                            variant="body1"
                            color="primary"
                        >
                            * Instrucciones:
                        </Typography>
                        <br /><br />
                        <Typography
                            // className={classes.dividerInset}
                            // display="block"
                            component={'span'}
                            variant="body1"
                            color="primary"
                        >
                            - Preparar un archivo con el listado de productos y sus atributos correspondientes a cada accion. <br />
                            - Puedes usar una planilla de MS Excel o CSV previstas en la tabla. En caso de CSV, puedes exportar desde Excel<br />
                            &nbsp;&nbsp;usando la función Exportar/Cambiar formato de archivo y elegir CSV (comma separated value en UTF8)<br />
                            - Luego de subir un archivo, puedes refrescar el estado y ver si se subio correctamente. <br />
                            &nbsp;&nbsp;El color del icono representa el estado, verde correcto, amarillo en proceso, rojo error.<br />
                            <br />
                            - Cuidados sobre la planilla:<br />
                            A. Mantener las hojas en el orden del modelo, quiere decir que la hoja Productos debe ser la primera.<br />
                            B. En la hoja Productos mantener los títulos de columna en la fila 1 y comenzar en la columna A, tal como figura en el modelo.<br />
                        </Typography>
                    </TabPanel> */}
                </div >
                <TextInputPopUp
                    open={csvDialog}
                    title="logs"
                    text={"Lista logs upload CSV_______________________________________________________"}
                    handleClose={this.handleCloseCsvDialog}
                    accept={false}
                    textField={this.state.csvDialogText}
                />
                <div ref={el => (this.instance = el)} />
            </React.Fragment >
        );
    }

    componentDidMount() {
        if (window.location.href.startsWith('https://arturito.chat') || window.location.href.startsWith('https://arturito.store')) {
            //tracking script from ActiveCampaign
            const s = document.createElement('script');
            s.type = 'text/javascript';
            s.async = true;
            s.innerHTML = '(function(e,t,o,n,p,r,i){e.visitorGlobalObjectAlias = n;e[e.visitorGlobalObjectAlias]=e[e.visitorGlobalObjectAlias]||function(){(e[e.visitorGlobalObjectAlias].q = e[e.visitorGlobalObjectAlias].q || []).push(arguments)};e[e.visitorGlobalObjectAlias].l=(new Date).getTime();r=t.createElement("script");r.src=o;r.async=true;i=t.getElementsByTagName("script")[0];i.parentNode.insertBefore(r,i)})(window,document,"https://diffuser-cdn.app-us1.com/diffuser/diffuser.js","vgo");vgo("setAccount", "476725427");vgo("setTrackByDefault", true);vgo("process");';
            this.instance.appendChild(s);
        }

        const { user, dispatch } = this.props;

        if (!user || !this.props.setWidget) {
            console.log('Error user or session, redirecting')
            localStorage.setItem('lasturl', `/options`);
            this.props.history.push('/login');
            return null;
        }

        dispatch(userActions.getIntegrations());
        dispatch(userActions.getTheme());
        dispatch(userActions.getCSVStatusStore());
        dispatch(userActions.getPromos());

        const queryParams = new URLSearchParams(window.location.search)
        const section = queryParams.get("section")

        if (section === 'preview') {
            // eslint-disable-next-line react/no-did-mount-set-state
            setTimeout(() => { this.setState({ value: 4 }); }, 500);
        }

        if (section === 'store') {
            // eslint-disable-next-line react/no-did-mount-set-state
            setTimeout(() => { this.setState({ value: 1 }); }, 500);
        }
        if (section === 'wizzard') {
            // eslint-disable-next-line react/no-did-mount-set-state
            const step = queryParams.get("step")
            setTimeout(() => {
                this.setState({
                    value: 0,
                    activeStep: step ? step : '0',
                });
            }, 300);
        }

        this.props.getData(['products']);

        if (user.store_role === "admin") {
            dispatch(userActions.getStoreQRs());
            dispatch(userActions.getStoreUsers());
            dispatch(userActions.getStorePaymentMethods());
        }
        // eslint-disable-next-line react/no-did-mount-set-state
        this.setState({
            checkTakeaway: user.store.takeaway_enabled,
            checkDelivery: user.store.delivery_enabled,
        })
        this.props.setWidget(true, false);
    }

    componentDidUpdate(prevProps, prevState) {
        if ((prevProps.qrs !== this.props.qrs) && (this.props.qrs && this.props.qrs.qrs)) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({
                qrsList: this.props.qrs.qrs,
            });
        }
        if ((this.state.updated !== undefined && this.state.updated === false) &&
            (this.props.integrations && this.props.integrations.integrations)) {
            this.processData();
        }
        if ((this.state.updated2 !== undefined && this.state.updated2 === false) &&
            (this.props.theme && this.props.theme.theme)) {
            this.updateThemeData();
        }

        const queryParams = new URLSearchParams(window.location.search)
        const section = queryParams.get("section")

        if (section === 'preview') {
            // eslint-disable-next-line react/no-did-mount-set-state, react/no-did-update-set-state
            this.setState({ value: 4 });
            this.props.history.push("/options")
        }

        if (section === 'users') {
            // eslint-disable-next-line react/no-did-mount-set-state, react/no-did-update-set-state
            this.setState({ value: 6 });
            this.props.history.push("/options")
        }

        if (section === 'sales') {
            // eslint-disable-next-line react/no-did-mount-set-state, react/no-did-update-set-state
            this.setState({ value: 5 });
            this.props.history.push("/options")
        }

        if (section === 'store') {
            // eslint-disable-next-line react/no-did-mount-set-state, react/no-did-update-set-state
            this.setState({ value: 1 });
            // setTimeout(() => { this.setState({ value: 1 }); }, 500);
            this.props.history.push("/options")
        }
        if (section === 'wizzard') {
            // eslint-disable-next-line react/no-did-mount-set-state, react/no-did-update-set-state
            const step = queryParams.get("step")
            setTimeout(() => {
                this.setState({
                    value: 0,
                    activeStep: step ? step : '0',
                });
            }, 300);
            // setTimeout(() => { this.setState({ value: 0 }); }, 500);
            this.props.history.push("/options")
        }

    }

    componentWillUnmount() {
        localStorage.setItem('lasturl', `/options`);
        localStorage.setItem('lastsection', `wizzard`);
    }
}

function mapStateToProps(state) {
    const { loggingIn, user } = state.authentication;
    const { integrations, users, qrs, promos, paymentMethods, csvstatus, products, theme } = state;

    return {
        loggingIn,
        paymentMethods,
        user,
        integrations,
        users,
        theme,
        promos,
        qrs,
        products,
        csvstatus,
    };
}

export default connect(mapStateToProps)(withStyles(styles)(Options));