import moment from 'moment';

export function dicUnits(value) {
    const units = {
        'unit': 'unid',
        'kg': 'kg',
        'dozen': 'doc',
        'meter': 'met',
        'gr': 'gr',
        'lt': 'lit'
    };

    return (units[value] ? units[value] : value);
}

export function dicUnitsrev(value) {
    const units = {
        'unid': 'unit',
        'kg': 'kg',
        'doc': 'dozen',
        'met': 'meter',
        'gr': 'gr',
        'lit': 'lt'
    };

    return (units[value] ? units[value] : value);
}

export function convertNumber(data, curr_symbol) {
    const curr_symbol_set = (curr_symbol ? curr_symbol : '$');
    return `${curr_symbol_set} ${data.toLocaleString("de-DE", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        localeMatcher: 'lookup',
        style: 'decimal',
    })}`
}

export function convertDate(date) {

    if (date) {
        moment.locale('ar');
        const dateN = moment(date);
        return dateN.format("DD/MM/YYYY HH:mm");
    }

    return '-';
}

export function convertDateToBE(date) {

    if (date) {
        moment.locale('en');
        const dateN = moment(date, "DD/MM/YYYY HH:mm");
        return dateN.format("YYYY-MM-DD HH:mm:ss");
    }

    return '-';
}
