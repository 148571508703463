import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import withStyles from '@material-ui/core/styles/withStyles';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';


// eslint-disable-next-line no-unused-vars
const styles = theme => ({
    alert: {
        color: '#f44336',
    },
    fullWidth: {
        width: '100%',
    },
    inline: {
        position: 'absolute',
        'margin-top': '33px',
    },
    inline2: {
        position: 'absolute',
        'margin-top': '20px',
    }
});

class Input extends React.PureComponent {

    static propTypes = {
        //  True if the form was submited
        submitted: PropTypes.bool,
        // disabled: PropTypes.bool,
        // The label to show over the input
        // id: PropTypes.string,
        // The label to show over the input
        // label: PropTypes.string,
        // The name of the variable to update
        // name: PropTypes.string,
        // The value to update
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        // The type of the input element
        type: PropTypes.string,
        // The error message to show
        errorMessage: PropTypes.string,
        // The function to call when input changes
        onChange: PropTypes.func,
        // onClick: PropTypes.func,
        classes: PropTypes.object,
        // placeholder: PropTypes.string,
        // helperTextname: PropTypes.string,
        fullWidth: PropTypes.bool,
        error: PropTypes.bool,
        eye: PropTypes.bool,
        disableAuto: PropTypes.bool,
        autoFill: PropTypes.array,
        updateValue: PropTypes.func,
    }

    state = {
        visiblePass: false,
    }

    setVisiblePass = () => {
        this.setState({
            visiblePass: !this.state.visiblePass,
        });
    }

    goAutoFill = () => {
        if (this.props.autoFill && this.props.autoFill.length > 0) {
            var event = {};
            event.preventDefault = () => {};
            event.target = {};
            event.target.name = this.props.name;
            event.target.value = this.props.autoFill[Math.floor(Math.random() * this.props.autoFill.length)];
            this.props.onChange(event);
        }
    }

    render() {
        const { submitted, errorMessage, fullWidth, value, error, classes, eye, type, disableAuto, autoFill, ...props } = this.props;
        const { visiblePass } = this.state;
        return (
            <div className={(fullWidth ? classes.fullWidth : '')}>
                <TextField
                    margin="normal"
                    fullWidth={fullWidth}
                    inputProps={(disableAuto) ? {
                        autoComplete: (type === 'password') ? 'one-time-code' : 'off',
                        form: {
                            autocomplete: (type === 'password') ? 'one-time-code' : 'off',
                        },
                    } : {}}
                    value={value}
                    type={(type === 'password') ? (visiblePass ? 'text' : 'password') : type}
                    {...props}
                />
                {(autoFill && autoFill.length > 0) &&
                    <Tooltip title="Auto generar contenido">
                        <AutoAwesomeIcon color='primary' className={(props.label? classes.inline : classes.inline2)} onClick={this.goAutoFill} />
                    </Tooltip>
                }
                {((type === 'password') && eye && !visiblePass) &&
                    <Tooltip title="Mostrar contraseña">
                        <VisibilityIcon color='primary' className={classes.inline} onClick={this.setVisiblePass} />
                    </Tooltip>
                }
                {((type === 'password') && eye && visiblePass) &&
                    <Tooltip title="Ocultar contraseña">
                        <VisibilityOffIcon color='primary' className={classes.inline} onClick={this.setVisiblePass} />
                    </Tooltip>
                }
                {
                    ((submitted && !value) || (error)) &&
                    <div className={classes.alert}>{errorMessage}</div>
                }
            </div >
        );
    }
}

Input.defaultProps = {
    submitted: false,
    // disabled: false,
    // placeholder: '',
    // id: 'ninguno',
    // label: '',
    // name: '',
    value: '',
    type: 'text',
    errorMessage: '',
    fullWidth: true,
    error: false,
    disableAuto: false,
    autoFill: null,
};

export default (withStyles(styles)(Input));