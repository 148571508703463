/* eslint-disable react/jsx-no-bind */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Tooltip } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import { orderActions, productActions, clientActions } from '../_actions';
import { history } from '../_helpers';


// eslint-disable-next-line no-unused-vars
const styles = theme => ({
    dot: {
        'backgroundColor': '#fff',
        'border-radius': '50%',
        bottom: '10px',
        height: '76px',
        position: 'fixed',
        right: '10px',
        width: '76px',
    },
    dotPlus: {
        'backgroundColor': '#fedc4e',
        // '-webkit-transition': 'background-color 1000ms linear',
        // '-ms-transition': 'background-color 1000ms linear',
        // transition: 'background-color 1000ms linear',
        'border-radius': '50%',
        bottom: '10px',
        height: '76px',
        position: 'fixed',
        right: '10px',
        width: '76px',
    },
    widgetHidden: {
        visibility: 'hidden',
        display: 'none',
    },
    MuiTooltipPopper: {
        'z-index': 1,
    },
});

var dicOrders = {};
var dicProducts = {};
var oneTimeWelcome = true;

function checkShadow(url) {
    const dfMessenger = document.querySelector('df-messenger');
    if (dfMessenger && dfMessenger.shadowRoot) {
        const widget = dfMessenger.shadowRoot.querySelector("#widgetIcon > img");
        if (widget) {
            if (url)
                widget.src = url;
            widget.style.width = '58px';
            widget.style.height = '58px';
            widget.style.left = '-1px';
            widget.style.top = '-1px';
            // widget.classList.remove('hidden');
            const close = document.querySelector("#df-messenger").shadowRoot.querySelector("#closeIcon");
            // // close.hidden = true;
            close.removeAttribute('class');
            var att2 = document.createAttribute('class');
            att2.value = "";
            dfMessenger.setAttributeNode(att2);
            const inp = dfMessenger.shadowRoot.querySelector("div > df-messenger-chat").shadowRoot.querySelector("div > df-messenger-user-input").shadowRoot.querySelector("div > div.input-box-wrapper > input[type=text]")

            if (inp) {
                var att0 = document.createAttribute('placeholder');
                att0.value = 'Escribe aqui ..';
                inp.removeAttribute('placeholder');
                inp.setAttributeNode(att0);
            }
        }
        else window.setTimeout(checkShadow, 200);
    } else {
        window.setTimeout(checkShadow, 200);
    }
}

function checkShadowTitle(title) {

    const dfMessenger = document.querySelector("#df-messenger")
    if (dfMessenger && dfMessenger.shadowRoot) {
        var titlePlace = document.querySelector("#df-messenger").shadowRoot.querySelector("div > df-messenger-chat").shadowRoot.querySelector("div > df-messenger-titlebar").shadowRoot.querySelector("#dfTitlebar")
        titlePlace.innerHTML = 'Arturito';
        if (title) {
            titlePlace.innerHTML = title;
        }
    }
    else window.setTimeout(checkShadowTitle, 200);
}


class Widget extends React.Component {
    static propTypes = {
        classes: PropTypes.object,
        widgetHidden: PropTypes.bool,
        widgetTitle: PropTypes.string,
        sessionId: PropTypes.string,
        widgetTooltip: PropTypes.string,
        widgetWelcome: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.bool]),
        widgetExpand: PropTypes.oneOfType([
            PropTypes.bool,
            PropTypes.number]),
        // dispatch: PropTypes.func,
        // match: PropTypes.object,
        widgetIco: PropTypes.string,
        // eslint-disable-next-line react/no-unused-prop-types
        history: PropTypes.object,
    }

    state = {
    }


    render() {
        // eslint-disable-next-line no-unused-vars
        const { classes, widgetHidden, widgetTitle, sessionId, widgetTooltip, widgetExpand, widgetIco } = this.props;

        return (
            <div className={(widgetHidden ? classes.widgetHidden : '')}>
                <df-messenger id='df-messenger'
                    intent="WELCOME"
                    chat-title={(widgetTitle) ? widgetTitle : 'Arturito'}
                    expand={widgetExpand}
                    // hidden= {false}
                    wait-open={true}
                    agent-id={process.env.REACT_APP_AGENT_ID}
                    language-code="es"
                    chat-icon={widgetIco}
                    session-id={sessionId}>
                </df-messenger>
                <Tooltip className={classes.MuiTooltipPopper} open={((widgetTooltip) ? true : false)} title={<h3 style={{ color: "black", 'backgroundColor': '#fedc4e' }}>{widgetTooltip}</h3>} placement="right" arrow>
                    <span className={(widgetTooltip ? classes.dotPlus : classes.dot)}></span>
                </Tooltip>
            </div>
        )
    }

    componentDidMount() {
        const dfMessenger = document.querySelector('df-messenger');
        if (this.props.widgetWelcome && oneTimeWelcome) {
            var att2 = document.createAttribute('intent');
            if (this.props.widgetWelcome === true)
                att2.value = "WELCOME";
            else att2.value = this.props.widgetWelcome;
            dfMessenger.setAttributeNode(att2);
            oneTimeWelcome = false;
        } else {
            dfMessenger.removeAttribute('intent');
        }

        if (this.props.widgetExpand) {
            var att = document.createAttribute('expand');
            att.value = "true";
            dfMessenger.setAttributeNode(att);
            oneTimeWelcome = false;
        } else {
            dfMessenger.removeAttribute('expand');
        }
        checkShadow();

        var thisg = this;
        // Catch events
        dfMessenger.addEventListener('df-response-received', function (event) {
            oneTimeWelcome = false;
            const phraseLista = /\bMostrando lista de productos\b/i
            const phrasePedido = /\bMostrando detalle del pedido\b/i
            const phraseBienvenido = /\bBienvenido\b/i
            const phraseOperando = /\bestas operando\b/i
            // const phraseEmpezamos = /\bEmpezamos\b/i
            // const phraseContinuamos = /\bcontinuamos\b/i

            const messages = event.detail.response.queryResult.fulfillmentMessages;
            // if (event.detail.response.queryResult.intent.displayName === 'order.continue') {
            //   const arr = event.detail.response.queryResult.intent.name.split('/');
            //   history.push('/orderstatus/' + arr[arr.length-1]);
            //   //old
            //   //history.push('/orderstatus/' + String(event.detail.response.queryResult.fulfillmentMessages[0].text.text[0]).slice(38, -1));
            // }

            if (event.detail.response.queryResult.intent.displayName === 'order_item.confirm') {
                const { dispatch } = thisg.props;
                if (thisg.props.sessionId) {
                    const log = {
                        "activity_name": "Widget arturito, item confirmado",
                        "payload": null,
                        "session_id": thisg.props.sessionId
                    };
                    dispatch(clientActions.setClientLog(log));
                }
                localStorage.setItem('updateOrder', 'true');
                localStorage.setItem('itemAdded', 'true');
            }

            if (event.detail.response.queryResult.intent.displayName === 'order_item.discard') {
                const { dispatch } = thisg.props;
                if (thisg.props.sessionId) {
                    const log = {
                        "activity_name": "Widget arturito, item descartado",
                        "payload": null,
                        "session_id": thisg.props.sessionId
                    };
                    dispatch(clientActions.setClientLog(log));
                }
                localStorage.setItem('updateOrder', 'true');
            }

            if (event.detail.response.queryResult.intent.displayName === 'order.discard') {
                const { dispatch } = thisg.props;
                if (thisg.props.sessionId) {
                    const log = {
                        "activity_name": "Widget arturito, orden descartada",
                        "payload": null,
                        "session_id": thisg.props.sessionId
                    };
                    dispatch(clientActions.setClientLog(log));
                }
                localStorage.setItem('updateOrder', 'true');
                localStorage.setItem('exitHome', 'true');
            }

            if (event.detail.response.queryResult.intent.displayName === 'order.confirm') {
                const { dispatch } = thisg.props;
                if (thisg.props.sessionId) {
                    const log = {
                        "activity_name": "Widget arturito, orden confirmada",
                        "payload": null,
                        "session_id": thisg.props.sessionId
                    };
                    dispatch(clientActions.setClientLog(log));
                }
                localStorage.setItem('updateOrder', 'true');
                localStorage.setItem('exitOrder', 'true');
            }

            // if ((event.detail.response.queryResult.intent.displayName === 'order_item.choose_product') ||
            // (event.detail.response.queryResult.intent.displayName === 'order.continue') ||
            //     (event.detail.response.queryResult.intent.displayName === 'order_item.not_detecting') ||
            //     (event.detail.response.queryResult.intent.displayName === 'order_item.loose_detection')) {
            //     localStorage.setItem('updateOrder', 'true');
            // }

            if (messages) {
                messages.forEach(message => {
                    if (message && message.payload && message.payload.richContent && (message.payload.richContent[0].length > 1) && message.payload.richContent[0][1].event) {
                        if (message.payload.richContent[0][1].event.name === 'vieworderdetail') {
                            Object.assign(dicOrders, { [message.payload.richContent[0][1].event.parameters.order_code]: message.payload.richContent[0][1].event.parameters.token });
                        }
                        if (message.payload.richContent[0][1].event.name === 'viewproductlist') {
                            Object.assign(dicProducts, { [message.payload.richContent[0][1].event.parameters.store_hash]: message.payload.richContent[0][1].event.parameters.token });
                        }
                    }
                    if (message && message.payload && message.payload.richContent && message.payload.richContent[0][0].event) {
                        if (message.payload.richContent[0][0].event.name === 'vieworderdetail') {
                            Object.assign(dicOrders, { [message.payload.richContent[0][0].event.parameters.order_code]: message.payload.richContent[0][0].event.parameters.token });
                        }
                        if (message.payload.richContent[0][0].event.name === 'viewproductlist') {
                            Object.assign(dicProducts, { [message.payload.richContent[0][0].event.parameters.store_hash]: message.payload.richContent[0][0].event.parameters.token });
                        }
                    }
                    if (message && message.text && message.text.text && !message.platform) {
                        message.text.text.forEach(element => {
                            if (phraseLista.test(element) && element) {
                                const { dispatch } = thisg.props;
                                if (thisg.props.sessionId) {
                                    const log = {
                                        "activity_name": "Widget arturito, click lista productos",
                                        "payload": null,
                                        "session_id": thisg.props.sessionId
                                    };
                                    dispatch(clientActions.setClientLog(log));
                                }
                                var messenger = document.getElementById("df-messenger");
                                messenger.removeAttribute('expand');
                                setTimeout(() => {
                                    if (window.location.href.indexOf('productlist') > -1) {
                                        dispatch(productActions.getAllNoToken(dicProducts[String(element.match(/#\S+/g))], thisg.props.sessionId));
                                        localStorage.setItem('updatedlist', dicProducts[String(element.match(/#\S+/g))]);
                                    } else history.push('/productlist/' + dicProducts[String(element.match(/#\S+/g))]);
                                }, 500)
                            }
                            if (phrasePedido.test(element)) {
                                const { dispatch } = thisg.props;
                                if (thisg.props.sessionId) {
                                    const log = {
                                        "activity_name": "Widget arturito, click resumen pedido",
                                        "payload": null,
                                        "session_id": thisg.props.sessionId
                                    };
                                    dispatch(clientActions.setClientLog(log));
                                }
                                var messenger1 = document.getElementById("df-messenger");
                                messenger1.removeAttribute('expand');
                                setTimeout(() => {
                                    if (window.location.href.indexOf('orderstatus') > -1) {
                                        dispatch(orderActions.getByIdNoToken(dicOrders[String(element).substring(29)], thisg.props.sessionId));
                                        localStorage.setItem('updatedorder', dicOrders[String(element).substring(29)]);
                                    } else history.push('/orderstatus/' + dicOrders[String(element).substring(29)]);
                                }, 500)
                            }
                            if (phraseBienvenido.test(element)) {
                                if (typeof window.gtag !== 'undefined')
                                    window.gtag('event', 'welcome', {
                                        'event_category': 'user_login',
                                        'event_label': 'login'
                                    });
                                const { dispatch } = thisg.props;
                                if (thisg.props.sessionId) {
                                    const log = {
                                        "activity_name": "Widget arturito, login",
                                        "payload": null,
                                        "session_id": thisg.props.sessionId
                                    };
                                    dispatch(clientActions.setClientLog(log));
                                }
                                //   ReactGA.event({
                                //     category: 'Client',
                                //     action: 'Login-widget'
                                //   });
                            }
                            if (phraseOperando.test(element)) {
                                if (typeof window.gtag !== 'undefined')
                                    window.gtag('event', 'changeStore', {
                                        'event_category': 'user_login',
                                        'event_label': 'login'
                                    });
                                const { dispatch } = thisg.props;
                                if (thisg.props.sessionId) {
                                    const log = {
                                        "activity_name": "Widget arturito, store change",
                                        "payload": null,
                                        "session_id": thisg.props.sessionId
                                    };
                                    dispatch(clientActions.setClientLog(log));
                                }
                                localStorage.setItem('exitHome', 'true');
                            }
                            // if (phraseEmpezamos.test(element)) {
                            //   history.push('/productlist/' + String(element.match(/#\S+/g)).substring(1));
                            // }
                            // if (phraseContinuamos.test(element)) {
                            //   history.push('/productlist/' + String(element.match(/#\S+/g)).substring(1));
                            // }
                        });
                    }
                });
            }
        });

        dfMessenger.addEventListener('df-user-input-entered', function (event) {
            // console.log(event);
            const phraseVolver = /\bvolver\b/i;
            const phraseSalir = /\bsalir\b/i;
            const phraseInfo = /\binfo\b/i;
            const phraseAyuda = /\bayuda\b/i;
            const messages = event.detail.input;

            if (phraseVolver.test(messages)) {
                event.preventDefault();
                event.stopImmediatePropagation();
                thisg.history.push('/homeclient');
            }
            if (phraseInfo.test(messages) || phraseAyuda.test(messages)) {
                event.preventDefault();
                event.stopImmediatePropagation();
                thisg.history.push('/info');
            }
            if (phraseSalir.test(messages)) {
                event.preventDefault();
                event.stopImmediatePropagation();
                localStorage.removeItem('sessionid');
                localStorage.removeItem('updateOrder');
                localStorage.removeItem('itemAdded');
                localStorage.removeItem('exitHome');
                localStorage.removeItem('updatedlist');
                localStorage.removeItem('updatedorder');
                thisg.history.push('/homeclient');
                window.location.reload();
            }
        });

        // console.log(process.env);
        // dfmessenget tunning:
        // window.addEventListener('dfMessengerLoaded', function (event) {
        //     event.preventDefault();
        //     // const dfm = document.getElementById("df-messenger");
        //     // const dfmsr = dfm.shadowRoot;
        //     // let icon;
        //     // icon = dfmsr.getElementById("closeIcon");
        //     // setInterval(function () {
        //     //     icon.setAttribute('class', '');
        //     // }, 10);

        //     // extra tunning:
        //     var $r1 = document.querySelector("df-messenger");
        //     var $r2 = $r1.shadowRoot.querySelectorAll("#widgetIcon img");
        //     // var $r3 = $r2.getElementsByTagName("img");

        //     // document.querySelector("#df-messenger").shadowRoot.querySelector("#widgetIcon")
        //     // var $r3 = $r2.shadowRoot.querySelector("df-messenger-user-input"); //for other mods

        //     var sheet = new CSSStyleSheet();
        //     // sheet.replaceSync( `div.chat-wrapper[opened="true"] { height: 80%; width: 500px; }`);
        //     sheet.replaceSync(`widgetIcon.df-chat-icon { border-radius: 50%; }`);
        //     console.log($r2[0]).style;
        //     // $r2[0].adoptedStyleSheets = [sheet];
        //     // console.log($r2);
        // });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.widgetWelcome !== this.props.widgetWelcome) {
            const dfMessenger = document.querySelector('df-messenger');
            if (this.props.widgetWelcome && oneTimeWelcome) {
                var att2 = document.createAttribute('intent');
                if (this.props.widgetWelcome === true)
                    att2.value = "WELCOME";
                else att2.value = this.props.widgetWelcome;
                dfMessenger.setAttributeNode(att2);
                oneTimeWelcome = false;
                // dfMessenger.removeAttribute('wait-open');
            } else {
                dfMessenger.removeAttribute('intent');
            }
        }
        if (prevProps.widgetExpand !== this.props.widgetExpand) {
            var messenger = document.getElementById("df-messenger");
            if (this.props.widgetExpand) {
                // window.setTimeout(() => {
                //     var att = document.createAttribute('expand');
                //     att.value = "true";
                //     messenger.setAttributeNode(att);
                //     // messenger.removeAttribute('intent');
                //     // messenger.removeAttribute('wait-open');
                // }, 2000);
                oneTimeWelcome = false;
            } else {
                messenger.removeAttribute('expand');
            }
        }

        if (this.props.widgetTitle && (prevProps.widgetTitle !== this.props.widgetTitle)) {
            checkShadowTitle(this.props.widgetTitle);
        }

        if (prevProps.widgetIco !== this.props.widgetIco) {
            checkShadow(this.props.widgetIco);
        }
    }
}

Widget.defaultProps = {
    widgetHidden: false,
    widgetTitle: false,
    sessionId: '',
    widgetTooltip: '',
    widgetWelcome: false,
    widgetExpand: false,
    widgetIco: '/logo.png',
};

// export default (withStyles(styles)(Widget));
function mapStateToProps(state) {
    const { user } = state.authentication;

    return {
        user,
    };
}

export default connect(mapStateToProps)(withStyles(styles)(Widget));