/* eslint-disable react/jsx-no-bind */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import { Typography, Grid } from '@material-ui/core';
import Link1 from '@material-ui/core/Link';
import logotext from '../images/logotext.png';
import vec5 from '../images/vector5.png';
import { clientActions } from '../_actions';


// eslint-disable-next-line no-unused-vars
const styles = theme => ({
    subheader: {
        width: '100%',
        textAlign: 'center',
        paddingTop: '50px !important',
        background: 'linear-gradient(#eee 0%, white 20%)',
    },
    image: {
        width: "350px",
        margin: '10px 20px 10px 20px',
    },
    imageTitle: {
        width: "200px",
        margin: '30px 0px 10px 0px',
    },
    imagebox: {
        textAlign: 'justify',
    },
    link: {
        textDecoration: 'none',
        color: '#124e5c',
        "&:hover": {
            color: '#ee8d2d',
        },
    },
    textbox: {
        margin: '10px',
        textAlign: 'center',
        width: '100%',
    },
    textbox2: {
        margin: '10px',
        textAlign: 'right',
        width: '100%',
    },
    textbox3: {
        textAlign: 'center',
        background: '#025e9f !important',
        color: 'white !important',
        fontSize: '1.2rem'
    },
});

class default404 extends React.Component {
    static propTypes = {
        //  object types
        classes: PropTypes.object,
        userType: PropTypes.string,
        setWidget: PropTypes.func,
        history: PropTypes.object,
        sessionId: PropTypes.string,
        dispatch: PropTypes.func,
    };

    render() {
        const { classes, userType } = this.props;
        return (
            <React.Fragment>
                <Grid container spacing={3}
                    direction="column"
                    alignItems="center"
                >
                    <Grid item xs={12} md={12}>
                        <a target="_blank" rel="noopener noreferrer" href="https://arturito.online" className={classes.link}>
                            <img src={logotext} alt="Logo" className={classes.imageTitle} />
                        </a>
                    </Grid>
                    <Grid item xs={11} sm={9} className={classes.imagebox}>
                        <img src={vec5} alt="" className={classes.image} />
                    </Grid>
                    <Grid item xs={12} sm={12} className={classes.textbox}>
                        <Typography variant="h1">
                            Lo sentimos, esta pagina no existe.
                        </Typography>
                        <br />
                        {(userType === 'store') &&
                            <Link1 onClick={(event) => {
                                event.preventDefault();
                                this.props.history.push('/home');
                            }}
                                className={classes.link}>Volver a la pagina principal</Link1>
                        }
                        {(userType === 'client') &&
                            <Link1 onClick={(event) => {
                                event.preventDefault();
                                this.props.history.push('/homeclient');
                            }}
                                className={classes.link}>Volver a la pagina principal</Link1>
                        }
                    </Grid>
                </Grid>
                <div ref={el => (this.instance = el)} />
            </React.Fragment>
        );
    }

    componentDidMount() {
        if (window.location.href.startsWith('https://arturito.chat') || window.location.href.startsWith('https://arturito.store')) {
            //tracking script from ActiveCampaign
            const s = document.createElement('script');
            s.type = 'text/javascript';
            s.async = true;
            s.innerHTML = '(function(e,t,o,n,p,r,i){e.visitorGlobalObjectAlias=n;e[e.visitorGlobalObjectAlias]=e[e.visitorGlobalObjectAlias]||function(){(e[e.visitorGlobalObjectAlias].q=e[e.visitorGlobalObjectAlias].q||[]).push(arguments)};e[e.visitorGlobalObjectAlias].l=(new Date).getTime();r=t.createElement("script");r.src=o;r.async=true;i=t.getElementsByTagName("script")[0];i.parentNode.insertBefore(r,i)})(window,document,"https://diffuser-cdn.app-us1.com/diffuser/diffuser.js","vgo");vgo("setAccount", "476725427");vgo("setTrackByDefault", true);vgo("process");';
            this.instance.appendChild(s);
        }

        //tracking script from GA
        if (typeof window.gtag !== 'undefined')
            window.gtag('event', 'default404', {
                'event_category': 'pagenotfound',
                'event_label': 'pagenotfound'
            });

        const { dispatch } = this.props;
        if (this.props.sessionId) {
            const log = {
                "activity_name": "Ingreso 404",
                "payload": null,
                "session_id": this.props.sessionId
            };
            dispatch(clientActions.setClientLog(log));
        }

        this.props.setWidget(true, false);
    }
}

function mapStateToProps(state) {
    const { loggingIn } = state.authentication;
    return {
        loggingIn
    };
}

export default connect(mapStateToProps)(withStyles(styles)(default404));