export const orderConstants = {
    GETALL_REQUEST: 'ORDERS_GETALL_REQUEST',
    GETALL_SUCCESS: 'ORDERS_GETALL_SUCCESS',
    GETALL_FAILURE: 'ORDERS_GETALL_FAILURE',

    GETBYCLIENT_REQUEST: 'ORDERS_GETBYCLIENT_REQUEST',
    GETBYCLIENT_SUCCESS: 'ORDERS_GETBYCLIENT_SUCCESS',
    GETBYCLIENT_FAILURE: 'ORDERS_GETBYCLIENT_FAILURE',

    GETBYID_REQUEST: 'ORDERS_GETBYID_REQUEST',
    GETBYID_SUCCESS: 'ORDERS_GETBYID_SUCCESS',
    GETBYID_FAILURE: 'ORDERS_GETBYID_FAILURE',

    UPDATE_REQUEST: 'ORDERS_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'ORDERS_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'ORDERS_UPDATE_FAILURE',

    ADDITEMS_REQUEST: 'ORDERS_ADDITEMS_REQUEST',
    ADDITEMS_SUCCESS: 'ORDERS_ADDITEMS_SUCCESS',
    ADDITEMS_FAILURE: 'ORDERS_ADDITEMS_FAILURE',
};
