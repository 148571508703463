/* eslint-disable react/jsx-no-bind */
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { history } from '../_helpers';
import {
  ListItemAvatar,
  Avatar,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Drawer,
  List,
  Divider,
  ListItem,
  ListItemText,
  Grid
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import ArrowBack from '@material-ui/icons/ArrowBack';
import RefreshIcon from '@material-ui/icons/Refresh';
// import ListAltOutlined from '@material-ui/icons/ListAltOutlined';
// import PointOfSaleOutlined from '@material-ui/icons/PointOfSaleOutlined';
// import PeopleOutlineOutlined from '@material-ui/icons/PeopleOutlineOutlined';
// import Inventory2Outlined from '@material-ui/icons/Inventory2Outlined';
// import SettingsOutlined from '@material-ui/icons/SettingsOutlined';
// import ForwardToInboxOutlined from '@material-ui/icons/ForwardToInboxOutlined';
// import VideoCameraFrontOutlined from '@material-ui/icons/VideoCameraFrontOutlined';
import { userActions } from '../_actions';
import logotext from '../images/logotext.png';
import defaultUser from '../images/icons/default-user.png';
import salir from '../images/icons/icon_salir.png';


// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles(theme => ({
  root: {
    zIndex: 1
  },
  list: {
    paddingTop: 0,
    // width: '350px',
    background: '#fff',
    height: '100%',
  },
  header: {
    height: '160px',
    'backgroundColor': '#e0e0e0',
    'align-items': 'center',
  },
  itemText: {
    'align-items': 'center',
    margin: 10,
  },
  appbar: {
    background: '#fff',
    height: '80px',
  },
  appbarxs: {
    background: '#fff',
    height: '55px',
  },
  nolink: {
    'text-decoration': 'none',
  },
  imageTitle: {
    width: "190px",
    'padding-top': '8px',
    'margin-bottom': '-8px',
  },
  toolbar: {
    textAlign: 'center',
    width: '100%',
    height: '80px',
  },
  menuButton: {
    marginRight: 0,
    fontSize: '2rem',
  },
  avatar: {
    width: '80px',
    height: '80px',
  },
  itemMLeft: {
    height: 'auto',
    width: '22px',
    marginLeft: '10px',
  },
  divider: {
    background: '#ececec !important',
  },
  itemTextSop: {
    'align-items': 'center',
    margin: 10,
    '& span': {
      textDecoration: 'none',
      color: "#025e9f",
      fontWeight: 800,
    },
  },
  itemTextPol: {
    '& span': {
      color: "#025e9f",
      fontSize: "0.75rem",
      fontWeight: 800,
    },
    'align-items': 'center',
    margin: 10,
  },
}));

Appbar.propTypes = {
  //  True if the back button is on
  back: PropTypes.bool,
  // The label to show over the bar
  label: PropTypes.string,
  //  True if the menu button is on
  menu: PropTypes.bool,
  xs: PropTypes.bool,
  // The function to call when input changes
  onBack: PropTypes.func,
  onLogout: PropTypes.func,
  user: PropTypes.object,
  dispatch: PropTypes.func,
  update: PropTypes.func,
}

Appbar.defaultProps = {
  back: false,
  menu: false,
  label: '',
  user: {},
  email: '',
  notifications: ['Ninguna'],
  newnotifications: true,
  online: true,
  xs: false,
};

export default function Appbar({ back, label, menu, onBack, onLogout, user, dispatch, update, xs }) {
  const classes = useStyles();
  const [state, setState] = React.useState({
    left: false,
    top: false,
  });
  const toggleDrawer = (side, open) => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [side]: open });
  };

  function onSelect(path) {
    history.push(path);
  }

  function getLogs() {
    dispatch(userActions.getLogs());
  }

  const { is_admin } = user;

  const sideList = () => (
    <div
      className={classes.list}
      role="presentation"
      onClick={toggleDrawer('left', false)}
      onKeyDown={toggleDrawer('left', false)}
    >
      <List className={classes.list}>
        <ListItem button className={classes.header}>
          <Grid container spacing={2}>
            <Grid item>
              <Avatar alt="FotoPerfil" className={classes.avatar} src={defaultUser} />
            </Grid>
            <Grid className={classes.itemText} item>
              <Typography variant="h5" color="primary">
                NOMBRE
              </Typography>
              <Typography variant="body1">
                {user.user}
              </Typography>
              <Typography variant="body1">
                {((user && user.store) ? user.store.hashtag : '')}
              </Typography>
            </Grid>
          </Grid>
        </ListItem>
        {(is_admin) &&
          <ListItem button onClick={(() => getLogs())}>
            <ListItemText className={classes.itemText} primary={'Exportar logs'} />
          </ListItem>
        }
        {(!is_admin) &&
          <div>
            <ListItem button onClick={(() => onSelect('/orderlist'))}>
              <ListItemText className={classes.itemText} primary={'Pedidos'} />
            </ListItem>
            <ListItem button onClick={(() => onSelect('/paymentlist'))}>
              <ListItemText className={classes.itemText} primary={'Cobros'} />
            </ListItem>
            <ListItem button onClick={(() => onSelect('/clientlist'))}>
              <ListItemText className={classes.itemText} primary={'Clientes'} />
            </ListItem>
            <ListItem button onClick={(() => onSelect('/productstore'))}>
              <ListItemText className={classes.itemText} primary={'Productos'} />
            </ListItem>
            <ListItem button onClick={(() => onSelect('/marketing'))}>
              <ListItemText className={classes.itemText} primary={'Marketing'} />
            </ListItem>
            {(user.store && user.store.urls && user.store.urls.report_general) &&
              <ListItem button onClick={(() => onSelect('/dashboard'))}>
                <ListItemText className={classes.itemText} primary={'Informes'} />
              </ListItem>
            }
            <Divider className={classes.divider} />
            <ListItem button onClick={(() => onSelect('/options'))}>
              <ListItemText className={classes.itemText} primary={'CONFIGURACION'} />
            </ListItem>
            <Divider className={classes.divider} />
            <ListItem className={classes.nolink}>
              <ListItemText className={classes.itemTextSop} primary={'SOPORTE'} />
            </ListItem>
            <a href="mailto:hola@arturito.online" target="_blank" rel="noopener noreferrer" className={classes.nolink}>
              <ListItem button>
                <ListItemText className={classes.itemText} primary={'Soporte por email'} />
              </ListItem>
            </a>
            <a href="https://calendly.com/tlanzillotta/30min-arturito-online-support?month=2021-06" target="_blank" rel="noopener noreferrer" className={classes.nolink}>
              <ListItem button>
                <ListItemText className={classes.itemText} primary={'Soporte por videollamada'} />
              </ListItem>
            </a>
            <Divider className={classes.divider} />
            {(!is_admin) &&
              <a href='https://www.trippe.com.br/politica-de-privacidade' target="_blank" rel="noopener noreferrer" className={classes.nolink}>
                <ListItem button>
                  <ListItemText className={classes.itemTextPol} primary={'POLITICA DE PRIVACIDAD'} />
                </ListItem>
              </a>
            }
          </div>
        }
        <Divider className={classes.divider} />
        <ListItem button onClick={onLogout}>
          <ListItemAvatar><Avatar variant='square' alt="Cerrar sesión" src={salir} className={classes.itemMLeft} /></ListItemAvatar>
          <ListItemText className={classes.itemText} primary={'Cerrar Sesión'} />
        </ListItem>
      </List>
    </div >
  );

  return (
    <div className={classes.root}>
      <AppBar position="static" color="primary" className={xs ? classes.appbarxs : classes.appbar}>
        <Toolbar className={classes.toolbar}>
          <Grid container
            direction="row"
            alignItems="center"
          >
            <Grid item xs={1} md={1}>
              {back &&
                <IconButton edge="start" className={classes.menuButton} color="primary" aria-label="Back" onClick={onBack}>
                  <ArrowBack fontSize="large" />
                </IconButton>}
              {menu &&
                <IconButton onClick={toggleDrawer('left', true)} edge="start" className={classes.menuButton} color="primary" aria-label="Menu" fontSize='large'>
                  <MenuIcon fontSize="large" />
                </IconButton>}
              {menu &&
                <Drawer open={state.left} onClose={toggleDrawer('left', false)}>
                  {sideList()}
                </Drawer>
              }
            </Grid>
            <Grid item xs={10} md={10}>
              {menu &&
                <div>
                  <img src={logotext} alt="Logo" className={classes.imageTitle} />
                  <Typography variant="body1">{((user && user.store) ? user.store.hashtag : '')}</Typography>
                </div>
              }
              {back &&
                <div>
                  <Typography variant="h6">
                    <b>{label}</b>
                  </Typography>
                  <Typography variant="body1">{((user && user.store) ? user.store.hashtag : '')}</Typography>
                </div>
              }
            </Grid>
            <Grid item xs={1} md={1}>
              {update &&
                <IconButton edge="end" className={classes.menuButton} color="primary" aria-label="Update" onClick={update}>
                  <RefreshIcon fontSize="large" />
                </IconButton>
              }
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </div>
  );
}