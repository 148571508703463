import { authHeader } from '../_helpers';

export const orderService = {
    getByIdNoToken,
    getById,
    getByClient,
    getAll,
    update,
    addItems,
    deleteItemClient,
    updateItemClient,
    addItemStore
};

const url = process.env.REACT_APP_API_URL;

async function getByIdNoToken(params) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(params)
    };

    const response = await fetch(`${url}/client/orderstatus`, requestOptions);
    return handleResponse(response);
}

async function getById(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    const response = await fetch(`${url}/store/orders/${id}`, requestOptions);
    return handleResponse(response);
}

async function getByClient(client) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    const response = await fetch(`${url}/store/clients/${client}/orders`, requestOptions);
    return handleResponse(response);
}

async function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    const response = await fetch(`${url}/store/orders`, requestOptions);
    return handleResponse(response);
}

async function update(order) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(order)
    };

    const response = await fetch(`${url}/store/orders/${order.id}`, requestOptions);
    return handleResponse(response);
}

async function addItems(data) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };

    const response = await fetch(`${url}/client/order/items`, requestOptions);
    return handleResponse(response);
}

async function addItemStore(data) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };

    const response = await fetch(`${url}/client/order/items`, requestOptions);
    return handleResponse(response);
}

async function updateItemClient(data, orderId, itemId) {
    const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };

    const response = await fetch(`${url}/client/order/${orderId}/items/${itemId}`, requestOptions);
    return handleResponse(response);
}

async function deleteItemClient(data, orderId, itemId) {
    const requestOptions = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };

    const response = await fetch(`${url}/client/order/${orderId}/items/${itemId}`, requestOptions);
    return handleResponse(response);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}