import { productConstants } from '../_constants';

export function products(state = {}, action) {
  switch (action.type) {
    case productConstants.GETALL_REQUEST:
      return {
        loading: true
      };
    case productConstants.GETALL_SUCCESS:
      return {
        products: action.products,
        metadata: action.metadata
      };
    case productConstants.GETALL_FAILURE:
      return {
        error: action.error
      };
    case productConstants.GETBYFIELD_REQUEST:
      return {
        loading: true
      };
    case productConstants.GETBYFIELD_SUCCESS:
      return {
        products: action.products
      };
    case productConstants.GETBYFIELD_FAILURE:
      return {
        error: action.error
      };
    case productConstants.DELETEPROD_REQUEST:
      return {
        loading: true
      };
    case productConstants.DELETEPROD_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}

export function product(state = {}, action) {
  switch (action.type) {
    case productConstants.UPDATE_REQUEST:
      return {
        loading: true
      };
    case productConstants.UPDATE_FAILURE:
      return {
        error: action.error
      };
    case productConstants.UPDATE_SUCCESS:
      return {
        product: action.product
      };
    case productConstants.DELETEPROD_REQUEST:
      return {
        loading: true
      };
    case productConstants.DELETEPROD_SUCCESS:
      return {
        product: action.product
      };
    case productConstants.DELETEPROD_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}