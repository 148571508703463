/* eslint-disable react/jsx-no-bind */
import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
    Table,
    TableBody,
    TableCell,
    TableRow,
    Paper,
    Hidden,
    CircularProgress,
    withWidth
} from '@material-ui/core';
import EnhancedTableHead from './TableHead';
import { convertNumber, convertDate } from '../../_helpers';


function descendingComparator(a, b, orderBy) {
    if (orderBy.indexOf('created') >= 0 | orderBy.indexOf('date') >= 0) {
        if (moment(b[orderBy]).isAfter(a[orderBy]))
            return 1
        else return -1
    }

    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy, date) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy, date)
        : (a, b) => -descendingComparator(a, b, orderBy, date);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles((theme) => ({
    paper: {
        width: '100%',
        background: 'white',
        // border: '1px solid #b7b7b7',
        boxShadow: 'none'
    },
    cellRows: {
        color: '#333 !important',
        borderBottom: '1px solid #b7b7b7',
        boxShadow: 'none',
        fontSize: 'small',
        padding: '5px !important',
        "&:hover": {
            cursor: 'pointer',
        },
    },
    rows: {
        borderBottom: '1px solid #b7b7b7'
    },
    rowsblue: {
        // background: "rgba(0,0,255,0.5)",
        'box-shadow': '6px 0px 0px 0px blue inset',
    },
    rowsred: {
        // background: "rgba(255,0,0,0.5)",
        'box-shadow': '6px 0px 0px 0px red inset',
    },
    // rowsviolet: {
    //     background: "rgba(255,0,255,0.5)",
    //     borderBottom: '1px solid #b7b7b7'
    // },
    root: {
        flex: 1,
        flexDirection: 'row-reverse',
        color: '#333',
        fontSize: 'small',
        padding: '5px !important',
        "& > svg": {
            color: '#333 !important'
        },
        "&:hover": {
            flexDirection: 'row-reverse',
            color: '#333',
            "& > svg": {
                color: '#333 !important',
            }
        }
    },
    tableHead: {
        borderBottom: '1px double #b7b7b7'
    },
    active: {
        color: '#333 !important'
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    loaderDiv: {
        display: 'flex',
        justifyContent: 'center'
    }
}));

function EnhancedTable(props) {
    const classes = useStyles();
    const [order, setOrder] = React.useState('asc');
    const [selectedIndex, setSelectedIndex] = React.useState(null);
    const [orderBy, setOrderBy] = React.useState(props.firstSort || '');

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectRow = (row, index) => {
        if (selectedIndex === null || selectedIndex !== index) {
            setSelectedIndex(index)
            props.handleSelectRow(row)
        } else {
            setSelectedIndex(null)
            props.handleSelectRow(null)
        }
    };

    const responsiveHeadCells = (cells) => {
        if (['sm', 'xs'].includes(props.width) && props.headResponsiveCells.length > 0) {
            const cellsFiltradas = cells.filter(cell => !props.headResponsiveCells.includes(cell.id))
            return cellsFiltradas
        }
        return cells
    }

    const cell = (data, type) => {
        if (data && type && type === 'number') {
            return convertNumber(data, props.curr_symbol);
        }
        if (data && type && (type === 'date')) {
            return convertDate(data)
        }
        return data
    }

    return (
        <Hidden only={props.hidden} >
            <Paper className={classes.paper}>
                <Table
                    aria-labelledby="Table"
                    size={'medium'}
                >
                    <EnhancedTableHead
                        classes={classes}
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        headCells={responsiveHeadCells(props.headCells)}
                    />
                    <TableBody>
                        {!props.loading && props.rows.length > 0 && stableSort(props.rows, getComparator(order, orderBy))
                            .map((row, index) => {
                                return (
                                    <TableRow
                                        hover
                                        key={index}
                                        onClick={() => handleSelectRow(row, index)}
                                        selected={index === selectedIndex}
                                        role="checkbox"
                                        classes={
                                            ((row.color === 'red') ? { root: classes.rowsred } :
                                                (row.color === 'blue') ? { root: classes.rowsblue } :
                                                    (row.color === 'violet') ? { root: classes.rowsviolet } :
                                                        { root: classes.rows })
                                        }
                                    >
                                        {responsiveHeadCells(props.headCells).map((headcell, i) => headcell.render ? headcell.render(row, index) :
                                            <TableCell align='right' key={i} classes={{ root: classes.cellRows }}>
                                                {cell(row[headcell.id], headcell.type)}
                                            </TableCell>
                                        )
                                        }
                                    </TableRow>
                                );
                            })}
                        {!props.loading && props.rows.length === 0 && (
                            <TableRow classes={{ root: classes.rows }}>
                                <TableCell colSpan={props.headCells.length} classes={{ root: classes.cellRows }}>
                                    {props.noResults}
                                </TableCell>
                            </TableRow>
                        )}
                        {props.loading && (
                            <TableRow classes={{ root: classes.rows }}>
                                <TableCell colSpan={props.headCells.length} classes={{ root: classes.cellRows }}>
                                    <div className={classes.loaderDiv}><CircularProgress color="secondary" size={30} thickness={5} /></div>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </Paper>
        </Hidden>
    );
}

EnhancedTable.defaultProps = {
    loading: false,
    rows: [],
    hidden: [],
    headCells: [],
    headResponsiveCells: [],
    firstSort: '',
    curr_symbol: '',
    noResults: 'No se encontraron resultados',
    handleSelectRow: () => { }
}

EnhancedTable.propTypes = {
    headCells: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired, // nombre para buscarlo en la row
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired
    })),
    rows: PropTypes.array.isRequired,
    handleSelectRow: PropTypes.func,
    hidden: PropTypes.array.isRequired,
    loading: PropTypes.bool,
    noResults: PropTypes.string,
    firstSort: PropTypes.string.isRequired,
    width: PropTypes.string,
    headResponsiveCells: PropTypes.array,
    curr_symbol: PropTypes.string,

}

export default withWidth()(EnhancedTable)