import { orderConstants } from '../_constants';

export function orders(state = {}, action) {
  switch (action.type) {
    case orderConstants.GETBYID_REQUEST:
      return {
        loading: true
      };
    case orderConstants.GETBYID_FAILURE:
      return {
        error: action.error
      };
    case orderConstants.GETBYID_SUCCESS:
      return {
        orders: action.order,
        metadata: action.metadata
      };
    case orderConstants.UPDATE_REQUEST:
      return {
        loading: true
      };
    case orderConstants.UPDATE_FAILURE:
      return {
        error: action.error
      };
    case orderConstants.UPDATE_SUCCESS:
      return {
        order: action.order
      };
    case orderConstants.GETALL_REQUEST:
      return {
        loading: true
      };
    case orderConstants.GETALL_SUCCESS:
      return {
        orders: action.orders
      };
    case orderConstants.GETALL_FAILURE:
      return {
        error: action.error
      };
    case orderConstants.GETBYCLIENT_REQUEST:
      return {
        loading: true
      };
    case orderConstants.GETBYCLIENT_SUCCESS:
      return {
        clientOrders: action.clientOrders
      };
    case orderConstants.GETBYCLIENT_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}

export function orderId(state = {}, action) {
  switch (action.type) {
    case orderConstants.ADDITEMS_REQUEST:
      return {
        loading: true
      };
    case orderConstants.ADDITEMS_SUCCESS:
      return {
        orderId: action.orderId
      };
    case orderConstants.ADDITEMS_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}