export const productConstants = {
    GETALL_REQUEST: 'PRODUCTS_GETALL_REQUEST',
    GETALL_SUCCESS: 'PRODUCTS_GETALL_SUCCESS',
    GETALL_FAILURE: 'PRODUCTS_GETALL_FAILURE',

    GETBYFIELD_REQUEST: 'PRODUCTS_GETBYFIELD_REQUEST',
    GETBYFIELD_SUCCESS: 'PRODUCTS_GETBYFIELD_SUCCESS',
    GETBYFIELD_FAILURE: 'PRODUCTS_GETBYFIELD_FAILURE',

    GETBYID_REQUEST: 'PRODUCTS_GETBYID_REQUEST',
    GETBYID_SUCCESS: 'PRODUCTS_GETBYID_SUCCESS',
    GETBYID_FAILURE: 'PRODUCTS_GETBYID_FAILURE',

    UPDATE_REQUEST: 'PRODUCTS_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'PRODUCTS_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'PRODUCTS_UPDATE_FAILURE',

    DELETEPROD_REQUEST: 'PRODUCTS_DELETEPROD_REQUEST',
    DELETEPROD_SUCCESS: 'PRODUCTS_DELETEPROD_SUCCESS',
    DELETEPROD_FAILURE: 'PRODUCTS_DELETEPROD_FAILURE'
};
