import { clientConstants } from '../_constants';
import { clientService } from '../_services';
import { alertActions } from './';
import { history } from '../_helpers';

export const clientActions = {
    register,
    getAll,
    getById,
    setClientLog,
    setStore,
    setClientQR,
};

function setStore(sessionid, storeid) {
    return dispatch => {
        dispatch(request());

        clientService.setStore(sessionid, storeid)
            .then(
                store => dispatch(success(store.data)),
                error => {
                    dispatch(failure(error.toString()))
                    // history.push('/default404');
                }
            );
    };

    function request() { return { type: clientConstants.SETSTORE_REQUEST } }
    function success(store) { return { type: clientConstants.SETSTORE_SUCCESS, store } }
    function failure(error) { return { type: clientConstants.SETSTORE_FAILURE, error } }
}

function setClientLog(data) {
    return dispatch => {
        dispatch(request(data));

        clientService.setClientLog(data)
            .then(
                (logs) => {
                    dispatch(success(logs.data));
                },
                error => {
                    dispatch(failure(error.toString()));
                    // dispatch(alertActions.error(error.toString()));
                    // if (lasturl)
                    // localStorage.setItem('lasturl', lasturl);
                }
            );
    };

    function request() { return { type: clientConstants.SETLOGS_REQUEST } }
    function success(logs) { return { type: clientConstants.SETLOGS_SUCCESS, logs } }
    function failure(error) { return { type: clientConstants.SETLOGS_FAILURE, error } }
}

function setClientQR(data) {
    return dispatch => {
        dispatch(request(data));

        clientService.setClientQR(data)
            .then(
                () => {
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() { return { type: clientConstants.SETLOGS_REQUEST } }
    function failure(error) { return { type: clientConstants.SETLOGS_FAILURE, error } }
}

function register(client) {
    return dispatch => {
        dispatch(request(client));

        clientService.register(client)
            .then(
                // eslint-disable-next-line no-unused-vars
                client => {
                    dispatch(success());
                    dispatch(alertActions.success('Cliente registrado exitosamente'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                    history.push('/login');
                }
            );
    };

    function request(client) { return { type: clientConstants.REGISTER_REQUEST, client } }
    function success(client) { return { type: clientConstants.REGISTER_SUCCESS, client } }
    function failure(error) { return { type: clientConstants.REGISTER_FAILURE, error } }
}

function getById(id, userId) {
    return dispatch => {
        dispatch(request());

        clientService.getById(id, userId)
            .then(
                client => dispatch(success(client)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: clientConstants.GETBYID_REQUEST } }
    function success(client) { return { type: clientConstants.GETBYID_SUCCESS, client } }
    function failure(error) { return { type: clientConstants.GETBYID_FAILURE, error } }
}

function getAll(lasturl) {
    return dispatch => {
        dispatch(request());

        clientService.getAll()
            .then(
                clients => dispatch(success(clients.data)),
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                    if (lasturl)
                        localStorage.setItem('lasturl', lasturl);
                    history.push('/login');
                }
            );
    };

    function request() { return { type: clientConstants.GETALL_REQUEST } }
    function success(clients) { return { type: clientConstants.GETALL_SUCCESS, clients } }
    function failure(error) { return { type: clientConstants.GETALL_FAILURE, error } }
}