/* eslint-disable react/jsx-no-bind */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    Grid
} from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import Appbar from '../_components/Appbar';
import ResponsiveTable from '../_components/Table';
import Filters from '../_components/Filters';
import Pagination from '@mui/material/Pagination';

// eslint-disable-next-line no-unused-vars
const styles = theme => ({
    table: {
        overflow: 'auto',
    }
});


class ClientList extends React.Component {
    static propTypes = {
        classes: PropTypes.object.isRequired,
        clients: PropTypes.oneOfType([
            PropTypes.array,
            PropTypes.object
        ]),
        history: PropTypes.object,
        setWidget: PropTypes.func,
        setCurrentClient: PropTypes.func,
        user: PropTypes.object,
        getData: PropTypes.func,
    };

    state = {
        clientList: [],
        updatedList: [],
        pagelist: [],
        npages: 0,
        npage: 0,
    };

    onBack = event => {
        event.preventDefault();
        this.props.history.push('/home');
    }

    onBack = event => {
        event.preventDefault();
        this.props.history.push('/home');
    }

    handleTableClick = (event) => {
        this.props.setCurrentClient(event);
        this.props.history.push('/clientdetail');
    }

    updateList = (list) => {
        this.setPages(list);
        this.setState({
            updatedList: list,
        })
    }

    // eslint-disable-next-line no-unused-vars
    handlePageChange = (event, page) => {
        const nitems = 15;
        this.setState({
            pagelist: this.state.updatedList.slice(nitems * (page - 1), nitems * page),
            npage: page
        });
    }

    setPages = (list) => {
        const nitems = 15;
        this.setState({
            pagelist: list.slice(0, 15),
            npages: Math.ceil(list.length / nitems),
            npage: 1
        });
    }

    render() {
        const { classes } = this.props;
        const { clientList, updatedList, pagelist, npages, npage } = this.state;
        let titulo = "MIS CLIENTES";

        return (
            <React.Fragment>
                <Appbar back={true} label={titulo} user={this.props.user} onBack={this.onBack}
                    update={() => {
                        this.props.getData(['clients'])
                        ++this.childKey;
                    }} />
                <Filters
                    key={this.childKey}
                    label='Filtros'
                    pantalla='Clientes'
                    exportXls={true}
                    reset={true}
                    fullWidth={true}
                    fieldList={[
                        {
                            label: 'EMAIL',
                            text: true,
                            field: 'email'
                        },
                        {
                            label: 'NOMBRE',
                            text: true,
                            field: 'first_name'
                        },
                        {
                            label: 'APELLIDO',
                            text: true,
                            field: 'last_name'
                        },
                        {
                            label: 'TELEFONO',
                            text: true,
                            field: 'phone'
                        },
                        {
                            label: 'REGISTRADO',
                            date: true,
                            field: 'first_seen'
                        },
                        {
                            label: 'ULTIMO INGRESO',
                            date: true,
                            field: 'last_seen'
                        },
                    ]}
                    list={clientList}
                    filteredList={updatedList}
                    updateList={this.updateList}
                />
                <Grid className={classes.table}>
                    <ResponsiveTable
                        headCells={[
                            {
                                id: 'email',
                                label: 'EMAIL'
                            },
                            {
                                id: 'first_name',
                                label: 'NOMBRE'
                            },
                            {
                                id: 'last_name',
                                label: 'APELLIDO'
                            },
                            {
                                id: 'phone',
                                label: 'TELEFONO'
                            },
                            {
                                id: 'surname',
                                label: 'SEG NOMBRE'
                            },
                            {
                                id: 'first_seen',
                                label: 'REGISTRADO',
                                type: 'date'
                            },
                            {
                                id: 'last_seen',
                                label: 'ULTIMO INGRESO',
                                type: 'date'
                            },
                            {
                                id: 'id',
                                label: 'ID'
                            }
                        ]}
                        rows={pagelist}
                        handleSelectRow={this.handleTableClick}
                    />
                </Grid >
                <Pagination
                    count={npages}
                    // size="large"
                    // variant="outlined"
                    shape="rounded"
                    page={npage}
                    onChange={this.handlePageChange}
                    color="primary"
                    sx={{ padding: '10px' }}
                />
                < div ref={el => (this.instance = el)} />
            </React.Fragment >
        );
    }
    componentDidMount() {
        if (window.location.href.startsWith('https://arturito.chat') || window.location.href.startsWith('https://arturito.store')) {
            //tracking script from ActiveCampaign
            const s = document.createElement('script');
            s.type = 'text/javascript';
            s.async = true;
            s.innerHTML = '(function(e,t,o,n,p,r,i){e.visitorGlobalObjectAlias=n;e[e.visitorGlobalObjectAlias]=e[e.visitorGlobalObjectAlias]||function(){(e[e.visitorGlobalObjectAlias].q=e[e.visitorGlobalObjectAlias].q||[]).push(arguments)};e[e.visitorGlobalObjectAlias].l=(new Date).getTime();r=t.createElement("script");r.src=o;r.async=true;i=t.getElementsByTagName("script")[0];i.parentNode.insertBefore(r,i)})(window,document,"https://diffuser-cdn.app-us1.com/diffuser/diffuser.js","vgo");vgo("setAccount", "476725427");vgo("setTrackByDefault", true);vgo("process");';
            this.instance.appendChild(s);
        }

        const { user } = this.props;

        if (!user || !this.props.setWidget) {
            console.log('Error user or session, redirecting')
            localStorage.setItem('lasturl', `/clientlist`);
            this.props.history.push('/login');
            return null;
        }

        if (this.props.clients && !this.props.clients.clients)
            this.props.getData(['clients']);
        else {
            this.setPages(this.props.clients.clients);
            // eslint-disable-next-line react/no-did-mount-set-state
            this.setState({
                clientList: this.props.clients.clients,
                updatedList: this.props.clients.clients,
            });
        }

        this.props.setWidget(true, false);
        const queryParams = new URLSearchParams(window.location.search)
        const client = queryParams.get("client")

        if (client) {
            // eslint-disable-next-line react/no-did-mount-set-state
            setTimeout(() => {
                var result = this.props.clients.clients.find(obj => {
                    return obj.id === client;
                })
                this.handleTableClick(result);
            }, 500);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if ((prevProps.clients !== this.props.clients) && (this.props.clients && this.props.clients.clients)) {
            this.setPages(this.props.clients.clients);
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({
                clientList: this.props.clients.clients,
                updatedList: this.props.clients.clients,
            });
        }
    }

    componentWillUnmount() {
        localStorage.setItem('lasturl', `/clientlist`);
    }
}

function mapStateToProps(state) {
    const { user } = state.authentication;
    const { clients } = state;

    return {
        user,
        clients
    };
}

export default connect(mapStateToProps)(withStyles(styles)(ClientList));